import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Slider from 'react-slick'

import { CompareColumn as Col } from '../../components/compare-page-helpers'
import CompareListingCard from '../../components/compare-listing-card'
import CompareBestFor from '../../components/compare-best-for'
import CompareSpaces from './compare-spaces'
import Amenities from '../../components/compare-amenities'
import CompareAddSpaces from '../../components/compare-add-spaces'
import { openCtaActionsModal, openIframeModal } from '../../actions/modal'

import { openInteractiveTourModal } from '../../actions/modal'
import { highlightMarker, highlightMarkerReset } from '../../actions/ui'

import getAllAmenities from '../../utils/amenities'
import { trackVirtualTourModal } from '../../utils/analytics'
import { ROOT } from '../../settings/routes'

const SlideColumn = props => {
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(false)
  const {
    listings,
    compareCardsHeight,
    searchFilterQueryUrl,
    setCompareCardsHeight,
    spaceByListingId,
    allowAdding,
    onRemoveSaved,
    spaces,
    isProposal
  } = props
  const map = useSelector(state => state.reviews.map)
  const highlightMarkerId = useSelector(state => state.ui.highlightMarkerId)
  const visitorId = useSelector(state => state.auth.visitorId)

  return (
    map && (
      <Slider
        slidesToShow={3.2}
        slidesToScroll={3}
        infinite={false}
        className="CompareSlider"
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2.2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1.2,
              slidesToScroll: 1
            }
          }
        ]}
      >
        {listings.map(listing => (
          <Col key={listing.id}>
            <CompareListingCard
              key={listing.id}
              onVisitSpace={() => {
                if (isProposal && listing.bookingCalendar) {
                  dispatch(
                    openIframeModal({
                      src: listing.bookingCalendar,
                      title: 'Booking Calendar',
                      width: '900px',
                      height: '815px'
                    })
                  );
                } else {
                  dispatch(
                    openCtaActionsModal({
                      heading: 'Visit This Space',
                      buttonName: 'Visit This Space',
                      fields: [
                        'firstName',
                        'email',
                        'seats',
                        'phoneNumber',
                        'preferredTourDate',
                        'moveinDate'
                      ],
                      initialValues: {
                        locations: [listing.id],
                        visitorId,
                        isWorkspace: [false],
                        form: 'visit-this-space'
                      },
                      successText:
                        'Thanks for booking your tour through Upsuite! We will be in touch shortly!'
                    })
                  )
                }
              }}
              onRequestInfo={() => {
                dispatch(
                  openCtaActionsModal({
                    heading: 'Request More Info',
                    fields: [
                      'requestInfo',
                      'firstName',
                      'email',
                      'seats',
                      'phoneNumber'
                    ],
                    initialValues: {
                      locations: [listing.id],
                      visitorId,
                      isWorkspace: [false],
                      form: 'request-more-info'
                    },
                    isWorkspace: false,
                    successText:
                      'Thanks for reaching out! We will get back to you quickly regarding your inquiry!'
                  })
                )
              }}
              onRemoveSaved={() => onRemoveSaved(listing.id)}
              onMount={() => {}}
              onVirtualTourClick={url => {
                trackVirtualTourModal(
                  `/${ROOT}/${listing.marketName}/${listing.marketplaceSlug}/`
                )
                dispatch(
                  openInteractiveTourModal({
                    tourUrl: url,
                    initialValues: {
                      locations: [listing.id],
                      isWorkspace: [false],
                      visitorId
                    }
                  })
                )
              }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...listing}
              onMouseEnter={() => dispatch(highlightMarker(listing.id))}
              onMouseLeave={() => dispatch(highlightMarkerReset())}
              heightOnMount={height => setCompareCardsHeight(height)}
              highlighted={highlightMarkerId === listing.id}
            />
            <CompareSpaces
              spaces={spaces[listing.id]}
              isProposal={isProposal}
              activeId={spaceByListingId[listing.id]}
              onSpaceChanged={(id) => {
                props.setSpaceByListingId({
                  ...spaceByListingId,
                  [listing.id]: id
                })
              }}
            />

            <CompareBestFor accolades={listing.accolades} />

            <Amenities
              expanded={expanded}
              amenities={getAllAmenities(listing)}
              onExpand={() => setExpanded(!expanded)}
              locationId={listing.id}
              cleaningPoliciesPDFLink={listing.cleaningPoliciesPDFLinkId}
            />
          </Col>
        ))}
        {allowAdding && (
          <Col key="addSpaces">
            <CompareAddSpaces
              link={searchFilterQueryUrl}
              label="Add Another Space"
              style={{ minHeight: `${compareCardsHeight + 4}px` }}
            />
          </Col>
        )}
      </Slider>
    )
  )
}

export default SlideColumn
