import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';

import { ClusterMarkerMainMap as Cluster } from '../../components/cluster-marker';

import marketsGet from '../../actions/markets';
import { getLocCount } from '../../actions/main-map';
import {
  MAPS_API_KEY as mapsKey,
} from '../../settings';

import {
  createMainMapOptions,
} from '../../utils/map-helpers';
import { ROOT } from '../../settings/routes';

const Map = (props) => {
  const dispatch = useDispatch();
  const marketsWithCount = useSelector(state => state.mainMap.isCoworking);
  const markets = useSelector(state => state.markets.list) || [];
  const clusters = (markets || []).filter(market => market.centerLat && market.centerLon)
    .map(market => {
      const numPoints = ((marketsWithCount || []).find(item => item.id === market.id) || {}).no_coworking

      return ({
        lat: market.centerLat,
        lng: market.centerLon,
        numPoints,
        id: market.id,
        marketSlug: market.marketSlug,
      })
    })

  useEffect(() => {
    dispatch(getLocCount('isCoworking'));
    dispatch(marketsGet());
  }, [dispatch]);

  return (
    <div style={props.style} className="MainMap">
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapsKey }}
        defaultCenter={{ lat: 41.08013, lng: -98.20684 }}
        defaultZoom={props.zoom}
        options={createMainMapOptions}
        yesIWantToUseGoogleMapApiInternals
      >
        {Boolean(marketsWithCount) && Boolean(markets) &&
          clusters.map(cluster => (
            <Cluster
              lat={cluster.lat}
              lng={cluster.lng}
              numPoints={cluster.numPoints}
              key={cluster.id}
              href={cluster.marketSlug && `/${ROOT}/${cluster.marketSlug}/`}
            />
          ))}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
