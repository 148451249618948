import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

import { ModalBody } from '../../components/modal';
import Heading from '../../components/heading';
import CtaForm from '../../components/cta-form';
import Spinner from '../../components/spinner';

import { setCookie } from '../../utils/cookies';
import { registerLead } from '../../actions/auth';

const CTAModal = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.lead.loading);
  const failure = useSelector(state => state.lead.failure);
  const [success, onSuccess] = useState(false);
  const { heading, initialValues, buttonName, fields, successText } = props;
  const savedValues = {
    firstName: useSelector(state => state.lead.firstName),
    email: useSelector(state => state.lead.email),
    seats: useSelector(state => state.lead.seats),
    phoneNumber: useSelector(state => state.lead.phoneNumber),
    preferredTourDate: useSelector(state => state.lead.preferredTourDate),
    preferredTourTime: useSelector(state => state.lead.preferredTourTime),
    moveinDate: useSelector(state => state.lead.moveinDate),
  };
  const enabledFields = reduce(fields, (result, value) => {
    if (!savedValues[value]) {
      // eslint-disable-next-line no-param-reassign
      result[value] = true;
    }

    return result;
  }, {});
  const formIsNotRequired = isEmpty(enabledFields);
  const resultValues = {
    ...initialValues,
    ...savedValues,
  };


  useEffect(() => {
    if (formIsNotRequired) {
      dispatch(registerLead({ ...resultValues }))
        .then((data) => {
          if (data) {
            setCookie('_REG_TEMP_COMPLETED', true, 365);
            onSuccess(true);
          }
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formIsNotRequired]);

  return (
    <ModalBody small withHeight>
      <Heading level="2" size="small" center>{heading}</Heading>

      {loading &&
        <Spinner size="medium" inline boxed />
      }

      {!loading && failure &&
        <p style={{ textAlign: 'center', color: 'red' }}>There was a problem with form submit</p>
      }

      {success && successText &&
        <p style={{ textAlign: 'center' }}>{successText}</p>
      }

      {!success && !formIsNotRequired &&
        <CtaForm
          initialValues={resultValues}
          buttonName={buttonName}
          fields={enabledFields}
          onSubmit={val => dispatch(registerLead({ ...val }))
            .then((data) => {
              if (data) {
                setCookie('_REG_TEMP_COMPLETED', true, 365);
                onSuccess(true);
              }
            })
          }
        />
      }
    </ModalBody>
  );
};

export default CTAModal;
