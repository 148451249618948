import React from 'react'
import { CompareColumn as Col } from '../../components/compare-page-helpers'
import CompareMap from './compare-map'
import CompareTable from '../../components/compare-table'

const LeftColumn = ({ compareListings, compareCardsHeight, isProposal }) => {
  return (
    <Col>
      <CompareMap compareCardsHeight={compareCardsHeight} listings={compareListings} />
      <CompareTable
        heading="Office/Suite"
        items={
          isProposal
            ? [
                'People',
                'Base Price',
                'Proposed Price',
                'Upsuite Price',
                'Size'
              ]
            : ['People', 'Price', 'Size']
        }
      />
      <CompareTable heading="Best For" />
      <CompareTable heading="Amenities" />
    </Col>
  )
}

export default LeftColumn
