import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import usePrevious from '../../hooks/use-previous';
import { ModalBody } from '../../components/modal';
import Heading from '../../components/heading';
import '../../components/register-form-steps/styles.scss';
import Flex from '../../components/flex';
import { CloseModal } from '../../components/icons';
import ListingCardObservable from '../../components/listing-card-observable';
import Spinner from '../../components/spinner';

import { hideModal } from '../../actions/modal';
import toggleFavorites from '../../actions/favorites';
import { closestLocationsGet } from '../../api/listings';
import { checkHttpStatus, parseJSON } from '../../utils';

const ClosestLocationsModal = props => {
  const dispatch = useDispatch();
  const { id, isWorkspace } = props;
  const { pathname } = useLocation();
  const distanceUnits = useSelector(state => state.location.distanceUnits);
  const favorites = useSelector(state => state.filterQuery.is_workplace ? state.favorites.workplaceIds : state.favorites.ids);
  const [list, setList] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const prevPath = usePrevious(pathname);

  useEffect(() => {
    setLoading(true);
    setError(null);
    closestLocationsGet(id, isWorkspace)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        setLoading(false);
        setList(response?.list);
      })
      .catch(() => {
        setLoading(false);
        setError('Error getting closest locations');
      });
  }, [id, isWorkspace]);

  useEffect(() => {
    if (prevPath && pathname !== prevPath) {
      dispatch(hideModal())
    }
  }, [prevPath, pathname, dispatch])

  return (
    <ModalBody small withHeight closestLoc>
      <button
        className="Modal-icon -close"
        type="button"
        onClick={() => dispatch(hideModal())}
      >
        <CloseModal />
      </button>
      <Heading level="3" size="xsmall" center>
        View 3 Closest Locations
      </Heading>
      <Flex columnMobile>
        {loading && !error && (
          <Spinner size="medium" center />
        )}
        {!loading && error && (
          <StyledError>{error}</StyledError>
        )}
        {list.map(location => (
          <ListingCardObservable
            toggleFavorites={() => dispatch(toggleFavorites(location.id, isWorkspace))}
            isFavorite={favorites && favorites.includes(location.id)}
            key={location.id}
            isWorkplace={isWorkspace}
            distanceUnits={distanceUnits}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...location}
          />
        ))}
      </Flex>
    </ModalBody>
  );
};

const StyledError = styled.span`
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: red;
`

export default ClosestLocationsModal;
