import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import throttle from 'lodash/throttle'
import Verified from '../verified'
import Tooltip from '../tooltip'
import { VirtualTourButton } from '../virtual-tour-button'
import HealthyFlag, { HealthyFlagContainer } from '../healthy-space-flag'

import { formatMeta, formatPriceRange } from '../../utils/format'
import { Close } from '../icons'

import {
  SINGLE_LISTING_BASE_ROUTE as singleRoute,
  SINGLE_LISTING_WORKPLACE_BASE_ROUTE as workspaceRoute
} from '../../settings'
import {
  verifiedText,
  verifiedNewText,
  healthySpaceText
} from '../../settings/text'
import { imageUrl } from '../../utils/crm-links'

import '../listing-card/styles.scss'

class CompareListingCard extends Component {
  constructor() {
    super()
    this.myRef = createRef()
    this.handleResize = throttle(this.handleResize.bind(this), 1000)
  }

  componentDidMount() {
    const { onMount, heightOnMount, mobile } = this.props
    onMount()

    if (!mobile) {
      heightOnMount(this.myRef.current.clientHeight)
      window.addEventListener('resize', this.handleResize)
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.rating !== this.props.rating && !this.props.mobile) {
      this.props.heightOnMount(this.myRef.current.clientHeight)
    }
  }

  componentWillUnmount() {
    if (!this.props.mobile) {
      window.removeEventListener('resize', this.handleResize)
    }
  }

  handleResize = () => {
    if (this.myRef.current) {
      this.props.heightOnMount(this.myRef.current.clientHeight)
    }
  }

  render() {
    const {
      id,
      name,
      capacityMin,
      capacityMax,
      isHealthy,
      neighborhoodName,
      spaces,
      observable,
      removeFavorites,
      marketplaceSlug,
      marketSlug,
      onMouseEnter,
      onMouseLeave,
      highlighted,
      essenceStatementNoBranding,
      essenceStatement,
      style,
      upsuiteVerified,
      disableVerifiedLabel,
      listingCardImageId,
      listingCardAltText,
      newLocation,
      onFirstClick,
      onVirtualTourClick,
      virtualTour,
      mobile,
      isWorkspace,
      onRemoveSaved,
      onVisitSpace,
      onRequestInfo
    } = this.props

    const boxClasses = classnames('ListingCard-box -compare', {
      '-highlighted': highlighted
    })

    const wrapClasses = classnames({ 'ListingCard-wrap': !observable })

    const metaPrefix = formatMeta(capacityMin, capacityMax, neighborhoodName)
    const priceRange = formatPriceRange(spaces)
    const listingMeta =
      metaPrefix && priceRange ? `${metaPrefix} • ${priceRange}` : metaPrefix
    const statement = isWorkspace
      ? essenceStatement
      : essenceStatementNoBranding

    return (
      <div
        style={{
          display: 'flex',
          marginBottom: 36,
          flexGrow: 1
        }}
      >
        <div id={id} className={wrapClasses} ref={this.myRef} style={style}>
          <div className={boxClasses}>
            <div className="ListingCard-header">
              <img
                className="ListingCard-image"
                src={imageUrl(listingCardImageId)}
                alt={listingCardAltText}
              />
              {marketSlug && (
                <Link
                  className="ListingCard-link"
                  aria-label={name}
                  to={`/${
                    isWorkspace ? workspaceRoute : singleRoute
                  }/${marketSlug}/${marketplaceSlug}/`}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
              )}
              {onVirtualTourClick && virtualTour && (
                <VirtualTourButton
                  small
                  tiny={mobile}
                  onClick={() => onVirtualTourClick(virtualTour)}
                />
              )}
              {onFirstClick && (
                <button
                  className="ListingCard-link"
                  onClick={onFirstClick}
                  type="button"
                />
              )}
              {!onFirstClick && marketSlug && (
                <Link
                  className="ListingCard-link"
                  aria-label={name}
                  to={`/${
                    isWorkspace ? workspaceRoute : singleRoute
                  }/${marketSlug}/${marketplaceSlug}/`}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
              )}
              {upsuiteVerified && !disableVerifiedLabel && (
                <Tooltip overlay={newLocation ? verifiedNewText : verifiedText}>
                  <div className="ListingCard-verified -compare">
                    <Verified size="small" newLocation={newLocation} />
                  </div>
                </Tooltip>
              )}
              {isHealthy && (
                <Tooltip placement="bottomLeft" overlay={healthySpaceText}>
                  <HealthyFlagContainer
                    upsuiteVerified={upsuiteVerified}
                    small
                    compare
                    isNew={newLocation}
                  >
                    <HealthyFlag upsuiteVerified={upsuiteVerified} small />
                  </HealthyFlagContainer>
                </Tooltip>
              )}
              {removeFavorites && (
                <button
                  type="button"
                  className="ListingCard-remove"
                  onClick={removeFavorites}
                >
                  <span>
                    <Close />
                    Remove
                  </span>
                </button>
              )}
              {onVisitSpace && (
                <button
                  type="button"
                  className="ListingCard-actionGroup -visit"
                  onClick={onVisitSpace}
                >
                  <span>
                    Visit This Space
                  </span>
                </button>
              )}
              {onRequestInfo && (
                <button
                  type="button"
                  className="ListingCard-actionGroup -request"
                  onClick={onRequestInfo}
                >
                  <span>
                    Request Info
                  </span>
                </button>
              )}
              {onRemoveSaved && (
                <button
                  type="button"
                  className="ListingCard-actionGroup -remove"
                  onClick={onRemoveSaved}
                >
                  <span>
                    Remove
                  </span>
                </button>
              )}
            </div>
            <div className="ListingCard-footer">
              <div className="ListingCard-col -top">
                <div>
                  <div className="ListingCard-heading">
                    <h3>{name}</h3>
                  </div>
                  <span className="ListingCard-subheading">
                    {statement || listingMeta}
                  </span>
                </div>
              </div>
              <div className="ListingCard-col -end">
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CompareListingCard.propTypes = {
  onMount: PropTypes.func,
  heightOnMount: PropTypes.func
}

CompareListingCard.defaultProps = {
  onMount: () => {},
  heightOnMount: () => {}
}

export default CompareListingCard
