import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const ButtonGroup = (props) => {
  const {
    fullWidth,
    gutter,
    justify,
    children,
    style,
    className,
    column,
    wrap,
  } = props;

  const buttonGroupClass = classNames(
    className,
    'ButtonGroup',
    `-${gutter}`,
    `-${justify}`, {
    '-fullWidth': fullWidth,
    '-column': column,
    '-wrap': wrap,
  });

  return (
    <div
      style={style}
      className={buttonGroupClass}
    >
      {children}
    </div>
  );
};

ButtonGroup.defaultProps = {
  gutter: 'none',
  justify: 'center',
  fullWidth: true,
  column: true,
};

export default ButtonGroup;
