export const dataByMarket = {
  'kansas-city': {
    name: 'Kansas City',
    locCount: 30,
    pricePerPerson: 266.6,
    spacesCount: 690,
    popularLocations: [{
      name: 'Power and Light District',
      link: '/coworking/search/?s=Power%20and%20Light%20District,%20Kansas%20City,%20MO,%20USA'
    }, {
      name: 'Downtown',
      link: '/coworking/search/?s=Downtown%20Kansas%20City,%20Kansas%20City,%20MO,%20USA'
    }, {
      name: 'Financial District',
      link: '/coworking/search/?s=Financial%20District,%20Kansas%20City,%20MO,%20USA'
    }],
    blogPost: 'https://www.upsuite.com/blog/best-coworking-spaces-in-kansas-city/',
    bestOf: [{
      name: 'WeWork',
      logo: 'https://www.upsuite.com/wp-content/uploads/2019/03/wework.png',
      bestFor: 'Bright'
    }, {
      name: 'Expansive Workspace',
      logo: 'https://www.upsuite.com/wp-content/uploads/2021/05/expansive-logo-branded.png',
      bestFor: 'Productivity'
    }, {
      name: 'Serendipity Labs',
      logo: 'https://www.upsuite.com/wp-content/uploads/2019/03/serendipity.png',
      bestFor: 'Small Teams'
    }]
  },
  'seattle': {
    name: 'Seattle',
    locCount: 76,
    pricePerPerson: 409.1,
    spacesCount: 2711,
    popularLocations: [{
      name: 'Downtown Seattle',
      link: '/coworking/search/?s=Downtown%20Seattle,%20Seattle,%20WA,%20USA'
    }, {
      name: 'Pioneer Square',
      link: '/coworking/search/?s=Pioneer%20Square,%20Seattle,%20WA,%20USA'
    }, {
      name: 'Belltown',
      link: '/coworking/search/?s=Belltown,%20Seattle,%20WA,%20USA'
    }],
    blogPost: 'https://www.upsuite.com/blog/the-10-best-coworking-spaces-in-seattle/',
    bestOf: [{
      name: 'The Pioneer Collective',
      logo: 'https://www.upsuite.com/wp-content/uploads/2021/06/image-20210624-181510.png',
      bestFor: 'Solo Entrepreneurs'
    }, {
      name: 'Galvanize',
      logo: 'https://www.upsuite.com/wp-content/uploads/2019/03/galvanize.png',
      bestFor: 'Tech Teams'
    }, {
      name: 'WeWork',
      logo: 'https://www.upsuite.com/wp-content/uploads/2019/03/wework.png',
      bestFor: 'High-Energy'
    }]
  },
  austin: {
    name: 'Austin',
    locCount: 60,
    pricePerPerson: 514.1,
    spacesCount: 2537,
    popularLocations: [{
      name: 'Downtown Austin',
      link: '/coworking/search/?s=Downtown%20Austin,%20Austin,%20TX,%20USA'
    }, {
      name: 'Georgetown',
      link: '/coworking/search/?s=Georgetown,%20Bowie%20Street,%20Austin,%20TX,%20USA'
    }, {
      name: 'East Austin',
      link: '/coworking/search/?s=East%20Austin,%20Austin,%20TX,%20USA'
    }],
    blogPost: 'https://www.upsuite.com/blog/best-coworking-spaces-in-austin/',
    bestOf: [{
      name: 'Common Desk',
      logo: 'https://www.upsuite.com/wp-content/uploads/2021/06/common-desk-logo-e1625046018300.png',
      bestFor: 'Interior Design'
    }, {
      name: 'Industrious',
      logo: 'https://www.upsuite.com/wp-content/uploads/2021/07/industrious-e1626174468135.png',
      bestFor: 'Energy'
    }, {
      name: 'WeWork',
      logo: 'https://www.upsuite.com/wp-content/uploads/2019/03/wework.png',
      bestFor: '1GB+ WiFi'
    }]
  },
  philadelphia: {
    name: 'Philadelphia',
    locCount: 74,
    pricePerPerson: 351.1,
    spacesCount: 1680,
    popularLocations: [{
      name: 'Center City',
      link: '/coworking/search/?s=Center%20City,%20Philadelphia,%20PA,%20USA'
    }, {
      name: 'Center City West',
      link: '/coworking/search/?s=Center%20City%20West,%20Philadelphia,%20PA,%20USA'
    }, {
      name: 'Logan Square',
      link: '/coworking/search/?s=Logan%20Square,%20Philadelphia,%20PA,%20USA'
    }],
    blogPost: 'https://www.upsuite.com/blog/best-coworking-spaces-in-philadelphia/',
    bestOf: [{
      name: 'Spaces',
      logo: 'https://www.upsuite.com/wp-content/uploads/2019/03/Spaces.-Logo.png',
      bestFor: 'Interior'
    }, {
      name: 'Convene',
      logo: 'https://www.upsuite.com/wp-content/uploads/2019/04/convene-coworking-250x250.png',
      bestFor: 'Luxurious'
    }, {
      name: 'Regus',
      logo: 'https://www.upsuite.com/wp-content/uploads/2019/03/regus-250x250.png',
      bestFor: 'Entrepreneurs'
    }]
  },
  portland: {
    name: 'Portland',
    locCount: 45,
    pricePerPerson: 359.4,
    spacesCount: 922,
    popularLocations: [{
      name: 'Downtown Portland',
      link: '/coworking/search/?s=Downtown%20Portland,%20Portland,%20OR,%20USA'
    }, {
      name: 'Lloyd District',
      link: '/coworking/search/?s=Lloyd%20District,%20Portland,%20OR,%20USA'
    }, {
      name: 'Beaverton',
      link: '/coworking/search/?s=Southwest%20Beaverton%20Court,%20Portland,%20OR,%20USA'
    }, {
      name: 'Lake Oswego',
      link: '/coworking/search/?s=Lake%20Oswego,%20OR,%20USA'
    }],
    blogPost: 'https://www.upsuite.com/blog/best-coworking-spaces-in-portland/',
    bestOf: [{
      name: 'Industrious',
      logo: 'https://www.upsuite.com/wp-content/uploads/2021/07/industrious-e1626174468135.png',
      bestFor: 'Amenities'
    }, {
      name: 'Spaces',
      logo: 'https://www.upsuite.com/wp-content/uploads/2019/03/Spaces.-Logo.png',
      bestFor: 'Bright'
    }, {
      name: 'CENTRL Office',
      logo: 'https://www.upsuite.com/wp-content/uploads/2021/06/image-20210624-181422.png',
      bestFor: 'Productivity'
    }]
  },
}
