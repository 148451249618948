import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import times from 'lodash/times';
import uniqueId from 'lodash/uniqueId';

import './styles.scss';

const StarRating = (props) => {
  const {
    rating,
    stars,
    defaultColor,
    color,
    size,
    caption,
    spaceBelow,
    spaceAbove,
    captionPosition,
    captionStyle,
    fullWidth,
    border,
    className,
  } = props;

  const shift = 55;
  const stopId = uniqueId('stop_');
  const sanitizeRating = Math.floor(rating);
  const stopRating = rating % 1;
  const isPartial = stopRating > 0;
  const partial = Math.round(stopRating * 100);
  const getFill = (step) => {
    if (step < sanitizeRating) {
      return color;
    }

    if (step === sanitizeRating && isPartial) {
      return `url(#${stopId})`;
    }

    return defaultColor;
  };

  const wrapClasses = classnames(
    className,
    'StarRating',
    `-spaceBelow-${spaceBelow}`,
    `-spaceAbove-${spaceAbove}`,
    `-captionPosition-${captionPosition}`,
    { '-fullWidth': fullWidth },
    { '-border': border },
  );

  const svgClasses = classnames(
    'StarRating-graphics',
    `-size-${size}`,
  );

  const captionClasses = classnames(
    'StarRating-caption',
    `-size-${size}`,
    `-position-${captionPosition}`,
    `-captionStyle-${captionStyle}`,
  );

  return (
    <div className={wrapClasses}>
      {caption &&
        <span className={captionClasses}>{caption}</span>
      }
      <div className="StarRating-items">
        <svg
          className={svgClasses}
          viewBox="0 0 5 50"
          preserveAspectRatio="xMinYMin meet"
          version="1.1"
        >
          <g>
            {isPartial &&
              <defs>
                <linearGradient
                  id={stopId}
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="0%"
                >
                  <stop
                    offset="0%"
                    className="StarRating-stop-first"
                    stopColor={color}
                    stopOpacity={1}
                  />
                  <stop
                    offset={`${partial}%`}
                    className="StarRating-stop-first"
                    stopColor={color}
                    stopOpacity={1}
                  />
                  <stop
                    offset={`${partial}%`}
                    className="StarRating-stop-final"
                    stopColor={defaultColor}
                    stopOpacity={1}
                  />
                  <stop
                    offset="100%"
                    className="StarRating-stop-final"
                    stopColor={defaultColor}
                    stopOpacity={1}
                  />
                </linearGradient>
              </defs>
            }

            {times(stars).map(step => (
              <path
                key={`star-rating-${step}`}
                transform={`translate(${step * shift}, 0)`}
                fill={getFill(step)}
                d="m0,18.1l19.1,0l5.9,-18.1l5.9,18.1l19.1,0l-15.4,11.2l5.9,18.1l-15.4,-11.2l-15.4,11.2l5.9,-18.1l-15.4,-11.2l0,0z"
              />
            ))}
          </g>
        </svg>
      </div>
    </div>
  );
};

StarRating.defaultProps = {
  rating: 5,
  stars: 5,
  defaultColor: '#bbbfc5',
  color: '#ffc93d',
  size: 'small',
  spaceBelow: 'none',
  spaceAbove: 'none',
  caption: null,
  captionPosition: 'before',
  captionStyle: 'default',
};

StarRating.propTypes = {
  rating: PropTypes.number,
  stars: PropTypes.number,
  defaultColor: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  caption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  spaceBelow: PropTypes.string,
  spaceAbove: PropTypes.string,
  captionPosition: PropTypes.string,
  captionStyle: PropTypes.string,
};

export default StarRating;
