import isEmpty from 'lodash/isEmpty';
import {
  SEARCH_QUERY,
} from '../settings';
import { ROOT } from '../settings/routes';

export const getCoordsByLabel = (props, callback) => {
  const { filterQuery } = props;

  if (filterQuery[ROOT] || filterQuery[SEARCH_QUERY]) {
    props.listingsGetByAddress(filterQuery[ROOT] || filterQuery[SEARCH_QUERY], !filterQuery[SEARCH_QUERY])
      .then((response) => {
        if (response && !isEmpty(response.payload.list)) {
          callback(response.payload.list);
        }
      });
  }
};
