import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import ButtonMeta from '../button-meta';
import ButtonGroupMeta from '../button-group-meta';
import { BestOfLabel } from '../best-of';
import Verified from '../verified';
import { SliderArrow } from '../icons';
import AmenitiesList from '../amenities-workplace';
import {
  verifiedText,
  verifiedNewText,
  healthySpaceText,
} from '../../settings/text';
import HealthyFlag, { HealthyFlagContainer } from '../healthy-space-flag';
import { VirtualTourButton, VirtualTourCardWrap } from '../virtual-tour-button';

import Slider from '../slick-slider';

import { getAllWorkplaceAmenities } from '../../utils/amenities';
import getInteriorImages from '../../utils/listings';
import { imageUrl } from '../../utils/crm-links';

import Tooltip from '../tooltip';

import './styles.scss';

import {
  SINGLE_LISTING_BASE_ROUTE as singleRoute,
  SINGLE_LISTING_WORKPLACE_BASE_ROUTE as singleWokrplaceRoute,
} from '../../settings';

const NextArrow = props => {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick} type="button">
      <SliderArrow />
    </button>
  );
};

const PrevArrow = props => {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick} type="button">
      <SliderArrow />
    </button>
  );
};

const ListingCard = props => {
  const [sliderInit, setSliderInit] = useState(false);

  const sliderActive = () => {
    setSliderInit(true);
  };

  const {
    name,
    marketName,
    marketSlug,
    marketplaceSlug,
    isFavorite,
    toggleFavorites,
    isHighlighted,
    upsuiteVerified,
    observable,
    accolades,
    isHealthy,
    onMouseEnter,
    onMouseLeave,
    disableBestOfLabel,
    disableVerifiedLabel,
    essenceStatementNoBranding,
    essenceStatement,
    isEdit,
    distance,
    distanceUnits,
    minPrice,
    listingCardImageId,
    listingCardAltText,
    newLocation,
    onFirstClick,
    onVirtualTourClick,
    virtualTour,
    isWorkplace,
    minWorkplacePrice,
  } = props;

  const boxClasses = classnames('ListingCard-box', {
    '-highlighted': isHighlighted,
  });

  const wrapClasses = classnames({ 'ListingCard-wrap': !observable });

  const slideImages = [
    { value: listingCardImageId, alt: listingCardAltText },
    ...getInteriorImages(props),
  ];

  const settings = {
    dots: true,
    infinite: true,
    draggable: false,
    touchMove: false,
    // lazyLoad: true,
    onInit: sliderActive,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const locationDistance = (
    Math.ceil(distanceUnits === 'mi' ? distance / 1.609344 : distance) / 1000
  ).toFixed(1);
  const listingUrl = `/${
    isWorkplace ? singleWokrplaceRoute : singleRoute
  }/${marketSlug}/${marketplaceSlug}/`;
  const statement = isWorkplace ? essenceStatement : essenceStatementNoBranding;

  return (
    <div
      className={wrapClasses}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={boxClasses}>
        {virtualTour && onVirtualTourClick && (
          <VirtualTourCardWrap>
            <VirtualTourButton
              small
              card
              onClick={() => onVirtualTourClick(virtualTour)}
            />
          </VirtualTourCardWrap>
        )}
        {!isHighlighted && !sliderInit && (
          <div className="ListingCard-header">
            {props.isVisible &&
              <img
                className="ListingCard-image"
                src={imageUrl(listingCardImageId)}
                alt={listingCardAltText}
              />
            }
            {onFirstClick ? (
              <button
                className="ListingCard-link"
                onClick={onFirstClick}
                type="button"
              />
            ) : (
              <Link
                className="ListingCard-link"
                aria-label={name}
                to={listingUrl}
              />
            )}
          </div>
        )}
        {(isHighlighted || sliderInit) && (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Slider {...settings}>
            {slideImages.map(item => (
              <div key={item} className="ListingCard-slide">
                <img
                  className="ListingCard-image"
                  src={imageUrl(item.value)}
                  alt={item.alt}
                />
                {onFirstClick ? (
                  <button
                    className="ListingCard-link"
                    onClick={onFirstClick}
                    type="button"
                  />
                ) : (
                  <Link
                    className="ListingCard-link"
                    aria-label={name}
                    to={listingUrl}
                  />
                )}
              </div>
            ))}
          </Slider>
        )}
        {parseInt(minPrice, 10) > 0 && !isWorkplace && (
          <span className="ListingCard-priceBlock">
            From{' '}
            <span className="ListingCard-price">${parseInt(minPrice, 10)}</span>
            /mo/person
          </span>
        )}
        {parseInt(minWorkplacePrice, 10) > 0 && isWorkplace && (
          <span className="ListingCard-priceBlock">
            From{' '}
            <span className="ListingCard-price">
              ${parseInt(minWorkplacePrice, 10)}
            </span>
            /mo
          </span>
        )}
        {upsuiteVerified && !disableVerifiedLabel && (
          <Tooltip overlay={newLocation ? verifiedNewText : verifiedText}>
            <div className="ListingCard-verified">
              <Verified size="small" newLocation={newLocation} />
            </div>
          </Tooltip>
        )}
        {isHealthy && (
          <Tooltip placement="bottomLeft" overlay={healthySpaceText}>
            <HealthyFlagContainer
              upsuiteVerified={upsuiteVerified}
              small
              isNew={newLocation}
            >
              <HealthyFlag upsuiteVerified={upsuiteVerified} small />
            </HealthyFlagContainer>
          </Tooltip>
        )}
        {toggleFavorites && (
          <ButtonGroupMeta position="card">
            <ButtonMeta
              onClick={toggleFavorites}
              size="small"
              isActive={isFavorite}
              className="card-save-btn"
            >
              + Save
            </ButtonMeta>
          </ButtonGroupMeta>
        )}
        <div className="ListingCard-footer">
          <div className="ListingCard-col -top">
            <div>
              <div className="ListingCard-heading">
                <h3>{statement || name}</h3>
              </div>
            </div>
            {(disableBestOfLabel || !isEmpty(accolades)) && (
              <div className="ListingCard-helper" />
            )}
            {!isEmpty(accolades) && !disableBestOfLabel && (
              <BestOfLabel location={marketName} size="small" />
            )}
          </div>
          <div className="ListingCard-col -end">
            {isWorkplace && (
              <AmenitiesList amenities={getAllWorkplaceAmenities(props)} />
            )}
            {locationDistance && locationDistance > 0 && (
              <div className="ListingCard-distance">
                {`${locationDistance} ${distanceUnits || ''}`}
              </div>
            )}
            {marketplaceSlug &&
              (onFirstClick ? (
                <button
                  className="ListingCard-button"
                  onClick={onFirstClick}
                  type="button"
                >
                  {isEdit ? 'Edit' : 'Details'}
                </button>
              ) : (
                <Link className="ListingCard-button" to={listingUrl}>
                  {isEdit ? 'Edit' : 'Details'}
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

ListingCard.propTypes = {
  disableBestOfLabel: PropTypes.bool,
  disableVerifiedLabel: PropTypes.bool,
};

ListingCard.defaultProps = {
  disableBestOfLabel: false,
  disableVerifiedLabel: false,
};

export default ListingCard;
