// Listings
export const LISTINGS_GET_REQUEST = 'LISTINGS_GET_REQUEST';
export const LISTINGS_GET_SUCCESS = 'LISTINGS_GET_SUCCESS';
export const LISTINGS_GET_FAILURE = 'LISTINGS_GET_FAILURE';
export const SINGLE_LISTING_GET_REQUEST = 'SINGLE_LISTING_GET_REQUEST';
export const SINGLE_LISTING_GET_SUCCESS = 'SINGLE_LISTING_GET_SUCCESS';
export const SINGLE_LISTING_GET_FAILURE = 'SINGLE_LISTING_GET_FAILURE';
export const FILTER_QUERY_SET = 'FILTER_QUERY_SET';
export const FILTER_QUERY_RESET = 'FILTER_QUERY_RESET';
export const LISTINGS_NO_SPACES_REQUEST = 'LISTINGS_NO_SPACES_REQUEST';
export const LISTINGS_NO_SPACES_SUCCESS = 'LISTINGS_NO_SPACES_SUCCESS';
export const LISTINGS_NO_SPACES_FAILURE = 'LISTINGS_NO_SPACES_FAILURE';
export const SYNC_VISIBLE_MARKERS_WITH_LISTINGS = 'SYNC_VISIBLE_MARKERS_WITH_LISTINGS';
export const LISTINGS_RELATED_GET_REQUEST = 'LISTINGS_RELATED_GET_REQUEST';
export const LISTINGS_RELATED_GET_SUCCESS = 'LISTINGS_RELATED_GET_SUCCESS';
export const LISTINGS_RELATED_GET_FAILURE = 'LISTINGS_RELATED_GET_FAILURE';
export const LISTINGS_TOP_MARKET_GET_REQUEST = 'LISTINGS_TOP_MARKET_GET_REQUEST';
export const LISTINGS_TOP_MARKET_GET_SUCCESS = 'LISTINGS_TOP_MARKET_GET_SUCCESS';
export const LISTINGS_TOP_MARKET_GET_FAILURE = 'LISTINGS_TOP_MARKET_GET_FAILURE';
export const LISTINGS_FILTER_BY_SPACES = 'LISTINGS_FILTER_BY_SPACES';
export const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS';

// Markets
export const MARKETS_GET_REQUEST = 'MARKETS_GET_REQUEST';
export const MARKETS_GET_SUCCESS = 'MARKETS_GET_SUCCESS';
export const MARKETS_GET_FAILURE = 'MARKETS_GET_FAILURE';

// Neighborhood
export const SINGLE_HOOD_GET_REQUEST = 'SINGLE_HOOD_GET_REQUEST';
export const SINGLE_HOOD_GET_SUCCESS = 'SINGLE_HOOD_GET_SUCCESS';
export const SINGLE_HOOD_GET_FAILURE = 'SINGLE_HOOD_GET_FAILURE';
export const NEIGHBORHOODS_GET_REQUEST = 'NEIGHBORHOODS_GET_REQUEST';
export const NEIGHBORHOODS_GET_SUCCESS = 'NEIGHBORHOODS_GET_SUCCESS';
export const NEIGHBORHOODS_GET_FAILURE = 'NEIGHBORHOODS_GET_FAILURE';

// Brands
export const BRANDS_GET_REQUEST = 'BRANDS_GET_REQUEST';
export const BRANDS_GET_SUCCESS = 'BRANDS_GET_SUCCESS';
export const BRANDS_GET_FAILURE = 'BRANDS_GET_FAILURE';

// Spaces
export const SPACES_BY_LOC_ID_GET_REQUEST = 'SPACES_BY_LOC_ID_GET_REQUEST';
export const SPACES_BY_LOC_ID_GET_SUCCESS = 'SPACES_BY_LOC_ID_GET_SUCCESS';
export const SPACES_BY_LOC_ID_GET_FAILURE = 'SPACES_BY_LOC_ID_GET_FAILURE';
export const SPACES_GET_REQUEST = 'SPACES_GET_REQUEST';
export const SPACES_GET_SUCCESS = 'SPACES_GET_SUCCESS';
export const SPACES_GET_FAILURE = 'SPACES_GET_FAILURE';
export const SPACES_CREATE_REQUEST = 'SPACES_CREATE_REQUEST';
export const SPACES_CREATE_SUCCESS = 'SPACES_CREATE_SUCCESS';
export const SPACES_CREATE_FAILURE = 'SPACES_CREATE_FAILURE';
export const SPACES_UPDATE_REQUEST = 'SPACES_UPDATE_REQUEST';
export const SPACES_UPDATE_SUCCESS = 'SPACES_UPDATE_SUCCESS';
export const SPACES_UPDATE_FAILURE = 'SPACES_UPDATE_FAILURE';
export const SPACES_DELETE_REQUEST = 'SPACES_DELETE_REQUEST';
export const SPACES_DELETE_SUCCESS = 'SPACES_DELETE_SUCCESS';
export const SPACES_DELETE_FAILURE = 'SPACES_DELETE_FAILURE';
export const SPACES_CLEAR_ERROR_MESSAGE = 'SPACES_CLEAR_ERROR_MESSAGE';
export const SPACES_PROPOSAL_GET_REQUEST = 'SPACES_PROPOSAL_GET_REQUEST';
export const SPACES_PROPOSAL_GET_SUCCESS = 'SPACES_PROPOSAL_GET_SUCCESS';
export const SPACES_PROPOSAL_GET_FAILURE = 'SPACES_PROPOSAL_GET_FAILURE';

// UI
export const FIXED_HEADER_ON = 'FIXED_HEADER_ON';
export const FIXED_HEADER_OFF = 'FIXED_HEADER_OFF';
export const HIGHLIGHT_LISTING = 'HIGHLIGHT_LISTING';
export const HIGHLIGHT_LISTING_RESET = 'HIGHLIGHT_LISTING_RESET';
export const HIGHLIGHT_MARKER = 'HIGHLIGHT_MARKER';
export const HIGHLIGHT_MARKER_RESET = 'HIGHLIGHT_MARKER_RESET';
export const PIN_MARKER = 'PIN_MARKER';
export const PIN_MARKER_RESET = 'PIN_MARKER_RESET';
export const OPEN_MOBILE_MAIN_NAV = 'OPEN_MOBILE_MAIN_NAV';
export const CLOSE_MOBILE_MAIN_NAV = 'CLOSE_MOBILE_MAIN_NAV';
export const COMPARE_MOBILE_PAGINATE = 'COMPARE_MOBILE_PAGINATE';
export const COMPARE_SPACE_BY_LISTING = 'COMPARE_SPACE_BY_LISTING';
export const RESET_TRANSITION_COUNT = 'RESET_TRANSITION_COUNT';
export const BIND_SCROLL_TO = 'BIND_SCROLL_TO';
export const CHANGE_SORT_METHOD = 'CHANGE_SORT_METHOD';
export const MOBILE_FIXED_HEADER_ON = 'MOBILE_FIXED_HEADER_ON';
export const MOBILE_FIXED_HEADER_OFF = 'MOBILE_FIXED_HEADER_OFF';
export const SET_COMPARE_CARDS_HEIGHT = 'SET_COMPARE_CARDS_HEIGHT';
export const TOGGLE_FEED_MAP_VISIBILITY = 'TOGGLE_FEED_MAP_VISIBILITY';
export const TOGGLE_SPACE_TYPE = 'TOGGLE_SPACE_TYPE';
export const TOGGLE_SPACE_TYPE_RESET = 'TOGGLE_SPACE_TYPE_RESET';

// Location
export const LOCATION_ACTION_REQUEST = 'LOCATION_ACTION_REQUEST';
export const LOCATION_ACTION_SET_POSITION = 'LOCATION_ACTION_SET_POSITION';
export const LOCATION_ACTION_SET_ERROR = 'LOCATION_ACTION_SET_ERROR';
export const LOCATION_SET_NEAREST_MARKET = 'LOCATION_SET_NEAREST_MARKET';
export const SET_DISTANCE_UNITS = 'SET_DISTANCE_UNITS';
export const SET_CURRENT_ZOOM = 'SET_CURRENT_ZOOM';

// Favorites
export const TOGGLE_FAVORITES = 'TOGGLE_FAVORITES';
export const TOGGLE_WORKPLACE_FAVORITES = 'TOGGLE_WORKPLACE_FAVORITES';
export const BULK_SET_FAVORITES = 'BULK_SET_FAVORITES';
export const FAVORITES_GET_REQUEST = 'FAVORITES_GET_REQUEST';
export const FAVORITES_GET_SUCCESS = 'FAVORITES_GET_SUCCESS';
export const FAVORITES_GET_FAILURE = 'FAVORITES_GET_FAILURE';

// Modal
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL_BELOW = 'SHOW_MODAL_BELOW';

// Reviews
export const GAPIS_GET_REVIEWS_REQUEST = 'GAPIS_GET_REVIEWS_REQUEST';
export const GAPIS_GET_REVIEWS_SUCCESS = 'GAPIS_GET_REVIEWS_SUCCESS';
export const GAPIS_GET_REVIEWS_FAILURE = 'GAPIS_GET_REVIEWS_FAILURE';
export const CONCIERGE_REVIEWS_GET_REQUEST = 'CONCIERGE_REVIEWS_GET_REQUEST';
export const CONCIERGE_REVIEWS_GET_SUCCESS = 'CONCIERGE_REVIEWS_GET_SUCCESS';
export const CONCIERGE_REVIEWS_GET_FAILURE = 'CONCIERGE_REVIEWS_GET_FAILURE';
export const BIND_MAP_TO_STATE = 'BIND_MAP_TO_STATE';

// Auth
export const AUTH_IDENTITY = 'AUTH_IDENTITY';
export const COGNITO_AUTH_FAILURE = 'COGNITO_AUTH_FAILURE';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTOCHECK_INFO = 'AUTOCHECK_INFO';
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET';

// User Locations
export const USER_PERMISSIONS_CHECK_REQUEST = 'USER_PERMISSIONS_CHECK_REQUEST';
export const USER_PERMISSIONS_CHECK_SUCCESS = 'USER_PERMISSIONS_CHECK_SUCCESS';
export const USER_PERMISSIONS_CHECK_FAILURE = 'USER_PERMISSIONS_CHECK_FAILURE';
export const OPERATOR_LOCATIONS_REQUEST = 'OPERATOR_LOCATIONS_REQUEST';
export const OPERATOR_LOCATIONS_SUCCESS = 'OPERATOR_LOCATIONS_SUCCESS';
export const OPERATOR_LOCATIONS_FAILURE = 'OPERATOR_LOCATIONS_FAILURE';

// Lead
export const LEAD_CREATING_REQUEST = 'LEAD_CREATING_REQUEST';
export const LEAD_CREATING_SUCCESS = 'LEAD_CREATING_SUCCESS';
export const LEAD_CREATING_FAILURE = 'LEAD_CREATING_FAILURE';
export const LEAD_CREATING_FAILURE_RESET = 'LEAD_CREATING_FAILURE_RESET';
export const LEAD_REGISTER_BY_ADMIN_REQUEST = 'LEAD_REGISTER_BY_ADMIN_REQUEST';
export const LEAD_REGISTER_BY_ADMIN_SUCCESS = 'LEAD_REGISTER_BY_ADMIN_SUCCESS';
export const LEAD_REGISTER_BY_ADMIN_FAILURE = 'LEAD_REGISTER_BY_ADMIN_FAILURE';
export const LEAD_SEARCHES_GET_REQUEST = 'LEAD_SEARCHES_GET_REQUEST';
export const LEAD_SEARCHES_GET_SUCCESS = 'LEAD_SEARCHES_GET_SUCCESS';
export const LEAD_SEARCHES_GET_FAILURE = 'LEAD_SEARCHES_GET_FAILURE';
export const LEAD_SEARCHES_ADD_REQUEST = 'LEAD_SEARCHES_ADD_REQUEST';
export const LEAD_SEARCHES_ADD_SUCCESS = 'LEAD_SEARCHES_ADD_SUCCESS';
export const LEAD_SEARCHES_ADD_FAILURE = 'LEAD_SEARCHES_ADD_FAILURE';
export const LEAD_SEARCHES_DELETE_REQUEST = 'LEAD_SEARCHES_DELETE_REQUEST';
export const LEAD_SEARCHES_DELETE_SUCCESS = 'LEAD_SEARCHES_DELETE_SUCCESS';
export const LEAD_SEARCHES_DELETE_FAILURE = 'LEAD_SEARCHES_DELETE_FAILURE';
export const LEAD_SPACES_GET_REQUEST = 'LEAD_SPACES_GET_REQUEST';
export const LEAD_SPACES_GET_SUCCESS = 'LEAD_SPACES_GET_SUCCESS';
export const LEAD_SPACES_GET_FAILURE = 'LEAD_SPACES_GET_FAILURE';
export const LEAD_SPACES_ADD_REQUEST = 'LEAD_SPACES_ADD_REQUEST';
export const LEAD_SPACES_ADD_SUCCESS = 'LEAD_SPACES_ADD_SUCCESS';
export const LEAD_SPACES_ADD_FAILURE = 'LEAD_SPACES_ADD_FAILURE';
export const LEAD_SPACES_UPDATE_REQUEST = 'LEAD_SPACES_UPDATE_REQUEST';
export const LEAD_SPACES_UPDATE_SUCCESS = 'LEAD_SPACES_UPDATE_SUCCESS';
export const LEAD_SPACES_UPDATE_FAILURE = 'LEAD_SPACES_UPDATE_FAILURE';
export const LEAD_ACCOUNT_GET_REQUEST = 'LEAD_ACCOUNT_GET_REQUEST';
export const LEAD_ACCOUNT_GET_SUCCESS = 'LEAD_ACCOUNT_GET_SUCCESS';
export const LEAD_ACCOUNT_GET_FAILURE = 'LEAD_ACCOUNT_GET_FAILURE';
export const LEAD_ACCOUNT_UPDATE_REQUEST = 'LEAD_ACCOUNT_UPDATE_REQUEST';
export const LEAD_ACCOUNT_UPDATE_SUCCESS = 'LEAD_ACCOUNT_UPDATE_SUCCESS';
export const LEAD_ACCOUNT_UPDATE_FAILURE = 'LEAD_ACCOUNT_UPDATE_FAILURE';
export const LEAD_PROPOSAL_GET_REQUEST = 'LEAD_PROPOSAL_GET_REQUEST';
export const LEAD_PROPOSAL_GET_SUCCESS = 'LEAD_PROPOSAL_GET_SUCCESS';
export const LEAD_PROPOSAL_GET_FAILURE = 'LEAD_PROPOSAL_GET_FAILURE';

// User Management
export const USERS_GET_REQUEST = 'USERS_GET_REQUEST';
export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS';
export const USERS_GET_FAILURE = 'USERS_GET_FAILURE';
export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAILURE = 'USER_CREATE_FAILURE';
export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';
export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE';
export const USERS_BUNCH_DELETE_REQUEST = 'USERS_BUNCH_DELETE_REQUEST';
export const USERS_BUNCH_DELETE_SUCCESS = 'USERS_BUNCH_DELETE_SUCCESS';
export const USERS_BUNCH_DELETE_FAILURE = 'USERS_BUNCH_DELETE_FAILURE';

// SEO
export const GET_LOC_BY_MARKET_REQUEST = 'GET_LOC_BY_MARKET_REQUEST';
export const GET_LOC_BY_MARKET_SUCCESS = 'GET_LOC_BY_MARKET_SUCCESS';
export const GET_LOC_BY_MARKET_FAILURE = 'GET_LOC_BY_MARKET_FAILURE';
export const GET_WORKSPACES_BY_MARKET_SUCCESS = 'GET_WORKSPACES_BY_MARKET_SUCCESS';

export const GET_FORECAST_MARKET_REQUEST = 'GET_FORECAST_MARKET_REQUEST';
export const GET_FORECAST_MARKET_SUCCESS = 'GET_FORECAST_MARKET_SUCCESS';
export const GET_FORECAST_MARKET_FAILURE = 'GET_FORECAST_MARKET_FAILURE';

export const GET_PRICE_MARKET_REQUEST = 'GET_PRICE_MARKET_REQUEST';
export const GET_PRICE_MARKET_SUCCESS = 'GET_PRICE_MARKET_SUCCESS';
export const GET_PRICE_MARKET_FAILURE = 'GET_PRICE_MARKET_FAILURE';

// MAIN MAP
export const GET_LOC_BY_STATUS_REQUEST = 'GET_LOC_BY_STATUS_REQUEST';
export const GET_LOC_BY_STATUS_SUCCESS = 'GET_LOC_BY_STATUS_SUCCESS';
export const GET_LOC_BY_STATUS_FAILURE = 'GET_LOC_BY_STATUS_FAILURE';
export const GET_MARKET_AVERAGE_COST_REQUEST = 'GET_MARKET_AVERAGE_COST_REQUEST';
export const GET_MARKET_AVERAGE_COST_SUCCESS = 'GET_MARKET_AVERAGE_COST_SUCCESS';
export const GET_MARKET_AVERAGE_COST_FAILURE = 'GET_MARKET_AVERAGE_COST_FAILURE';

// Best of
export const GET_BEST_OF_REQUEST = 'GET_BEST_OF_REQUEST';
export const GET_BEST_OF_SUCCESS = 'GET_BEST_OF_SUCCESS';
export const GET_BEST_OF_FAILURE = 'GET_BEST_OF_FAILURE';

// Cognito
export const AUTHCHECK_REQUEST = 'AUTHCHECK_REQUEST'
export const AUTHCHECK_SUCCESS = 'AUTHCHECK_SUCCESS'
export const AUTHCHECK_FAILURE = 'AUTHCHECK_FAILURE'
export const AUTH_SIGNIN_REQUEST = 'AUTH_SIGNIN_REQUEST'
export const AUTH_SIGNIN_SUCCESS = 'AUTH_SIGNIN_SUCCESS'
export const AUTH_SIGNIN_FAILURE = 'AUTH_SIGNIN_FAILURE'
export const AUTH_SESSION_REQUEST = 'AUTH_SESSION_REQUEST'
export const AUTH_SESSION_SUCCESS = 'AUTH_SESSION_SUCCESS'
export const AUTH_SESSION_FAILURE = 'AUTH_SESSION_FAILURE'
export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
export const PASSWORD_RECOVERY_REQUEST = 'PASSWORD_RECOVERY_REQUEST'
export const PASSWORD_RECOVERY_SUCCESS = 'PASSWORD_RECOVERY_SUCCESS'
export const PASSWORD_RECOVERY_FAILURE = 'PASSWORD_RECOVERY_FAILURE'
