import { BASE_URL } from './index';
import { ROOT, WORKPLACE } from './routes';
import { imageUrl } from '../utils/crm-links';

const getLocationsByMarket = (market, locations, isWorkspace) => {
  const locationCount = locations && locations.length;
  const baseUrl = `${BASE_URL}/${isWorkspace ? WORKPLACE : ROOT}`;

  if (locationCount > 50) {
    return locations.map((location, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      url: `${baseUrl}/${market.marketSlug}/${location.marketplaceSlug}/`,
      name: location.name,
    }));
  }

  return locations.map(location => ({
    '@type': 'LocalBusiness',
    name: location.name,
    url: `${baseUrl}/${market.marketSlug}/${location.marketplaceSlug}/`,
    address: {
      '@type': 'PostalAddress',
      addressCountry: location.addressCountry,
      addressLocality: location.addressCity,
      streetAddress: location.addressStreet,
      postalCode: location.addressPostalCode,
    },
    image: imageUrl(location.listingCardImageId),
    telephone: '+18002077269',
    priceRange: '$$$',
    geo: {
      '@type': 'GeoCoordinates',
      latitude: location.latitude,
      longitude: location.longitude,
    },
  }));
};

const getItemList = (market, locations, isWorkspace) => {
  const locationCount = locations && locations.length;
  const baseUrl = `${BASE_URL}/${isWorkspace ? WORKPLACE : ROOT}`;


  if (locationCount > 50) {
    return ({
      '@type': 'ItemList',
      name: market.metaTitle,
      url: `${baseUrl}/${market.marketSlug}/`,
      description: market.metaDescription,
      itemListOrder: 'Unordered',
      numberOfItems: locations && locations.length,
      itemListElement: getLocationsByMarket(market, locations, isWorkspace),
    });
  }

  return ({
    '@type': 'ListItem',
    name: market.metaTitle,
    url: `${baseUrl}/${market.marketSlug}/`,
    item: getLocationsByMarket(market, locations, isWorkspace),
  })
}

export const getSchemaMarket = (market, locations, isWorkspace) => {
  const locationCount = locations && locations.length;
  const baseUrl = `${BASE_URL}/${isWorkspace ? WORKPLACE : ROOT}`;
  const breadcrumbs = {
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        name: isWorkspace ? 'Workspaces' : 'Coworking Spaces',
        position: 1,
        item: `${baseUrl}/`,
      },
      {
        '@type': 'ListItem',
        name: market.metaTitle,
        position: 2,
        item: `${baseUrl}/${market.marketSlug}/`,
      },
    ],
  };
  const organization = {
    '@type': 'Organization',
    name: 'Upsuite',
    url: BASE_URL,
    logo: `${BASE_URL}/wp-content/uploads/2018/05/logo.png`,
    location: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        // TODO: add addressCountry field to ESPO
        // addressCountry: 'United States',
        addressRegion: market.name,
      },
      name: market.metaTitle,
      description: market.metaDescription,
      url: `${baseUrl}/${market.marketSlug}/`,
    },
  };

  return {
    '@context': 'http://schema.org',
    '@graph': locationCount <= 50 ? [
      { ...breadcrumbs },
      { ...organization },
      { ...getItemList(market, locations, isWorkspace) }
    ] : [
        { ...organization },
        { ...getItemList(market, locations, isWorkspace) }
      ],
  };
};
