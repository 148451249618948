import styled from 'styled-components/macro';
import { lgUp } from '../../styles/theme/breakpoints';

export const Iframe = styled.iframe`
  border: none;
  width: 100%;
  min-height: ${props => props.height};

  @media ${lgUp} {
    min-width: ${props => props.width};
  }
`;
