import React from 'react';
import classnames from 'classnames';

import './styles.scss';

export default ({ count, type, children }) => (
  <div className={classnames('NotificationCounter', type && `-type-${type}`)}>
    {children}
    {count > 0 && <span className="NotificationCounter-count">{count}</span>}
  </div>
);
