import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import random from 'lodash/random';
import { quoteAuthorsExtended } from '../../settings/quotes';

import './styles.scss';

const ConciergeReview = (props) => {
  const {
    className,
    description,
  } = props;

  const classes = classNames(
    'ConciergeReview',
    className,
  );

  const src = useMemo(() => quoteAuthorsExtended[random(0, 5)], [])

  return (
    <div className={classes}>
      {src && (
        <div className="ConciergeReview-author">
          <img src={src} alt="Concierge" />
        </div>
      )}
      {description && (
        <div className="ConciergeReview-desc">
          <p>{`"${description}"`}</p>
        </div>
      )}
    </div>
  );
};

ConciergeReview.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
};

ConciergeReview.defaultProps = {
  className: null,
  description: null,
};

export default ConciergeReview;
