import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ModalBody } from '../../components/modal';
import HubspotForm from '../../components/hubspot-form';
import Spinner from '../../components/spinner';

import { hideModal } from '../../actions/modal';

class CTAModal extends Component {
  state = {
    formReady: false,
  }

  render() {
    return (
      <ModalBody small withHeight>
        {!this.state.formReady &&
          <Spinner size="medium" center />
        }
        <HubspotForm
          title="Export Coworking Comparison"
          portalId="4175778"
          region="na1"
          formId="2d72a961-6578-4f3b-bc0b-677729cf37a3"
          withBg
          onFormSubmit={this.props.onFormSubmit}
          setFormValues={{
            coworking_location_of_interest: this.props.listingName,
          }}
          onFormReady={() => this.setState({ formReady: true })}
        />
      </ModalBody>
    );
  }
}

export default connect(
  null, { hideModal },
)(CTAModal);
