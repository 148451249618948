import React from 'react'
import { withRouter } from 'react-router'
import { Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'

import {
  ROOT,
  COMPARE,
  COGNITO_REDIRECT,
  USER_MANAGEMENT,
  SEARCH,
  HEALTHY,
  CLOSED_LOC,
  NEW_LOC,
  BEST,
  AVERAGE,
  WORKPLACE,
  UNSUBSCRIBE,
  LANDING,
  profile
} from '../../settings/routes'

import Flex from '../../components/flex'
import PrivateRoute from '../../components/private-route'
import SiteHeader from '../SiteHeader'
import Listings from '../Listings'
import SingleLocation from '../SingleListing'
import SingleWorkplace from '../SingleWorkplace'
import Compare from '../Favorites'
import CompareWorkspace from '../FavoriteWorkspaces'
import MatchPage from '../MatchPage'
import ModalRoot from '../ModalRoot'
import NotFound from '../NotFound'
import CognitoRedirect from '../CognitoRedirect'
import Unsubscribe from '../Unsubscribe'
import UserManagement from '../UserManagement'
import HealthyLocations from '../HealthyLocations'
import ClosedLocations from '../ClosedLocations'
import NewLocations from '../NewLocations'
import BestLocations from '../BestLocations'
import AveragePriceLocations from '../AveragePriceLocations'
import Main from '../Main'
import EmbedLocation from '../EmbedLocation'
import LandingPage from '../LandingPage'
import Profile from '../Profile'
import Spaces from '../Profile/Spaces'
import SpacesList from '../Profile/SavedSpaces'
import Searches from '../Profile/Searches'
import Settings from '../Profile/Settings'
import VerifyPassword from '../Profile/VerifyPassword'
import ResetPassword from '../Profile/ResetPassword'

import { bodyClassAdd } from '../../utils/ui'

const params = queryString.parse(window.location.search)
document.documentElement.style.setProperty(
  '--brand-color',
  params.bcolor ? `#${params.bcolor}` : '#e7483d'
)
const isIframe = params.iframe && params.iframe === 'true'
if (isIframe) {
  bodyClassAdd('-isIframe')
}

const Root = () => (
  <Flex column fullHeight>
    <Helmet titleTemplate="Upsuite | %s" defaultTitle="Upsuite" />
    {!isIframe && <SiteHeader />}
    <Switch>
      <Route path={[`/${ROOT}/`, `/${WORKPLACE}`]} exact component={Main} />
      <Route path={`/${COGNITO_REDIRECT}/`} exact component={CognitoRedirect} />
      <Route path={`/${UNSUBSCRIBE}/`} component={Unsubscribe} />
      <Route path={`/${USER_MANAGEMENT}/`} exact component={UserManagement} />
      <Route path={`/${ROOT}/match/`} exact component={MatchPage} />
      <Route path={`/${ROOT}/${HEALTHY}/`} exact component={HealthyLocations} />
      <Route
        path={`/${ROOT}/${CLOSED_LOC}/`}
        exact
        component={ClosedLocations}
      />
      <Route path={`/${ROOT}/${NEW_LOC}/`} exact component={NewLocations} />
      <Route path={`/${ROOT}/${BEST}/`} exact component={BestLocations} />
      <Route
        path={`/${ROOT}/${AVERAGE}/`}
        exact
        component={AveragePriceLocations}
      />
      <Route path={`/${ROOT}/${COMPARE}/:ids/`} exact component={Compare} />
      <Route path={`/${ROOT}/${COMPARE}/`} exact component={Compare} />
      <Route
        path={`/${WORKPLACE}/${COMPARE}/:ids/`}
        exact
        component={CompareWorkspace}
      />
      <Route
        path={`/${WORKPLACE}/${COMPARE}/`}
        exact
        component={CompareWorkspace}
      />
      <Route
        path={[
          `/${ROOT}/${SEARCH}/`,
          `/${WORKPLACE}/${SEARCH}/`,
          `/${ROOT}/${SEARCH}/:searchParent/`,
          `/${WORKPLACE}/${SEARCH}/:searchParent/`,
          `/${ROOT}/:marketSlug/`,
          `/${WORKPLACE}/:marketSlug/`,
          `/${ROOT}/:marketSlug/${HEALTHY}/`,
          `/${ROOT}/:marketSlug/${CLOSED_LOC}/`,
          `/${ROOT}/:marketSlug/${NEW_LOC}/`,
          `/${ROOT}/:marketSlug/${BEST}/`
        ]}
        exact
        component={Listings}
      />
      <Route
        path={`/${ROOT}/:marketSlug/:slug/`}
        exact
        component={SingleLocation}
      />
      <Route
        path={`/${WORKPLACE}/:marketSlug/:slug/`}
        exact
        component={SingleWorkplace}
      />
      <Route path={`/${LANDING}/:marketSlug/`} exact component={LandingPage} />
      <PrivateRoute path={`/${profile.main}/`} exact component={Profile} />
      <PrivateRoute path={`/${profile.main}/${profile.spaces}/`} exact component={Spaces} />
      <PrivateRoute path={`/${profile.main}/${profile.spaces}/:slug/`} exact component={SpacesList} />
      <PrivateRoute path={`/${profile.main}/${profile.searches}/`} exact component={Searches} />
      <PrivateRoute path={`/${profile.main}/${profile.settings}/`} exact component={Settings} />
      <Route path={`/${profile.main}/${profile.verify}/`} exact component={VerifyPassword} />
      <Route path={`/${profile.main}/${profile.pass}/`} exact component={ResetPassword} />
      <Route
        path={[
          `/${WORKPLACE}/:marketSlug/:slug/embed/`,
          `/${ROOT}/:marketSlug/:slug/embed/`
        ]}
        exact
        component={EmbedLocation}
      />
      <Route component={NotFound} />
    </Switch>
    <ModalRoot />
  </Flex>
)

export default withRouter(Root)
