import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Pencil } from '../../components/icons'
import { spacesUpdate } from '../../actions/lead'

const ShortlistName = ({ title, id, ids }) => {
  const dispatch = useDispatch()
  const [name, setName] = useState(title || '')
  const [isEditMode, setIsEditMode] = useState(false)
  const inputRef = useRef()

  useEffect(() => {
    setName(title || '')
    setIsEditMode(false)
  }, [id, title])

  useEffect(() => {
    if (isEditMode && inputRef?.current) {
      inputRef.current.focus()
    }
  }, [isEditMode])

  return (
    <Wrap>
      <TitleWrap data-visible={!isEditMode}>
        <Heading>{title || ''}</Heading>
        <Toggle onClick={() => setIsEditMode(true)}>
          <Icon />
        </Toggle>
      </TitleWrap>
      <InputWrap data-visible={isEditMode}>
        <Input
          ref={inputRef}
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </InputWrap>
      {isEditMode && (
        <>
          {!name && <ErrorMessage>Required</ErrorMessage>}
          <ActionsWrap>
            <ActionBtn data-cancel onClick={() => setIsEditMode(false)}>
              Cancel
            </ActionBtn>
            <ActionBtn
              disabled={!name}
              onClick={() => {
                dispatch(
                  spacesUpdate({
                    id,
                    name,
                    spaces: ids
                  })
                )
              }}
            >
              Save Name
            </ActionBtn>
          </ActionsWrap>
        </>
      )}
    </Wrap>
  )
}

const Heading = styled.h1`
  text-align: center;
  font-size: 30px;
  color: #2e2c2b;
  line-height: 1.1;
  font-weight: normal;
`

const Wrap = styled.div`
  margin-bottom: 20px;
  margin-top: 40px;
  max-width: calc(100% - 70px);
  position: relative;
`

const TitleWrap = styled.div`
  position: relative;

  &[data-visible='false'] {
    visibility: hidden;
  }
`

const InputWrap = styled.div`
  width: 100%;
  margin-bottom: 10px;

  &[data-visible='false'] {
    display: none;
  }
`

const ErrorMessage = styled.span`
  color: red;
  line-height: 1;
  display: block;
  margin-bottom: 10px;
`

const Input = styled.input.attrs({
  name: 'shortlist-name',
  id: 'shortlist-name',
  type: 'text'
})`
  font-size: 29px;
  color: #2e2c2b;
  line-height: 1.1;
  font-weight: normal;
  width: 100%;
  top: 0;
  position: absolute;
`

const Toggle = styled.button.attrs({
  type: 'button',
  'aria-label': 'edit shortlist name'
})`
  position: absolute;
  position: absolute;
  top: -10px;
  right: -27px;
  padding: 0;
  border: 0;
  background: none;
  outline: none;
  color: #828180;
  cursor: pointer;

  &:hover {
    color: #2e2c2b;
  }
`

const ActionsWrap = styled.div`
  text-align: center;
`

const ActionBtn = styled.button.attrs({ type: 'button' })`
  background-color: var(--brand-color);
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 15px;
  opacity: 1;
  max-width: unset;
  width: auto;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: 0 0.3em 0.6em rgb(0 0 0 / 16%);
  border-radius: 0.36em;
  border: none;
  cursor: pointer;

  &[data-cancel='true'] {
    background-color: #e2e2e2;
    color: #828180;
    box-shadow: none;
  }
`

const Icon = styled(Pencil)`
  width: 24px;
`

export default ShortlistName
