export const posts = [{
  title: 'Corporate Coworking: Why Large Corporations Are Turning To Flexible Offices [May 2021]',
  link: 'https://www.upsuite.com/blog/corporate-coworking-why-large-corporations-are-turning-to-flexible-offices/',
  imgSrc: 'https://www.upsuite.com/wp-content/uploads/2020/02/Upsuite_Blog_Why-Large-Corporations-Are-Turning-to-Flexible-Offices-392x216.jpg',
  imgAlt: 'Large Corporations Blog',
  text: 'Coworking isn\'t just for startups and remote workers anymore. Over the last few years, the…'
}, {
  title: 'The 11 Types of Coworking Space Available Today [May 2021]',
  link: 'https://www.upsuite.com/blog/types-of-coworking-space-available/',
  imgSrc: 'https://www.upsuite.com/wp-content/uploads/2020/02/Upsuite_Blog_The-9-Types-of-Coworking-Spaces-Available-Today-392x216.jpg',
  imgAlt: 'The 11 Types of Coworking Space Available Today [May 2021]',
  text: 'Whether you\'re a large enterprise or a small one, a startup or a mature company,…'
}, {
  title: '[2021] Coworking Space Agreements & Contracts (Template Included)',
  link: 'https://www.upsuite.com/blog/coworking-space-agreements-contracts-template-included/',
  imgSrc: 'https://www.upsuite.com/wp-content/uploads/2020/02/Upsuite_Blog_Coworking-Space-Agreements-Contracts-392x216.jpg',
  imgAlt: 'Agreement Coworking Blog',
  text: 'One of coworking space’s most notable features is the simplicity of its contracts, especially in…'
}, {
  title: '[2021] Coworking Space Costs & Average Membership Pricing',
  link: 'https://www.upsuite.com/blog/coworking-space-costs-average-membership-pricing/',
  imgSrc: 'https://www.upsuite.com/wp-content/uploads/2020/02/Upsuite_Blog_Coworking-Space-Costs-Average-Membership-Pricing-392x216.jpg',
  imgAlt: 'Coworking Costs BLog',
  text: 'Companies of all shapes and sizes gravitate towards coworking space for its flexibility. While the…'
}]
