import sortBy from 'lodash/sortBy';
import take from 'lodash/take';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import getDistanceFromLatLonInKm from './geo';

export const getRelatedList = (state, list, slug) => {
  const selfId = state.bySlug[slug];
  const getSelf = state.byId[selfId];
  const excludeSelf = filter(list, o => slug !== `${o.marketSlug}/${o.marketplaceSlug}`);
  const dstBySelf = excludeSelf.map(item => ({
    ...item,
    distance: getDistanceFromLatLonInKm(
      getSelf.latitude,
      getSelf.longitude,
      item.latitude,
      item.longitude,
    ) * 1000,
  }));

  return take(sortBy(dstBySelf, 'distance'), 2);
};

export const getRelatedSlugs = (state, list, slug) =>
  getRelatedList(state, list, slug).map(o => `${o.marketSlug}/${o.marketplaceSlug}`);

export const getRelatedIds = (state, list, slug) =>
  getRelatedList(state, list, slug).map(o => o.id);

export const groupByMarketId = list => reduce(groupBy(list, 'marketId'), (result, value, key) =>
  ({ ...result, [key]: value.map(l => l.id) }), {});
