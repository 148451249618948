import styled from 'styled-components';
import * as breakpoints from '../../styles/theme/breakpoints';
import * as colors from '../../styles/theme/colors';

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 9px;

  @media ${breakpoints.mdUp} {
    padding-left: 16px;
  }
`;

export const Top = styled.div`
  display: flex;
  width: 100%;
  color: ${colors.gray8};

  @media ${breakpoints.mdDown} {
    flex-direction: column;
  }
`;

export const TopLeft = styled.div`
  width: 100%;

  @media ${breakpoints.mdUp} {
    padding-right: 40px;
  }

  h1 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    margin: 0 0 16px 0;
    font-size: 17px;
  }
`;

export const TopRight = styled.div`
  width: 100%;
  color: ${colors.gray8};

  @media ${breakpoints.mdUp} {
    width: 600px;
  }
`;

export const Bar = styled.div`
  width: 100%;
  padding-top: 30px;
  display: flex;
  margin-bottom: 20px;

  @media ${breakpoints.mdUp} {
    align-items: center;
    margin-bottom: 40px;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    color: ${colors.gray8};

    @media ${breakpoints.mdDown} {
      margin-bottom: 12px;
    }
  }

  @media ${breakpoints.mdDown} {
    flex-direction: column;
  }
`;

export const Suggest = styled.div`
  max-width: 335px;
  width: 100%;

  @media ${breakpoints.mdUp} {
    margin-left: 20px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  margin-bottom: 30px;

  @media ${breakpoints.mdDown} {
    flex-direction: column;
  }

  > h3 {
    font-size: 24px;
    line-height: 1.1;
    font-weight: normal;
    color: #2e2c2b;
    border-bottom: 1px solid black;
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const BottomLeft = styled.div`
  width: 100%;
  margin-bottom: 20px;
  font-size: 16px;

  ul {
    column-width: 200px;
    padding-left: 0;
    list-style: none;
    margin: 0;

    li {
      margin-bottom: 10px;

      a {
        color: #2e2c2b;

        :hover {
          color: ${colors.brand};
        }
      }

      &.isSubmarket {
        padding-left: 25px;
      }
    }
  }
`;

export const BottomRight = styled.div`
  @media ${breakpoints.mdUp} {
    width: 470px;
  }

  a {
    font-size: 16px;
    color: ${colors.brand};
  }
`;

export const BlogHeading = styled.div`
  border-left: 4px solid #57ab83;
  padding-left: 20px;
  margin-bottom: 20px;
  color: #57ab83;

  h3 {
    font-size: 20px
    font-weight: 600;
  }

  span {
    font-size: 15px;
    margin-top: 0;
  }
`;
