import React from 'react';
import Select from '../select';

import './styles.scss';

export default ({ value, onChange, options }) => (
  <Select
    className="SortSelect"
    value={value}
    placeholder="Sort By"
    simpleValue
    onChange={val => onChange(val)}
    options={options}
    searchable={false}
    clearable={false}
  />
);
