import { checkHttpResponse, parseJSON } from '../utils';
import {
  USERS_GET_REQUEST,
  USERS_GET_SUCCESS,
  USERS_GET_FAILURE,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAILURE,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
  USERS_BUNCH_DELETE_REQUEST,
  USERS_BUNCH_DELETE_SUCCESS,
  USERS_BUNCH_DELETE_FAILURE,
} from '../constants';
import * as api from '../api/users';

const usersGetRequest = () => ({
  type: USERS_GET_REQUEST,
});

const usersGetSuccess = payload => ({
  type: USERS_GET_SUCCESS,
  payload,
});

const usersGetFailure = () => ({
  type: USERS_GET_FAILURE,
});

export const usersGet = token => dispatch => {
  dispatch(usersGetRequest());

  return api
    .getUsers(token)
    .then(checkHttpResponse)
    .then(parseJSON)
    .then(response => {
      dispatch(usersGetSuccess(response));
    })
    .catch(error => {
      dispatch(usersGetFailure(error));
      if (error) {
        error.json().then(message => window.alert(`${error.status}: ${message}`));
      }
    });
};

const userCreateRequest = () => ({
  type: USER_CREATE_REQUEST,
});

const userCreateSuccess = payload => ({
  type: USER_CREATE_SUCCESS,
  payload,
});

const userCreateFailure = payload => ({
  type: USER_CREATE_FAILURE,
  payload,
});

export const userCreate = (data, token) => (dispatch) => {
  dispatch(userCreateRequest());
  return api
    .createUser(token, data)
    .then(checkHttpResponse)
    .then(() => {
      dispatch(userCreateSuccess(data));
    })
    .catch(error => {
      if (error) {
        error.json().then(message => dispatch(userCreateFailure(`Error: ${message}`)));
      }
    });
};

const userUpdateRequest = () => ({
  type: USER_UPDATE_REQUEST,
});

const userUpdateSuccess = payload => ({
  type: USER_UPDATE_SUCCESS,
  payload,
});

const userUpdateFailure = payload => ({
  type: USER_UPDATE_FAILURE,
  payload,
});

export const userUpdate = (data, token) => (dispatch) => {
  dispatch(userUpdateRequest());

  return (
    api
      .updateUser(token, data)
      .then(checkHttpResponse)
      .then(() => {
        dispatch(userUpdateSuccess(data));
      })
      .catch(error => {
        if (error) {
          error.json().then(message => dispatch(userUpdateFailure(`Error: ${message}`)));
        }
      })
  );
};

const userDeleteRequest = payload => ({
  type: USER_DELETE_REQUEST,
  payload,
});

const userDeleteSuccess = payload => ({
  type: USER_DELETE_SUCCESS,
  payload,
});

const userDeleteFailure = payload => ({
  type: USER_DELETE_FAILURE,
  payload,
});

export const userDelete = (username, token) => (dispatch) => {
  dispatch(userDeleteRequest(username));

  return (
    api
      .deleteUser(token, username)
      .then(checkHttpResponse)
      .then(() => {
        dispatch(userDeleteSuccess(username));
      })
      .catch(error => {
        if (error) {
          error.json().then(message => dispatch(userDeleteFailure(`Error: ${message}`)));
        }
      })
  );
};

const usersBunchDeleteRequest = () => ({
  type: USERS_BUNCH_DELETE_REQUEST,
});

const usersBunchDeleteSuccess = payload => ({
  type: USERS_BUNCH_DELETE_SUCCESS,
  payload,
});

const usersBunchDeleteFailure = (payload) => ({
  type: USERS_BUNCH_DELETE_FAILURE,
  payload,
});

export const usersBunchDelete = (users, token) => (dispatch) => {
  dispatch(usersBunchDeleteRequest());

  return (
    api
      .bunchDeleteUsers(token, users)
      .then(checkHttpResponse)
      .then(() => {
        dispatch(usersBunchDeleteSuccess(users));
      })
      .catch(error => {
        if (error) {
          error.json().then(message => dispatch(usersBunchDeleteFailure(`Error ${error.status}: ${message}`)));
        }
      })
  );
};
