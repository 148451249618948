import React from 'react';
import { Swipeable } from 'react-swipeable';

import './styles.scss';

export default ({ children, flex, ...props }) => (
  <Swipeable className="Swipeable" {...props}>
    {children}
  </Swipeable>
);
