import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { CompareColumn as Col } from '../../components/compare-page-helpers';
import CompareListingCard from '../../components/compare-listing-card';
import CompareBestFor from '../../components/compare-best-for';
import CompareSpaces from '../../components/compare-spaces';
import Amenities from '../../components/compare-amenities';
import CompareAddSpaces from '../../components/compare-add-spaces';

import { openRegisterModal, openInteractiveTourModal, openVerifyPassModal } from '../../actions/modal';
import { removeCompareListings } from '../../actions/favorites';
import {
  highlightMarker, highlightMarkerReset, compareSpaceByListing, setCompareCardsHeight,
} from '../../actions/ui';

import getAllAmenities from '../../utils/amenities';
import { trackVirtualTourModal } from '../../utils/analytics';
import { ROOT } from '../../settings/routes';

class SlideColumn extends Component {
  constructor() {
    super();
    this.state = {
      expanded: false,
    };
  }

  handleExpand() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  hendleCardHeight(height) {
    this.props.setCompareCardsHeight(height);
  }

  render() {
    const {
      listings, spaces, map, highlightMarkerId,
      spaceByListingId, searchFilterQueryUrl, compareCardsHeight,
      userIsAuthenticated, isCrawler, visitorId, isCognitoAuth
    } = this.props;
    const { expanded } = this.state;

    return (
      map && (
        <Slider
          slidesToShow={3.2}
          slidesToScroll={3}
          infinite={false}
          className="CompareSlider"
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2.2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {listings.map(listing => (
            <Col key={listing.id}>
              <CompareListingCard
                key={listing.id}
                removeFavorites={() =>
                  this.props.removeCompareListings(
                    listings,
                    listing,
                    false,
                  )
                }
                onMount={() => {}}
                onVirtualTourClick={url => {
                  trackVirtualTourModal(
                    `/${ROOT}/${listing.marketName}/${listing.marketplaceSlug}/`,
                  );
                  this.props.openInteractiveTourModal({
                    tourUrl: url,
                    initialValues: {
                      locations: [listing.id],
                      isWorkspace: [false],
                      visitorId,
                    },
                  });
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...listing}
                onMouseEnter={() => this.props.highlightMarker(listing.id)}
                onMouseLeave={() => this.props.highlightMarkerReset()}
                heightOnMount={height => this.hendleCardHeight(height)}
                highlighted={highlightMarkerId === listing.id}
                onFirstClick={!(isCognitoAuth || isCrawler) ? () => {
                  if (userIsAuthenticated && !isCognitoAuth) {
                    this.props.openVerifyPassModal()
                  } else {
                    this.props.openRegisterModal({
                      heading:
                        'We have pricing and avialibality for this location!',
                      closeOnSubmit: true,
                      initialValues: {
                        locations: [listing.id],
                        isWorkspace: [false],
                      },
                      isWorkspace: false,
                      form: 'compare-card-registration',
                      visitorId,
                    })
                  }
                } : null}
              />
              <CompareSpaces
                spaces={spaces[listing.id]}
                activeSpaceIndex={spaceByListingId[listing.id]}
                onSpaceChanged={index =>
                  this.props.compareSpaceByListing(listing.id, index)
                }
              />

              <CompareBestFor accolades={listing.accolades} />

              <Amenities
                expanded={expanded}
                amenities={getAllAmenities(listing)}
                onExpand={() => this.handleExpand()}
                locationId={listing.id}
                cleaningPoliciesPDFLink={listing.cleaningPoliciesPDFLinkId}
              />
            </Col>
          ))}
          <Col key="addSpaces">
            <CompareAddSpaces
              link={searchFilterQueryUrl}
              label="Add Another Space"
              style={{ minHeight: `${compareCardsHeight + 4}px` }}
            />
          </Col>
        </Slider>
      )
    );
  }
}

const mapStateToProps = state => ({
  spaces: state.spaces.byLocationId,
  greviews: state.reviews.greviewsById,
  map: state.reviews.map,
  highlightMarkerId: state.ui.highlightMarkerId,
  spaceByListingId: state.ui.spaceByListingId,
  compareCardsHeight: state.ui.compareCardsHeight,
  markets: state.markets.byName,
  userIsAuthenticated: state.auth.userIsAuthenticated,
  isCrawler: state.auth.isCrawler,
  visitorId: state.auth.visitorId,
});

export default connect(
  mapStateToProps, {
    removeCompareListings,
    highlightMarker,
    highlightMarkerReset,
    compareSpaceByListing,
    setCompareCardsHeight,
    openRegisterModal,
    openInteractiveTourModal,
    openVerifyPassModal
  },
)(SlideColumn);
