import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import Marker from '../../components/location-marker'
import {
  MAPS_API_KEY as mapsKey,
  SINGLE_LISTING_BASE_ROUTE as singleRoute
} from '../../settings'
import { CompareMapHelper } from '../../components/compare-page-helpers'
import InfoBox from '../../components/info-box'

import {
  syncVisibleMarkersWithListings,
  setMapBounds
} from '../../actions/listings'
import { bindMapToState } from '../../actions/reviews'
import toggleFavorites from '../../actions/favorites'
import { selectHighlightId } from '../../selectors/ui'
import { highlightMarker, highlightMarkerReset } from '../../actions/ui'

import { imageUrl } from '../../utils/crm-links'
import {
  createCompareMapOptions,
  handleApiLoaded,
  getBoundsOnChange,
  getPoints
} from '../../utils/map-helpers'

class Markers extends Component {
  componentDidUpdate(prevProps) {
    const { map, maps, listings, highlightMarkerId } = this.props

    if (map) {
      if (
        (!isEmpty(listings) &&
          prevProps.highlightMarkerId === highlightMarkerId) ||
        !isEqual(prevProps.listings, listings)
      ) {
        map.fitBounds(getBoundsOnChange(maps, getPoints(listings)))
      }
    }
  }

  getMarker = (listing, index) => {
    const { listings, highlightMarkerId } = this.props

    return (
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <Marker
        key={`marker-${listing.id}`}
        onClick={() =>
          listing.marketSlug &&
          this.props.push(
            `/${singleRoute}/${listing.marketSlug}/${listing.marketplaceSlug}`
          )
        }
        zIndex={
          highlightMarkerId === listing.id
            ? listings.length
            : listings.length - index
        }
        lat={listing.latitude}
        lng={listing.longitude}
        onMouseOver={() => this.props.highlightMarker(listing.id)}
        onMouseOut={this.props.highlightMarkerReset}
        isFavorite
        hover={highlightMarkerId === listing.id}
      >
        <InfoBox
          name={listing.name}
          accolades={listing.accolades}
          essenceStatementMapPin={listing.essenceStatementMapPin}
          marketplaceSlug={listing.marketplaceSlug}
          marketName={listing.marketName}
          marketSlug={listing.marketSlug}
          formattedCardImage={imageUrl(listing.listingCardImageId)}
          toggleFavorites={() => this.props.toggleFavorites(listing.id)}
          isFavorite
          verified={listing.upsuiteVerified}
          newLocation={listing.newLocation}
          hideSaveAbility={this.props.hideSaveAbility}
        />
      </Marker>
    )
  }

  render() {
    const { listings } = this.props

    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapsKey }}
        defaultCenter={{ lat: 38, lng: -91 }}
        zoom={4}
        options={createCompareMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          handleApiLoaded(map, maps, this.props.bindMapToState)
        }}
      >
        {listings && listings.map(this.getMarker)}
      </GoogleMapReact>
    )
  }
}

const mapStateToProps = state => ({
  greviews: state.reviews.greviewsById,
  highlightMarkerId: selectHighlightId(state),
  map: state.reviews.map,
  maps: state.reviews.maps
})

const Map = connect(mapStateToProps, {
  bindMapToState,
  syncVisibleMarkersWithListings,
  toggleFavorites,
  setMapBounds,
  push,
  highlightMarker,
  highlightMarkerReset
})(Markers)

const CompareMap = ({
  withSearchBar,
  listings,
  compareCardsHeight,
  mobile
}) => (
  <CompareMapHelper
    withSearchBar={withSearchBar}
    style={{ maxHeight: mobile ? 'none' : `${compareCardsHeight + 8}px` }}
  >
    <Map listings={listings} hideSaveAbility />
  </CompareMapHelper>
)

export default CompareMap
