import React from 'react';
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux';
import { ModalBody } from '../../../components/modal';
import Heading from '../../../components/heading';
import SaveShortlistForm from './save-shortlist-form';
import Spinner from '../../../components/spinner';
import { hideModal } from '../../../actions/modal';
import { spacesAdd } from '../../../actions/lead';

const SaveShortlist = ({ placeholder }) => {
  const dispatch = useDispatch()
  const {
    status: { creating: loading },
    error: { creating: error }
  } = useSelector(state => state.lead.savedSpaces)
  const { ids } = useSelector(state => state.favorites)

  return (
    <ModalBody small withHeight>
      <Heading level="3" size="xsmall" center>Save Shortlist</Heading>

      {loading && (
        <SpinnerWrap>
          <Spinner size="small" center />
        </SpinnerWrap>
      )}

      {error && (
        <ErrorText>There was a problem saving the shortlist</ErrorText>
      )}

      <SaveShortlistForm
        placeholder={placeholder}
        disabled={loading}
        onSubmit={({ shortlistName }) => {
          dispatch(
            spacesAdd({
              name: shortlistName,
              spaces: ids
            })
          ).then((data) => data && dispatch(hideModal()))
        }}
      />
    </ModalBody>
  );
};

SaveShortlist.defaultProps = {
  onSubmit: () => {},
  closeOnSubmit: false
}

const SpinnerWrap = styled.div`
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
`

const ErrorText = styled.span`
  color: red;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  display: block;
`

export default SaveShortlist
