import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';

import {
  SearchBarWrapper,
  SearchBarCol,
  SearchBarRow,
  SearchBarMeta,
} from '../../components/search-bar';
import FilterList from '../../components/filter-list';
import Counter from '../../components/counter';
import DropDown from '../../components/dropdown';
import Select from '../../components/select';
import Button from '../../components/button';
import SlideToggle from '../../components/slide-toggle';

import { toogleFeedMapVisibility } from '../../actions/ui';
import amenitiesLookup, {
  amenitiesByCategory,
} from '../../settings/amenities-lookup';
import teamtypes from '../../settings/teamtypes';
import { ReactGAEvent } from '../../utils/analytics';
import { selectMarketsWithCoords } from '../../selectors/markets';
import priceScheme, { pricePeriodOptions, getPricePeriodVal, getPeriodByPrice } from '../../settings/price-range';
import officeTypesScheme from '../../settings/office-types';
import { capacityFormat } from '../../utils/format';
import {
  changeFilterQuery,
  changeFilterQueryMarket,
  resetFilterQuery,
} from '../../actions/filter-query';
import { selectMarketBySlug } from '../../selectors/filter-query';
import { selectNoLocations } from '../../selectors/listings';

import {
  CAPACITY_ROUTE as capacityRoute,
  PRICE_ROUTE as priceRoute,
  PRICE_PERIOD as pricePeriod,
  AMENITIES_ROUTE as amenitiesRoute,
  TEAM_TYPE_ROUTE as matchRoute,
} from '../../settings';
import { WORKPLACE as workplaceRoute } from '../../settings/routes';
import SaveSearch from '../../components/save-search'

const isMobile = () => window.innerWidth <= 639;

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.filtersDropdown = createRef();
    this.state = {
      expanded: false,
    };
  }

  handleExpand = expand => {
    this.setState({ expanded: expand });
  };

  render() {
    const { filterQuery, mobileNavOpened, fixtures, marketVal, seoPage, auth, userIsAuthenticated } = this.props;
    const { expanded } = this.state;

    const capacityVal = filterQuery[capacityRoute];
    const priceVal = filterQuery[priceRoute];
    const pricePeriodVal = filterQuery[pricePeriod];
    const amenitiesVal = filterQuery[amenitiesRoute];
    const matchVal = filterQuery[matchRoute];
    // eslint-disable-next-line dot-notation
    const isWorkplaceVal = filterQuery['is_workplace'];

    const { isFeedMapVisible } = this.props;

    const priceOptions = reduce(
      priceScheme,
      (result, value, key) => {
        result.push({
          value: key,
          label: value,
        });

        return result;
      },
      [],
    );
    const officeTypeOptions = reduce(
      officeTypesScheme,
      (result, value, key) => {
        result.push({
          value: key,
          label: value,
        });

        return result;
      },
      [],
    );

    return (
      <>
        <SearchBarWrapper seoPage={seoPage} navOpened={mobileNavOpened}>
          <SearchBarRow expanded={expanded}>
            <SearchBarCol size="xxlarge" fullWidthMobile>
              <Select
                name="market"
                value={filterQuery.coworking}
                placeholder="Market"
                backspaceRemoves={false}
                simpleValue
                onChange={val => {
                  console.log('val', val)
                  changeFilterQueryMarket(this.props, val)
                }}
                options={fixtures.map(item => ({
                  value: item.slug,
                  label: item.label,
                  isSubmarket: item.isSubmarket
                }))}
                optionRenderer={(item) => {
                  return (<span style={{ paddingLeft: item.isSubmarket ? '15px' : 0}}>{item.label}</span>)
                }}
                clearable={false}
                searchable={false}
                onOpen={() => this.handleExpand(true)}
                onClose={() => this.handleExpand(false)}
              />
            </SearchBarCol>
            <SearchBarCol size="xllarge" hideMobile>
              <Select
                name={workplaceRoute}
                value={isWorkplaceVal ? 'workplace' : 'coworking'}
                placeholder="Office Type"
                backspaceRemoves={false}
                simpleValue
                onChange={val => {
                  changeFilterQuery(this.props, {
                    is_workplace: val === 'workplace',
                  });
                }}
                options={officeTypeOptions}
                clearable={false}
                searchable={false}
                onOpen={() => this.handleExpand(true)}
                onClose={() => this.handleExpand(false)}
              />
            </SearchBarCol>
            <SearchBarCol size="xlarge" hideMobile>
              <DropDown
                title={
                  capacityVal ? capacityFormat(capacityVal) : 'How Many People?'
                }
                highlight={capacityVal}
                onOpen={() => this.handleExpand(true)}
                onClose={() => this.handleExpand(false)}
              >
                <Counter
                  value={capacityVal}
                  onChange={val =>
                    changeFilterQuery(this.props, {
                      [capacityRoute]: val,
                    })
                  }
                  label="People"
                  placeholder="1"
                  type={2}
                  min={1}
                />
              </DropDown>
            </SearchBarCol>
            <SearchBarCol size="medium" hideMobile>
              <Select
                name={priceRoute}
                value={
                  priceVal && {
                    value: priceVal,
                    label: priceScheme[priceVal],
                  }
                }
                placeholder="Price"
                backspaceRemoves={false}
                simpleValue
                onChange={val => {
                  changeFilterQuery(this.props, {
                    [priceRoute]: val,
                    [pricePeriod]: getPeriodByPrice(val) ? getPeriodByPrice(val).value : null
                  });

                  if (val) {
                    ReactGAEvent({
                      category: 'Listing Filters',
                      action: 'Price',
                      label: val,
                    });
                  }
                }}
                options={priceOptions}
                searchable={!isMobile()}
                onOpen={() => this.handleExpand(true)}
                onClose={() => this.handleExpand(false)}
              />
            </SearchBarCol>
            <SearchBarCol size="medium" hideMobile>
              <Select
                name={pricePeriod}
                value={getPricePeriodVal(priceVal, pricePeriodVal)}
                placeholder=""
                backspaceRemoves={false}
                clearable={false}
                simpleValue
                disabled={!priceVal}
                onChange={val => changeFilterQuery(this.props, {
                  [pricePeriod]: val,
                })}
                options={pricePeriodOptions}
                searchable={false}
                onOpen={() => this.handleExpand(true)}
                onClose={() => this.handleExpand(false)}
              />
            </SearchBarCol>
            {!isWorkplaceVal && (
              <SearchBarCol size="large" hideMobile>
                <DropDown
                  title="More Filters"
                  onOpen={() => this.handleExpand(true)}
                  onClose={() => this.handleExpand(false)}
                  arrow
                  ref={this.filtersDropdown}
                >
                  <FilterList
                    amenitiesByCategory={amenitiesByCategory}
                    amenitiesLookup={amenitiesLookup}
                    amenitiesChecked={amenitiesVal && amenitiesVal.split(',')}
                    teamtypes={teamtypes}
                    teamTypesChecked={matchVal && matchVal.split(',')}
                    onApplyFilters={val => {
                      changeFilterQuery(this.props, {
                        [amenitiesRoute]: !isEmpty(val.amenities)
                          ? val.amenities
                          : null,
                        [matchRoute]: !isEmpty(val.teamtypes)
                          ? val.teamtypes
                          : null,
                      });
                      this.filtersDropdown.current.closeDropdown();
                    }}
                  />
                </DropDown>
              </SearchBarCol>
            )}
          </SearchBarRow>
          {(auth.token || userIsAuthenticated )&& (
            <SearchBarCol size="large">
              <SaveSearch
                marketVal={marketVal}
                filterQuery={filterQuery}
              />
            </SearchBarCol>
          )}
          <SearchBarMeta justify gutter="medium">
            <Button
              scheme="bordered-link"
              spaceBelow="none"
              color="black"
              onClick={() => resetFilterQuery(this.props, isWorkplaceVal)}
            >
              Clear Filters
            </Button>
            {!this.props.noLocations && (
              <SlideToggle
                id="map-toggle"
                label="Map"
                checked={isFeedMapVisible}
                onChange={e => this.props.toogleFeedMapVisibility(e)}
              />
            )}
          </SearchBarMeta>
        </SearchBarWrapper>
      </>
    );
  }
}

const mapStateToProps = state => ({
  fixtures: selectMarketsWithCoords(state),
  filterQuery: state.filterQuery,
  mobileNavOpened: state.ui.mobileNavOpened,
  isFeedMapVisible: state.ui.isFeedMapVisible,
  maps: state.reviews.maps,
  map: state.reviews.map,
  marketVal: selectMarketBySlug(state),
  noLocations: selectNoLocations(state),
  auth: state.cognito,
  userIsAuthenticated: state.auth.userIsAuthenticated
});

export default connect(mapStateToProps, {
  push,
  toogleFeedMapVisibility,
})(SearchBar);
