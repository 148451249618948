import { createReducer } from '../utils';
import { SHOW_MODAL, HIDE_MODAL, SHOW_MODAL_BELOW } from '../constants';

const initialState = {
  modals: [],
};

export default createReducer(initialState, {
  [SHOW_MODAL]: (state, payload) => ({
    ...state,
    modals: [
      ...state.modals,
      {
        modalType: payload.modalType,
        modalProps: payload.modalProps,
        modalOptions: payload.modalOptions,
      },
    ],
  }),
  [SHOW_MODAL_BELOW]: (state, payload) => ({
    ...state,
    modals: [
      {
        modalType: payload.modalType,
        modalProps: payload.modalProps,
        modalOptions: payload.modalOptions,
      },
      ...state.modals,
    ],
  }),
  [HIDE_MODAL]: state => ({
    ...state,
    modals: state.modals.filter(
      (item, index) => index !== state.modals.length - 1,
    ),
  }),
});
