import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import classnames from 'classnames';

import './styles.scss';

const deferredOnChange = debounce((value, onChange) => {
  onChange(value);
}, 700);

const Counter = props => {
  const { label, placeholder, type, units, min } = props;
  const classes = classnames(
    'Counter',
    { '-hasValue': props.value },
    `-type-${type}`,
  );
  const [value, setValue] = useState(props.value);

  const handleChange = (event) => {
    event.persist();
    setValue(event.target.value);
    deferredOnChange(event.target.value, props.onChange);
  };

  const increase = (event) => {
    event.persist();
    const clearedValue = value ? parseInt(value, 10) : min;
    const responseValue = clearedValue + 1;

    setValue(responseValue);
    deferredOnChange(responseValue, props.onChange);
  };

  const decrease = (event) => {
    event.persist();
    const clearedValue = value ? parseInt(value, 10) : min;

    const responseValue = clearedValue > min ? clearedValue - 1 : null;

    setValue(responseValue);
    deferredOnChange(responseValue, props.onChange);
  };

  return (
    <div className={classes}>
      {type === 2 && <span className="Counter-label">{label}</span>}
      <button
        className="Counter-btn -decrease"
        type="button"
        onClick={decrease}
      >
        <span>-</span>
      </button>
      <input
        className="Counter-input"
        type="number"
        min={min}
        step="1"
        value={value || ''}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {value && units && (
        <span className="Counter-units">{`${value} ${units}`}</span>
      )}
      <button
        className="Counter-btn -increase"
        type="button"
        onClick={increase}
      >
        <span>+</span>
      </button>
    </div>
  );
};

Counter.defaultProps = {
  value: 1,
  type: 1,
  min: 0,
};

export default Counter;
