import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Heading from '../../components/heading';
import MemberReviews from '../../components/member-reviews';
import { mdUp } from '../../styles/theme/breakpoints';
import { dataByMarket } from './market-data';
import { Link } from 'react-router-dom';
import { getAskingprice } from '../../actions/seo';
import marketsGet from '../../actions/markets';

export const BestOfSection = ({ marketSlug }) => {
  const marketData = dataByMarket[marketSlug];

  return (
    <div className="Container">
      <Heading
        center
        level="2"
        mobileScheme="landing"
        style={{
          color: 'black',
          width: '100%',
        }}
      >
        The Best Coworking Spaces In {marketData?.name || ''} {marketData?.blogPost && (
          <a style={{ color: 'black', textDecoration: 'underline' }} href={marketData.blogPost}>see all</a>
        )}
      </Heading>
      {marketData?.bestOf && (
        <LogoList>
          <li key="bestfor"><span>Best for:</span></li>
          {marketData.bestOf.map(item => (
            <li key={item.name}>
              {item?.logo && (
                <figure>
                  <img src={item.logo} alt={item.name} />
                </figure>
              )}
              <span>{item.bestFor}</span>
            </li>
          ))}
        </LogoList>
      )}
    </div>
  );
}

const LogoList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  align-item: flex-end;
  justify-content: center;
  padding-bottom: 40px;

  @media (min-width: 40em) {
    padding-bottom: 40px;
  }

  li {
    &:not(:first-child) {
      width: 25%;
      flex: 0 0 25%;
    }
    text-align: center;
    align-self: flex-end;

    span {
      font-weight: 600;
      display: block;
      font-size: 16px;

      @media (min-width: 40em) {
        font-size: 20px;
      }
    }
  }

  figure {
    height: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    margin: 0 10px 40px 10px;

    img {
      width: auto;
      max-width: 200px;
      margin: auto;
      position: absolute;
      max-height: 100px;
    }
  }
`

export const FilterSectionTitle = ({ marketSlug }) => {
  const marketData = dataByMarket[marketSlug];

  return (
    <Heading
      center
      level="2"
      mobileScheme="landing"
      style={{ color: 'black', width: '100%', marginTop: 20, marginBottom: 20 }}
    >
      Find Coworking Space in {marketData?.name || ''} - Choose From More Than {marketData?.locCount || ''} Locations
    </Heading>
  );
}

export const AboutRow = ({ marketSlug }) => {
  const marketData = dataByMarket[marketSlug];
  const chartData = useSelector(state => state.seo.priceBySlug[marketSlug]) || []
  const price = (chartData[chartData.length - 1] || {}).PricePerSeat

  return (
    <div className="Container">
      <Row>
        <Col data-col="12" data-styles="about">
          <Heading
            center
            level="2"
            mobileScheme="landing"
            style={{ marginBottom: 10, color: 'black' }}
          >
            Coworking Spaces in {marketData?.name || ''}
          </Heading>
          <p>
            Are you looking to find coworking office space in {marketData?.name || ''}? You’ll
            find the most flexible office options in {marketData?.name || ''} on the Upsuite
            Network. We consolidate workspaces from multiple sources to provide
            a complete view of the flexible commercial real estate market. With
            more than <strong>{marketData?.locCount || ''}</strong> locations throughout {marketData?.name || ''}, you’re sure to find
            the perfect office match. We offer coworking, executive offices,
            team suites, meeting rooms, and more. Expect to pay around <strong>{`${price ? `$${Math.round(price)}` : ''}`} per
            person per month</strong>. Of course, rent prices vary based on city, size,
            and the amenities you choose. You may pay less for a coworking hot
            desk, and more for an executive office suite. Rent office space from
            one hour or up to three years and longer.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export const ReviewsRow = () => {
  return (
    <div className="Container">
      <Row>
        <Col data-col="6" data-styles="benefits">
          <Heading
            center
            level="2"
            mobileScheme="landing"
            style={{ marginBottom: 30, color: 'black' }}
          >
            Benefits of Finding Coworking Space with Upsuite
          </Heading>
          <ul>
            <li>Largest Coworking Space Selection</li>
            <li>No cost Coworking Advisor</li>
            <li>Exclusive discounts for Companies</li>
            <li>Satisfaction Guarantee</li>
          </ul>
        </Col>
        <Col data-col="6">
          <Heading
            center
            level="2"
            mobileScheme="landing"
            style={{ marginBottom: 20, color: 'black' }}
          >
            Member Reviews of Upsuite
          </Heading>
          <MemberReviews
            text="Had a really great experience working with Upsuite! Very professional, fast service – Upsuite helped me find the perfect space within days."
            author="Julia Bricker"
            company="WTax"
          />
        </Col>
      </Row>
    </div>
  );
};

export const PopularRow = ({ marketSlug }) => {
  const marketData = dataByMarket[marketSlug];

  return (
    <div className="Container">
      <Row data-wrap>
        <Col data-col="12" data-styles="popular-locations">
          <Heading
            center
            level="2"
            mobileScheme="landing"
            style={{ marginBottom: 30, color: 'black' }}
          >
            Popular Locations for Coworking Space in {marketData?.name || ''}
          </Heading>
          {marketData?.popularLocations && (
            <ul>
              {marketData?.popularLocations.map(item => (
                <li key={item.name}><Link to={item.link}>{item.name}</Link></li>
              ))}
            </ul>
          )}
        </Col>
        <Col data-col="12" data-styles="popular-searches">
          <Heading
            center
            level="2"
            mobileScheme="landing"
            style={{ marginBottom: 30, marginTop: 30, color: 'black' }}
          >
            Other Popular Searches
          </Heading>
          <ul>
            <li>
              <Link to={`/workspace/${marketSlug}/?people=4`}>
                4 Person Private Office in {marketData?.name || ''}
              </Link>
            </li>
            <li>
              <Link to={`/coworking/${marketSlug}/?amenities=DogFriendly`}>
                Pet Friendly Coworking Spaces in {marketData?.name || ''}
              </Link>
            </li>
            <li>
              <Link to={`/coworking/${marketSlug}/?amenities=FitnessCenter`}>
                Locations With On Site Fitness Centers
              </Link>
            </li>
            <li>
              <Link
                to={`/coworking/${marketSlug}/?amenities=PublicTransportation`}
              >
                Within 5 minutes of Public Transportation
              </Link>
            </li>
            <li>
              <Link to={`/coworking/${marketSlug}/`}>
                Locations with Virtual Tours
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export const FaqRow = ({ marketSlug }) => {
  const dispatch = useDispatch();
  const marketData = dataByMarket[marketSlug];
  const { id: marketId } = useSelector(state => state.markets.bySlug[marketSlug]) || {}
  const chartData = useSelector(state => state.seo.priceBySlug[marketSlug]) || []
  const period = (chartData[chartData.length - 1] || {}).Label
  const price = (chartData[chartData.length - 1] || {}).PricePerSeat

  useEffect(() => {
    if (marketId) {
      dispatch(getAskingprice(marketSlug, marketId))
    }
    // eslint-disable-next-line
  }, [marketId, marketSlug, dispatch])

  useEffect(() => {
    dispatch(marketsGet())
    // eslint-disable-next-line
  }, [])

  return (
    <div className="Container">
      <Row>
        <Col data-col="12" data-styles="faq">
          <Heading center level="2" mobileScheme="landing">
            Frequently Asked Questions
          </Heading>
          <ol>
            <li>
              <span>
                What's the average cost for a coworking space in{' '}
                {marketData?.name || ''}?
              </span>
              <ul>
                <li>
                  {`The average cost per person for a coworking space ${
                    period ? `in ${period} is $${Math.round(price)}` : '...'
                  }. Find more about `}
                  <a href="https://www.upsuite.com/blog/coworking-space-costs-average-membership-pricing/">
                    coworking space costs
                  </a>
                  .
                </li>
              </ul>
            </li>
            <li>
              <span>
                How many coworking space providers are in{' '}
                {marketData?.name || ''}?
              </span>
              <ul>
                <li>
                  There are many coworking space providers in{' '}
                  {marketData?.name || ''}. They offer several working
                  environments for different company needs. Go through the{' '}
                  <a href={marketData.blogPost}>
                    top coworking space providers in {marketData?.name || ''}
                  </a>
                  .
                </li>
              </ul>
            </li>
            <li>
              <span>How to choose the right coworking space for you?</span>
              <ul>
                <li>
                  There are several significant factors to consider when
                  choosing the right coworking space. The most important ones
                  include: strong Wi-Fi, space size, and number of seats. Check
                  out this guide on{' '}
                  <a href="https://www.upsuite.com/blog/how-to-choose-coworking-office-space-find-the-right-location-terms-for-your-budget/">
                    how to choose the right coworking space
                  </a>
                  .
                </li>
              </ul>
            </li>
            <li>
              <span>
                How many coworking spaces are available in{' '}
                {marketData?.name || ''}?
              </span>
              <ul>
                <li>
                  There are different coworking spaces for different company
                  needs. Currently, there are {marketData?.locCount || ''}{' '}
                  available{' '}
                  <Link to={window.location.pathname}>
                    coworking space locations in {marketData?.name || ''}
                  </Link>
                  .
                </li>
              </ul>
            </li>
          </ol>
        </Col>
      </Row>
    </div>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: -15px;
  margin-left: -15px;

  @media (min-width: 40em) {
    flex-direction: row;
  }

  &[data-wrap="true"] {
    flex-wrap: wrap;
  }
`;

const Col = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 15px;

  &[data-col='6'] {
    @media (min-width: 40em) {
      width: 50%;
      flex: 0 0 50%;
    }
  }

  &[data-col='7'] {
    @media (min-width: 40em) {
      width: 60%
      flex: 0 0 60%
    }
  }

  &[data-col='5'] {
    @media (min-width: 40em) {
      width: 40%;
      flex: 0 0 40%;
    }
  }

  &[data-col='4'] {
    @media (min-width: 40em) {
      width: 33%;
      flex: 0 0 33%;
    }
  }

  &[data-styles='about'] {
    p {
      font-size: 20px;
      line-height: 1.3;
    }
  }

  &[data-styles='counter'] {
    text-align: center;

    @media (min-width: 40em) {
      &:first-child {
        text-align: right;
      }

      &:last-child {
        text-align: left;
      }
    }

    span {
      display: block;
    }

    span:first-child {
      font-size: 50px;
      font-weight: 600;
    }

    span:last-child {
      font-size: 20px;
      font-weight: 600;
    }
  }

  &[data-styles='table'] {
    table {
      width: 100%;
      font-size: 16px;

      @media ${mdUp} {
        font-size: 18px;
      }

      th {
        text-align: left;
        text-decoration: underline;
      }

      th,
      td {
        padding: 2px;
        vertical-align: top;
      }
    }

    h3 {
      text-decoration: underline;
      margin-bottom: 5px;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        font-size: 17px;
        margin-bottom: 3px;
      }
    }
  }

  &[data-styles='benefits'] {
    ul {
      li {
        font-size: 20px;
        margin-bottom: 5px;
      }
    }
  }

  &[data-styles='faq'] {

    ol {
      font-size: 22px;
      font-weight: 600;

      @media ${mdUp} {
        margin-left: 20px;
      }
    }

    ul {
      list-style-type: lower-latin;
      font-size: 20px;
      font-weight: 400;
    }

    li {
      margin-bottom: 10px;

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &[data-styles='popular-searches'],
  &[data-styles='popular-locations'] {
    font-size: 18px;

    ul {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        margin-bottom: 20px;
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;

        @media ${mdUp} {
          width: auto;
        }

        a {
          display: block;
          background-color: #fff;
          padding: 9px 20px;
          color: var(--brand-color);
          line-height: 1.3;
          border: 2px solid var(--brand-color);
          font-weight: 600;
          box-shadow: 0 0.4em 0.3em rgb(0 0 0 / 16%);
          border-radius: 0.36em;
          min-width: 250px;
          text-align: center;
          width: 100%;

          @media ${mdUp} {
            width: auto;
          }
        }
      }
    }
  }
`;
