import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Helmet from 'react-helmet';

import Flex from '../../components/flex';
import Slider from '../SingleListing/slider';
import Verified from '../../components/verified';
import ButtonMeta from '../../components/button-meta';
import Button from '../../components/button';
import ButtonGroupMeta from '../../components/button-group-meta';
import Tooltip from '../../components/tooltip';
import { PlusAlt, ShareAlt, Multiple } from '../../components/icons';
import BestOf from '../../components/best-of';
import { Mobile, Default } from '../../components/responsive-helpers';
import Counter from '../../components/notifications-counter';
import HealthyFlag from '../../components/healthy-space-flag';

import { listingBySlugGet } from '../../actions/listings';
import { openShareModal, openRegisterModal } from '../../actions/modal';
import toggleFavorites from '../../actions/favorites';
import { selectSingleListing } from '../../selectors/listings';

import { COMPARE, WORKPLACE, ROOT } from '../../settings/routes';

import {
  verifiedText,
  verifiedNewText,
  healthySpaceText,
} from '../../settings/text';

const EmbedLocation = props => {
  const { slug, marketSlug } = props.match.params;

  useEffect(() => {
    props.listingBySlugGet(slug, marketSlug, true);
  // eslint-disable-next-line
  }, [slug, marketSlug]);

  const {
    listing: {
      upsuiteVerified,
      marketName,
      id,
      accolades,
      isHealthy,
      newLocation,
    },
    listing,
    listingLoading,
    isFavorite,
    isWorkspace,
  } = props;
  const sliderHeight = listingLoading ? 450 : null;

  if (!listing?.id) {
    return null;
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      <Flex column shrink>
        <div
          style={{
            height: sliderHeight,
            width: '100%',
            position: 'relative',
          }}
        >
          {(upsuiteVerified || isHealthy) && (
            <div className="TooltipSingleSlider">
              {upsuiteVerified && (
                <Tooltip
                  placement="bottomLeft"
                  overlay={newLocation ? verifiedNewText : verifiedText}
                >
                  <div>
                    <Verified newLocation={newLocation} />
                  </div>
                </Tooltip>
              )}
              {isHealthy && (
                <Tooltip placement="bottomLeft" overlay={healthySpaceText}>
                  <div>
                    <HealthyFlag upsuiteVerified={upsuiteVerified} />
                  </div>
                </Tooltip>
              )}
            </div>
          )}
          <Default>
            <ButtonGroupMeta position="topright" gutter="small">
              <ButtonMeta
                svgIcon={<ShareAlt />}
                onClick={() =>
                  props.openShareModal({
                    params: `${marketSlug}/${slug}`,
                    isWorkspace,
                  })
                }
                className="share-button"
              >
                Share
              </ButtonMeta>
              <ButtonMeta
                isActive={isFavorite}
                onClick={() => props.toggleFavorites(id, isWorkspace)}
                className="compare-button"
              >
                + Save
              </ButtonMeta>
              {!isEmpty(props.favorites) && (
                <Counter count={props.favorites.length} type="listing">
                  <a
                    href={`/${isWorkspace ? WORKPLACE : ROOT}/${COMPARE}/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Saved Spaces
                  </a>
                </Counter>
              )}
            </ButtonGroupMeta>
          </Default>
          <Mobile>
            <ButtonGroupMeta column={false} position="topright" gutter="medium">
              <Button
                onClick={() =>
                  props.openShareModal({
                    params: `${marketSlug}/${slug}`,
                    isWorkspace,
                  })
                }
                className="share-button"
                scheme="icons"
              >
                <ShareAlt className="export-button-icon" />
              </Button>
              <Button
                onClick={() => props.toggleFavorites(id, isWorkspace)}
                className="compare-button"
                scheme="icons"
              >
                <PlusAlt className="export-button-icon" />
              </Button>
              {!isEmpty(props.favorites) && (
                <Counter count={props.favorites.length} type="icon">
                  <a
                    href={`/${isWorkspace ? WORKPLACE : ROOT}/${COMPARE}/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Multiple />
                  </a>
                </Counter>
              )}
            </ButtonGroupMeta>
          </Mobile>
          <Slider listing={listing} isWorkplace={isWorkspace} visitorId isEmbed />
          {!isEmpty(accolades) && upsuiteVerified && (
            <BestOf
              features={accolades}
              location={marketName}
              tooltip="Users have ranked this location as best in class for specific amenity, community, and environment attributes"
            />
          )}
        </div>
      </Flex>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const isWorkspace = window.location.pathname.includes('/workspace/');
  const { singleLoading: listingLoading } = state.listings;
  const listing = selectSingleListing(state, ownProps);
  const { id } = listing;
  const isFavorite = isWorkspace
    ? state.favorites.workplaceIds && state.favorites.workplaceIds.includes(id)
    : state.favorites.ids && state.favorites.ids.includes(id);

  return {
    listingLoading,
    listing,
    isFavorite,
    favorites: isWorkspace ? state.favorites.workplaceIds : state.favorites.ids,
    isWorkspace,
  };
};

export default connect(mapStateToProps, {
  listingBySlugGet,
  openShareModal,
  toggleFavorites,
  openRegisterModal,
})(EmbedLocation);
