import React, { Component } from 'react';
import classnames from 'classnames';
import sortBy from 'lodash/sortBy';
import amenitiesLookup from '../../settings/amenities-lookup';
import { getLocCleaningPoliciesFile } from '../../utils/crm-links';

import './styles.scss';

const AmenityItem = ({ amenity: { disabled, title, icon, link }, cleaningPoliciesPDFLink, locationId }) => {
  const Icon = icon;
  const classes = classnames(
    'CompareAmenities-item',
    { '-disabled': disabled },
  );

  return (
    <li className={classes}>
      {(link && cleaningPoliciesPDFLink && !disabled) ? (
        <a
          href={getLocCleaningPoliciesFile(locationId)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon linkable />
          <span>{title}</span>
        </a>
      ) : (
        <>
          <Icon />
          <span>{title}</span>
        </>
      )}
    </li>
  );
};

class CompareAmenities extends Component {
  constructor() {
    super();
    this.state = {
      height: 'auto',
    };
  }

  componentDidMount() {
    this.getNewHeight();
  }

  componentDidUpdate(prevProps) {
    const { expanded } = this.props;

    if (expanded !== prevProps.expanded) {
      this.getNewHeight();
    }
  }

  getNewHeight() {
    this.setState({ height: this.content && (this.content.offsetHeight - 3) });
  }

  render() {
    const { amenities, expanded, onExpand, locationId, cleaningPoliciesPDFLink } = this.props;
    const { height } = this.state;

    let sortedAmenities = sortBy(amenities.map((item) => {
      if (!amenitiesLookup[item.key]) return null;

      return ({
        title: amenitiesLookup[item.key].title,
        key: item.key,
        icon: amenitiesLookup[item.key].icon,
        disabled: item.disabled,
        link: amenitiesLookup[item.key].link,
      });
    }).filter(Boolean), 'title');

    sortedAmenities = expanded ? sortedAmenities : sortedAmenities.slice(0, 7);

    return (
      <div className="CompareAmenities" style={{ overflow: 'hidden' }}>
        <div className="CompareAmenities-wrap" style={{ height }}>
          <ul
            className="CompareAmenities-list"
            ref={c => {
              this.content = c;
            }}
          >
            {sortedAmenities.map(amenity => (
              <AmenityItem
                key={amenity.key}
                amenity={amenity}
                locationId={locationId}
                cleaningPoliciesPDFLink={cleaningPoliciesPDFLink}
              />
            ))}
          </ul>
        </div>
        <button
          className="CompareAmenities-toggle"
          type="button"
          onClick={() => onExpand()}
        >
          <span>{!expanded ? '+' : '-'}</span>
        </button>
      </div>
    );
  }
}

export default CompareAmenities;
