import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { Auth } from 'aws-amplify'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'
import { Wrap } from './styles'
import Flex from '../../components/flex'
import Heading from '../../components/heading'
import { EyeVisible, EyeHidden } from '../../components/icons'
import Button from '../../components/button'
import { profile } from '../../settings/routes'
import { accountGet } from '../../actions/lead'
import { authSessionSuccess, authSigninSuccess } from '../../actions/cognito'
import { passwordEndReset } from '../../api/leads'
import { checkHttpResponse, parseJSON } from '../../utils'

const validationInitial = {
  newIsEmpty: true,
  confirmIsEmpty: true,
  charactersLength: true,
  containsNumber: true,
  containsUppercase: true,
  containsLowercase: true,
  notMatch: true
}

const ResetPass = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { email, code, redirect } = queryString.parse(search)
  const [newPassVisible, setNewPassVisible] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassVisible, setConfirmPassVisible] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [validation, setValidation] = useState(validationInitial)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!(code || email)) {
      dispatch(push('/coworking/'))
    }
  }, [dispatch, code, email, redirect])

  useEffect(() => {
    setValidation({
      newIsEmpty: newPassword.length === 0,
      confirmIsEmpty: confirmPassword.length === 0,
      charactersLength: newPassword.length < 8,
      containsNumber: !/\d/.test(newPassword),
      containsUppercase: !/[A-Z]/g.test(newPassword),
      containsLowercase: !/[a-z]/g.test(newPassword),
      notMatch: confirmPassword !== newPassword
    })
  }, [newPassword, confirmPassword])

  const handleSubmit = () => {
    setLoading(true)

    passwordEndReset({
      email: window.encodeURIComponent(email),
      code
    })
      .then(checkHttpResponse)
      .then(parseJSON)
      .then(() => {
        Auth.signIn(email, code)
          .then(response => {
            if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
              Auth.completeNewPassword(response, newPassword)
                .then(user => {
                  setLoading(false)

                  if (user.signInUserSession) {
                    dispatch(
                      authSessionSuccess(user.signInUserSession.idToken.jwtToken)
                    )
                    dispatch(
                      authSigninSuccess(user.signInUserSession.idToken.jwtToken)
                    )
                    dispatch(accountGet(user.signInUserSession.idToken.jwtToken))
                  }

                  dispatch(
                    push(redirect || `/${profile.main}/${profile.settings}/`)
                  )
                })
                .catch(error => {
                  setLoading(false)
                  setError(error && error.message ? error.message : error)
                })
            }
          })
          .catch(error => {
            setLoading(false)
            setError(error && error.message ? error.message : error)
          })
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          setError('An unknown server error occurred')
        }
      })
  }

  return (
    <Wrap>
      <Container>
        <Flex column fullHeight grow>
          {email && code && (
            <>
              <Heading
                level="1"
                size="xsmall"
                spaceBelow="small"
                spaceAbove="medium"
              >
                Password Reset
              </Heading>

              <form onSubmit={e => e.preventDefault()}>
                <FieldRow>
                  <Label htmlFor="newPassword">Password</Label>
                  <Field>
                    <Input
                      id="newPassword"
                      name="newPassword"
                      type={newPassVisible ? 'text' : 'password'}
                      value={newPassword}
                      disabled={loading}
                      onChange={e => {
                        setNewPassword(e.target.value)
                      }}
                    />
                    <Icon
                      type="button"
                      onClick={() => setNewPassVisible(!newPassVisible)}
                    >
                      {newPassVisible ? <EyeVisible /> : <EyeHidden />}
                    </Icon>
                  </Field>
                  <Validation data-margin>
                    {validation.newIsEmpty && <span>Required</span>}
                    {validation.charactersLength && (
                      <span>Password is at least 8 characters long</span>
                    )}
                    {validation.containsNumber && (
                      <span>Password contains at least 1 number</span>
                    )}
                    {validation.containsUppercase && (
                      <span>Password contains at least 1 uppercase letter</span>
                    )}
                    {validation.containsLowercase && (
                      <span>Password contains at least 1 lowercase letter</span>
                    )}
                    {validation.notMatch && (
                      <span>Passwords Don&apos;t Match</span>
                    )}
                  </Validation>
                  <Label htmlFor="confirmPassword">Confirm Password</Label>
                  <Field>
                    <Input
                      id="confirmPassword"
                      name="confirmPassword"
                      type={confirmPassVisible ? 'text' : 'password'}
                      value={confirmPassword}
                      disabled={loading}
                      onChange={e => {
                        setConfirmPassword(e.target.value)
                      }}
                    />
                    <Icon
                      type="button"
                      onClick={() => setConfirmPassVisible(!confirmPassVisible)}
                    >
                      {confirmPassVisible ? <EyeVisible /> : <EyeHidden />}
                    </Icon>
                  </Field>
                  <Validation>
                    {validation.confirmIsEmpty && <span>Required</span>}
                  </Validation>
                </FieldRow>
                {error && (
                  <Validation data-margin>
                    <span>{error}</span>
                  </Validation>
                )}
                <BtnGroup>
                  <Button
                    type="button"
                    disabled={
                      Object.values(validation).filter(item => item).length >
                        0 || loading
                    }
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </BtnGroup>
              </form>
            </>
          )}
        </Flex>
      </Container>
    </Wrap>
  )
}

const Field = styled.div`
  height: 40px;
  max-width: 100%;
  width: 100%;
  box-shadow: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  background: #f2f2f2;
  margin-bottom: 10px;
  max-width: 250px;
  display: flex;
  align-items: center;
`

const Input = styled.input`
  border: none;
  padding: 10px 12px;
  font-size: 14px;
  font-family: 'Euclid Circular B', sans-serif;
  font-size: 14px;
  background: transparent;
  width: 100%;
`

const Icon = styled.button.attrs({ type: 'button' })`
  border: none;
  background: none;
  box-shadow: none;
  cursor: pointer;
  padding: 0;
  width: 46px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Label = styled.label`
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
`

const Validation = styled.div`
  color: red;

  > span {
    display: block;
  }

  &[data-margin] {
    margin-bottom: 20px;
  }
`

const FieldRow = styled.div`
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 25px;
  }
`

const BtnGroup = styled.div`
  button {
    margin-right: 20px;
  }
`

const Container = styled.div`
  padding: 0 20px;
`

export default ResetPass
