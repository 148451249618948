import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import supercluster from 'points-cluster';
import { push } from 'connected-react-router';

import { MainClusterMarker as Cluster } from '../../components/cluster-marker';

import marketsGet from '../../actions/markets';
import { getLocByStatus } from '../../actions/main-map';
import {
  MAPS_API_KEY as mapsKey,
} from '../../settings';

import {
  createMainMapOptions, getMapBounds,
} from '../../utils/map-helpers';
import { ROOT, CLOSED_LOC } from '../../settings/routes';

const Map = (props) => {
  const dispatch = useDispatch();
  const [clusters, setClusters] = useState(null);
  const [map, setMap] = useState(null);
  const locations = useSelector(state => state.mainMap.isClosed);

  useEffect(() => {
    dispatch(getLocByStatus('isClosed'));
    dispatch(marketsGet());
  }, [dispatch]);

  useEffect(() => {
    if (map && locations) {
      const center = map.getCenter();
      const zoom = map.getZoom();
      const bounds = getMapBounds(map);

      const clusterSettings = {
        minZoom: 0,
        maxZoom: 15,
        radius: 30,
      };

      const mapClusters = supercluster(locations.map(item => ({
        lat: item.latitude,
        lng: item.longitude,
        id: item.id,
        marketSlug: item.marketSlug,
      })), clusterSettings)({
        center,
        zoom,
        bounds,
      });

      setClusters(mapClusters.map(({
        wx, wy, numPoints, points,
      }) => ({
        lat: wy,
        lng: wx,
        numPoints,
        id: `${points[0].id}`,
        points,
        marketSlug: points[0].marketSlug,
      })));
    }
  }, [map, locations]);

  return (
    <div style={props.style} className="MainMap">
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapsKey }}
        defaultCenter={{ lat: 41.08013, lng: -98.20684 }}
        defaultZoom={props.zoom}
        options={createMainMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={(api) => {
          setMap(api.map);
        }}
      >
        {clusters && clusters.map((cluster) => (
          <Cluster
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...cluster}
            key={cluster.id}
            onClick={() => cluster.marketSlug && dispatch(push(`/${ROOT}/${cluster.marketSlug}/${CLOSED_LOC}/`))}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
