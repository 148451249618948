/* global window, document */
export const scrollTop = () => window.scrollTo(0, 0);

export const scrollTo = s => window.scrollTo(0, s);

export const bodyClassAdd = cssClass =>
  document.body.classList.add(cssClass);

export const bodyClassRemove = cssClass =>
  document.body.classList.remove(cssClass);

export const bodyClassToggle = (cssClass, isActive) => {
  if (isActive) {
    return bodyClassAdd(cssClass);
  }

  return bodyClassRemove(cssClass);
};
