import React from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { CompareColumn } from '../../components/compare-page-helpers'
import CompareListingCard from '../../components/compare-listing-card'

import {
  openRegisterModal,
  openInteractiveTourModal
} from '../../actions/modal'

import toggleFavorites from '../../actions/favorites'
import { trackVirtualTourModal } from '../../utils/analytics'
import { ROOT } from '../../settings/routes'

const ListingCards = props => {
  const {
    listings,
    paginate,
    visitorId
  } = props
  const listing =
    paginate >= 0 && paginate <= listings.length - 1 ? listings[paginate] : {}

  return (
    !isEmpty(listing) && (
      <CompareColumn className="-mobile">
        <CompareListingCard
          removeFavorites={() => props.toggleFavorites(listing.id)}
          key={listing.id}
          onMount={() => {}}
          mobile
          onVirtualTourClick={url => {
            trackVirtualTourModal(
              `/${ROOT}/${listing.marketName}/${listing.marketplaceSlug}/`
            )
            props.openInteractiveTourModal({
              tourUrl: url,
              initialValues: {
                locations: [listing.id],
                isWorkspace: [false],
                visitorId
              }
            })
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...listing}
        />
      </CompareColumn>
    )
  )
}

const mapStateToProps = state => ({
  spaces: state.spaces.byLocationId,
  greviews: state.reviews.greviewsById,
  map: state.reviews.map,
  markets: state.markets.byName
})

export default connect(mapStateToProps, {
  toggleFavorites,
  openRegisterModal,
  openInteractiveTourModal
})(ListingCards)
