export const ROOT = 'coworking'
export const WORKPLACE = 'workspace'
export const LANDING = 'coworking-spaces'
export const HEALTHY = 'healthy-spaces'
export const CLOSED_LOC = 'locations-closed'
export const NEW_LOC = 'locations-new'
export const BEST = 'best'
export const AVERAGE = 'average-cost'
export const COMPARE = 'compare'
export const SEARCH = 'search'
export const COGNITO_REDIRECT = `redirect`
export const UNSUBSCRIBE = 'unsubscribe'
export const USER_MANAGEMENT = `user-management`

export const profile = {
  main: 'profile',
  spaces: 'saved-spaces',
  searches: 'searches',
  settings: 'settings',
  verify: 'verify',
  pass: 'password'
}
