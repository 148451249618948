import { checkHttpStatus, parseJSON } from '../utils';
import {
  GAPIS_GET_REVIEWS_REQUEST,
  GAPIS_GET_REVIEWS_SUCCESS,
  CONCIERGE_REVIEWS_GET_REQUEST,
  CONCIERGE_REVIEWS_GET_SUCCESS,
  CONCIERGE_REVIEWS_GET_FAILURE,
  BIND_MAP_TO_STATE,
} from '../constants';
import * as api from '../api/listings';

export const bindMapToState = payload => ({
  type: BIND_MAP_TO_STATE,
  payload,
});

const conciergeReviewsGetRequest = () => ({
  type: CONCIERGE_REVIEWS_GET_REQUEST,
});

const conciergeReviewsGetSuccess = (payload, config) => ({
  type: CONCIERGE_REVIEWS_GET_SUCCESS,
  payload,
  config,
});

const conciergeReviewsGetFailure = () => ({
  type: CONCIERGE_REVIEWS_GET_FAILURE,
});

export const conciergeReviewsGet = id =>
  (dispatch, getState) => {
    if (!id || getState().reviews.conciergeReviewsById[id]) {
      return null;
    }

    dispatch(conciergeReviewsGetRequest());

    return api.conciergeReviewsGet(id)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(conciergeReviewsGetSuccess(response, id));
      })
      .catch(() => {
        dispatch(conciergeReviewsGetFailure());
      });
  };

const gapisGetReviewsRequest = () => ({
  type: GAPIS_GET_REVIEWS_REQUEST,
});

const gapisGetReviewsSuccess = payload => ({
  type: GAPIS_GET_REVIEWS_SUCCESS,
  payload,
});

const getDetails = (service, placeID, id, dispatch) => service.getDetails({
  placeId: placeID,
  fields: ['rating', 'reviews'],
}, (detailsResponse, status) => {
  if (status === 'INVALID_REQUEST') {
    return null;
  }

  if (status === 'OVER_QUERY_LIMIT') {
    setTimeout(() => getDetails(service, placeID, id, dispatch), 2000);
  }

  return dispatch(gapisGetReviewsSuccess({ detailsResponse, id }));
});

export const gapisGetReviews = (placeID, id, map) => (dispatch, getState) => {
  if (!map || !id || !placeID || getState().reviews.greviewsById[id]) {
    return null;
  }

  dispatch(gapisGetReviewsRequest());

  if (!window.google.maps.places) {
    return null;
  }

  const service = new window.google.maps.places.PlacesService(map);

  return getDetails(service, placeID, id, dispatch);
};

export const getReviewsByQuery = (listing, map) => (dispatch) => {
  const { placeID, id } = listing;
  if (!placeID) return null;

  return dispatch(gapisGetReviews(
    placeID,
    id,
    map,
  ));
};
