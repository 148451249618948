export const required = value => !value && 'Please complete this required field.';

export const email = (value) => {
  if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(value)) {
    return 'Email must be formatted correctly.';
  }

  return undefined;
};

export const positiveNumber = value => parseInt(value, 10) <= 0 && 'Please enter a number that\'s greater than or equal to 1.';

export const allFalse = o => Object.keys(o).every(k => !o[k]);

export const isNum = val => !/[^[0-9]]*/.test(val);
