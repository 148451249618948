import { createSelector } from 'reselect';
import {
  CAPACITY_ROUTE as capacityRoute,
  PRICE_ROUTE as priceRoute,
  SEARCH_QUERY,
} from '../settings';
import { ROOT } from '../settings/routes';
import { urlFilterQuery } from '../utils/filter-url';

const nearestMarket = state => state.location.nearestMarket;

const marketsByName = state => state.markets.byName;
const marketsBySlug = state => state.markets.bySlug;

export const getRoute = state => urlFilterQuery(state.filterQuery);

export const selectCapacity = createSelector(
  state => state.filterQuery[capacityRoute],
  capacity => capacity,
);

export const selectPrice = createSelector(
  state => state.filterQuery[priceRoute],
  price => price,
);

export const selectMarket = createSelector(
  state => state.filterQuery[ROOT] || state.filterQuery[SEARCH_QUERY],
  market => market,
);

export const selectMarketBySlug = createSelector(
         state =>
           (state.markets.bySlug[state.filterQuery[ROOT]] || {}).name ||
           state.filterQuery[SEARCH_QUERY],
         market => market,
       );

export const selectMarketMeta = createSelector(
  (state) => {
    return ({
      metaTitle: (state.markets.bySlug[state.filterQuery[ROOT]] || {}).metaTitle,
      metaDescription: (state.markets.bySlug[state.filterQuery[ROOT]] || {}).metaDescription,
    })
  },
  marketMeta => marketMeta,
);

export const selectNearestMarket = createSelector(
  nearestMarket,
  marketsByName,
  (market, byName) => byName[market],
);

export const selectMarketObject = createSelector(
  state => state.filterQuery[ROOT],
  marketsBySlug,
  (market, bySlug) => bySlug[market],
);

export const selectIfIsSearch = createSelector(
  state => !state.filterQuery[ROOT] && !state.filterQuery[SEARCH_QUERY],
  isSearch => isSearch,
);
