import includes from 'lodash/includes';
import { HEALTHY, CLOSED_LOC, NEW_LOC, BEST, WORKPLACE } from '../settings/routes';

export const mapExtraFilterQuery = (url) => ({
  is_healthy: includes(url, HEALTHY),
  is_closed: includes(url, CLOSED_LOC),
  is_new: includes(url, NEW_LOC),
  is_best_of: includes(url, BEST),
  is_workplace: includes(url, WORKPLACE),
});

export const resetExtraFilterQuery = {
  is_healthy: null,
  is_closed: null,
  is_new: null,
  is_best_of: null,
  is_workplace: false,
};
