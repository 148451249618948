import React from 'react';
import styled from 'styled-components';
import StarRating from '../star-rating';

const MemberReviews = ({ text, author, company }) => (
  <Wrapper>
    <Content>
      <p>{text}</p>
      <Caret />
    </Content>
    <Bottom>
      <Author>
        {author}
      </Author>
      <Company>
        {company}
      </Company>
      <StarRating rating={5} size="small" />
    </Bottom>
  </Wrapper>
)

const Wrapper = styled.div`
  padding: 15px;
  background-color: #d5d5d5;
`

const Content = styled.div`
  position: relative;
  background-color: #f7f7f7;
  border: 1px solid #eee;
  color: #616161;
  padding: 20px;

  p {
    margin: 0;
    color: #616161;
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
  }
`

const Caret = styled.span`
  position: absolute;
  display: block;
  left: 10px;
  bottom: -12px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #f7f7f7;
`

const Bottom = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 100%;
  margin-bottom: 20px;
`

const Author = styled.span`
  display: block;
  line-height: 1.4;
  margin: 0;
  font-family: inherit;
  font-size: 1.077em;
  font-weight: 700;
  color: #222;
  overflow-wrap: break-word;
  word-wrap: break-word;
  letter-spacing: normal;
  font-style: normal;
  text-transform: none;
  margin-bottom: 2px;
`

const Company = styled.span`
  color: #9e9e9e;
  line-height: 1.4;
  display: block;
  margin-bottom: 4px;
`

export default MemberReviews
