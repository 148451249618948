import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.scss';

const Promo = (props) => {
  const {
    className,
    children,
    bgColor,
    borderRadius,
    size,
    footnote,
    sticky,
    spaceBelow,
    spaceAboveMobile,
  } = props;

  const classes = classnames(
    className,
    'Promo',
    `-bgColor-${bgColor}`,
    `-size-${size}`,
    `-spaceBelow-${spaceBelow}`,
    spaceAboveMobile && `-spaceAboveMobile-${spaceAboveMobile}`,
    { '-borderRadius': borderRadius },
    { '-sticky': sticky },
  );

  return (
    <div className={classes}>
      <span className="Promo-inner">
        {children}
        {footnote && <span className="Promo-footnote">{ footnote }</span>}
      </span>
    </div>);
};

Promo.propTypes = {
  className: PropTypes.string,
  bgColor: PropTypes.string,
  size: PropTypes.string,
  borderRadius: PropTypes.bool,
  footnote: PropTypes.string,
};

Promo.defaultProps = {
  className: null,
  bgColor: 'default',
  size: 'default',
  footnote: '',
  borderRadius: false,
  sticky: false,
  spaceBelow: 'default',
  spaceAboveMobile: false,
};

export default Promo;
