import React from 'react';
import { connect } from 'react-redux';

import { ModalBody } from '../../components/modal';
import SpacesEditForm from '../../components/spaces-edit-form';

import { hideModal, openSpacesDeleteModal } from '../../actions/modal';

const SpacesEditModal = (props) => {
  const { item, token, onSubmit, onDuplicate } = props;
  return (
    <ModalBody small>
      <SpacesEditForm
        onSubmit={onSubmit}
        onClose={() => props.hideModal()}
        onDuplicate={onDuplicate}
        onRemove={() =>
          props.openSpacesDeleteModal({
            token,
            spaceId: item.id,
            locationId: item.locationId,
            closeForm: props.hideModal,
          })}
        initialValues={item}
        form="spaces-edit-form"
      />
    </ModalBody>
  );
};

export default connect(
  () => ({
    token: null,
  }),
  { hideModal, openSpacesDeleteModal })(SpacesEditModal);
