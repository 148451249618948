import { checkHttpStatus, parseJSON } from '../utils';
import {
  GET_BEST_OF_REQUEST,
  GET_BEST_OF_SUCCESS,
  GET_BEST_OF_FAILURE,
} from '../constants';
import * as api from '../api/bestof';

const getLocBestOfRequest = () => ({
  type: GET_BEST_OF_REQUEST,
});

const getLocBestOfSuccess = (payload, config) => ({
  type: GET_BEST_OF_SUCCESS,
  payload,
  config,
});

const getLocBestOfFailure = () => ({
  type: GET_BEST_OF_FAILURE,
});

export const getLocBestOf = (marketSlug, isWorkspace = false) =>
  (dispatch, getState) => {
    if (getState().bestof.byMarket[marketSlug]) {
      return null;
    }

    dispatch(getLocBestOfRequest());

    return api
      .getLocBestOf(marketSlug, isWorkspace)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(getLocBestOfSuccess(response, marketSlug));
      })
      .catch(error => {
        dispatch(getLocBestOfFailure(error));
      });
  };
