import React from 'react';
import Panel from '../../components/panel';
import { Wrapper } from '../../components/panel/styles';
import Map from '../Main/map';
import WorkplacesMap from '../Main/workplaces-map';
import Heading from '../../components/heading';
import { Desktop, Mobile } from '../../components/responsive-helpers';

const NotFoundLocations = ({ markets, nearestMarket, isWorkplace }) => {
  return (
    <Wrapper>
      <Panel
        id="no-listings-text"
        markets={markets}
        nearestMarket={nearestMarket}
      />
      <div style={{ paddingLeft: 15, marginTop: 20 }}>
        <Heading level="1" center={false} spaceBelow="small" size="xsmall">All Markets</Heading>
      </div>
      <Desktop>
        {isWorkplace && (
          <WorkplacesMap
            style={{ height: 450 }}
            zoom={4}
          />
        )}
        {!isWorkplace && (
          <Map
            style={{ height: 450 }}
            zoom={4}
          />
        )}
      </Desktop>
      <Mobile>
        {isWorkplace && (
          <WorkplacesMap
            style={{ height: 250 }}
            zoom={3}
          />
        )}
        {!isWorkplace && (
          <Map
            style={{ height: 250 }}
            zoom={3}
          />
        )}
      </Mobile>
    </Wrapper>
  );
}

export default NotFoundLocations;
