import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'
import styled from 'styled-components'

import Heading from '../../components/heading'
import { ModalBody } from '../../components/modal'
import Button from '../../components/button'
import { getCookie } from '../../utils/cookies'
import { EyeVisible, EyeHidden } from '../../components/icons'

import { hideModal } from '../../actions/modal'
import { accountGet } from '../../actions/lead'
import { authSessionSuccess, authSigninSuccess } from '../../actions/cognito'

const validationInitial = {
  newIsEmpty: true,
  charactersLength: true,
  containsNumber: true,
  containsUppercase: true,
  containsLowercase: true
}

const VerifyPassword = () => {
  const dispatch = useDispatch()
  const tempPass = getCookie('_REG_TEMP_TOKEN')
  const savedEmail = getCookie('email')
  const [newPassVisible, setNewPassVisible] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [validation, setValidation] = useState(validationInitial)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    setValidation({
      newIsEmpty: newPassword.length === 0,
      charactersLength: newPassword.length < 8,
      containsNumber: !/\d/.test(newPassword),
      containsUppercase: !/[A-Z]/g.test(newPassword),
      containsLowercase: !/[a-z]/g.test(newPassword)
    })
  }, [newPassword])


  const handleSubmit = () => {
    setLoading(true)

    Auth.signIn(savedEmail, tempPass)
      .then(response => {
        if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
          Auth.completeNewPassword(response, newPassword)
            .then(user => {
              setLoading(false)

              if (user.signInUserSession) {
                dispatch(
                  authSessionSuccess(user.signInUserSession.idToken.jwtToken)
                )
                dispatch(
                  authSigninSuccess(user.signInUserSession.idToken.jwtToken)
                )
                dispatch(accountGet(user.signInUserSession.idToken.jwtToken))
              }

              dispatch(
                dispatch(hideModal())
              )
            })
            .catch(error => {
              setLoading(false)
              setError(error && error.message ? error.message : error)
            })
        }
      })
      .catch(error => {
        setLoading(false)
        setError(error && error.message ? error.message : error)
      })
  }

  return (
    <ModalBody small>
      <Heading level="3" size="xsmall" center>
        Please enter a password to save and visit spaces
      </Heading>
      <Form onSubmit={e => e.preventDefault()}>
        <FieldRow>
          <Label htmlFor="newPassword">Password</Label>
          <Field>
            <Input
              id="newPassword"
              name="newPassword"
              type={newPassVisible ? 'text' : 'password'}
              value={newPassword}
              disabled={loading}
              onChange={e => {
                setNewPassword(e.target.value)
              }}
            />
            <Icon
              type="button"
              onClick={() => setNewPassVisible(!newPassVisible)}
            >
              {newPassVisible ? <EyeVisible /> : <EyeHidden />}
            </Icon>
          </Field>
          <Validation>
            {validation.newIsEmpty && <span>Required</span>}
            {validation.charactersLength && (
              <span>Password is at least 8 characters long</span>
            )}
            {validation.containsNumber && (
              <span>Password contains at least 1 number</span>
            )}
            {validation.containsUppercase && (
              <span>Password contains at least 1 uppercase letter</span>
            )}
            {validation.containsLowercase && (
              <span>Password contains at least 1 lowercase letter</span>
            )}
          </Validation>
        </FieldRow>
        {error && (
          <Validation data-margin>
            <span>{error}</span>
          </Validation>
        )}
        <BtnGroup>
          <Button
            type="button"
            disabled={
              Object.values(validation).filter(item => item).length > 0 ||
              loading
            }
            onClick={handleSubmit}
          >
            Save
          </Button>
        </BtnGroup>
      </Form>
    </ModalBody>
  )
}

const Form = styled.form`
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
`

const Field = styled.div`
  height: 40px;
  max-width: 100%;
  width: 100%;
  box-shadow: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  background: #f2f2f2;
  margin-bottom: 5px;
  max-width: 250px;
  display: flex;
  align-items: center;
`

const Input = styled.input`
  border: none;
  padding: 10px 12px;
  font-size: 14px;
  font-family: 'Euclid Circular B', sans-serif;
  font-size: 14px;
  background: transparent;
  width: 100%;
`

const Icon = styled.button.attrs({ type: 'button' })`
  border: none;
  background: none;
  box-shadow: none;
  cursor: pointer;
  padding: 0;
  width: 46px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Label = styled.label`
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
`

const Validation = styled.div`
  color: red;

  > span {
    display: block;
  }

  &[data-margin] {
    margin-bottom: 20px;
  }
`

const FieldRow = styled.div`
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 25px;
  }
`

const BtnGroup = styled.div`
  button {
    margin-right: 20px;
  }
`

export default VerifyPassword
