import React from 'react';
import Helmet from 'react-helmet';

import { getSchemaLocation } from '../../settings/schema-location';
import { ROOT, WORKPLACE } from '../../settings/routes';

export default ({
  marketName,
  name,
  metaDescription,
  listingCardImageId,
  modifiedAt,
  imageUrl,
  listing,
  marketSlug,
  spaces,
  market,
  isWorkspace,
  isCoworking
}) => (
  <Helmet titleTemplate="%s | Upsuite">
    <title>{`${name}, ${marketName}`}</title>
    <meta property="og:title" content={`${name}, ${marketName}`} />
    {listingCardImageId && (
      <meta property="og:image" content={imageUrl(listingCardImageId)} />
    )}
    {metaDescription && (
      <meta property="og:description" content={metaDescription} />
    )}
    {metaDescription && <meta name="description" content={metaDescription} />}
    <meta httpEquiv="last-modified" content={modifiedAt} />
    {listing && listing.marketplaceSlug && (
      <link
        rel="canonical"
        href={`${window.location.origin}/${
          isWorkspace && !isCoworking ? WORKPLACE : ROOT
        }/${marketSlug}/${listing.marketplaceSlug}/`}
      />
    )}
    {listing && market && (
      <script type="application/ld+json">
        {JSON.stringify(
          getSchemaLocation(listing, market, spaces, isWorkspace),
        )}
      </script>
    )}
  </Helmet>
);
