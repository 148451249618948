import reduce from 'lodash/reduce';
import { amenitiesWorkplaceMap } from '../settings/amenities-lookup';

export default listing => (
  reduce(listing, (result, value, key) => {
    if (/amenity/.test(key)) {
      result.push({ key: key.slice(7), disabled: !value });
    }

    return result;
  }, [])
);

export const getAllWorkplaceAmenitiesFavorites = (listing) => (
  reduce(listing, (result, value, key) => {
    const amenityKey = key.slice(0, key.length - 8);
    if (/Rollover$/.test(key)) {
      result.push({ key: amenitiesWorkplaceMap[amenityKey], disabled: !value });
    }

    return result;
  }, [])
);

export const getAllWorkplaceAmenities = (listing) => (
  reduce(listing, (result, value, key) => {
    const amenityKey = key.slice(0, key.length - 8);
    if (/Rollover$/.test(key) && listing[`amenity${amenitiesWorkplaceMap[amenityKey]}`]) {
      result.push({ key: amenityKey, value });
    }

    return result;
  }, [])
);

export const getAllWorkplaceSpaceAmenities = (space) => (
  reduce(space, (result, value, key) => {
    const amenityKey = key.slice(0, key.length - 8);
    if (/Rollover$/.test(key) && space[amenityKey]) {
      result.push({ key: amenityKey, value });
    }

    return result;
  }, [])
);
