/* global AmazonCognitoIdentity */
import isEmpty from 'lodash/isEmpty'
import forIn from 'lodash/forIn'
import {
  AUTH_IDENTITY,
  COGNITO_AUTH_FAILURE,
  AUTH_USER_SUCCESS,
  AUTOCHECK_INFO,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_REQUEST,
  USER_REGISTER_FAILURE,
  USER_REGISTER_RESET,
  LEAD_CREATING_REQUEST,
  LEAD_CREATING_SUCCESS,
  LEAD_CREATING_FAILURE,
  LEAD_CREATING_FAILURE_RESET,
  LEAD_REGISTER_BY_ADMIN_REQUEST,
  LEAD_REGISTER_BY_ADMIN_SUCCESS,
  LEAD_REGISTER_BY_ADMIN_FAILURE
} from '../constants'
import {
  COGNITO_APPWEBDOMAIN as AppWebDomain,
  COGNITO_CLIENTID as ClientId
} from '../settings'
import { COGNITO_REDIRECT } from '../settings/routes'
import * as api from '../api/users'
import { checkHttpStatus, checkHttpResponse, parseJSON } from '../utils'
import { setCookie, getCookie } from '../utils/cookies'

const autocheckInfo = payload => ({
  type: AUTOCHECK_INFO,
  payload
})

export const autocheckInfoGet = token => dispatch => {
  return api
    .userGetInfo(token)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => {
      dispatch(autocheckInfo(response))
    })
    .catch(() => {
      //
    })
}

export const initAuthIdentity = payload => ({
  type: AUTH_IDENTITY,
  payload
})

export const cognitoAuthFailure = payload => ({
  type: COGNITO_AUTH_FAILURE,
  payload
})

export const authUserSuccess = payload => ({
  type: AUTH_USER_SUCCESS,
  payload
})

export const initCognitoSDK = (
  onSuccessCallback = () => {},
  onFailureCallback = () => {}
) => {
  const authData = {
    ClientId,
    AppWebDomain,
    TokenScopesArray: ['openid', 'email', 'profile'],
    RedirectUriSignIn: `${window.location.origin}/${COGNITO_REDIRECT}`,
    RedirectUriSignOut: window.location.origin,
    AdvancedSecurityDataCollectionFlag: false
  }
  const auth = new AmazonCognitoIdentity.CognitoAuth(authData)
  auth.setState(window.location.pathname)
  auth.userhandler = {
    onSuccess: result => {
      onSuccessCallback(result)
    },
    onFailure: error => onFailureCallback(error)
  }

  auth.useCodeGrantFlow()

  return auth
}

export const initCognito = store => {
  const auth = initCognitoSDK(
    session => {
      store.dispatch(
        authUserSuccess(session && session.getIdToken().getJwtToken())
      )
      store.dispatch(
        autocheckInfoGet(session && session.getIdToken().getJwtToken())
      )
    },
    error => store.dispatch(cognitoAuthFailure(error))
  )
  auth.parseCognitoWebResponse(window.location.href)
  store.dispatch(initAuthIdentity(auth))
  if (auth.isUserSignedIn()) {
    store.dispatch(authUserSuccess(auth.signInUserSession.idToken.jwtToken))
    store.dispatch(autocheckInfoGet(auth.signInUserSession.idToken.jwtToken))
  }
}

export const userRegisterRequest = () => ({
  type: USER_REGISTER_REQUEST
})

export const userRegisterSuccess = data => ({
  type: USER_REGISTER_SUCCESS,
  payload: data
})

export const userRegisterFailure = () => ({
  type: USER_REGISTER_FAILURE
})

export const userRegisterReset = () => ({
  type: USER_REGISTER_RESET
})

export const leadCreatingRequest = () => ({
  type: LEAD_CREATING_REQUEST
})

export const leadCreatingSuccess = data => ({
  type: LEAD_CREATING_SUCCESS,
  payload: data
})

export const leadCreatingFailure = () => ({
  type: LEAD_CREATING_FAILURE
})

export const leadCreatingFailureReset = () => ({
  type: LEAD_CREATING_FAILURE_RESET
})

export const registerLead = data => dispatch => {
  dispatch(userRegisterRequest())
  dispatch(leadCreatingRequest())
  const hubspotutk = getCookie('hubspotutk')

  return api
    .registerLead({ ...data, ...(hubspotutk && { hubspotutk }) })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then((response) => {
      dispatch(userRegisterSuccess(true))
      forIn(data, (value, key) => {
        setCookie(key, value, 365)
      })

      window.sessionStorage.setItem('registerSession', true);

      dispatch(leadCreatingSuccess(data))

      if (response.Password) {
        setCookie('_REG_TEMP_TOKEN', response.Password, 365)
      }

      setCookie('upsuite_user_is_authenticated', '', -1)

      return true
    })
    .catch(() => {
      dispatch(userRegisterFailure())
      dispatch(leadCreatingFailure())
    })
}

export const registerLeadByAdminRequest = () => ({
  type: LEAD_REGISTER_BY_ADMIN_REQUEST
})

export const registerLeadByAdminSuccess = data => ({
  type: LEAD_REGISTER_BY_ADMIN_SUCCESS,
  payload: data
})

export const registerLeadByAdminFailure = error => ({
  type: LEAD_REGISTER_BY_ADMIN_FAILURE,
  payload: error
})

export const registerLeadByAdmin = data => dispatch => {
  dispatch(registerLeadByAdminRequest())

  return api
    .registerLeadByAdmin(data)
    .then(checkHttpResponse)
    .then(response => {
      dispatch(registerLeadByAdminSuccess(data))

      if (response) {
        response.json().then(warnings => {
          if (!isEmpty(warnings)) {
            // eslint-disable-next-line no-alert
            window.alert(`Warning\n${warnings.join('\n')}`)
          }
        })
      }

      return true
    })
    .catch(error => {
      error
        .text()
        .then(errorBody => dispatch(registerLeadByAdminFailure(errorBody)))
    })
}
