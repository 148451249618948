import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { unsubscribe } from '../../api/users';
import Spinner from '../../components/spinner';

const Unsubscribe = () => {
  const location = useLocation();
  const { email, id } = parse(location.search);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (id && email) {
      unsubscribe(id, email).then(response => {
        setLoading(false);
        if (response?.status !== 200) {
          setError(true);
        }
      });
    }

    if (!id || !email) {
      setLoading(false);
      setError(true);
    }
  }, [id, email]);

  return (
    <Wrapper>
      {loading && <Spinner inline size="medium" />}
      {error && <span data-error>There was a problem completing your request</span>}
      {!loading && !error && (
        <span>You have been successfully unsubscribed</span>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 100px;
  font-size: 18px;
  line-height: 1.5;
  color: #828180;
  font-weight: 400;
  text-align: center;

  & [data-error="true"] {
    color: red;
  }
`;

export default Unsubscribe;
