import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import Button from '../button'
import { searchAdd } from '../../actions/lead'
import usePrevious from '../../hooks/use-previous';
import { openVerifyPassModal } from '../../actions/modal'

const SaveSearch = ({ filterQuery, marketVal }) => {
  const [saved, setSaved] = useState(false)
  const dispatch = useDispatch()
  const { pathname, search } = useLocation();
  const { loading, error } = useSelector(state => state.lead.savedSearches)
  const { userIsAuthenticated } = useSelector(state => state.auth)
  const prevLoading = usePrevious(loading)
  const prevLocation = usePrevious(`${pathname}${search}`)

  useEffect(() => {
    if (prevLocation !== `${pathname}${search}`) {
      setSaved(false)
    }
  }, [pathname, search, prevLocation])

  useEffect(() => {
    if (loading !== !!prevLoading && !loading) {
      setSaved(true)
    }
  }, [loading, prevLoading])

  return (
    <Button
      scheme="save-search"
      spaceBelow="none"
      onClick={() => {
        if (userIsAuthenticated) {
          dispatch(openVerifyPassModal())
        } else {
          dispatch(searchAdd({
            ...filterQuery,
            name: marketVal
          }))
        }
      }
      }
      disabled={(saved || loading) && !error}
    >
      {loading ? 'Saving...' : error ? 'Error Saving' : saved ? 'Search Saved' : 'Save Search'}
    </Button>
  )
}

export default SaveSearch
