import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const List = styled.ul`
  padding-left: 0;
  border: 1px solid #707070;
  border-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #828180;
  margin: 0;
  overflow: auto;
`;

export const Item = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  height: 250px;
  transition: max-height 0.3s ease;
  border-bottom: 1px solid #707070;
  min-width: 1130px;
  max-height: ${props => (props.edit ? '250px' : '48px')};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
`;

export const Col = styled.div`
  width: 100%;
  height: 100%;
  padding: 13px 5px;
  display: flex;
  align-items: center;

  ${props =>
    props.colWidth &&
    `
    max-width: ${props.colWidth}%;
    flex: 1 0 ${props.colWidth}%;
  `}

  ${props =>
    props.bordered &&
    `
    border-right: 1px solid #707070;
  `}

  ${props =>
    props.center &&
    `
    justify-content: center;
  `}

  > button {
    margin-bottom: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .FormField {
    display: flex;
    flex-direction: column;
  }
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .button {
    margin-bottom: 0;
  }

  .Spinner {
    position: relative;
    width: 20px;
    margin-left: 10px;
  }
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  > .button.-scheme-default-normal {
    margin-bottom: 0;
    padding: 8px 20px;
    font-weight: 500;
  }

  @media (max-width: 639px) {
    flex-direction: column;
  }
`;

export const Head = styled(Item)`
  font-weight: 800;
`;

export const Search = styled.input`
  margin-right: 10px;
  border: 1px solid #707070;
  padding: 5px;

  @media (max-width: 639px) {
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

export const Mail = styled.a`
  background-color: #57AB83;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #57AB83;
`;

export const CombinedButtons = styled.a`
  display: flex;
  align-items: center;

  @media (max-width: 639px) {
    margin-bottom: 15px;
  }
`;

export const ButtonsGroup = styled.div`
  .button {
    position: relative;
    margin-bottom: 0;
  }

  .Spinner {
    position: absolute;
    left: -34px;
    top: 8px;
  }
`;

export const Error = styled.span`
  color: var(--brand-color);
  flex-shrink: 0;
`;
