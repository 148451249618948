import styled from 'styled-components';
import * as breakpoints from '../../styles/theme/breakpoints';

export const Wrapper = styled.div`
  margin-left: -15px;
  margin-right: -15px;

  @media ${breakpoints.mdUp} {
    margin-left: -16px;
    margin-right: -32px;
    margin-top: -6px;
  }
`;

