import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import listings from './listings';
import markets from './markets';
import ui from './ui';
import location from './location';
import filterQuery from './filter-query';
import favorites from './favorites';
import neighborhood from './neighborhood';
import spaces from './spaces';
import brand from './brand';
import auth from './auth';
import cognito from './cognito';
import modal from './modal';
import reviews from './reviews';
import userPermissions from './user-permissions';
import lead from './lead';
import users from './users';
import seo from './seo';
import mainMap from './main-map';
import bestof from './bestof';

const rootReducer = ({
  listings,
  markets,
  ui,
  location,
  filterQuery,
  favorites,
  neighborhood,
  spaces,
  brand,
  auth,
  modal,
  form,
  reviews,
  userPermissions,
  lead,
  users,
  seo,
  mainMap,
  bestof,
  cognito
});

export default history => combineReducers({
  router: connectRouter(history),
  ...rootReducer,
});
