import React, { useMemo } from 'react';
import styled from 'styled-components';
import random from 'lodash/random';
import { useDispatch } from 'react-redux';

import { mdUp } from '../../styles/theme/breakpoints';
import { quotes } from '../../settings/quotes';
import StarRating from '../star-rating';
import Flex from '../flex';
import AdCard from '../ad-card';
import { openIframeModal } from '../../actions/modal';

const AdvisorCard = () => {
  const dispatch = useDispatch();
  const quote = useMemo(() => quotes[random(0, 8)], []);

  return (
    <AdvisorCardWrap>
      <AdCard
        inline
        onButtonClick={() => {
          dispatch(
            openIframeModal({
              src: 'https://meetings.hubspot.com/hailey-barnett1',
              title: 'Work with an Upsuite Advisor',
              width: '900px',
              height: '815px',
            }),
          );
        }}
      />
      <Flex center>
        <StarRating rating={5} size="small" />
        <div>
          <Text data-brand-color data-heading data-margin>4.8 Average Star Rating!</Text>
          <Text>{quote}</Text>
        </div>
      </Flex>
    </AdvisorCardWrap>
  );
};

const AdvisorCardWrap = styled.div`
  border: 1px solid black;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 25px;
  width: 100%;

  @media ${mdUp} {
    width: 100%;
    max-width: 400px;
  }
`;

const Text = styled.span`
  display: block;

  &[data-heading="true"] {
    font-size: 1.2rem;
  }

  &[data-margin="true"] {
    margin-bottom: 0.5rem;
  }

  &[data-brand-color="true"] {
    color: var(--brand-color);
  }
`

export default AdvisorCard;
