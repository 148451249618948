/* global window */
import React from 'react';
import { connect } from 'react-redux';

import Heading from '../../components/heading';
import { ModalBody } from '../../components/modal';
import { List as ShareList } from '../../components/share';

import { hideModal } from '../../actions/modal';
import { ROOT, WORKPLACE } from '../../settings/routes';

const ShareModal = (props) => {
  const { params, isWorkspace } = props;
  const location = `${window.location.origin}/${isWorkspace ? WORKPLACE : ROOT}/${params}/`;

  return (
    <ModalBody small>
      <Heading level="2" size="medium" center={false}>Share</Heading>
      <ShareList
        location={location}
        clickHandler={() => setTimeout(() => props.hideModal(), 1000)}
      />
    </ModalBody>
  );
};

export default connect(null, { hideModal })(ShareModal);

