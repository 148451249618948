import { checkHttpStatus, parseJSON } from '../utils'
import {
  MARKETS_GET_REQUEST,
  MARKETS_GET_SUCCESS,
  MARKETS_GET_FAILURE
} from '../constants'
import * as api from '../api/listings'

const marketsGetRequest = () => ({
  type: MARKETS_GET_REQUEST
})

const marketsGetSuccess = payload => ({
  type: MARKETS_GET_SUCCESS,
  payload
})

const marketsGetFailure = () => ({
  type: MARKETS_GET_FAILURE
})

const marketsGet = () => (dispatch, getState) => {
  if (getState().markets.list) {
    return Promise.resolve()
  }

  dispatch(marketsGetRequest())

  return api
    .marketsGet()
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => {
      dispatch(marketsGetSuccess(response))
    })
    .catch(error => {
      dispatch(marketsGetFailure(error))
    })
}

export default marketsGet
