import { createReducer } from '../utils'
import {
  AUTH_SIGNIN_REQUEST,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNIN_FAILURE,
  AUTH_SESSION_REQUEST,
  AUTH_SESSION_SUCCESS,
  AUTH_SESSION_FAILURE,
  NEW_PASSWORD_REQUIRED,
  LOGOUT_SUCCESS,
  PASSWORD_RECOVERY_REQUEST,
  PASSWORD_RECOVERY_SUCCESS,
  PASSWORD_RECOVERY_FAILURE
} from '../constants'

const initialState = {
  token: null,
  tokenDate: null,
  recoveryEmail: null,
  newPasswordRequired: false,
  status: {
    loading: false,
    completed: false,
    error: false,
    errorText: null
  },
  session: {
    loading: false,
    completed: false,
    error: false,
    errorText: null
  }
}

export default createReducer(initialState, {
  [AUTH_SIGNIN_REQUEST]: state => ({
    ...state,
    token: null,
    tokenDate: null,
    status: {
      ...initialState.status,
      loading: true
    }
  }),
  [AUTH_SIGNIN_SUCCESS]: (state, payload) => ({
    ...state,
    token: payload,
    tokenDate: new Date(),
    status: {
      ...initialState.status,
      completed: true
    }
  }),
  [AUTH_SIGNIN_FAILURE]: (state, payload) => ({
    ...state,
    token: null,
    tokenDate: null,
    status: {
      ...initialState.status,
      completed: true,
      error: true,
      errorText: payload
    }
  }),
  [AUTH_SESSION_REQUEST]: state => ({
    ...state,
    token: null,
    tokenDate: null,
    session: {
      ...initialState.session,
      loading: true
    }
  }),
  [AUTH_SESSION_SUCCESS]: (state, payload) => ({
    ...state,
    token: payload,
    tokenDate: new Date(),
    session: {
      ...initialState.session,
      completed: true
    }
  }),
  [AUTH_SESSION_FAILURE]: (state, payload) => ({
    ...state,
    token: null,
    tokenDate: null,
    session: {
      ...initialState.session,
      completed: true,
      error: true,
      errorText: payload
    }
  }),
  [NEW_PASSWORD_REQUIRED]: (state, payload) => ({
    ...state,
    cognitoUser: payload,
    newPasswordRequired: true
  }),
  [LOGOUT_SUCCESS]: state => ({
    ...state,
    ...initialState
  }),
  [PASSWORD_RECOVERY_REQUEST]: state => ({
    ...state,
    status: {
      loading: true
    }
  }),
  [PASSWORD_RECOVERY_SUCCESS]: (state, payload) => ({
    ...state,
    status: { loading: false },
    recoveryEmail: payload
  }),
  [PASSWORD_RECOVERY_FAILURE]: (state, payload) => ({
    ...state,
    status: { loading: false, error: true, errorText: payload }
  })
})
