import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ModalBody } from '../../components/modal';
import HubspotForm from '../../components/hubspot-form';
import Spinner from '../../components/spinner';

import { hideModal } from '../../actions/modal';

class CTAModal extends Component {
  state = {
    formReady: false,
  }

  render() {
    return (
      <ModalBody small withHeight>
        {!this.state.formReady &&
          <Spinner size="medium" center />
        }
        <HubspotForm
          portalId="4312546"
          formId="2cd31e79-5f2d-4594-82c2-5f088bdcfb69"
          withBg
          onFormReady={() => this.setState({ formReady: true })}
          setFormValues={{
            coworking_location_of_interest: this.props.listingName,
            operating_market: this.props.marketName,
          }}
        />
      </ModalBody>
    );
  }
}

export default connect(
  null, { hideModal },
)(CTAModal);
