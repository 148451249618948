import React from 'react';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import differenceInDays from 'date-fns/difference_in_calendar_days';
import format from 'date-fns/format';
import max from 'date-fns/max';

export const formatMeta = (capacityMin, capacityMax, neighborhoodName) => {
  if (!capacityMin && !capacityMax && !neighborhoodName) return null;

  const range = capacityMin && capacityMax &&
    `${capacityMin}-${capacityMax} People`;

  if (range && !neighborhoodName) return range;

  return range && neighborhoodName ?
    <>{range} • {neighborhoodName}</> :
    neighborhoodName;
};

export const formatPriceRange = (spaces) => {
  if (isEmpty(spaces)) return null;

  const minSpace = minBy(spaces, o => o.spacePrice);
  const maxSpace = maxBy(spaces, o => o.spacePrice);

  if (!minSpace || !maxSpace) {
    return null;
  }

  if (!minSpace.spacePrice && !minSpace.spacePrice) {
    return null;
  }

  if (!minSpace && maxSpace && maxSpace.spacePrice) {
    return `$${maxSpace.spacePrice}`;
  }

  if (!maxSpace && minSpace && minSpace.spacePrice) {
    return `$${minSpace.spacePrice}`;
  }

  if (minSpace.spacePrice === maxSpace.spacePrice) {
    return `$${minSpace.spacePrice}`;
  }

  return `$${minSpace.spacePrice} - $${maxSpace.spacePrice}`;
};

export const getDayDifference = dateAvailable => differenceInDays(dateAvailable, new Date());

export const getLatestDate = dates => max(...dates);

export const spaceAvailabilityFormat = ({ dateAvailable, isAvailable }) => {
  if ((!isAvailable && !dateAvailable) || dateAvailable === '0000-00-00') {
    return null;
  }

  return (getDayDifference(dateAvailable) < 0) || isAvailable ? 'Available Now' : format(dateAvailable, 'MMM D, YYYY');
};

export const spaceCapacityFormat = ({ spaceCapacity }) => {
  if (!spaceCapacity || spaceCapacity <= 1) {
    return '1 person';
  }

  return `${spaceCapacity} people`;
};

export const spaceModifiedDate = date => format(date, 'MM/DD/YYYY');

export const spacePerPersonFormat = (spacePrice, spaceCapacity = 1) => {
  if (!spacePrice) return null;
  if (!spaceCapacity) return spacePrice;

  return Math.ceil(spacePrice / spaceCapacity);
};

export const spaceSqFtFormat = ({ sqFt }) => {
  if (!sqFt) return null;

  if (!isArray(sqFt)) {
    return `${sqFt} Sq Ft`;
  }

  return `${sqFt[0]} - ${sqFt[1]} Sq Ft`;
};

export const currencyFormat = v => v && v.toLocaleString();

export const applyPromo = v => v && Math.ceil(v * 0.95);

export const currencyBefore = v => (v ? `$${v}` : '');

export const capacityFormat = (capacity) => {
  if (!capacity || capacity <= 1) {
    return '1 Person';
  }

  return `${capacity} People`;
};
