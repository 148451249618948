import benHeadshot from '../assets/ben-headshot.png'
import roryHeadshot from '../assets/rory-headshot.png'
import daveHeadshot from '../assets/dave-headshot.png'
import mattHeadshot from '../assets/matt-headshot.png'
import sammieHeadshot from '../assets/sammie-headshot.png'
import morganHeadshot from '../assets/morgan-headshot.png'

export const quotes = [
  '"It was super easy. I told them what I needed and they came back with at least half a dozen good options for me" - Susan P.',
  '"Very helpful to compare the many co-working options in our area. My advisor was very helpful." - Brad N.',
  '"It was very easy. I had an office space within days." - Elisa T.',
  '"Upsuite is a very useful layout to compare locations. They also set up tours of all potential spaces which saved a ton of time for me." - Elliot A.',
  '"Really productive, helpful, professional. Helped cut through the legwork and the challenge of figuring out where and how to get office space." - Liz S.',
  '"Great from start to finish." - Joe T.',
  '"Helpful to have a Upsuite guide to set up appts and tour multiple facilities." - Alex B.',
  '"It was awesome and very easy. Made my job to find space a breeze! " - Sam P.',
  'Working with Upsuite was vital to my finding the right office for me that is in the right location. Couldn\'t have done it without Upsuite!" - Jennifer A.',
];

export const quoteAuthors = [
  daveHeadshot,
  mattHeadshot,
  sammieHeadshot,
  morganHeadshot,
];

export const quoteAuthorsExtended = [
  daveHeadshot,
  mattHeadshot,
  sammieHeadshot,
  morganHeadshot,
  benHeadshot,
  roryHeadshot,
];
