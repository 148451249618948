import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import isNaN from 'lodash/isNaN';
import trim from 'lodash/trim';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import flatten from 'lodash/flatten';
import values from 'lodash/values';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { spacePerPersonFormat, getDayDifference, getLatestDate } from './format';
import { groupSpaces } from './group-spaces';

export const spaceTypes = ['Floating Desk', 'Dedicated Desk', 'Suite', 'Virtual Office', 'Meeting Space'];

const spaceTypeOrder = space => spaceTypes.indexOf(trim(space.spaceType));
const byPrice = space => (isArray(space.spacePrice) ? space.spacePrice[0] : space.spacePrice);
const byPerDay = space => (isArray(space.pricePerDay) ? space.pricePerDay[0] : space.pricePerDay);
const byPerHour = space => (isArray(space.pricePerHour) ? space.pricePerHour[0] : space.pricePerHour);
const bySqFt = space => (isArray(space.sqFt) ? space.sqFt[0] : space.sqFt);
const byType = space => trim(space.spaceType);
const byPerPerson = space => spacePerPersonFormat(space);
const byCapacity = space => space.spaceCapacity || 1;
const byDate = space => getDayDifference(space.dateAvailable);
const byInterior = space => space.interior;

const alphanumericOrder = (spaces, isDesc) => {
  const numbers = spaces.filter(s => s.name && !isNaN(Number(s.name)));
  const strings = spaces.filter(s => s.name && isNaN(Number(s.name)));
  const empty = spaces.filter(s => !s.name);
  const orderedNumbers = orderBy(numbers, s => Number(s.name), isDesc && 'desc');
  const orderedStrings = orderBy(strings, 'name', isDesc && 'desc');

  if (!isDesc) {
    return [...orderedNumbers, ...orderedStrings, ...empty];
  }

  return [...orderedStrings, ...orderedNumbers, ...empty];
};

export const sortSpacesMain = (spaces, spacesOwner) => {
  if (spacesOwner) {
    const sorted = orderBy(spaces, [
      spaceTypeOrder,
      byCapacity,
      'spacePrice',
    ], 'asc');

    return flatten(
      values(
        mapValues(
          groupBy(sorted, 'spaceType'), o => alphanumericOrder(o, false),
        ),
      ),
    );
  }

  const available = filter(spaces, o =>
    o.isAvailable || (!isEmpty(o.dateAvailable) && o.dateAvailable !== '0000-00-00'));
  const grouped = groupSpaces(available);
  return sortBy(grouped, [
    spaceTypeOrder,
    byCapacity,
    'spacePrice',
  ]);
};

export const sortWorkplacesMain = (spaces) => {
  const available = filter(spaces, o =>
    o.isAvailable || (!isEmpty(o.dateAvailable) && o.dateAvailable !== '0000-00-00'));

  return sortBy(available, [
    spaceTypeOrder,
    byCapacity,
    'spacePrice',
  ]);
}

const byLookup = {
  price: byPrice,
  pricePerHour: byPerHour,
  pricePerDay: byPerDay,
  pricePromo: byPrice,
  sqft: bySqFt,
  type: byType,
  perPerson: byPerPerson,
  perPersonPromo: byPerPerson,
  capacity: byCapacity,
  date: byDate,
  interior: byInterior,
};

export const orderSpacesBy = (spaces, by, isDesc) => {
  if (by === 'name') {
    return alphanumericOrder(spaces, isDesc);
  }

  return orderBy(spaces, byLookup[by], isDesc && 'desc');
};

export const getRecentModified = spaces => getLatestDate(spaces.map(space => space.modifiedAt));
