import { createReducer } from '../utils';
import {
  USER_PERMISSIONS_CHECK_REQUEST,
  USER_PERMISSIONS_CHECK_SUCCESS,
  USER_PERMISSIONS_CHECK_FAILURE,
  OPERATOR_LOCATIONS_SUCCESS,
} from '../constants';

const initialState = {
  allowedLocations: [],
  operatorLocations: [],
};

export default createReducer(initialState, {
  [USER_PERMISSIONS_CHECK_REQUEST]: state => ({
    ...state,
  }),
  [USER_PERMISSIONS_CHECK_SUCCESS]: (state, payload) => ({
    ...state,
    allowedLocations: [...state.allowedLocations, payload],
  }),
  [USER_PERMISSIONS_CHECK_FAILURE]: state => ({
    ...state,
  }),
  [OPERATOR_LOCATIONS_SUCCESS]: (state, payload) => ({
    ...state,
    operatorLocations: payload.list.map(l => l.id),
  }),
});
