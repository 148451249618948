import React from 'react';
import classNames from 'classnames';

import './style.scss';

const SlideToggle = (props) => {
  const {
    id,
    label,
    checked,
    onChange,
  } = props;

  const baseClass = 'SlideToggle';

  const wrapClasses = classNames(
    baseClass,
  );

  const labelClasses = classNames(
    `${baseClass}-label`,
  );

  return (
    <div className={wrapClasses}>
      <span className={labelClasses}>{label}</span>
      <label
        htmlFor={id}
      >
        <input
          type="checkbox"
          checked={checked}
          id={id}
          onChange={e => onChange(e.target.checked)}
        />
        <div className="slide-toggle" />
      </label>
    </div>
  );
};

export default SlideToggle;
