import React from 'react';
import { connect } from 'react-redux';

import { ModalBody } from '../../components/modal';
import Heading from '../../components/heading';
import LeadRegisterForm from '../../components/lead-register-form';

import { registerLeadByAdmin } from '../../actions/auth';
import { hideModal } from '../../actions/modal';

const RegisterModal = (props) => {
  const {
    onSubmit, ids, error, isWorkspace
  } = props;

  return (
    <ModalBody small withHeight>
      <Heading level="3" size="xsmall" center>{error ? 'Error' : 'Register Lead'}</Heading>

      {error && <Heading level="4" size="tiny" center>{error}</Heading>}

      <LeadRegisterForm
        onSubmit={val => props.registerLeadByAdmin({ ...val, locations: ids, isWorkspace })
          .then((success) => {
            if (success) {
              props.hideModal();
            }
            onSubmit();
          })}
      />
    </ModalBody>
  );
};

RegisterModal.defaultProps = {
  onSubmit: () => { },
  closeOnSubmit: false,
};

const mapStateToProps = (state) => ({
  error: state.lead.error,
});

export default connect(mapStateToProps, {
  registerLeadByAdmin,
  hideModal,
})(RegisterModal);
