import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  Wrapper,
  List,
  Item,
  Col,
  TopBar,
  Head,
  Search,
  ButtonsGroup,
  Mail,
  CombinedButtons,
} from './styles';
import UserEditForm from './edit-form';
import UserCreateForm from './create-form';
import Button from '../button';
import Spinner from '../spinner';
import { Plus } from '../icons';

export const UsersTable = (props) => {
  const {
    users,
    onCreate,
    creating,
    onUpdate,
    updating,
    onRemove,
    deleting,
    onBunchRemove,
    onSearch,
    search,
  } = props;
  const [editFormUser, setEditFormUser] = useState('');
  const [selected, selectUser] = useState([]);
  const [selectAll, selectAllUsers] = useState(false);

  const openUserEditForm = userId => setEditFormUser(userId);
  const closeUserEditForm = () => setEditFormUser('');
  const openUserCreateForm = () => setEditFormUser('create');
  const userCreateHandler = data => onCreate(data).then(closeUserEditForm);
  const userUpdateHandler = data => onUpdate(data).then(closeUserEditForm);
  const userRemoveHandler = username =>
    onRemove(username).then(closeUserEditForm);
  const userSelectHandler = e => {
    if (selected.includes(e.target.value)) {
      selectUser(selected.filter(item => item !== e.target.value));
      selectAllUsers(false);
    } else {
      selectUser([...selected, e.target.value]);
    }
  };
  const userSelectAllHandler = () => {
    if (selectAll) {
      selectAllUsers(false);
      selectUser([]);
    } else {
      selectAllUsers(true);
      selectUser(users.map(user => user.username));
    }
  };
  const usersBunchDeleteHandler = () => {
    onBunchRemove(selected.map(username => ({ username }))).then(() =>
      selectUser([]),
    );
  };

  return (
    <Wrapper>
      <TopBar>
        <Search
          type="text"
          onChange={onSearch}
          value={search}
          placeholder="Search..."
        />
        {!isEmpty(selected) && (
          <CombinedButtons>
            <Button
              style={{ margin: '0 15px 0 0' }}
              scheme="default-normal"
              onClick={usersBunchDeleteHandler}
            >
              Delete
            </Button>
            <Mail
              scheme="default-normal"
              href={`mailto:${selected.join(',')}`}
            >
              Send email
            </Mail>
          </CombinedButtons>
        )}

        <Button
          style={{ marginLeft: 'auto' }}
          scheme="green"
          onClick={openUserCreateForm}
        >
          <Plus />
          Add User
        </Button>
      </TopBar>
      <List>
        <Head>
          <Col colWidth={4} bordered center>
            <input
              type="checkbox"
              value="selectAll"
              checked={selectAll}
              onChange={userSelectAllHandler}
            />
          </Col>
          <Col colWidth={30}>
            <span>Username</span>
          </Col>
          <Col colWidth={10}>First Name</Col>
          <Col>Last Name</Col>
        </Head>
        {editFormUser === 'create' && (
          <Item key="create" edit>
            <UserCreateForm
              onSubmit={userCreateHandler}
              onClose={closeUserEditForm}
              form="user-edit-form-new"
              creating={creating}
            />
          </Item>
        )}
        {users.map(user => (
          <Item key={user.username} edit={editFormUser === user.username}>
            {editFormUser !== user.username && (
              <>
                <Col colWidth={4} bordered center>
                  <input
                    type="checkbox"
                    value={user.username}
                    checked={selected.includes(user.username)}
                    onChange={userSelectHandler}
                  />
                </Col>
                <Col colWidth={30}>
                  <span>{user.username}</span>
                </Col>
                <Col colWidth={10}>{user.firstName}</Col>
                <Col colWidth={12}>{user.lastName}</Col>
                <Col colWidth={10}>{user.isSuperuser && 'Superuser'}</Col>
                <Col colWidth={10}>{user.isAdmin && 'Admin'}</Col>
                <Col colWidth={10}>{user.isOperator && 'Operator'}</Col>
                <Col flex>
                  {isEmpty(selected) && (
                    <ButtonsGroup>
                      <Button
                        onClick={() => openUserEditForm(user.username)}
                        scheme="link-underline"
                      >
                        Edit
                      </Button>
                      {!user.isSuperuser && (
                        <Button
                          onClick={() => userRemoveHandler(user.username)}
                          scheme="link-underline"
                        >
                          {deleting === user.username && (
                            <Spinner size="small" />
                          )}
                          Delete
                        </Button>
                      )}
                    </ButtonsGroup>
                  )}
                </Col>
              </>
            )}
            {editFormUser === user.username && (
              <UserEditForm
                initialValues={user}
                onClose={closeUserEditForm}
                onSubmit={userUpdateHandler}
                form={`user-edit-form-${user.username}`}
                updating={updating}
              />
            )}
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};
