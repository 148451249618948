import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { groupSubmarkets } from '../../selectors/markets';
import { ROOT, WORKPLACE } from '../../settings/routes';
import Heading from '../../components/heading';

const MarketList = props => {
  const markets = useSelector(state => state.markets.list);

  return (
    <Wrap className="Container">
      {props.title && (
        <Heading
          level="2"
          center
          spaceBelow="small"
          size="xsmall"
          mobileScheme="landing"
          style={{ color: 'black' }}
        >
          {props.title}
        </Heading>
      )}
      {markets && (
        <div>
          <Grid>
            {groupSubmarkets(markets).filter(item => !item.isSubmarket).map(market => (
              <li
                key={market.id}
              >
                <Link
                  to={`/${props.isWorkplace ? WORKPLACE : ROOT}/${
                    market.marketSlug
                  }/`}
                >
                  {market.name}
                </Link>
                {market.isParent && (
                  <ul>
                    {market.submarkets && market.submarkets.map(market => (
                      <li key={market.id}>
                        <Link
                          to={`/${props.isWorkplace ? WORKPLACE : ROOT}/${
                            market.marketSlug
                          }/`}
                        >
                          {market.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </Grid>
        </div>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  margin-bottom: 50px;
`;

const Grid = styled.ul`
  @media (min-width: 64em) {
    columns: 5 auto;
  }

  padding-left: 0;
  list-style: none;
  margin: 0;

  > li {
    padding: 0 10px;
    margin-bottom: 15px;

    ul {
      margin-top: 10px;
      padding: 0;

      li {
        margin-bottom: 5px;
        list-style: disc;
        margin-left: 25px;
      }
    }
  }

  a {
    font-size: 19px;
    color: #2e2c2b;

    &:hover {
      color: var(--brand-color);
    }
  }
`;

export default MarketList;
