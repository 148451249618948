import React from 'react';
import { Field, reduxForm } from 'redux-form';

import Button from '../button';
import { renderField, dateField } from '../form-fields';
import { required, isNum } from '../../utils/lead-validation';

import './styles.scss';

const RegisterFormSteps = props => {
  const { handleSubmit } = props;

  return (
    <>
      <form id="registration-form-main" className="RegisterForm" onSubmit={handleSubmit}>
        <>
          <Field
            component={dateField}
            name="moveinDate"
            id="moveinDate"
            type="text"
            placeholder=""
            required
            label="Move In Date"
          />
          <Field
            component={renderField}
            name="phoneNumber"
            id="phoneNumber"
            type="number"
            placeholder=""
            label="Phone number"
            required
            validate={[required, isNum]}
          />
          <Field
            component={renderField}
            name="company"
            id="company"
            type="text"
            placeholder=""
            label="Company Name"
            required
            validate={required}
          />
          <Button type="submit">See Listing Details</Button>
        </>
      </form>
    </>
  );
};

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'register-form',
})(RegisterFormSteps);
