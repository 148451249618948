import React, { Fragment } from 'react'
import isEmpty from 'lodash/isEmpty'

import CompareBestFor from '../../components/compare-best-for'

import {
  CompareColumn,
  CompareTitleMobile
} from '../../components/compare-page-helpers'

const CompareBestForMobile = props => {
  const { paginate, listings } = props
  const listing =
    paginate >= 0 && paginate <= listings.length - 1 ? listings[paginate] : {}

  return (
    !isEmpty(listing) && (
      <Fragment>
        <CompareTitleMobile>Best For</CompareTitleMobile>
        <CompareColumn className="-mobile">
          <CompareBestFor accolades={listing.accolades} />
        </CompareColumn>
      </Fragment>
    )
  )
}

export default CompareBestForMobile
