import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';

import { ROOT, COMPARE, WORKPLACE } from '../../settings/routes';
import { SEARCH_QUERY } from '../../settings';
import { searchH1, searchParagraph } from '../../settings/text';
import Select from '../../components/select-sort-listings';
import Counter from '../../components/notifications-counter';
import InfoBar from '../../components/total-spaces';
import { profile } from '../../settings/routes'

import { changeSortMethod } from '../../actions/ui';
import { openRegisterModal, openVerifyPassModal } from '../../actions/modal';

import { selectMarketBySlug, selectMarketObject } from '../../selectors/filter-query';

const sortOptions = [{
  value: 'recomended',
  label: 'Recommended',
}, {
  value: 'price-asc',
  label: 'Price low - high',
}, {
  value: 'totalofseats-desc',
  label: 'Availability',
}, {
  value: 'distance',
  label: 'Distance',
}];

const FeedInfoBar = (props) => {
  const {
    favorites, marketName, total, sortMethod,
    isCrawler, market, filterQuery,
    visitorId, isWorkplace, seoPage, isCognitoAuth, userIsAuthenticated
  } = props;


  const h1 = isWorkplace
    ? (!filterQuery[ROOT] && !filterQuery[SEARCH_QUERY]) ? searchH1 : (market && market.h1Workspace)
    : (!filterQuery[ROOT] && !filterQuery[SEARCH_QUERY]) ? searchH1 : (market && market.h1);

  const paragraph = isWorkplace
    ? (!filterQuery[ROOT] && !filterQuery[SEARCH_QUERY]) ? searchParagraph : (market && market.paragraphWorkspace)
    : (!filterQuery[ROOT] && !filterQuery[SEARCH_QUERY]) ? searchParagraph : (market && market.paragraph);

  return (
    <InfoBar
      total={total}
      market={marketName}
      h1={h1}
      paragraph={paragraph}
      isWorkplace={isWorkplace}
      seoPage={seoPage}
    >
      {!isEmpty(favorites) && (
        <Counter count={favorites.length} type="infobar">
          {isCognitoAuth || isCrawler ? (
            <Link to={`/${profile.main}/${profile.spaces}/`}>View Saved Spaces</Link>
          ) : (
            <button
              type="button"
              onClick={() => {
                if (userIsAuthenticated && !isCognitoAuth) {
                  props.openVerifyPassModal()
                } else {
                  props.openRegisterModal({
                    heading: 'To see and save your shortlist',
                    closeOnSubmit: true,
                    onSubmit: () =>
                      props.push(`/${isWorkplace ? WORKPLACE : ROOT}/${COMPARE}`),
                    initialValues: {
                      seats: filterQuery.people,
                      locations: favorites,
                      isWorkspace: (favorites || []).map(() => !!isWorkplace)
                    },
                    form: 'shortlist-button-registration',
                    visitorId,
                    isWorkspace: isWorkplace
                  })
                }
              }}
            >
              View Saved Spaces
            </button>
          )}
        </Counter>
      )}
      <Select
        value={sortMethod}
        onChange={val => props.changeSortMethod(val)}
        options={sortOptions}
      />
    </InfoBar>
  )
};

const mapStateToProps = state => ({
  favorites: state.filterQuery.is_workplace ? state.favorites.workplaceIds : state.favorites.ids,
  marketName: selectMarketBySlug(state),
  sortMethod: state.ui.sortMethod,
  market: selectMarketObject(state),
  isCognitoAuth: state.cognito.token,
  isCrawler: state.auth.isCrawler,
  filterQuery: state.filterQuery,
  visitorId: state.auth.visitorId,
  userIsAuthenticated: state.auth.userIsAuthenticated
});

export default connect(
  mapStateToProps,
  {
    changeSortMethod,
    openRegisterModal,
    openVerifyPassModal,
    push,
  },
)(FeedInfoBar);
