import React from 'react';
import classnames from 'classnames';

import './styles.scss';

export default (props) => {
  const {
    children,
    className,
    withSearchBar,
    landing,
    expanded,
    isNotVisible,
    seoPage
  } = props;

  const classes = classnames(
    'ListingsMap-wrapper',
    className,
    { '-withSearchBar': withSearchBar },
    { '-landing': landing },
    { '-expanded': expanded },
    { '-hidden': isNotVisible },
    { '-asSection': seoPage },
  );

  return (
    <div className={classes}>
      <div className="ListingsMap-sub">
        {children}
      </div>
    </div>
  );
};
