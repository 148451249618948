import fetch from 'isomorphic-fetch';
import { saveAs } from 'file-saver';
import format from 'date-fns/format';
import {
  LISTINGS_API_URL as url,
  LISTINGS_API_KEY as key,
  LISTINGS_API_HEAD as head,
  LISTINGS_API_BASE as base,
} from '../settings';

// eslint-disable-next-line arrow-body-style
export const exportFileGet = (spaceIds, locationIds, token) => {
  const headers = {
    [head]: key,
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ...token ? { Authorization: token } : {},
  };

  return fetch(`${url}${base}/report/compare?space=${spaceIds}&location=${locationIds}`, {
    method: 'get',
    headers,
    credentials: 'omit',
  }).then((response) => {
    if (response.ok) {
      return response.blob();
    }
    throw new Error('Network response was not ok.');
  }).then((blobData) => {
    saveAs(blobData, `Upsuite Coworking Comparison ${format(new Date(), 'MM.DD.YYYY')}.xlsx`);
  }).catch((error) => {
    console.log('There has been a problem with generating export file ', error.message);
  });
};
