import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalBody } from '../../../components/modal';
import Heading from '../../../components/heading';
import '../../../components/register-form-steps/styles.scss';
import RegisterMessage from '../../../components/register-message';
import Flex from '../../../components/flex';
import StarRating from '../../../components/star-rating';
import { CloseModal } from '../../../components/icons';
import First from '../../../components/register-form-steps/first';
import Second from '../../../components/register-form-steps/second';

import { registerLead } from '../../../actions/auth';
import { hideModal } from '../../../actions/modal';

import { setCookie, getCookie } from '../../../utils/cookies';
import { ROOT, WORKPLACE } from '../../../settings/routes';
import { trackFormSubmit, trackFormOptions, trackFormsByType } from '../../../utils/analytics';

const RegisterModal = (props) => {
  const dispatch = useDispatch();
  const { onSubmit, closeOnSubmit, initialValues, form, visitorId, isWorkspace } = props;
  const showSecondary = useSelector(state => state.lead.reg2ndForm)
  const id = initialValues?.locations && initialValues?.locations[0]
  const locationName = useSelector(state => state.listings.byId[id])
  const setShowSecondary = () => dispatch({ type: 'LEAD_REG_2ND_FORM' })

  useEffect(() => {
    trackFormsByType(
      isWorkspace ? WORKPLACE : ROOT,
      form === 'listing-card-registration' ? 'See Details About Registration' : form,
    );
  }, [form, isWorkspace]);

  return (
    <ModalBody small withHeight>
      {showSecondary && (
        <button
          className="Modal-icon -close"
          type="button"
          onClick={() => dispatch(hideModal())}
        >
          <CloseModal />
        </button>
      )}
      <Heading level="3" size="xsmall" center>
        {showSecondary
          ? 'Tell Us a Little More About You'
          : `See Details About ${locationName?.name || ''}`}
      </Heading>
      <Flex columnMobile>
        {!showSecondary && (
          <RegisterMessage heading="I’d like to see" className="-left">
            <ol>
              <li>Photography and virtual tours</li>
              <li>Suite availability</li>
              <li>Pricing and discounts</li>
            </ol>
          </RegisterMessage>
        )}
        {showSecondary ? (
          <Second
            initialValues={{
              ...initialValues,
              seats: getCookie('seats'),
            }}
            onSubmit={val =>
              dispatch(registerLead({ ...val, form, visitorId })).then(() => {
                if (closeOnSubmit) {
                  dispatch(hideModal());
                }
                trackFormSubmit(isWorkspace ? WORKPLACE : ROOT, form);
                trackFormSubmit(isWorkspace ? WORKPLACE : ROOT, 'See Details About Registration');
                trackFormOptions(isWorkspace ? WORKPLACE : ROOT, 'See Details About Registration');
              })
            }
          />
        ) : (
          <First
            buttonName="See Details"
            initialValues={{
              ...initialValues,
              seats: getCookie('seats'),
            }}
            onSubmit={val => {
              onSubmit();
              setShowSecondary();
              dispatch(registerLead({ ...val, form, visitorId })).then(() => {
                setCookie('_REG_TEMP_COMPLETED', true, 365);
                setCookie('firstName', val.firstName, 365);
                trackFormSubmit(isWorkspace ? WORKPLACE : ROOT, form);
                trackFormSubmit(isWorkspace ? WORKPLACE : ROOT, 'See Details About Registration');
                trackFormOptions(isWorkspace ? WORKPLACE : ROOT, 'See Details About Registration');
              });
            }}
          />
        )}
      </Flex>
      <Flex center>
        <StarRating rating={5} size="small" />
        <span style={{ fontSize: 15 }}>
          &quot;Upsuite was very helpful to compare the many flexible office
          options in my area.&quot; - Beth S.
        </span>
      </Flex>
    </ModalBody>
  );
};

RegisterModal.defaultProps = {
  onSubmit: () => {},
  closeOnSubmit: false,
};

export default RegisterModal;
