import keyBy from 'lodash/keyBy';
import { createReducer } from '../utils';
import {
  USERS_GET_REQUEST,
  USERS_GET_SUCCESS,
  USERS_GET_FAILURE,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAILURE,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
  USERS_BUNCH_DELETE_REQUEST,
  USERS_BUNCH_DELETE_SUCCESS,
  USERS_BUNCH_DELETE_FAILURE,
} from '../constants';

const initialState = {
  byUsername: {},
  userNames: [],
  status: {
    loading: false,
    creating: false,
    updating: false,
    deleting: false,
  },
  error: {
    loading: false,
    creating: false,
    updating: false,
    deleting: false,
  },
};

export default createReducer(initialState, {
  [USERS_GET_REQUEST]: state => ({
    ...state,
    status: {
      ...initialState.status,
      loading: true,
    },
    error: {
      ...initialState.error,
      loading: false,
    },
  }),
  [USERS_GET_SUCCESS]: (state, payload) => ({
    ...state,
    byUsername: {
      ...state.byUsername,
      ...keyBy(payload, 'username'),
    },
    userNames: payload.map(u => u.username),
    status: {
      ...initialState.status,
      loading: false,
    },
    error: {
      ...initialState.error,
      loading: false,
    },
  }),
  [USERS_GET_FAILURE]: state => ({
    ...state,
    status: {
      ...initialState.status,
      loading: false,
    },
    error: {
      ...initialState.error,
      loading: true,
    },
  }),
  [USER_CREATE_REQUEST]: state => ({
    ...state,
    status: {
      ...initialState.status,
      creating: true,
    },
    error: {
      ...initialState.error,
      creating: false,
    },
  }),
  [USER_CREATE_SUCCESS]: (state, payload) => ({
    ...state,
    byUsername: {
      ...state.byUsername,
      ...keyBy(payload, 'username'),
    },
    userNames: [...state.userNames, payload.map(u => u.username)],
    status: {
      ...initialState.status,
      creating: false,
    },
    error: {
      ...initialState.error,
      creating: false,
    },
  }),
  [USER_CREATE_FAILURE]: (state, payload) => ({
    ...state,
    status: {
      ...initialState.status,
      creating: false,
    },
    error: {
      ...initialState.error,
      creating: payload,
    },
  }),
  [USER_UPDATE_REQUEST]: state => ({
    ...state,
    status: {
      ...initialState.status,
      updating: true,
    },
    error: {
      ...initialState.error,
      updating: false,
    },
  }),
  [USER_UPDATE_SUCCESS]: (state, payload) => ({
    ...state,
    byUsername: {
      ...state.byUsername,
      [payload.username]: payload,
    },
    status: {
      ...initialState.status,
      updating: false,
    },
    error: {
      ...initialState.error,
      updating: false,
    },
  }),
  [USER_UPDATE_FAILURE]: (state, payload) => ({
    ...state,
    status: {
      ...initialState.status,
      updating: false,
    },
    error: {
      ...initialState.error,
      updating: payload,
    },
  }),
  [USER_DELETE_REQUEST]: (state, payload) => ({
    ...state,
    status: {
      ...initialState.status,
      deleting: payload,
    },
    error: {
      ...initialState.error,
      deleting: false,
    },
  }),
  [USER_DELETE_SUCCESS]: (state, payload) => ({
    ...state,
    userNames: state.userNames.filter(username => username !== payload),
    status: {
      ...initialState.status,
      deleting: false,
    },
    error: {
      ...initialState.error,
      deleting: false,
    },
  }),
  [USER_DELETE_FAILURE]: (state, payload) => ({
    ...state,
    status: {
      ...initialState.status,
      deleting: false,
    },
    error: {
      ...initialState.error,
      deleting: payload,
    },
  }),
  [USERS_BUNCH_DELETE_REQUEST]: state => ({
    ...state,
    status: {
      ...initialState.status,
      deleting: true,
    },
    error: {
      ...initialState.error,
      deleting: false,
    },
  }),
  [USERS_BUNCH_DELETE_SUCCESS]: (state, payload) => ({
    ...state,
    userNames: state.userNames.filter(
      username => !payload.map(user => user.username).includes(username),
    ),
    status: {
      ...initialState.status,
      deleting: false,
    },
    error: {
      ...initialState.error,
      deleting: false,
    },
  }),
  [USERS_BUNCH_DELETE_FAILURE]: (state, payload) => ({
    ...state,
    status: {
      ...initialState.status,
      deleting: false,
    },
    error: {
      ...initialState.error,
      deleting: payload,
    },
  }),
});
