import React from 'react'
import { Link } from 'react-router-dom'

import ButtonMeta from '../button-meta'
import Verified from '../verified'
import Tooltip from '../tooltip'
import AmenitiesList from '../amenities-workplace'
import HealthyFlag, { HealthyFlagInfoContainer } from '../healthy-space-flag'

import {
  SINGLE_LISTING_BASE_ROUTE as singleRoute,
  SINGLE_LISTING_WORKPLACE_BASE_ROUTE as workspaceRoute
} from '../../settings'
import {
  verifiedText,
  verifiedNewText,
  healthySpaceText
} from '../../settings/text'

import './styles.scss'

const InfoBox = props => {
  const {
    formattedCardImage,
    marketSlug,
    marketplaceSlug,
    name,
    toggleFavorites,
    isFavorite,
    verified,
    isHealthy,
    onFirstClick,
    essenceStatementMapPin,
    newLocation,
    isWorkplace,
    amenities,
    hideSaveAbility
  } = props

  return (
    <div className="InfoBox">
      <div className="InfoBox-wrapper">
        <div className="InfoBox-top">
          {formattedCardImage && (
            <div
              className="InfoBox-image"
              style={{ backgroundImage: `url("${formattedCardImage}")` }}
            />
          )}
          {marketplaceSlug &&
            marketSlug &&
            (onFirstClick ? (
              <button
                className="InfoBox-link"
                onClick={onFirstClick}
                type="button"
              />
            ) : (
              <Link
                className="InfoBox-link"
                to={`/${
                  isWorkplace ? workspaceRoute : singleRoute
                }/${marketSlug}/${marketplaceSlug}/`}
              />
            ))}

          <div className="InfoBox-meta">
            {verified && (
              <Tooltip overlay={newLocation ? verifiedNewText : verifiedText}>
                <div className="InfoBox-verified">
                  <Verified size="tiny" newLocation={newLocation} />
                </div>
              </Tooltip>
            )}
            {isHealthy && (
              <Tooltip placement="bottomLeft" overlay={healthySpaceText}>
                <HealthyFlagInfoContainer
                  upsuiteVerified={verified}
                  tiny
                  isNew={newLocation}
                >
                  <HealthyFlag upsuiteVerified={verified} tiny />
                </HealthyFlagInfoContainer>
              </Tooltip>
            )}
            {toggleFavorites && !hideSaveAbility && (
              <ButtonMeta
                onClick={toggleFavorites}
                size="small"
                isActive={isFavorite}
                className="compare-button"
              >
                + Save
              </ButtonMeta>
            )}
          </div>
        </div>
        <div className="InfoBox-bottom">
          <div className="InfoBox-col -start">
            <div>
              <div className="InfoBox-heading">
                <h3>{essenceStatementMapPin || name}</h3>
              </div>
            </div>
          </div>
          <div className="InfoBox-col -end">
            <div className="InfoBox-rating">
              {isWorkplace && <AmenitiesList amenities={amenities} />}
            </div>
          </div>
        </div>
        <div className="InfoBox-accolades" />
      </div>
      <div className="InfoBox-arrow" />
    </div>
  )
}

export default InfoBox
