/* global window */
import React from 'react';
import classNames from 'classnames';

import { Facebook, Twitter, Email } from '../icons';
import ShareCopy from '../copy-link';

import './styles.scss';

export const ShareLink = ({ type, link, children, onClick }) => {
  const types = {
    facebook: {
      link: `https://www.facebook.com/sharer/sharer.php?u=${link}`,
      icon: <Facebook />,
    },
    twitter: {
      link: `https://twitter.com/home?status=${link}`,
      icon: <Twitter />,
    },
  };

  return (
    <button className={classNames('share-link', `-${type}`)} onClick={() => { window.open(types[type].link, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=400'); onClick(); }}>
      {types[type].icon}
      {children}
    </button>
  );
};

export const ShareEmail = ({ link, children, onClick }) => (
  <a
    href={`mailto:?&subject=Check out these Coworking Spaces&body=I was researching a few Coworking spaces on Upsuite.com and found this one. If we book a tour or trial day at a verified location through their site we get 5% off our first 12 months.%20%0A %20%0A
Check it out: %20%0A ${link}`}
    className="share-link -email"
    onClick={onClick}
  >
    <Email />
    {children}
  </a>
);

export const Item = ({ children }) =>
  <li className="share-item">{children}</li>;

export const List = ({ location, clickHandler }) => (
  <ul className="share-list">
    <Item>
      <ShareLink link={location} onClick={clickHandler} type="facebook">Facebook</ShareLink>
    </Item>
    <Item>
      <ShareLink link={location} onClick={clickHandler} type="twitter">Twitter</ShareLink>
    </Item>
    <Item>
      <ShareEmail link={location} onClick={clickHandler} type="email">Email</ShareEmail>
    </Item>
    <Item>
      <ShareCopy link={location} />
    </Item>
  </ul>
);
