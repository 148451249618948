import sortBy from 'lodash/sortBy';
import getDistanceFromLatLonInKm from './geo';

export const getNearestMarket = (location, marketList = []) => {
  const listWithDistance = marketList
    .map(({ centerLat, centerLon, name }) => ({
      distance: getDistanceFromLatLonInKm(
        location.lat,
        location.lng,
        centerLat,
        centerLon,
      ),
      name,
    }));
  const byDistance = sortBy(listWithDistance, 'distance');

  return byDistance[0].name;
};
