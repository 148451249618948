import React from 'react';

import { Logo } from '../icons';
import Button from '../button';
import Heading from '../heading';

import './styles.scss';

export default ({ onButtonClick, inline }) => (
  <div className={`AdCard ${inline ? '-inline' : ''}`}>
    <div className="AdCard-inner">
      <div className="AdCard-top">
        <Logo />
        Upsuite Advisors
      </div>

      <Heading center={false} className="AdCard-heading" level="2" size="small">
        Save 90% of the research time comparing different options.
        {' '}
        <span>Work with an Upsuite Advisor.</span>
      </Heading>
      <Button size="tiny" onClick={onButtonClick}>Work with an Upsuite Advisor</Button>
    </div>
  </div>
);
