import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import ButtonGroup from '../../components/button-group'
import Button from '../../components/button'
import Sticky from '../../components/sticky'
import AdvisorBlock from '../../components/advisor-block'

import {
  openCtaActionsModal,
  openIframeModal,
  openVerifyPassModal
} from '../../actions/modal'
import { virtualtourRequested } from '../../api/users'
import { getCookie } from '../../utils/cookies'
import { ROOT, WORKPLACE } from '../../settings/routes'

const SingleCTA = props => {
  const dispatch = useDispatch()
  const userIsAuthenticated = useSelector(
    state => state.auth.userIsAuthenticated
  )
  const isCognitoAuth = useSelector(state => state.cognito.token)

  const {
    listingId,
    marketName,
    visitorId,
    isWorkplace,
    isClosed,
    searchFilterQueryUrl,
    marketSlug
  } = props

  if (!listingId) return null

  return (
    <Sticky>
      {isClosed && marketName && (
        <ButtonGroup fullWidth>
          <Button
            onClick={() => {
              if (!isEmpty(searchFilterQueryUrl)) {
                dispatch(push(searchFilterQueryUrl))
                return
              }

              dispatch(
                push(`/${isWorkplace ? WORKPLACE : ROOT}/${marketSlug}/`)
              )
            }}
            className="schedule-a-tour-button"
            style={{ textTransform: 'unset' }}
          >
            {`Find Space in ${marketName}`}
          </Button>
        </ButtonGroup>
      )}
      {!isClosed && (
        <ButtonGroup fullWidth>
          {isWorkplace && (
            <Button
              onClick={() => {
                dispatch(
                  openIframeModal({
                    src: 'https://meetings.hubspot.com/hailey-barnett1',
                    title: 'Book a Guided Virtual Tour',
                    width: '900px',
                    height: '815px'
                  })
                )
                virtualtourRequested({
                  email: getCookie('email'),
                  leadid: visitorId,
                  location: [listingId]
                })
              }}
              className="schedule-a-tour-button"
              style={{ textTransform: 'unset' }}
            >
              Book a Guided Virtual Tour
            </Button>
          )}
          <Button
            onClick={() => {
              if (userIsAuthenticated && !isCognitoAuth) {
                dispatch(openVerifyPassModal())
              } else {
                dispatch(
                  openCtaActionsModal({
                    heading: 'Visit This Space',
                    buttonName: 'Visit This Space',
                    fields: [
                      'firstName',
                      'email',
                      'seats',
                      'phoneNumber',
                      'preferredTourDate',
                      'preferredTourTime',
                      'moveinDate'
                    ],
                    initialValues: {
                      locations: [listingId],
                      visitorId,
                      isWorkspace: [!!isWorkplace],
                      form: 'visit-this-space'
                    },
                    isWorkspace: !!isWorkplace,
                    successText:
                      'Thanks for booking your tour through Upsuite! We will be in touch shortly!'
                  })
                )
              }
            }}
            className="schedule-a-tour-button"
            style={{ textTransform: 'unset' }}
          >
            Visit This Space
          </Button>
          <Button
            scheme="bordered-transparent"
            onClick={() => {
              if (userIsAuthenticated && !isCognitoAuth) {
                dispatch(openVerifyPassModal())
              } else {
                dispatch(
                  openCtaActionsModal({
                    heading: 'Request More Info',
                    fields: [
                      'requestInfo',
                      'firstName',
                      'email',
                      'seats',
                      'phoneNumber'
                    ],
                    initialValues: {
                      locations: [listingId],
                      visitorId,
                      isWorkspace: [!!isWorkplace],
                      form: 'request-more-info'
                    },
                    isWorkspace: !!isWorkplace,
                    successText:
                      'Thanks for reaching out! We will get back to you quickly regarding your inquiry!'
                  })
                )
              }
            }}
            className="request-more-info-button"
          >
            Request More Information
          </Button>
          {!isEmpty(props.brochure) && (
            <a
              href={props.brochure}
              className="button -color-default -scheme-bordered-transparent -size-default -spaceBelow-default request-more-info-button"
              style={{ textAlign: 'center' }}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              Download Brochure
            </a>
          )}
        </ButtonGroup>
      )}

      {!isWorkplace && marketName && <AdvisorBlock />}
    </Sticky>
  )
}

export default SingleCTA
