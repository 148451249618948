import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { openRegisterUserModal } from '../../actions/modal'
import {
  configureAmplify,
  getAuthCurrentSession,
  logout
} from '../../actions/cognito'
import { getCookie } from '../../utils/cookies'
import { hideModal, openVerifyPassModal } from '../../actions/modal'
import { profile } from '../../settings/routes'

const Account = () => {
  const dispatch = useDispatch()
  const { visitorId, userIsAuthenticated } = useSelector(state => state.auth)
  const auth = useSelector(state => state.cognito)
  const lead = useSelector(state => state.lead)
  const isCognitoAuth = auth.token
  const firstName = getCookie('firstName') || lead.firstName
  const lastName = getCookie('upsuite_user_last_name') || lead.lastName
  const tempReg = getCookie('upsuite_user_is_authenticated')

  const authModal = () =>
    dispatch(
      openRegisterUserModal({
        closeOnSubmit: true,
        initialValues: {},
        onSubmit: () => dispatch(hideModal()),
        form: 'user-registration',
        visitorId
      })
    )

  useEffect(() => {
    configureAmplify()

    dispatch(getAuthCurrentSession())
  }, [dispatch])

  return (
    <Wrap>
      {((userIsAuthenticated && !isCognitoAuth) ||
        !(isCognitoAuth || userIsAuthenticated)) && (
        <MenuItem
          onClick={() => {
            if (!(isCognitoAuth || userIsAuthenticated)) {
              authModal()
              return
            }

            if (userIsAuthenticated && !isCognitoAuth) {
              dispatch(openVerifyPassModal())
            }
          }}
        >
          Saved Spaces
        </MenuItem>
      )}
      {isCognitoAuth && (
        <MainMenuItem to={`/${profile.main}/${profile.spaces}/`}>
          Saved Spaces
        </MainMenuItem>
      )}
      {!(isCognitoAuth || userIsAuthenticated || tempReg) && (
        <RegisterMenu>
          <button type="button" aria-haspopup="true">
            Register/Sign in
          </button>
          <ul role="menu">
            <li role="none">
              <button role="menuitem" type="button" onClick={() => authModal()}>
                Member Sign In
              </button>
            </li>
            <li role="none">
              <a
                href="https://upsuite.flexofficepro.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >Operator Sign In</a>
            </li>
          </ul>
        </RegisterMenu>
      )}
      {(isCognitoAuth || userIsAuthenticated || tempReg) && (
        <DropWrap>
          <User>
            <svg
              aria-hidden="true"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="20"
              height="23"
            >
              <path
                fill="currentColor"
                d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
              ></path>
            </svg>
            {firstName && <span>{firstName}</span>}
            {lastName && <span>{lastName}</span>}
          </User>

          <DropMenu>
            {userIsAuthenticated && !isCognitoAuth && (
              <>
                <li>
                  <button
                    type="button"
                    onClick={() => dispatch(openVerifyPassModal())}
                  >
                    Saved Spaces
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => dispatch(openVerifyPassModal())}
                  >
                    Saved Searches
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => dispatch(openVerifyPassModal())}
                  >
                    Profile
                  </button>
                </li>
              </>
            )}
            {tempReg && !isCognitoAuth && !userIsAuthenticated && (
              <>
                <li>
                  <button type="button" onClick={() => authModal()}>
                    Saved Spaces
                  </button>
                </li>
                <li>
                  <button type="button" onClick={() => authModal()}>
                    Saved Searches
                  </button>
                </li>
                <li>
                  <button type="button" onClick={() => authModal()}>
                    Profile
                  </button>
                </li>
              </>
            )}
            {isCognitoAuth && (
              <>
                <li>
                  <NavLink to={`/${profile.main}/${profile.spaces}/`}>
                    Saved Spaces
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/${profile.main}/${profile.searches}/`}>
                    Saved Searches
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/${profile.main}/${profile.settings}/`}>
                    Profile
                  </NavLink>
                </li>
                <li>
                  <button type="button" onClick={() => [dispatch(logout())]}>
                    Logout
                  </button>
                </li>
              </>
            )}
          </DropMenu>
        </DropWrap>
      )}
    </Wrap>
  )
}

const DropMenu = styled.ul`
  padding-left: 15px;

  @media (min-width: 64em) {
    position: absolute;
    display: none;
    top: 100%;
    min-width: 170px;
    width: 100%;
    right: 0;
    z-index: 999;
    padding: 0;

    border-color: #828282;
    border-style: solid;
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-width: 0;
  }

  background: white;
  list-style: none;
  margin: 0;

  li {
    cursor: pointer;
    padding: 10px 15px;

    button {
      background: none;
      border: none;
      box-shadow: none;
      cursor: pointer;
      padding: 0;
      line-height: 1.15;
    }

    a {
      color: inherit;

      &.active {
        color: var(--brand-color);
      }
    }

    a, button {
      &:hover {
        color: var(--brand-color);
      }
    }
  }
`

const DropWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media (min-width: 64em) {
    flex-direction: row;
    height: 100%;
  }

  &:hover {
    ${DropMenu} {
      display: block;
    }
  }
`

const User = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  padding-left: 15px;

  span {
    font-size: 14px;
    color: #2e2c2b;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    line-height: 1.1;
    margin-right: 5px;
    margin-left: 5px;
  }

  svg {
    color: lightgray;
    margin-right: 10px;
    width: 20px;
    height: 23px;
  }

  @media (min-width: 64em) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;

  @media (min-width: 64em) {
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;
    height: 100%;
  }
`

const MenuItem = styled.button.attrs({ type: 'button' })`
  color: #2e2c2b;
  padding: 10px 15px;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 1.1;

  &:hover {
    color: var(--brand-color);
  }

  @media (min-width: 64em) {
    font-size: 14px;
    padding-top: 0;
    padding-bottom: 0;
  }
`

const MainMenuItem = styled(NavLink)`
  color: #2e2c2b;
  padding: 10px 15px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 1.1;

  &.active, &:hover {
    color: var(--brand-color);
  }

  @media (min-width: 64em) {
    font-size: 14px;
    padding: 0 15px;
  }
`

const RegisterMenu = styled.div`
  position: relative;

  > button {
    display: block;
    text-align: center;
    background-color: #fff;
    color: var(--brand-color);
    padding: 9px 20px;
    line-height: 1.3;
    border: 2px solid var(--brand-color);
    font-weight: 600;
    box-shadow: 0 0.4em 0.3em rgb(0 0 0 / 16%);
    border-radius: 0.36em;
    font-size: 14px;
    cursor: pointer;

    margin-left: 15px;

    display: none;

    @media (min-width: 64em) {
      margin-left: 0;
      display: block;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    background: white;
    padding-top: 2px;
    padding-bottom: 2px;

    @media (min-width: 64em) {
      position: absolute;
      top: 100%;
      display: none;
    }

    li button {
      background: none;
      box-shadow: none;
      outline: none;
      cursor: pointer;
      border: none;
    }

    li button, li a {
      padding: 10px 15px;
      color: #2e2c2b;
      font-weight: 600;
      font-size: 16px;
      width: 100%;
      text-align: left;
      display: inline-block;
      font-family: 'Montserrat', sans-serif;

      &:hover {
        color: var(--brand-color);
      }

      @media (min-width: 64em) {
        font-size: 14px;
        padding: 8px 12px;
      }
    }
  }

  &:hover {
    ul {
      display: block;
    }
  }
`

export default Account
