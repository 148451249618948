import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import {
  listingBySlug,
  relatedListingsBySlug,
  listingsByMarket,
  listingsByRoute,
  latLonByRoute,
  listingById,
  compareById,
  operatorLocById,
  latLonByLastRequest,
  latLonByLastRequestSorted,
  getSyncedMarkers,
  compareSavedIds,
  selectSpaceMarkets
} from './mappers';
import { getRoute } from '../filter-query';
import { getId, getPinId } from '../ui';

const bySlug = state => state.listings.bySlug;

const byId = state => state.listings.byId;

const relatedBySlug = state => state.listings.relatedBySlug;

const relatedById = state => state.listings.relatedById;

const byMarketId = state => state.listings.byMarketId;
const marketsByName = state => state.markets.byName;

const byRoute = state => state.listings.byRoute;

const byLastRequest = state => state.listings.byLastRequest;

const topByMarket = state => state.listings.topByMarket;

const getPath = (state, props) => {
  const { slug, marketSlug } = props.match.params;
  return `${marketSlug}/${slug}`;
};

const getPathByProps = (state, props) => props

const marketId = (state, props) => props;

const nearestMarket = state => state.listings.nearestMarket;

const getCompareIds = state => state.favorites.ids;

const getSavedIds = (state, props) => props.ids

const getCompareWorkspaceIds = state => state.favorites.workplaceIds;

const getOperatorLocIds = state => state.userPermissions.operatorLocations;

const getShareIds = (state, props) => props.match.params.ids && props.match.params.ids.split(',');

const getSortMethod = state => state.ui.sortMethod;

export const getSyncMarkers = createSelector(
  state => state.listings.syncMarkers,
  markers => markers,
);

export const selectSingleListing = createSelector(
  bySlug,
  byId,
  getPath,
  listingBySlug,
);

export const selectRelated = createSelector(
  relatedBySlug,
  relatedById,
  getPath,
  relatedListingsBySlug,
);

export const selectRelatedSlug = createSelector(
  relatedBySlug,
  relatedById,
  getPathByProps,
  relatedListingsBySlug,
);

export const selectByMarketId = createSelector(
  byMarketId,
  byId,
  marketId,
  listingsByMarket,
);

export const selectByRoute = createSelector(
  byRoute,
  byId,
  getRoute,
  listingsByRoute,
);

export const selectLatLonByRoute = createSelector(
  byRoute,
  byId,
  getRoute,
  latLonByRoute,
);

export const selectHighlighted = createSelector(
  byId,
  getId,
  listingById,
);

export const selectPin = createSelector(
  byId,
  getPinId,
  listingById,
);

export const selectTopMarkets = createSelector(
  topByMarket,
  byId,
  nearestMarket,
  listingsByMarket,
);

export const selectCompareListings = createSelector(
  getCompareIds,
  getShareIds,
  byId,
  marketsByName,
  compareById,
);

export const selectSavedListings = createSelector(
  getSavedIds,
  byId,
  marketsByName,
  compareSavedIds,
);

export const selectSavedSpaceMarkets = createSelector(
  getSavedIds,
  byId,
  selectSpaceMarkets
)

export const selectCompareWorkplaceListings = createSelector(
  getCompareWorkspaceIds,
  getShareIds,
  byId,
  marketsByName,
  compareById,
);

export const selectCompareIds = createSelector(
  getCompareIds,
  getShareIds,
  (compareIds, shareIds) => shareIds || compareIds,
);

export const selectCompareWordspaceIds = createSelector(
  getCompareWorkspaceIds,
  getShareIds,
  (compareIds, shareIds) => shareIds || compareIds,
);

export const selectLatLonByLastRequest = createSelector(
  byId,
  byLastRequest,
  latLonByLastRequest,
);

export const selectOperatorListings = createSelector(
  getOperatorLocIds,
  byId,
  operatorLocById,
);

export const selectSortedListingsByLastRequest = createSelector(
  getSortMethod,
  byId,
  byLastRequest,
  latLonByLastRequestSorted,
);

export const selectSyncedMarkers = createSelector(
  getSyncMarkers,
  selectSortedListingsByLastRequest,
  getSyncedMarkers,
);

export const selectNoLocations = createSelector(
  state => state.listings.loading,
  state => state.listings.completed,
  selectSyncedMarkers,
  (loading, completed, listings) => !loading && completed && isEmpty(listings),
);
