import React, { Component } from 'react';
import classnames from 'classnames';

import { CloseModal } from '../icons';

import './styles.scss';

export default class ExpandableMap extends Component {
  state = {
    expanded: false,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children.key !== this.props.children.key) {
      this.setState({ expanded: false });
    }
  }

  handleExpand() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  render() {
    const { children, className } = this.props;
    const { expanded } = this.state;

    const mainCls = classnames(
      className,
      'ExpandableMap',
      { '-expanded': expanded },
    );

    const btnCls = classnames(
      'ExpandableMap-action',
      { '-expanded': expanded },
    );

    return (
      <div className={mainCls}>
        {children}
        <button
          className={btnCls}
          onClick={() => this.handleExpand()}
        >{expanded ? <CloseModal /> : 'View Map'}</button>
      </div>
    );
  }
}
