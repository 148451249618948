import fetch from 'isomorphic-fetch';
import {
  LISTINGS_API_URL as url,
  LISTINGS_API_BASE as base,
  LISTINGS_API_KEY as apiKey,
} from '../settings';

export const getLocationsByMarketSlug = (marketSlug, isWorkspace) =>
  fetch(
    `${url}${base}/location/bymarketslug?slug=${marketSlug}${isWorkspace ? '&is_workplace=true' : ''}&fields=id,name,marketplaceSlug,addressCity,addressCountry,addressStreet,addressPostalCode,latitude,longitude,listingCardImageId`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    },
  );

export const getForecast = (marketid) =>
  fetch(
    `${url}${base}/invester/forecast?marketid=${marketid}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    },
  );

export const getAskingprice = (marketid) =>
  fetch(
    `${url}${base}/invester/askingprice?marketid=${marketid}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    },
  );
