import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { mdUp, lgUp } from '../../styles/theme/breakpoints';

import './styles.scss';

const Header = (props) => {
  const {
    children,
    className,
    fixed,
    fixedMobile,
    navOpened,
    alignLeft,
  } = props;

  const classes = classnames(
    className,
    'Header', {
      '-fixed': fixed,
      '-fixedMobile': fixedMobile,
      '-navOpened': navOpened,
      '-alignLeft': alignLeft,
    },
  );

  const wrapClasses = classnames(
    className,
    'Header-wrap', {
      '-alignLeft': alignLeft,
    },
  );

  return (
    <div className={classes}>
      <div className={wrapClasses}>
        {children}
      </div>
    </div>
  );
};

Header.propTypes = {
  fixed: PropTypes.bool,
  navOpened: PropTypes.bool,
  alignLeft: PropTypes.bool,
  className: PropTypes.string,
};

Header.defaultProps = {
  fixed: false,
  navOpened: false,
  className: null,
  alignLeft: false,
};

export default Header;

export const HeaderPhone = styled.span`
  display: none;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  font-family: "Montserrat";

  @media ${mdUp} {
    display: inline;
  }

  a {
    color: #fff;

    &:hover {
      color: #e7483d;
    }
  }

  @media ${lgUp} {
    color: #000;

    a {
      color: #000;
    }
  }
`;

export const HeaderPhoneMobile = styled.span`
  font-weight: 600;
  color: #fff;
  width: 16px;
  height: 16px;
  margin-left: auto;
  margin-right: 16px;

  @media ${mdUp} {
    display: none;
  }

  a {
    color: #fff;
  }
`;
