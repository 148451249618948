import { createReducer } from '../utils'
import { getCookie } from '../utils/cookies'
import {
  LEAD_CREATING_REQUEST,
  LEAD_CREATING_SUCCESS,
  LEAD_CREATING_FAILURE,
  LEAD_CREATING_FAILURE_RESET,
  FILTER_QUERY_SET,
  LEAD_REGISTER_BY_ADMIN_REQUEST,
  LEAD_REGISTER_BY_ADMIN_SUCCESS,
  LEAD_REGISTER_BY_ADMIN_FAILURE,
  LEAD_SEARCHES_GET_REQUEST,
  LEAD_SEARCHES_GET_SUCCESS,
  LEAD_SEARCHES_GET_FAILURE,
  LEAD_SEARCHES_DELETE_REQUEST,
  LEAD_SEARCHES_DELETE_SUCCESS,
  LEAD_SEARCHES_DELETE_FAILURE,
  LEAD_SEARCHES_ADD_REQUEST,
  LEAD_SEARCHES_ADD_SUCCESS,
  LEAD_SEARCHES_ADD_FAILURE,
  LEAD_SPACES_GET_REQUEST,
  LEAD_SPACES_GET_SUCCESS,
  LEAD_SPACES_GET_FAILURE,
  LEAD_SPACES_ADD_REQUEST,
  LEAD_SPACES_ADD_SUCCESS,
  LEAD_SPACES_ADD_FAILURE,
  LEAD_SPACES_UPDATE_REQUEST,
  LEAD_SPACES_UPDATE_SUCCESS,
  LEAD_SPACES_UPDATE_FAILURE,
  LEAD_ACCOUNT_GET_REQUEST,
  LEAD_ACCOUNT_GET_SUCCESS,
  LEAD_ACCOUNT_GET_FAILURE,
  LEAD_ACCOUNT_UPDATE_REQUEST,
  LEAD_ACCOUNT_UPDATE_SUCCESS,
  LEAD_ACCOUNT_UPDATE_FAILURE,
  LEAD_PROPOSAL_GET_REQUEST,
  LEAD_PROPOSAL_GET_SUCCESS,
  LEAD_PROPOSAL_GET_FAILURE
} from '../constants'

const initialState = {
  firstName: getCookie('firstName'),
  lastName: getCookie('upsuite_user_last_name'),
  email: getCookie('email'),
  seats: getCookie('seats'),
  visitorId: getCookie('visitorId'),
  phoneNumber: getCookie('phoneNumber'),
  preferredTourDate: getCookie('preferredTourDate'),
  preferredTourTime: getCookie('preferredTourTime'),
  moveinDate: getCookie('moveinDate'),
  company: null,
  jobTitle: null,
  marketId: null,
  industry: null,
  loading: false,
  failure: false,
  error: false,
  reg2ndForm: false,
  status: {
    loading: false,
    updating: false
  },
  statusError: {
    loading: false,
    updating: false
  },
  savedSearches: {
    loading: false,
    error: false,
    list: []
  },
  savedSpaces: {
    status: {
      completed: false,
      loading: false,
      creating: false,
      deleting: false,
      updating: false
    },
    error: {
      loading: false,
      creating: false,
      deleting: false,
      updating: false
    },
    list: []
  },
  proposal: {
    status: {
      completed: false,
      loading: false
    },
    error: {
      loading: false
    },
    list: []
  }
}

export default createReducer(initialState, {
  [LEAD_CREATING_REQUEST]: state => ({
    ...state,
    loading: true
  }),
  [LEAD_CREATING_SUCCESS]: (state, payload) => ({
    ...state,
    ...payload,
    loading: false,
    failure: false
  }),
  [LEAD_CREATING_FAILURE]: state => ({
    ...state,
    loading: false,
    failure: true
  }),
  [LEAD_CREATING_FAILURE_RESET]: state => ({
    ...state,
    loading: false,
    failure: false
  }),
  [FILTER_QUERY_SET]: (state, payload) => ({
    ...state,
    seats: payload.people
  }),
  [LEAD_REGISTER_BY_ADMIN_REQUEST]: state => ({
    ...state,
    error: false
  }),
  [LEAD_REGISTER_BY_ADMIN_SUCCESS]: state => ({
    ...state
  }),
  [LEAD_REGISTER_BY_ADMIN_FAILURE]: (state, payload) => ({
    ...state,
    error: payload
  }),
  [LEAD_SEARCHES_GET_REQUEST]: state => ({
    ...state,
    savedSearches: {
      ...state.savedSearches,
      loading: true,
      error: false
    }
  }),
  [LEAD_SEARCHES_GET_SUCCESS]: (state, payload) => ({
    ...state,
    savedSearches: {
      loading: false,
      error: false,
      list: payload
    }
  }),
  [LEAD_SEARCHES_GET_FAILURE]: state => ({
    ...state,
    savedSearches: {
      ...state.savedSearches,
      loading: false,
      error: true
    }
  }),
  [LEAD_SEARCHES_DELETE_REQUEST]: state => ({
    ...state,
    savedSearches: {
      ...state.savedSearches,
      loading: true,
      error: false
    }
  }),
  [LEAD_SEARCHES_DELETE_SUCCESS]: (state, payload) => ({
    ...state,
    savedSearches: {
      loading: false,
      error: false,
      list: state.savedSearches.list.filter(item => item.id !== payload)
    }
  }),
  [LEAD_SEARCHES_DELETE_FAILURE]: state => ({
    ...state,
    savedSearches: {
      ...state.savedSearches,
      loading: false,
      error: true
    }
  }),
  [LEAD_SEARCHES_ADD_REQUEST]: state => ({
    ...state,
    savedSearches: {
      ...state.savedSearches,
      loading: true,
      error: false
    }
  }),
  [LEAD_SEARCHES_ADD_SUCCESS]: state => ({
    ...state,
    savedSearches: {
      ...state.savedSearches,
      loading: false,
      error: false
    }
  }),
  [LEAD_SEARCHES_ADD_FAILURE]: state => ({
    ...state,
    savedSearches: {
      ...state.savedSearches,
      loading: false,
      error: true
    }
  }),
  [LEAD_SPACES_GET_REQUEST]: state => ({
    ...state,
    savedSpaces: {
      ...state.savedSpaces,
      status: {
        ...state.savedSpaces.status,
        loading: true,
        completed: false
      },
      error: {
        ...state.savedSpaces.error,
        loading: false
      }
    }
  }),
  [LEAD_SPACES_GET_SUCCESS]: (state, payload) => ({
    ...state,
    savedSpaces: {
      ...state.savedSpaces,
      status: {
        ...state.savedSpaces.status,
        loading: false,
        completed: true
      },
      error: {
        ...state.savedSpaces.error,
        loading: false
      },
      list: payload
    }
  }),
  [LEAD_SPACES_GET_FAILURE]: state => ({
    ...state,
    savedSpaces: {
      ...state.savedSpaces,
      status: {
        ...state.savedSpaces.status,
        loading: false
      },
      error: {
        ...state.savedSpaces.error,
        loading: true
      }
    }
  }),
  [LEAD_SPACES_ADD_REQUEST]: state => ({
    ...state,
    savedSpaces: {
      ...state.savedSpaces,
      status: {
        ...state.savedSpaces.status,
        creating: true
      },
      error: {
        ...state.savedSpaces.error,
        creating: false
      }
    }
  }),
  [LEAD_SPACES_ADD_SUCCESS]: (state, payload) => ({
    ...state,
    savedSpaces: {
      ...state.savedSpaces,
      status: {
        ...state.savedSpaces.status,
        creating: false
      },
      error: {
        ...state.savedSpaces.error,
        creating: false
      },
      list: [...state.savedSpaces.list, payload]
    }
  }),
  [LEAD_SPACES_ADD_FAILURE]: state => ({
    ...state,
    savedSpaces: {
      ...state.savedSpaces,
      status: {
        ...state.savedSpaces.status,
        creating: false
      },
      error: {
        ...state.savedSpaces.error,
        creating: true
      }
    }
  }),
  [LEAD_SPACES_UPDATE_REQUEST]: state => ({
    ...state,
    savedSpaces: {
      ...state.savedSpaces,
      status: {
        ...state.savedSpaces.status,
        updating: true
      },
      error: {
        ...state.savedSpaces.error,
        updating: false
      }
    }
  }),
  [LEAD_SPACES_UPDATE_SUCCESS]: (state, payload) => ({
    ...state,
    savedSpaces: {
      ...state.savedSpaces,
      status: {
        ...state.savedSpaces.status,
        updating: false
      },
      error: {
        ...state.savedSpaces.error,
        updating: false
      },
      list: state.savedSpaces.list.map(item => item.id === payload.id ? payload : item)
    }
  }),
  [LEAD_SPACES_UPDATE_FAILURE]: state => ({
    ...state,
    savedSpaces: {
      ...state.savedSpaces,
      status: {
        ...state.savedSpaces.status,
        updating: false
      },
      error: {
        ...state.savedSpaces.error,
        updating: true
      }
    }
  }),
  [LEAD_ACCOUNT_GET_REQUEST]: state => ({
    ...state,
    status: {
      ...state.status,
      loading: true
    },
    statusError: {
      ...state.statusError,
      loading: false
    }
  }),
  [LEAD_ACCOUNT_GET_SUCCESS]: (state, payload) => ({
    ...state,
    firstName: payload.first_name,
    lastName: payload.last_name,
    email: payload.email,
    seats: payload.seats,
    phoneNumber: payload.phone_number,
    company: payload.company,
    jobTitle: payload.job_title,
    marketId: payload.market_id,
    industry: payload.industry,
    status: {
      ...state.status,
      loading: false
    },
    statusError: {
      ...state.statusError,
      loading: false
    }
  }),
  [LEAD_ACCOUNT_GET_FAILURE]: state => ({
    ...state,
    status: {
      ...state.status,
      loading: false
    },
    statusError: {
      ...state.statusError,
      loading: true
    }
  }),
  [LEAD_ACCOUNT_UPDATE_REQUEST]: state => ({
    ...state,
    status: {
      ...state.status,
      updating: true
    },
    statusError: {
      ...state.statusError,
      updating: false
    }
  }),
  [LEAD_ACCOUNT_UPDATE_SUCCESS]: (state, payload) => ({
    ...state,
    firstName: payload.first_name,
    lastName: payload.last_name,
    email: payload.email,
    seats: payload.seats,
    phoneNumber: payload.phone_number,
    company: payload.company,
    jobTitle: payload.job_title,
    marketId: payload.market_id,
    industry: payload.industry,
    status: {
      ...state.status,
      updating: false
    },
    statusError: {
      ...state.statusError,
      updating: false
    }
  }),
  [LEAD_ACCOUNT_UPDATE_FAILURE]: state => ({
    ...state,
    status: {
      ...state.status,
      updating: false
    },
    statusError: {
      ...state.statusError,
      updating: true
    }
  }),
  [LEAD_PROPOSAL_GET_REQUEST]: state => ({
    ...state,
    proposal: {
      ...state.proposal,
      status: {
        ...state.proposal.status,
        loading: true,
        completed: false
      },
      error: {
        ...state.proposal.error,
        loading: false
      }
    }
  }),
  [LEAD_PROPOSAL_GET_SUCCESS]: (state, payload) => ({
    ...state,
    proposal: {
      ...state.proposal,
      status: {
        ...state.proposal.status,
        loading: false,
        completed: true
      },
      error: {
        ...state.proposal.error,
        loading: false
      },
      list: payload
    }
  }),
  [LEAD_PROPOSAL_GET_FAILURE]: state => ({
    ...state,
    proposal: {
      ...state.proposal,
      status: {
        ...state.proposal.status,
        loading: false,
        completed: false
      },
      error: {
        ...state.proposal.error,
        loading: true
      }
    }
  }),
  LEAD_REG_2ND_FORM: state => ({
    ...state,
    reg2ndForm: true
  })
})
