import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';

import BottomNavbar from '../BottomNav';
import SingleCtaMobile from '../SingleCtaMobile';

import SingleCTA from './single-cta';
import Slider from './slider';
import Helmet from './helmet';
import VisialSliders from './visual-sliders';
import SpacesTable from './spaces-table';
import SpacesTableMobile from './spaces-table-mobile';

import Flex from '../../components/flex';
import Text from '../../components/text-box';
import RelatedLocations from '../../components/related-listings';
import { Container, Cols, Col, Section } from '../../components/wrapper';
import Heading from '../../components/heading';
import Meta from '../../components/meta';
import Verified from '../../components/verified';
import ButtonMeta from '../../components/button-meta';
import Button from '../../components/button';
import ButtonGroupMeta from '../../components/button-group-meta';
import { ExpandableAmenitiesList, TwoColumnsAmenityList } from '../../components/amenities-list';
import Tooltip from '../../components/tooltip';
import { PlusAlt, ShareAlt, Multiple } from '../../components/icons';
import BestOf from '../../components/best-of';
import ConciergeReview from '../../components/concierge-review';
import { Mobile, Default } from '../../components/responsive-helpers';
import ExpandableMap from '../../components/expandable-map';
import Promo from '../../components/promo';
import Counter from '../../components/notifications-counter';
import PromoSingle from '../../components/promo-single';
import HealthyFlag from '../../components/healthy-space-flag';

import { listingBySlugGet } from '../../actions/listings';
import { getFilterQueryLink } from '../../actions/filter-query';
import { setDistanceUnits } from '../../actions/location';
import { neighborhoodGet } from '../../actions/neighborhood';
import { spacesByLocationIdGet } from '../../actions/spaces';
import {
  hideModal,
  openShareModal,
  openRegisterModal,
  openVerifyPassModal
} from '../../actions/modal';
import { conciergeReviewsGet, bindMapToState } from '../../actions/reviews';
import toggleFavorites from '../../actions/favorites';
import marketsGet from '../../actions/markets';
import { mobileFixedHeaderOn, mobileFixedHeaderOff, spaceTypeReset } from '../../actions/ui';
import { checkPermissions } from '../../actions/user-permissions';

import { imageUrl } from '../../utils/crm-links';
import { formatMeta, formatPriceRange } from '../../utils/format';
import { scrollTop, bodyClassAdd, bodyClassRemove } from '../../utils/ui';
import { googleAnalytics } from '../../utils/analytics';

import { selectSingleListing } from '../../selectors/listings';

import { ROOT, COMPARE } from '../../settings/routes';

import { verifiedText, verifiedNewText, healthySpaceText } from '../../settings/text';

import Map from '../SingleMap';
import WorkplaceSpacesTable from '../SingleWorkplace/workplace-spaces-table';
import LocClosedHero from '../../components/loc-closed-hero'
import { BreadcrumbsLocation } from '../../components/breadcrumbs'
import { profile } from '../../settings/routes'

const hideLoginModal = () => dispatch => dispatch(hideModal());

const loadData = (props) => {
  const { slug, marketSlug } = props.match.params;

  const { listing: { neighborhoodId, id } } = props;
  props.marketsGet();

  props.listingBySlugGet(slug, marketSlug)
    .then((response) => {
      const [listing] = response ? response.list : [];

      if (neighborhoodId) {
        props.neighborhoodGet(neighborhoodId);
      }

      if (listing && listing.neighborhoodId) {
        props.neighborhoodGet(listing.neighborhoodId);
      }

      if (listing && listing.id) {
        props.spacesByLocationIdGet(listing.id);
        props.conciergeReviewsGet(listing.id);
      }

      if (id) {
        props.spacesByLocationIdGet(id);
        props.conciergeReviewsGet(id);
      }
    });
};

class SingleListing extends Component {
  componentDidMount() {
    loadData(this.props);

    if (this.props.listing.name) {
      const { listing: { marketName, name }, location: { pathname } } = this.props;
      googleAnalytics(pathname, `${name}, ${marketName} | Upsuite`);
    }

    scrollTop();

    const mq = window.matchMedia('(min-width: 640px)');
    mq.addListener(e => e.matches && this.props.hideModal());

    this.props.mobileFixedHeaderOff();
    bodyClassAdd('page-single-location');

    if (this.props.listing && this.props.listing.id && this.props.isUserSignedIn) {
      this.props.checkPermissions(this.props.token, this.props.listing.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      loadData(this.props);

      scrollTop();
      this.props.spaceTypeReset();
    }

    if (!isEmpty(this.props.listing) && prevProps.listing.name !== this.props.listing.name) {
      const { listing: { marketName, name }, location: { pathname } } = this.props;
      googleAnalytics(pathname, `${name}, ${marketName} | Upsuite`);
    }

    if ((!isEqual(prevProps.listing.id, this.props.listing.id) && this.props.isUserSignedIn) ||
      prevProps.isUserSignedIn !== this.props.isUserSignedIn) {
      this.props.checkPermissions(this.props.token, this.props.listing.id);
    }

    if (prevProps.singleError !== this.props.singleError && this.props.singleError) {
      this.props.push(`/${ROOT}/`)
    }
  }

  componentWillUnmount() {
    this.props.hideLoginModal();
    this.props.mobileFixedHeaderOn();
    bodyClassRemove('page-single-location');
    this.props.spaceTypeReset();
  }

  render() {
    const {
      listing: {
        name,
        description,
        capacityMin,
        capacityMax,
        neighborhoodName,
        latitude,
        longitude,
        upsuiteVerified,
        marketName,
        marketSlug,
        listingCardImageId,
        id,
        environmentDimBright,
        environmentLuxuryBudget,
        environmentPrivateOpen,
        environmentProfessionalCasual,
        environmentQuietEnergetic,
        accolades,
        isHealthy,
        metaDescription,
        essenceStatementNoBranding,
        modifiedAt,
        placeID,
        newLocation,
        promoText,
      },
      neighborhood: {
        neighborhoodDescription,
      },
      listing,
      spaces,
      listingLoading,
      filterQuery,
      isFavorite,
      token,
      conciergeReviews,
      conciergeImage,
      isUserSignedIn,
      userAllowedEditSpaces,
      userIsAuthenticated,
      market,
      isCrawler,
      visitorId,
      isCognitoAuth
    } = this.props;

    const availableAmenities = reduce(listing, (result, value, key) => {
      if (/amenity/.test(key) && value) {
        result.push(key.slice(7));
      }

      return result;
    }, []);

    const metaPrefix = formatMeta(capacityMin, capacityMax, neighborhoodName);
    const priceRange = formatPriceRange(spaces);
    const listingMeta = metaPrefix && priceRange ?
      <>{metaPrefix} • {priceRange}</> :
      metaPrefix;
    const sliderHeight = listingLoading ? 450 : null;
    const searchFilterQueryUrl = getFilterQueryLink(filterQuery);
    const isEnvSection = environmentQuietEnergetic ||
      environmentPrivateOpen ||
      environmentLuxuryBudget ||
      environmentProfessionalCasual ||
      environmentDimBright;

    const isClosed = listing.isOpen === false && listing?.healthStatus === 'Closed'

    return (
      <Flex column shrink>
        {marketName && name && (
          <Helmet
            marketName={marketName}
            market={market}
            marketSlug={marketSlug}
            name={name}
            metaDescription={metaDescription}
            listing={listing}
            listingCardImageId={listingCardImageId}
            imageUrl={imageUrl}
            modifiedAt={modifiedAt}
            spaces={spaces}
          />
        )}
        {upsuiteVerified && !userIsAuthenticated && !isClosed && !isCognitoAuth && (
          <Mobile>
            <Promo
              spaceBelow="none"
              size="small"
              sticky
              footnote="*offer valid at Upsuite Verified Locations only."
            >
              Register your email with Upsuite{' '}
              <button
                type="button"
                onClick={() =>
                  this.props.openRegisterModal({
                    closeOnSubmit: true,
                    initialValues: {
                      locations: [listing.id],
                      isWorkspace: [false],
                    },
                    onSubmit: () => {},
                    form: 'mobile-ribbon-registration',
                    visitorId,
                    isWorkspace: false,
                  })
                }
              >
                here
              </button>{' '}
              to save 5% on your first 12 months.*
            </Promo>
          </Mobile>
        )}
        <Container topShift={false} fullWidth>
          <Cols reverseMobile>
            <Col sixten fluid fullWidthMobile>
              {!isClosed && id && (
                <div
                  style={{
                    height: sliderHeight,
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  {(upsuiteVerified || isHealthy) && (
                    <div className="TooltipSingleSlider">
                      {upsuiteVerified && (
                        <Tooltip
                          placement="bottomLeft"
                          overlay={newLocation ? verifiedNewText : verifiedText}
                        >
                          <div>
                            <Verified newLocation={newLocation} />
                          </div>
                        </Tooltip>
                      )}
                      {isHealthy && (
                        <Tooltip
                          placement="bottomLeft"
                          overlay={healthySpaceText}
                        >
                          <div>
                            <HealthyFlag upsuiteVerified={upsuiteVerified} />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  )}
                  <Default>
                    <ButtonGroupMeta position="topright" gutter="small">
                      <ButtonMeta
                        svgIcon={<ShareAlt />}
                        onClick={() =>
                          this.props.openShareModal({
                            params: `${this.props.match.params.marketSlug}/${this.props.match.params.slug}`,
                            isWorkspace: false,
                          })
                        }
                        className="share-button"
                      >
                        Share
                      </ButtonMeta>
                      <ButtonMeta
                        isActive={isFavorite}
                        onClick={() => this.props.toggleFavorites(id)}
                        className="compare-button"
                      >
                        + Save
                      </ButtonMeta>
                      {!isEmpty(this.props.favorites) && (
                        <Counter
                          count={this.props.favorites.length}
                          type="listing"
                        >
                          {isCognitoAuth || isCrawler ? (
                            <Link to={`/${profile.main}/${profile.spaces}/`}>View Saved Spaces</Link>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                if (userIsAuthenticated && !isCognitoAuth) {
                                  this.props.openVerifyPassModal()
                                } else {
                                  this.props.openRegisterModal({
                                    heading: 'To see and save your shortlist',
                                    closeOnSubmit: true,
                                    onSubmit: () =>
                                      this.props.push(`/${ROOT}/${COMPARE}/`),
                                    form: 'shortlist-button-registration',
                                    visitorId,
                                    isWorkspace: false,
                                    initialValues: {
                                      locations: this.props.favorites,
                                      isWorkspace: (
                                        this.props.favorites || []
                                      ).map(() => false)
                                    }
                                  })
                                }
                              }}
                            >
                              View Saved Spaces
                            </button>
                          )}
                        </Counter>
                      )}
                    </ButtonGroupMeta>
                  </Default>
                  <Mobile>
                    <ButtonGroupMeta
                      column={false}
                      position="topright"
                      gutter="medium"
                    >
                      <Button
                        onClick={() =>
                          this.props.openShareModal({
                            params: `${this.props.match.params.marketName}/${this.props.match.params.slug}`,
                            isWorkspace: false,
                          })
                        }
                        className="share-button"
                        scheme="icons"
                      >
                        <ShareAlt className="export-button-icon" />
                      </Button>
                      <Button
                        onClick={() => this.props.toggleFavorites(id)}
                        className="compare-button"
                        scheme="icons"
                      >
                        <PlusAlt className="export-button-icon" />
                      </Button>
                      {!isEmpty(this.props.favorites) && (
                        <Counter
                          count={this.props.favorites.length}
                          type="icon"
                        >
                          {isCognitoAuth || isCrawler ? (
                            <Link to={`/${profile.main}/${profile.spaces}/`}><Multiple /></Link>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                if (userIsAuthenticated && !isCognitoAuth) {
                                  this.props.openVerifyPassModal()
                                } else {
                                  this.props.openRegisterModal({
                                    heading: 'To see and save your shortlist',
                                    closeOnSubmit: true,
                                    onSubmit: () =>
                                      this.props.push(`/${ROOT}/${COMPARE}/`),
                                    form: 'shortlist-button-registration',
                                    visitorId,
                                    isWorkspace: false,
                                    initialValues: {
                                      locations: this.props.favorites,
                                      isWorkspace: (
                                        this.props.favorites || []
                                      ).map(() => false)
                                    }
                                  })
                                }
                              }}
                            >
                              <Multiple />
                            </button>
                          )}
                        </Counter>
                      )}
                    </ButtonGroupMeta>
                  </Mobile>
                  <Slider
                    listing={listing}
                    conciergeImage={conciergeImage}
                    market={market}
                    visitorId
                  />
                  {!isEmpty(accolades) && upsuiteVerified && (
                    <BestOf
                      features={accolades}
                      location={marketName}
                      tooltip="Users have ranked this location as best in class for specific amenity, community, and environment attributes"
                    />
                  )}
                </div>
              )}
              {isClosed && name && <LocClosedHero name={name} />}
            </Col>
            <Col fourten fluid fullWidthMobile>
              {latitude && longitude && (
                <>
                  <Mobile>
                    <ExpandableMap>
                      <Map
                        lng={longitude}
                        lat={latitude}
                        highlight={isFavorite}
                        bindMapToState={this.props.bindMapToState}
                        key={id}
                        expandable
                        placeID={placeID}
                      />
                    </ExpandableMap>
                  </Mobile>
                  <Default>
                    <Map
                      lng={longitude}
                      lat={latitude}
                      highlight={isFavorite}
                      bindMapToState={this.props.bindMapToState}
                      placeID={placeID}
                    />
                  </Default>
                </>
              )}
            </Col>
          </Cols>
        </Container>
        <Container mobileScheme="detailsContent">
          <Cols columnMobile columnTablet>
            <Col twothird column fullWidthMobile fullWidthTablet>
              <Default>
                <Col alignStart fluid>
                  {listing.id && (
                    <BreadcrumbsLocation
                      marketName={marketName}
                      to={
                        !isEmpty(searchFilterQueryUrl)
                          ? searchFilterQueryUrl
                          : `/${ROOT}/${marketSlug}/`
                      }
                      name={name}
                    />
                  )}
                </Col>
              </Default>
              {promoText && !isClosed && <PromoSingle heading={promoText} />}
              {!isClosed && (
                <>
                  <Heading
                    level="1"
                    size="medium"
                    spaceAbove="small"
                    center={false}
                    mobileScheme="detailsMain"
                  >
                    {listing.h1 || name}
                  </Heading>
                  <Meta shift>{essenceStatementNoBranding || listingMeta}</Meta>
                  {!isEmpty(conciergeReviews) && upsuiteVerified && (
                    <Section spaceBelow="large">
                      {conciergeReviews.map(review => (
                        <ConciergeReview
                          key={review.id}
                          description={review.description}
                        />
                      ))}
                    </Section>
                  )}
                </>
              )}
              {isClosed && (
                <>
                  <RelatedLocations
                    slug={this.props.match.params.slug}
                    market={market}
                    marketSlug={marketSlug}
                    isWorkspace={false}
                    heading={`If you Like ${name} You Might Also Like`}
                  />
                  <Section spaceBelow="medium" spaceAbove="small">
                    <Heading
                      level="2"
                      size="small"
                      center={false}
                      withBar
                      mobileScheme="detailsSecondary"
                    >
                      About Upsuite
                    </Heading>
                    <Text size="medium" spaceBelow="small">
                      Upsuite helps companies, teams, and individuals engage in
                      person, anywhere. Upsuite&apos;s 50,000+ private office
                      suites and meeting spaces minimize commute times, maximize
                      in-person collaboration and culture, saves 10 to 50% of
                      rent, all while minimizing the complexity.
                    </Text>
                  </Section>
                </>
              )}
              {(spaces || []).filter(item => !item?.isWorkplace).length > 0 &&
                upsuiteVerified && (
                  <Section spaceBelow="large">
                    <Heading
                      level="2"
                      size="small"
                      center={false}
                      withBar
                      mobileScheme="detailsSecondary"
                    >
                      {`${!isClosed ? 'Available ' : ''}${
                        this.props.spaceTypeWorkspace
                          ? 'Private Workspaces in'
                          : 'Spaces at'
                      } ${name}`}
                    </Heading>

                    {!this.props.spaceTypeWorkspace ? (
                      <>
                        <Default>
                          <SpacesTable
                            spaces={(spaces || []).filter(
                              item => !item?.isWorkplace,
                            )}
                            isUserSignedIn={isUserSignedIn}
                            userAllowedEditSpaces={userAllowedEditSpaces}
                            id={id}
                            token={token}
                            name={name}
                            marketName={marketName}
                            market={market}
                            filterQuery={filterQuery}
                            userIsAuthenticated={userIsAuthenticated}
                            isCrawler={isCrawler}
                            visitorId={visitorId}
                            isClosed={isClosed}
                            isCognitoAuth={isCognitoAuth}
                            showSpaceTypeToggle={
                              (spaces || []).filter(item => item?.isWorkplace)
                                .length > 0 &&
                              (this.props.listing?.officeType || []).includes(
                                'Workplace',
                              )
                            }
                          />
                        </Default>
                        <Mobile>
                          <SpacesTableMobile
                            spaces={(spaces || []).filter(
                              item => !item?.isWorkplace,
                            )}
                            isClosed={isClosed}
                            token={token}
                            id={id}
                            name={name}
                            marketName={marketName}
                            isUserSignedIn={isUserSignedIn}
                            userAllowedEditSpaces={userAllowedEditSpaces}
                            filterQuery={filterQuery}
                            userIsAuthenticated={userIsAuthenticated}
                            isCrawler={isCrawler}
                            market={market}
                            visitorId={visitorId}
                            isCognitoAuth={isCognitoAuth}
                            showSpaceTypeToggle={
                              (spaces || []).filter(item => item?.isWorkplace)
                                .length > 0 &&
                              (this.props.listing?.officeType || []).includes(
                                'Workplace',
                              )
                            }
                          />
                        </Mobile>
                      </>
                    ) : (
                      <WorkplaceSpacesTable
                        spaces={(spaces || []).filter(
                          item => item?.isWorkplace,
                        )}
                        showSpaceTypeToggle={
                          (spaces || []).filter(item => item?.isWorkplace)
                            .length > 0 &&
                          (this.props.listing?.officeType || []).includes(
                            'Workplace',
                          )
                        }
                      />
                    )}
                  </Section>
                )}
              {!isEmpty(availableAmenities) && (
                <Section spaceBelow="large">
                  <Heading
                    level="2"
                    size="small"
                    center={false}
                    withBar
                    mobileScheme="detailsSecondary"
                  >
                    {`Amenities at ${name}`}
                  </Heading>
                  <Default>
                    <TwoColumnsAmenityList
                      availableAmenities={availableAmenities}
                      cleaningPoliciesPDFLink={
                        listing.cleaningPoliciesPdfLinkId
                      }
                      locationId={id}
                    />
                  </Default>
                  <Mobile>
                    <ExpandableAmenitiesList
                      availableAmenities={availableAmenities}
                      cleaningPoliciesPDFLink={
                        listing.cleaningPoliciesPdfLinkId
                      }
                      locationId={id}
                    />
                  </Mobile>
                </Section>
              )}
              {isEnvSection && upsuiteVerified && (
                <VisialSliders
                  name={name}
                  environmentQuietEnergetic={environmentQuietEnergetic}
                  environmentPrivateOpen={environmentPrivateOpen}
                  environmentLuxuryBudget={environmentLuxuryBudget}
                  environmentProfessionalCasual={environmentProfessionalCasual}
                  environmentDimBright={environmentDimBright}
                />
              )}
              {description && (
                <>
                  <Heading
                    level="2"
                    size="small"
                    center={false}
                    withBar
                    mobileScheme="detailsSecondary"
                  >
                    Operator Description
                  </Heading>
                  <p
                    className="WysiwygText"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                </>
              )}
              {neighborhoodDescription && (
                <>
                  <Heading
                    level="2"
                    size="small"
                    center={false}
                    withBar
                    mobileScheme="detailsSecondary"
                  >
                    Neighborhood
                  </Heading>
                  <Text size="medium">{neighborhoodDescription}</Text>
                </>
              )}
              {!isClosed && (
                <RelatedLocations
                  slug={this.props.match.params.slug}
                  market={market}
                  isWorkspace={false}
                  marketSlug={marketSlug}
                />
              )}
            </Col>

            <Default>
              <Col onethird fullWidthMobile fullWidthTablet alignStart>
                <SingleCTA
                  brochure={listing.image5Desc}
                  verified={upsuiteVerified}
                  visitorId={visitorId}
                  listingId={listing.id}
                  listingName={name}
                  market={market}
                  marketName={marketName}
                  isClosed={isClosed}
                  searchFilterQueryUrl={searchFilterQueryUrl}
                  marketSlug={marketSlug}
                />
              </Col>
            </Default>
            <Mobile>
              <SingleCtaMobile
                verified={upsuiteVerified}
                listingName={name}
                marketName={marketName}
                visitorId={visitorId}
                listingId={listing.id}
                isClosed={isClosed}
                searchFilterQueryUrl={searchFilterQueryUrl}
                marketSlug={marketSlug}
              />
            </Mobile>
          </Cols>
        </Container>
        {id && <BottomNavbar />}
      </Flex>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { singleLoading: listingLoading, singleError } = state.listings;
  const {
    singleLoading: hoodLoading,
    byId,
  } = state.neighborhood;
  const { byLocationId } = state.spaces;
  const listing = selectSingleListing(state, ownProps);
  const { neighborhoodId, id } = listing;
  const spaces = byLocationId[id];
  const neighborhood = neighborhoodId && !listingLoading && !hoodLoading && byId[neighborhoodId]
    ? byId[neighborhoodId]
    : {};
  const isFavorite = state.favorites.ids && state.favorites.ids.includes(id);
  const conciergeReviews = state.reviews.conciergeReviewsById[id];
  const market = state.markets.byName[listing.marketName]
    ? state.markets.byName[listing.marketName]
    : null;
  const conciergeImage = market ? market.marketRepPhotoId : null;
  const userAllowedEditSpaces = state.userPermissions.allowedLocations.includes(listing.id)
    || state.userPermissions.operatorLocations.includes(listing.id);
  const { auth: { userIsAuthenticated, isCrawler, visitorId } } = state;

  return {
    listingLoading,
    singleError,
    listing,
    neighborhood,
    spaces,
    filterQuery: state.filterQuery,
    isFavorite,
    conciergeReviews,
    map: state.reviews.map,
    conciergeImage,
    isUserSignedIn: state.auth.isUserSignedIn,
    token: state.auth.token,
    userAllowedEditSpaces,
    favorites: state.favorites.ids,
    distanceUnits: state.location.distanceUnits,
    userIsAuthenticated,
    market,
    isCrawler,
    visitorId,
    spaceTypeWorkspace: state.ui.spaceTypeWorkspace,
    isCognitoAuth: state.cognito.token,
  };
};

export default connect(
  mapStateToProps,
  {
    listingBySlugGet,
    neighborhoodGet,
    spacesByLocationIdGet,
    openShareModal,
    hideLoginModal,
    bindMapToState,
    conciergeReviewsGet,
    toggleFavorites,
    push,
    marketsGet,
    hideModal,
    mobileFixedHeaderOn,
    mobileFixedHeaderOff,
    checkPermissions,
    setDistanceUnits,
    openRegisterModal,
    openVerifyPassModal,
    spaceTypeReset
  },
)(SingleListing);
