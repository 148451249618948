import { createReducer } from '../utils';
import {
  GET_LOC_BY_MARKET_REQUEST,
  GET_LOC_BY_MARKET_SUCCESS,
  GET_LOC_BY_MARKET_FAILURE,
  GET_WORKSPACES_BY_MARKET_SUCCESS,
  // GET_FORECAST_MARKET_REQUEST,
  GET_FORECAST_MARKET_SUCCESS,
  // GET_FORECAST_MARKET_FAILURE,
  // GET_PRICE_MARKET_REQUEST,
  GET_PRICE_MARKET_SUCCESS,
  // GET_PRICE_MARKET_FAILURE,
} from '../constants';

const initialState = {
  loading: false,
  failure: false,
  locByMarketSlug: {},
  workspacesByMarketSlug: {},
  forecastBySlug: {},
  priceBySlug: {},
};

export default createReducer(initialState, {
  [GET_LOC_BY_MARKET_REQUEST]: state => ({
    ...state,
    loading: true,
  }),
  [GET_LOC_BY_MARKET_SUCCESS]: (state, payload, config) => ({
    ...state,
    loading: false,
    failure: false,
    locByMarketSlug: {
      ...state.locByMarketSlug,
      [config]: payload,
    }
  }),
  [GET_WORKSPACES_BY_MARKET_SUCCESS]: (state, payload, config) => ({
    ...state,
    loading: false,
    failure: false,
    workspacesByMarketSlug: {
      ...state.workspacesByMarketSlug,
      [config]: payload,
    }
  }),
  [GET_LOC_BY_MARKET_FAILURE]: state => ({
    ...state,
    loading: false,
    failure: true,
  }),
  [GET_FORECAST_MARKET_SUCCESS]: (state, payload, config) => ({
    ...state,
    forecastBySlug: {
      ...state.forecastBySlug,
      [config]: payload
    }
  }),
  [GET_PRICE_MARKET_SUCCESS]: (state, payload, config) => ({
    ...state,
    priceBySlug: {
      ...state.priceBySlug,
      [config]: payload
    }
  }),
});
