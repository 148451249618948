import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import { createSelector } from 'reselect';
import { selectCompareIds, selectCompareWordspaceIds } from './listings/index';

export const byLocationId = state => state.spaces.byLocationId;

const byId = state => state.spaces.byId;

const getProposals = state => state.lead.proposal.list;

const spaceByListingId = state => state.ui.spaceByListingId;

export const selectByLocationId = createSelector(
  byLocationId,
  spaces => spaces,
);

export const selectByProposal = createSelector(
  byId,
  getProposals,
  (bySpaceId, proposals) => {
    return proposals.reduce((acc, proposal) => {
      return {
        ...acc,
        [proposal.LocationId]: proposal.SpaceIds.map(id => bySpaceId[id])
      }
    }, {})
  }
);

export const selectChosenSpaceIds = createSelector(
  selectCompareIds,
  selectByLocationId,
  spaceByListingId,
  (compareIds, spaces, chosenSpaces) => {
    if (isEmpty(spaces)) {
      return null;
    }

    return compareIds.filter(compareId => !!spaces[compareId])
      .map((compareId) => {
        // compare/report API requires space ID,
        // if nothing chosen get first space from location spaces array
        const chosenSpaceIndex = chosenSpaces[compareId] || 0;


        return (spaces[compareId][chosenSpaceIndex] || {}).id;
      });
  },
);

const selectWorkspaceSpaces = locations => {
  return mapValues(locations, (spaces) => (spaces || []).filter(item => item.isWorkplace))
};

export const selectWorkspacesByLocationId = createSelector(
  byLocationId,
  selectWorkspaceSpaces,
);

export const selectWorkspacesChosenSpaceIds = createSelector(
  selectCompareWordspaceIds,
  selectWorkspacesByLocationId,
  spaceByListingId,
  (compareIds, spaces, chosenSpaces) => {
    if (isEmpty(spaces)) {
      return null;
    }

    return compareIds.filter(compareId => !!spaces[compareId])
      .map((compareId) => {
        // compare/report API requires space ID,
        // if nothing chosen get first space from location spaces array
        const chosenSpaceIndex = chosenSpaces[compareId] || 0;


        return (spaces[compareId][chosenSpaceIndex] || {}).id;
      });
  },
);
