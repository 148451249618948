import fetch from 'isomorphic-fetch';
import {
  LISTINGS_API_URL as url,
  LISTINGS_API_BASE as base,
  LISTINGS_API_KEY as apiKey,
} from '../settings';

export const getLocBestOf = (marketSlug, isWorkspace) =>
  fetch(
    `${url}${base}/location/bymarketslug?is_best_of=true&slug=${marketSlug}${isWorkspace ? '&is_workplace=true' : ''}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    },
  );
