import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import MainPageList from '../MainPageList';
import MarketList from '../MainPageList/MarketList';
import Map from './map';
import WorkplacesMap from './workplaces-map';
import BottomNavbar from '../BottomNav';

import Flex from '../../components/flex';
import Heading from '../../components/heading';
import { googleAnalytics } from '../../utils/analytics';
import { Default, Mobile } from '../../components/responsive-helpers';
import MainLocSelect from '../../components/select-main-loc';

import { ROOT, WORKPLACE } from '../../settings/routes';
import { BASE_URL } from '../../settings';
import { options } from '../../settings/main-select-options';

import Hero from './hero'
import { AboutRow, ReviewsRow, FaqRow } from './text-sections'
import BlogPosts from '../../components/blog-posts'
import { posts } from './posts'

const Main = props => {
  const {
    location: { pathname },
    match: { path },
  } = props;
  const dispatch = useDispatch();
  const isWorkplace = path === `/${WORKPLACE}`;

  useEffect(() => {
    googleAnalytics(pathname, 'Upsuite | All Markets');
  }, [pathname]);

  return (
    <Flex column shrink fullHeight stickyFooter>
      <Helmet>
        <title>All Markets</title>
        <link
          rel="canonical"
          href={`${BASE_URL}/${isWorkplace ? WORKPLACE : ROOT}/`}
        />
      </Helmet>

      {!isWorkplace && (
        <>
          <Hero />
          <MarketList title="Popular Markets for Coworking Space" />
        </>
      )}

      {isWorkplace && (
        <div style={{ paddingLeft: 15, marginTop: 20 }}>
          <Heading level="1" center={false} spaceBelow="small" size="xsmall">
            All Markets
          </Heading>
        </div>
      )}
      {!isWorkplace && (
        <Heading level="2" center spaceBelow="small" size="xsmall" mobileScheme="landing" style={{ color: 'black' }}>
          Find Coworking Space In 40+ Major Markets and 2,400 Cities
        </Heading>
      )}
      <MainLocSelect
        value={path}
        options={
          isWorkplace
            ? [{ label: 'All Locations', value: `/${WORKPLACE}` }]
            : options
        }
        onChange={({ value }) => dispatch(push(value))}
      />
      <Default>
        {isWorkplace && <WorkplacesMap style={{ height: 450 }} zoom={4} />}
        {!isWorkplace && (
          <MapRow>
            <Map style={{ width: '100%', height: 700 }} zoom={4} />
          </MapRow>
        )}
      </Default>
      <Mobile>
        {isWorkplace && <WorkplacesMap style={{ height: 250 }} zoom={3} />}
        {!isWorkplace && (
          <MapRow>
            <Map style={{ width: '100%', height: 250 }} zoom={3} />
          </MapRow>
        )}
      </Mobile>
      {isWorkplace && <MainPageList isWorkplace={isWorkplace} />}
      {!isWorkplace && (
        <AboutRow />
      )}
      {!isWorkplace && (
        <>
          <ReviewsRow />
          <FaqRow />
          <BlogPosts posts={posts} sectionTitle="Upblog: Coworking, Flexible Offices, and the Future of Work" />
        </>
      )}
      {!isWorkplace && <BottomNavbar />}
    </Flex>
  );
};

const MapRow = styled.div`
  display: flex;
  flex-direction: column;

  iframe {
    width: 100%;
    flex: 0 0 100%;
    height: 700px;
  }

  @media screen and (min-width: 40em) {
    flex-direction: row;

    iframe {
      width: 360px;
      flex: 0 0 360px;
      margin-left: 20px;
      height: auto;
    }
  }
`

export default Main;
