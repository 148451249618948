import React from 'react';
import styled, { css } from 'styled-components';
import * as colors from '../../styles/theme/colors';
import * as breakpoints from '../../styles/theme/breakpoints';

import { IconHealthyHeart } from '../icons';

const Wrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: ${(props) => !props.upsuiteVerified ? '30px' : '15px'} ;
  width: auto;
  z-index: 1;

  @media (min-width: 1170px) {
    margin-left: 15px;
  }

  @media ${breakpoints.smOnly} {
    margin-top: ${(props) => !props.upsuiteVerified ? '15px' : '8px'} ;
    font-size: 13px;
  }

  ${({ tiny }) => tiny && css`
    margin: 0 !important;
    font-size: 9px !important;
  `}

  ${({ small }) => small && css`
    margin: 0 !important;
    font-size: 11px !important;

    @media ${breakpoints.smOnly} {
      font-size: 14px !important;
    }
  `}
`;

const Label = styled.div`
  position: relative;
  color: white;
  background-color: ${colors.brand};
  padding: 6px 10px 6px 15px;
  font-family: "Euclid Circular B", sans-serif;
  line-height: 1.15;
  margin-left: 9px;

  &::after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    border-width: 15px;
    border-style: solid;
    border-color: ${colors.brand};
    z-index: -1;
    right: -13px;
    border-left-width: 25px;
    border-right-width: 12px;
    border-right-color: transparent;
  }

  @media ${breakpoints.smOnly} {
    padding: 4px 4px 4px 10px;
    margin-left: 5px;

    &::after {
      border-width: 11px;
      border-left-width: 12px;
      border-right-width: 4px;
      right: -7px;
    }
  }

  ${({ small }) => small && css`
    margin-left: 5px;
    padding: 0.36363636em 0.36363636em 0.36363636em 0.8em !important;

    &::after {
      border-width: 0.93em !important;
      border-left-width: 1.09090909em !important;
      border-right-width: 0.36363636em !important;
      right: -0.36363636em !important;
    }
  `}

  ${({ tiny }) => tiny && css`
    margin-left: 4px;
    padding: 0.36363636em 0.36363636em 0.36363636em .8em !important;

    &::after {
      border-width: 0.90909091em !important;
      border-left-width: 1.09090909em !important;
      border-right-width: 0.36363636em !important;
      right: -0.36363636em !important;
    }
  `}
`;

const Icon = styled(IconHealthyHeart)`
  width: 40px;
  color: ${colors.brand};

  @media ${breakpoints.smOnly} {
    width: 30px;
    height: 30px;
  }

  ${({ small, tiny }) => (small || tiny) && css`
    width: 2.72727273em !important;
    height: 2.72727273em !important;
  `}
`;

export const HealthyFlagContainer = styled.div`
  position: absolute;
  top: ${(props) => !props.upsuiteVerified ? '7px' : !props.isNew ? '40px' : '50px'};
  left: 11px;
  z-index: 1;

  ${({ small }) => small && css`
    @media ${breakpoints.smOnly} {
      top: ${(props) => !props.upsuiteVerified ? '7px' : '50px'} !important;
    }
  `}

  ${({ compare }) => compare && css`
    @media ${breakpoints.smOnly} {
      display: none;
    }

    top: ${(props) => !props.upsuiteVerified ? '10px' : !props.isNew ? '42px' : '52px'} !important;
    left: 9px !important;
  `}
`;

export const HealthyFlagInfoContainer = styled.div`
  position: absolute;
  top: ${(props) => !props.upsuiteVerified ? '7px' : !props.isNew ? '32px' : '38px'};
  left: 6px;
  z-index: 1;
`;

const HealthyFlag = ({ upsuiteVerified, small, tiny }) => {

  return (
    <Wrap small={small} upsuiteVerified={upsuiteVerified} tiny={tiny}>
      <Icon small={small} tiny={tiny} />
      <Label small={small} tiny={tiny}>
        <span>Healthy Space</span>
      </Label>
    </Wrap>
  )
};

export default HealthyFlag;
