import React from 'react';

import VisualSlider from '../../components/visual-slider';
import { Section } from '../../components/wrapper';
import Heading from '../../components/heading';



export default (props) => {
  const {
    name,
    environmentQuietEnergetic,
    environmentPrivateOpen,
    environmentLuxuryBudget,
    environmentProfessionalCasual,
    environmentDimBright
  } = props;

  return (
    <Section spaceBelow="large">
      <Heading level="2" size="small" center={false} withBar mobileScheme="detailsSecondary">
        {`Work Environment at ${name}`}
      </Heading>
      {environmentQuietEnergetic &&
        <VisualSlider label={{ min: 'Quiet', max: 'Energetic' }} value={environmentQuietEnergetic} />
      }
      {environmentPrivateOpen &&
        <VisualSlider label={{ min: 'Private', max: 'Open' }} value={environmentPrivateOpen} />
      }
      {environmentLuxuryBudget &&
        <VisualSlider label={{ min: 'Luxury', max: 'Budget' }} value={environmentLuxuryBudget} />
      }
      {environmentProfessionalCasual &&
        <VisualSlider label={{ min: 'Professional', max: 'Casual' }} value={environmentProfessionalCasual} />
      }
      {environmentDimBright &&
        <VisualSlider label={{ min: 'Dim', max: 'Bright' }} value={environmentDimBright} />
      }
    </Section>
  )
}
