import { checkHttpStatus, parseJSON } from '../utils';
import {
  LISTINGS_GET_REQUEST,
  LISTINGS_GET_SUCCESS,
  LISTINGS_GET_FAILURE,
  LISTINGS_RELATED_GET_SUCCESS,
  SINGLE_LISTING_GET_REQUEST,
  SINGLE_LISTING_GET_SUCCESS,
  SINGLE_LISTING_GET_FAILURE,
  SYNC_VISIBLE_MARKERS_WITH_LISTINGS,
  LISTINGS_FILTER_BY_SPACES,
  LISTINGS_TOP_MARKET_GET_REQUEST,
  LISTINGS_TOP_MARKET_GET_SUCCESS,
  LISTINGS_TOP_MARKET_GET_FAILURE,
  SET_MAP_BOUNDS,
} from '../constants';
import * as api from '../api/listings';
import {
  getFilterString as getLocationsString,
  urlFilterQuery,
} from '../utils/filter-url';
import { getRadius } from '../utils/get-radius';
import { getSearchRadiusByBounds } from '../utils/map-helpers';

const listingsGetRequest = payload => ({
  type: LISTINGS_GET_REQUEST,
  payload,
});

const listingsGetSuccess = (payload, config) => ({
  type: LISTINGS_GET_SUCCESS,
  payload,
  config,
});

const listingsGetFailure = () => ({
  type: LISTINGS_GET_FAILURE,
});

export function listingsGet(filterString, route, paprams) {
  return (dispatch) => {
    dispatch(listingsGetRequest());

    return api.listingsGet(filterString, paprams)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => dispatch(listingsGetSuccess(response, route)))
      .catch((error) => {
        dispatch(listingsGetFailure(error));
      });
  };
}

export function listingsGetByMaket(market, route) {
  return (dispatch) => {
    dispatch(listingsGetRequest());

    return api.listingsByMarketGet(market)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => dispatch(listingsGetSuccess(response, route)))
      .catch((error) => {
        dispatch(listingsGetFailure(error));
      });
  };
}

export const listingsGetByCoords = (coords, defaultRadius = false) => (dispatch, getState) => {
  const { filterQuery, reviews: { map } } = getState();
  const params = defaultRadius
    ? { minradius: 5000, maxradius: 20000, minresults: 10 }
    : { minradius: getSearchRadiusByBounds(map.getBounds()) * 1000 };

  dispatch(listingsGetRequest());

  return api.listingsGet(getLocationsString(filterQuery, coords), params, true)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => dispatch(listingsGetSuccess(response, urlFilterQuery(filterQuery))))
    .catch((error) => {
      dispatch(listingsGetFailure(error));
    });
};

export const listingsGetByAddress = (address, defaultRadius = false) => (dispatch, getState) => {
  const { filterQuery } = getState();
  const params = defaultRadius
    ? { minradius: getRadius(address).minradius, maxradius: getRadius(address).maxradius, minresults: 10 }
    : { minradius: 500, maxradius: 4000, minresults: 8 };

  dispatch(listingsGetRequest());

  return api.listingsGet(getLocationsString(filterQuery, { address }), params, true)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => dispatch(listingsGetSuccess(response, filterQuery?.s || urlFilterQuery(filterQuery))))
    .catch((error) => {
      dispatch(listingsGetFailure(error));
    });
};

export const listingsGetRelated = (marketName, isWorkspace, slug) => (dispatch, getState) => {
  const params = {
    minradius: getRadius(marketName).minradius,
    maxradius: getRadius(marketName).maxradius,
    minresults: 10,
  };

  dispatch(listingsGetRequest());

  return api.listingsGet(getLocationsString({ is_workplace: isWorkspace }, { address: marketName }), params)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => dispatch(listingsGetSuccess(response, marketName)))
    .catch((error) => {
      dispatch(listingsGetFailure(error));
    });
};

const listingBySlugGetRequest = () => ({
  type: SINGLE_LISTING_GET_REQUEST,
});

const listingBySlugGetSuccess = payload => ({
  type: SINGLE_LISTING_GET_SUCCESS,
  payload,
});

const listingBySlugGetFailure = () => ({
  type: SINGLE_LISTING_GET_FAILURE,
});

const listingsRelatedGetSuccess = (payload, config) => ({
  type: LISTINGS_RELATED_GET_SUCCESS,
  payload,
  config,
});


export function listingBySlugGet(slug, marketSlug, isWorkplace = null) {
  return (dispatch, getState) => {
    const path = `${marketSlug}/${slug}`;
    const { isUserSignedIn, token } = getState().auth;
    const { email } = getState().lead;

    dispatch(listingBySlugGetRequest());

    return api.listingBySlugGet(slug, marketSlug, email, isUserSignedIn ? token : null, isWorkplace)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(listingBySlugGetSuccess(response));
        dispatch(listingsRelatedGetSuccess(response.list.slice(1), path));

        return response;
      })
      .catch((error) => {
        dispatch(listingBySlugGetFailure(error));
      });
  };
}

export const syncVisibleMarkersWithListings = payload => ({
  type: SYNC_VISIBLE_MARKERS_WITH_LISTINGS,
  payload,
});

export const listingsFilterBySpaces = (payload, config) => ({
  type: LISTINGS_FILTER_BY_SPACES,
  payload,
  config,
});

const listingsTopMarketGetRequest = payload => ({
  type: LISTINGS_TOP_MARKET_GET_REQUEST,
  payload,
});

const listingsTopMarketGetSuccess = (payload, config) => ({
  type: LISTINGS_TOP_MARKET_GET_SUCCESS,
  payload,
  config,
});

const listingsTopMarketGetFailure = () => ({
  type: LISTINGS_TOP_MARKET_GET_FAILURE,
});

export function listingsTopMarketGet(market) {
  return (dispatch, getState) => {
    if (getState().listings.topByMarket[market]) {
      return Promise.resolve(getState().listings.topByMarket[market]);
    }

    dispatch(listingsTopMarketGetRequest());

    return api.listingsTopMarketGet(market, 8)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(listingsTopMarketGetSuccess(response, market));

        return response.list.map(l => l.id);
      })
      .catch((error) => {
        dispatch(listingsTopMarketGetFailure(error));
      });
  };
}

export const setMapBounds = payload => ({
  type: SET_MAP_BOUNDS,
  payload,
});
