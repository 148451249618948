/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';

import ModalWrapper from '../../components/modal';
import { hideModal } from '../../actions/modal';

import Share from './share';
import SpacesEdit from './spaces-edit';
import SpacesDelete from './space-delete';
import CompareTourRequest from './compareTourRequest';
import CtaActions from './requestMoreInfoForm';
import WorkHereModal from './workHereModal';
import ExportList from './export-list';
import EmailRequest from './emailRequest';
import HelpFind from './help-find';
import RegisterLead from './register-lead';
import RegisterUser from './register-lead/register-user-2';
import AdvisorBookMeeting from './advisor-book-meeting';
import FindSpace from './find-space';
import LeadRegister from './lead-register';
import InteractiveTour from './interactive-tour';
import SaveShortlist from './save-shortlist';
import Iframe from './iframe';
import Slider from './slider';
import ClosestLocationsModal from './closest-locations';
import VerifyPassword from './verify-password';

const MODAL_COMPONENTS = {
  SHARE: Share,
  SPACES_EDIT_FORM: SpacesEdit,
  SPACES_DELETE_MODAL: SpacesDelete,
  COMPARE_TOUR_REQUEST: CompareTourRequest,
  CTA_ACTIONS: CtaActions,
  WORK_HERE: WorkHereModal,
  EXPORT_LIST: ExportList,
  EMAIL_REQUEST: EmailRequest,
  REGISTER_MODAL: RegisterLead,
  SAVE_SHORTLIST: SaveShortlist,
  REGISTER_USER: RegisterUser,
  HELP_FIND: HelpFind,
  ADVISOR_BOOK_MEETING: AdvisorBookMeeting,
  FIND_SPACE: FindSpace,
  LEAD_REGISTER: LeadRegister,
  INTERACTIVE_TOUR: InteractiveTour,
  IFRAME: Iframe,
  SLIDER: Slider,
  CLOSEST_LOCATIONS: ClosestLocationsModal,
  VERIFY_PASS: VerifyPassword
};

const ModalRoot = props => (
  <>
    {props.modals.map((item, index) => {
      const { modalType, modalProps, modalOptions } = item;
      if (!modalType) {
        return null;
      }
      const ModalComponent = MODAL_COMPONENTS[modalType];
      return (
        <ModalWrapper
          key={index}
          {...modalProps}
          hideModal={() => props.dispatch(hideModal())}
        >
          <ModalComponent {...modalOptions} />
        </ModalWrapper>
      );
    })}
  </>
);

const MapStateToProps = state => ({
  modals: state.modal.modals,
});

export default connect(
  MapStateToProps,
)(ModalRoot);
