import React from 'react';
import styled from 'styled-components';
import { mdUp, lgUp } from '../../styles/theme/breakpoints';

const BlogPosts = ({ posts, sectionTitle }) => (
  <div className="Container">
    <SectionTitle>
      <span aria-hidden="true" />
      <svg
        aria-hidden="true"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32z"
        />
      </svg>
      <h2>{sectionTitle}</h2>
      <span aria-hidden="true" />
    </SectionTitle>
    <Row>
      {posts.map(({ link, title, imgSrc, imgAlt, text }) => (
        <Col key={title}>
          <Media>
            <a href={link} title={title}>
              <img src={imgSrc} alt={imgAlt} loading="lazy" />
            </a>
          </Media>
          <Details>
            <h3>
              <a href={link}>{title}</a>
            </h3>
            <p>{text}</p>
            <a href={link}>Read More</a>
          </Details>
        </Col>
      ))}
    </Row>
  </div>
);

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 50px;

  svg {
    color: #e7483d;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    margin-left: 10px;
  }

  span {
    border-top-width: 2px;
    border-color: #ebebeb;
    height: 1px;
    border-top: 1px solid #ebebeb;
    display: block;
    position: relative;
    top: 1px;
    width: 100%;
  }

  h2 {
    text-align: center;
    font-weight: 600;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 300px;

    @media ${lgUp} {
      flex-shrink: 0;
    }
  }
`

const Row = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mdUp} {
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const Col = styled.div`
  margin: 0 0 20px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  flex: 0 0 290px;
  max-width: 290px;
  width: 100%;
`

const Media = styled.div`
  height: 135px;
  overflow: hidden;
  position: relative;

  a {
    display: flex;

    img {
      width: 100%;
      height: auto;
    }
  }
`

const Details = styled.div`
  border: 1px solid #eee;
  padding: 20px;

  h3 {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 600;
    color: #222;

    > a {
      color: inherit;

      &:hover {
        color: #e7483d;
      }
    }
  }

  p {
    margin-top: 15px;
    margin-bottom: 15px;
    color: #616161;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
  }

  > a {
    border-radius: 5px;
    background-color: #e7483d;
    color: #f8f8f8;
    box-shadow: 0 10px 20px rgba(0,0,0,.16);
    padding: .5em 1em;
    font-weight: 600;
    line-height: 1.5;
    display: inline-block;
  }
`

export default BlogPosts
