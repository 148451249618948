import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { CloseModal } from '../icons'

import './styles.scss'

const ModalWrapper = props => {
  const {
    hideModal,
    onClose,
    canClose,
    showClose,
    children,
    small,
    medium,
    large,
    maxHeightOff
  } = props

  const handleOutsideClick = e => {
    if (e.target === e.currentTarget && canClose) {
      onClose()
      hideModal()
    }
  }

  const classes = classnames('Modal-window', {
    '-small': small,
    '-medium': medium,
    '-large': large,
    '-maxHeightOff': maxHeightOff
  })

  return (
    <div className="Modal-overlay" onClick={handleOutsideClick}>
      <div className={classes}>
        {showClose && (
          <button
            className="Modal-icon -close"
            type="button"
            onClick={() => {
              onClose()
              hideModal()
            }}
          >
            <CloseModal />
          </button>
        )}
        {children}
      </div>
    </div>
  )
}

ModalWrapper.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showClose: PropTypes.bool,
  canClose: PropTypes.bool,
  onClose: PropTypes.func
}

ModalWrapper.defaultProps = {
  showClose: false,
  canClose: false,
  onClose: () => {}
}

export default ModalWrapper

export const ModalBody = props => {
  const {
    className,
    children,
    small,
    medium,
    large,
    withHeight,
    closestLoc
  } = props

  const classes = classnames(className, 'Modal-body', {
    '-small': small,
    '-medium': medium,
    '-large': large,
    '-withHeight': withHeight,
    '-closestLoc': closestLoc
  })

  return <div className={classes}>{children}</div>
}
