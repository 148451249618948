import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import ProfileMenu from './menu'
import { Wrap } from './styles'
import Flex from '../../components/flex'
import Heading from '../../components/heading'
import marketsGet from '../../actions/markets'
import ProfileForm from './ProfileForm'
import PasswordChange from './PasswordChange'
import Spinner from '../../components/spinner'
import { accountUpdate } from '../../actions/lead'

const Settings = () => {
  const dispatch = useDispatch()
  const lead = useSelector(state => state.lead)
  const { list: markets, loading } = useSelector(state => state.markets)
  const title = `Profile${
    lead.firstName || lead.lastName
      ? ` for ${lead.firstName || ''} ${lead.lastName || ''}${
          lead.company ? ` at ${lead.company}` : ''
        }`
      : ''
  }`

  const initialValues = {
    first_name: lead.firstName,
    last_name: lead.lastName,
    company: lead.company,
    phone_number: lead.phoneNumber,
    market_id: lead.marketId,
    other: lead.other
  }

  useEffect(() => {
    dispatch(marketsGet())
  }, [dispatch])

  return (
    <Wrap>
      <ProfileMenu />
      <Flex column fullHeight grow stickyFooter>
        {title && (
          <Heading
            level="1"
            size="xsmall"
            spaceBelow="small"
            spaceAbove="medium"
          >
            {title}
          </Heading>
        )}
        <Container>
          <PasswordChange />
          <FormWrap>
            {loading || lead.status.loading ? (
              <LoadingWrap>
                <Spinner size="medium" />
              </LoadingWrap>
            ) : (
              <ProfileForm
                disabled={lead.status.loading}
                initialValues={initialValues}
                onSubmit={data => {
                  dispatch(accountUpdate({
                    ...data,
                    other: data.market_id === 'other' ? data.other : null,
                    market_id: data.other ? null : data.market_id
                  }))
                }}
                selectOptions={(markets || []).map(item => ({
                  label: item.name,
                  value: item.id
                }))}
              />
            )}
          </FormWrap>
        </Container>
      </Flex>
    </Wrap>
  )
}

const FormWrap = styled.div`
  max-width: 400px;
  margin-bottom: 50px;
`

const Container = styled.div`
  padding: 0 20px;
`

const LoadingWrap = styled.div`
  position: relative;
  padding: 50px;
  width: 100%;
`

export default Settings
