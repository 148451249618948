import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Flex from '../../components/flex';

import { googleAnalytics } from '../../utils/analytics';


export default class NotFoundContainer extends Component {
  componentDidMount() {
    googleAnalytics(this.props.location.pathname, 'Upsuite | 404');
  }

  render() {
    return (
      <Flex middle center fullHeight>
        <Helmet>
          <title>404</title>
        </Helmet>
        <h1>404 Page Not Found</h1>
      </Flex>
    );
  }
}
