import React from 'react';
import { connect } from 'react-redux';

import { ModalBody } from '../../components/modal';
import Button from '../../components/button';
import ButtonGroup from '../../components/button-group';

import { hideModal } from '../../actions/modal';
import { spaceDelete } from '../../actions/spaces';

const SpacesDeleteModal = (props) => {
  const { token, spaceId, locationId, closeForm } = props;
  return (
    <ModalBody small>
      <div>
        <span>Are you sure?</span><br />
        <span>This will permanently remove this space.</span>
      </div>
      <br />

      <ButtonGroup fullWidth justify="right" column={false}>
        <Button
          onClick={() => props.hideModal()}
          scheme="link-underline"
        >Cancel</Button>
        <Button
          onClick={() => props.spaceDelete(token, spaceId, locationId, closeForm)
            .then(() => props.hideModal())}
          scheme="link-underline"
        >Remove</Button>
      </ButtonGroup>
    </ModalBody>
  );
};

export default connect(null, { hideModal, spaceDelete })(SpacesDeleteModal);
