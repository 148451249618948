import React from 'react';
import { Field, reduxForm } from 'redux-form';

import Button from '../button';
import { renderField } from '../form-fields';

import './styles.scss';

const required = value => (value ? undefined : 'Please complete this required field.');
const email = value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? 'Email must be formatted correctly.'
  : undefined);
const isNum = val => !/[^[0-9]]*/.test(val) ? undefined : 'Please complete this required field.';

const RegisterForm = (props) => {
  const { handleSubmit, seatsRequired } = props;

  return (
    <form id="registration-form-main" className="RegisterForm" onSubmit={handleSubmit}>
      <Field
        component={renderField}
        name="firstName"
        id="firstName"
        type="text"
        placeholder=""
        label="First Name"
        required
        validate={required}
      />
      <Field
        component={renderField}
        name="email"
        id="email"
        type="text"
        placeholder=""
        label="Work Email"
        required
        validate={[required, email]}
      />
      <Field
        component={renderField}
        name="phoneNumber"
        id="phoneNumber"
        type="number"
        placeholder=""
        label="Phone number"
        required
        validate={[required, isNum]}
      />
      {seatsRequired &&
        <Field
          component={renderField}
          name="seats"
          id="seats"
          type="number"
          placeholder=""
          label="How Many People?"
          required
          validate={[required, isNum]}
        />
      }
      <Button type="submit">
        Book a Meeting
      </Button>
    </form>
  );
};

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  form: 'register-form',
})(RegisterForm);
