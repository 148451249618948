import React from 'react';
import classnames from 'classnames';

import './styles.scss';


export const CompareLayoutHelper = ({ children }) => (
  <div className="CompareLayout">
    <div className="CompareLayout-wrapper">
      {children}
    </div>
  </div>
);

export const CompareMapHelper = ({ children, style }) => (
  <div className="CompareMap-wrapper" style={style}>
    {children}
  </div>
);

export const CompareColumn = ({ className, children }) => (
  <div className={classnames('CompareColumn', className)}>
    {children}
  </div>
);

export const CompareRow = ({ children }) => (
  <div className="CompareRow">
    {children}
  </div>
);

export const CompareTitleMobile = ({ children }) => (
  <div className="CompareTitleMobile">
    <span>{children}</span>
  </div>
);
