import { createReducer } from '../utils';
import {
  GET_LOC_BY_STATUS_REQUEST,
  GET_LOC_BY_STATUS_SUCCESS,
  GET_LOC_BY_STATUS_FAILURE,
  GET_MARKET_AVERAGE_COST_REQUEST,
  GET_MARKET_AVERAGE_COST_SUCCESS,
  GET_MARKET_AVERAGE_COST_FAILURE,
} from '../constants';

const initialState = {
  loading: false,
  failure: false,
  isHealthy: null,
  isClosed: null,
  isNew: null,
  isBest: null,
  averageCost: null,
  isWorkplace: null,
};

export default createReducer(initialState, {
  [GET_LOC_BY_STATUS_REQUEST]: state => ({
    ...state,
    loading: true,
  }),
  [GET_LOC_BY_STATUS_SUCCESS]: (state, payload, config) => ({
    ...state,
    loading: false,
    failure: false,
    [config]: payload,
  }),
  [GET_LOC_BY_STATUS_FAILURE]: state => ({
    ...state,
    loading: false,
    failure: true,
  }),
  [GET_MARKET_AVERAGE_COST_REQUEST]: state => ({
    ...state,
    loading: true,
  }),
  [GET_MARKET_AVERAGE_COST_SUCCESS]: (state, payload) => ({
    ...state,
    loading: false,
    failure: false,
    averageCost: payload,
  }),
  [GET_MARKET_AVERAGE_COST_FAILURE]: state => ({
    ...state,
    loading: false,
    failure: true,
  }),
});
