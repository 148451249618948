import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './styles.scss';
import { ClusterNew } from '../icons';

const getScale = (num) => {
  let scale;
  switch (true) {
    case num > 25:
      scale = 1.5;
      break;
    case num > 20:
      scale = 1.4;
      break;
    case num > 15:
      scale = 1.3;
      break;
    case num > 10:
      scale = 1.2;
      break;
    case num > 5:
      scale = 1.1;
      break;
    case num < 5:
      scale = 1;
      break;
    default:
      scale = 1;
  }

  return scale;
};

export const ClusterMarkerMainMap = ({ numPoints, href, highlight, zIndex }) => (
  <a
    className={classnames('ClusterMarker', { '-green': highlight })}
    href={href}
    style={{ transform: `scale(${getScale(numPoints)})`, display: 'block', zIndex }}
  >
    <ClusterNew />
    <span>{numPoints}</span>
  </a>
);

export const ClusterMarkerLanding = ({ numPoints, href, highlight, zIndex }) => (
  <Link
    className={classnames('ClusterMarker', { '-green': highlight, '-borderless': numPoints === 1 })}
    to={href}
    style={{ transform: `scale(${getScale(20)})`, display: 'block', zIndex }}
  >
    <ClusterNew borderless={numPoints === 1} />
    {numPoints > 1 && (
      <span>{numPoints}</span>
    )}
  </Link>
);

export const CostMarkerMainMap = ({ cost, href, zIndex }) => (
  <a
    className={classnames('ClusterMarker', '-cost')}
    href={href}
    style={{ transform: `scale(${getScale(200)})`, display: 'block', zIndex }}
  >
    <ClusterNew />
    {cost &&
      <span>${cost}</span>
    }
  </a>
);

export const MainClusterMarker = ({ numPoints, onClick, highlight }) => (
  <div
    className={classnames('ClusterMarker', { '-green': highlight })}
    onClick={onClick}
    style={{ transform: `scale(${getScale(100)})` }}
  >
    <ClusterNew />
    <span>{numPoints}</span>
  </div>
);

const ClusterMarker = ({ numPoints, onClick, highlight }) => (
  <div
    className={classnames('ClusterMarker', { '-green': highlight })}
    onClick={onClick}
    style={{ transform: `scale(${getScale(numPoints)})` }}
  >
    <ClusterNew />
    <span>{numPoints}</span>
  </div>
);

export default ClusterMarker;
