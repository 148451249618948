export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];

    return reducer ? reducer(state, action.payload, action.config) : state;
  };
}

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  throw response.status;
}

export function checkHttpResponse(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  throw response;
}

export function parseJSON(response) {
  return response.json();
}
