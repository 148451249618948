import React from 'react';
import classnames from 'classnames';

import './styles.scss';

const Heading = (props) => {
  const {
    level,
    size,
    weight,
    center,
    style,
    color,
    children,
    className,
    spaceAbove,
    spaceBelow,
    mobileScheme,
    withBar,
  } = props;

  const headingClassName = classnames(
    className,
    'Heading',
    `-${color}`,
    `-color-${color}`,
    `-weight-${weight}`,
    `-spaceAbove-${spaceAbove}`,
    `-spaceBelow-${spaceBelow}`,
    `-mScheme-${mobileScheme}`,
    `-${size}`, {
      '-center': center,
      '-withBar': withBar,
    },
  );

  const Tag = `h${level}`;

  return (
    <Tag
      style={style}
      className={headingClassName}
    >{children}</Tag>
  );
};

Heading.defaultProps = {
  level: '1',
  center: true,
  color: 'default',
  size: 'large',
  weight: 'default',
  spaceBelow: 'large',
  spaceAbove: 'default',
  mobileScheme: 'none',
};

export default Heading;
