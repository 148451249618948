import React from 'react';
import { Field, reduxForm } from 'redux-form';

import Button from '../button';
import { renderField } from '../form-fields';

const required = value => (value ? undefined : 'Please complete this required field.');
const email = value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? 'Email must be formatted correctly.'
  : undefined);

const LeadRegisterForm = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Field
        component={renderField}
        name="email"
        id="email"
        type="text"
        placeholder=""
        label="Email"
        required
        validate={[required, email]}
      />
      <Button type="submit">
        Register
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'lead-register-form',
})(LeadRegisterForm);
