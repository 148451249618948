import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { FILTER_QUERY_SET, FILTER_QUERY_RESET } from '../constants';
import { setCookie } from '../utils/cookies';
import { ROOT, SEARCH, WORKPLACE } from '../settings/routes';
import { SEARCH_QUERY } from '../settings';
import { resetExtraFilterQuery } from './filter-query-extra-params';

export const filterQuerySet = payload => {
  // Saving number of people here to use in register lead forms
  if (payload.people) {
    setCookie('seats', payload.people, 365);
  }

  const filterQuery = payload[ROOT] ? {
    ...payload,
    [ROOT]: payload[ROOT].toLowerCase(),
  } : payload;

  // FILTER_QUERY_SET will be used in lead reducer
  return {
    type: FILTER_QUERY_SET,
    payload: filterQuery,
  };
};

export const filterQueryReset = () => ({
  type: FILTER_QUERY_RESET,
});

export const changeFilterQuery = (props, newParam) => {
  const { filterQuery } = props;

  let isWorkplace = filterQuery.is_workplace;
  if (newParam.hasOwnProperty('is_workplace')) {
    isWorkplace = newParam['is_workplace']
  }
  // Market in the url
  if (filterQuery[ROOT]) {
    const newFilterQuery = {
      ...filterQuery,
      amenities: isWorkplace ? null : filterQuery.amenities,
      ...newParam,
      ...resetExtraFilterQuery,
      is_workplace: null,
      [ROOT]: null,
      [SEARCH_QUERY]: null,
    };

    const params = queryString.stringify(newFilterQuery, {
      skipNull: true,
      arrayFormat: 'comma',
      encode: false,
      strict: false,
    });

    props.push(`/${isWorkplace ? WORKPLACE : ROOT}/${filterQuery[ROOT]}/?${params}`);
  } else {
    const newFilterQuery = {
      ...filterQuery,
      amenities: isWorkplace ? null : filterQuery.amenities,
      ...newParam,
      ...resetExtraFilterQuery,
      is_workplace: null,
      [ROOT]: null,
      [SEARCH_QUERY]: filterQuery[SEARCH_QUERY],
    };
    const params = queryString.stringify(newFilterQuery, {
      skipNull: true,
      arrayFormat: 'comma',
      encode: false,
      strict: false,
    });
    props.push(`/${isWorkplace ? WORKPLACE : ROOT}/${SEARCH}/?${params}`);
  }
};

export const resetFilterQuery = (props, isWorkplace) => {
  const { filterQuery } = props;
  // Market in the url
  if (filterQuery[ROOT]) {
    props.push(`/${isWorkplace ? WORKPLACE : ROOT}/${filterQuery[ROOT]}/`);
  } else {
    const newFilterQuery = {
      ...resetExtraFilterQuery,
      [SEARCH_QUERY]: filterQuery[SEARCH_QUERY],
    };
    const params = queryString.stringify(newFilterQuery, {
      skipNull: true,
      arrayFormat: 'comma',
      encode: false,
      strict: false,
    });
    props.push(`/${isWorkplace ? WORKPLACE : ROOT}/${SEARCH}/?${params}`);
  }
};

export const getResetFilterQueryLink = (props) => {
  const { filterQuery } = props;
  // Market in the url
  if (filterQuery[ROOT]) {
    return `/${ROOT}/${filterQuery[ROOT]}/`;
  }

  const newFilterQuery = {
    ...resetExtraFilterQuery,
    [SEARCH_QUERY]: filterQuery[SEARCH_QUERY],
  };

  const params = queryString.stringify(newFilterQuery, {
    skipNull: true,
    arrayFormat: 'comma',
    encode: false,
    strict: false,
  });

  return `/${ROOT}/${SEARCH}/?${params}`;
};

export const getFilterQueryLink = (filterQuery) => {
  if (!filterQuery[ROOT] && !filterQuery[SEARCH_QUERY]) {
    return null;
  }
  const isWorkplace = filterQuery.is_workplace;

  // Market in the url
  if (filterQuery[ROOT]) {
    const newFilterQuery = {
      ...filterQuery,
      ...resetExtraFilterQuery,
      is_workplace: null,
      [ROOT]: null,
      [SEARCH_QUERY]: null,
    };
    const params = queryString.stringify(newFilterQuery, {
      skipNull: true,
      arrayFormat: 'comma',
      encode: false,
      strict: false,
    });

    return `/${isWorkplace ? WORKPLACE : ROOT}/${filterQuery[ROOT]}/?${params}`;
  }

  const newFilterQuery = {
    ...filterQuery,
    ...resetExtraFilterQuery,
    is_workplace: null,
    [ROOT]: null,
    [SEARCH_QUERY]: filterQuery[SEARCH_QUERY],
  };
  const params = queryString.stringify(newFilterQuery, {
    skipNull: true,
    arrayFormat: 'comma',
    encode: false,
    strict: false,
  });

  return `/${isWorkplace ? WORKPLACE : ROOT}/${SEARCH}/?${params}`;
};

export const changeFilterQueryMarket = (props, value) => {
  if (!value) return;

  const { filterQuery } = props;
  const newFilterQuery = {
    ...filterQuery,
    ...resetExtraFilterQuery,
    is_workplace: null,
    [SEARCH_QUERY]: null,
    [ROOT]: null,
  };
  const params = queryString.stringify(newFilterQuery, {
    skipNull: true,
    arrayFormat: 'comma',
    encode: false,
    strict: false,
  });
  props.push(`/${filterQuery.is_workplace ? WORKPLACE : ROOT}/${value}/?${params}`);
}

export const changeFilterQuerySuggest = (props, value) => {
  const { filterQuery } = props;

  if (!isEmpty(value)) {
    if (value.isFixture) {
      const newFilterQuery = {
        ...filterQuery,
        ...resetExtraFilterQuery,
        is_workplace: null,
        [SEARCH_QUERY]: null,
        [ROOT]: null,
      };
      const params = queryString.stringify(newFilterQuery, {
        skipNull: true,
        arrayFormat: 'comma',
        encode: false,
        strict: false,
      });
      props.push(`/${filterQuery.is_workplace ? WORKPLACE : ROOT}/${value.slug}/?${params}`);
    } else {
      const newFilterQuery = {
        ...filterQuery,
        ...resetExtraFilterQuery,
        is_workplace: null,
        [SEARCH_QUERY]: value.label,
        [ROOT]: null,
      };
      const params = queryString.stringify(newFilterQuery, {
        skipNull: true,
        arrayFormat: 'comma',
        encode: false,
        strict: false,
      });
      props.push(`/${filterQuery.is_workplace ? WORKPLACE : ROOT}/${SEARCH}/?${params}`);
    }
  }
};
