import { checkHttpStatus, parseJSON } from '../utils';
import {
  GET_LOC_BY_MARKET_REQUEST,
  GET_LOC_BY_MARKET_SUCCESS,
  GET_LOC_BY_MARKET_FAILURE,
  GET_WORKSPACES_BY_MARKET_SUCCESS,
  GET_FORECAST_MARKET_REQUEST,
  GET_FORECAST_MARKET_SUCCESS,
  GET_FORECAST_MARKET_FAILURE,
  GET_PRICE_MARKET_REQUEST,
  GET_PRICE_MARKET_SUCCESS,
  GET_PRICE_MARKET_FAILURE,
} from '../constants';
import * as api from '../api/seo';

const getLocByMarketRequest = () => ({
  type: GET_LOC_BY_MARKET_REQUEST,
});

const getLocByMarketSuccess = (payload, config) => ({
  type: GET_LOC_BY_MARKET_SUCCESS,
  payload,
  config,
});

const getWorkspacesByMarketSuccess = (payload, config) => ({
  type: GET_WORKSPACES_BY_MARKET_SUCCESS,
  payload,
  config,
});

const getLocByMarketFailure = () => ({
  type: GET_LOC_BY_MARKET_FAILURE,
});

export const getLocByMarket = (marketSlug, isWorkspace = false) =>
  (dispatch, getState) => {
    if (isWorkspace && getState().seo.workspacesByMarketSlug[marketSlug]) {
      return null;
    }

    if (!isWorkspace && getState().seo.locByMarketSlug[marketSlug]) {
      return null;
    }

    dispatch(getLocByMarketRequest());

    return api.getLocationsByMarketSlug(marketSlug, isWorkspace)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        if (isWorkspace) {
          dispatch(getWorkspacesByMarketSuccess(response, marketSlug));
        } else {
          dispatch(getLocByMarketSuccess(response, marketSlug));
        }
      })
      .catch((error) => {
        dispatch(getLocByMarketFailure(error));
      });
  };

export const getForecast = (marketSlug, marketId) => (dispatch, getState) => {
  const forecast = getState().seo.forecastBySlug[marketSlug]

  if (forecast) return null;

  dispatch({ type: GET_FORECAST_MARKET_REQUEST })

  return api.getForecast(marketId)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then((response) => {
      dispatch({ type: GET_FORECAST_MARKET_SUCCESS, payload: response, config: marketSlug });
    })
    .catch((error) => {
      dispatch({ type: GET_FORECAST_MARKET_FAILURE })
    });
}

export const getAskingprice = (marketSlug, marketId) => (dispatch, getState) => {
  const price = getState().seo.priceBySlug[marketSlug]

  if (price) return null;

  dispatch({ type: GET_PRICE_MARKET_REQUEST })

  return api.getAskingprice(marketId)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then((response) => {
      dispatch({ type: GET_PRICE_MARKET_SUCCESS, payload: response, config: marketSlug });
    })
    .catch((error) => {
      dispatch({ type: GET_PRICE_MARKET_FAILURE })
    });
}
