import React from 'react';
import classnames from 'classnames';

import './styles.scss';

const Spinner = (props) => {
  const {
    size,
    inline,
    center,
    right,
    style,
    color,
    boxed,
    absolute,
    pageHeight,
  } = props;

  const wrapClasses = classnames(
    'Spinner',
    `-size-${size}`, {
      '-inline': inline,
      '-center': center,
      '-right': right,
      '-boxed': boxed,
      '-absolute': absolute,
      '-pageHeight': pageHeight,
    },
  );

  const elementClasses = classnames(
    'Spinner-loader',
    `Spinner-loader-${size}`,
    `Spinner-loader-${color}`,
  );

  return (
    <div className={wrapClasses} {...style}>
      <span className={elementClasses} />
    </div>
  );
};

Spinner.defaultProps = {
  inline: false,
  center: false,
  right: false,
  boxed: false,
  size: 'small',
  color: 'default',
};

export default Spinner;
