import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux'

import Button from '../../components/button';
import { renderField, selectBaseField } from '../../components/form-fields';
import { required } from '../../utils/lead-validation'

let ProfileForm = (props) => {
  const { handleSubmit, disabled, selectOptions, selectValue, hasOther } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Field
        component={renderField}
        name="first_name"
        id="first_name"
        type="text"
        placeholder="First Name"
        label="First Name"
        disabled={disabled}
        validate={[required]}
      />
      <Field
        component={renderField}
        name="last_name"
        id="last_name"
        type="text"
        placeholder="Last Name"
        label="Last Name"
        disabled={disabled}
        validate={[required]}
      />
      <Field
        component={renderField}
        name="phone_number"
        id="phone_number"
        type="text"
        placeholder="Phone"
        label="Phone"
        disabled={disabled}
      />
      <Field
        component={renderField}
        name="company"
        id="company"
        type="text"
        placeholder="Company Name"
        label="Company Name"
        disabled={disabled}
      />
      <Field
        component={selectBaseField}
        name="market_id"
        id="market_id"
        placeholder="Market"
        label="Market"
        options={[...selectOptions, {
          label: 'Other',
          value: 'other'
        }]}
        value={selectValue}
        disabled={disabled}
      />
      {(hasOther || (props?.initialValues?.other && !props?.initialValues?.market_id)) && (
        <Field
          component={renderField}
          name="other"
          id="other"
          type="text"
          placeholder="Member Market"
          label="Member Market"
          disabled={disabled}
        />
      )}
      <Button type="submit" disabled={disabled}>
        Save
      </Button>
    </form>
  );
};

const selector = formValueSelector('profile-form');

ProfileForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'profile-form'
})(ProfileForm)

ProfileForm = connect(state => {
  const hasOther = selector(state, 'market_id') === 'other';

  return {
    hasOther
  }
})(ProfileForm)

export default ProfileForm
