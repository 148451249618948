
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import { imageUrl } from './crm-links';



const spaceKeys = [
  'dateAvailable',
  'deleted',
  'description',
  'isAvailable',
  'locationName',
  'locationId',
  'name',
  'spaceCapacity',
  'spaceFloor',
  'spacePrice',
  'spacePriceConverted',
  'spacePriceCurrency',
  'spaceType',
  'spaceView',
  'spaceWindows',
  'sqFt',
];

export const normalizeSpaceObject = data =>
  reduce(spaceKeys, (results, item) =>
    (data[item] !== null || item === 'dateAvailable' ? { ...results, [item]: data[item] } : results), {});

export function errorHandler(status) {
  if (status === 401) {
    return 'Not authorized';
  }

  if (status === 403) {
    return 'You are not allowed to edit spaces of the location';
  }

  return 'Server Error';
}

export const getSpaceImages = (space) => {
  const arr = space.dollhouseImageId ? [{ url: imageUrl(space.dollhouseImageId), alt: space.dollhouseImageName }] : [];

  return reduce(space, (result, value, key) => {
    if (/spaceImage\d{1,2}Id/.test(key)) {
      if (!isEmpty(value))
        result.push({ url: imageUrl(value), alt: space[key.replace('Id', 'Name')] });
    }
    return result;
  }, arr);
}
