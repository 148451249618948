import mapstyles from '../settings/mapstyles.json';
import getDistanceByCoords, { getPointByDistance } from './geo';

export const createMapOptions = maps => ({
  styles: mapstyles,
  mapTypeControlOptions: {
    style: maps.MapTypeControlStyle.DROPDOWN_MENU,
    mapTypeIds: ['roadmap', 'terrain', 'satellite', 'hybrid', 'bicycles', 'transit'],
  },
  mapTypeControl: true,
  zoomControlOptions: {
    position: window.google.maps.ControlPosition.RIGHT_TOP,
  },
  streetViewControl: true,
  streetViewControlOptions: {
    position: window.google.maps.ControlPosition.RIGHT_TOP,
  },
  maxZoom: 18,
});

export const createMainMapOptions = maps => ({
  styles: mapstyles,
  mapTypeControlOptions: {
    style: maps.MapTypeControlStyle.DROPDOWN_MENU,
    mapTypeIds: ['roadmap', 'terrain', 'satellite', 'hybrid', 'bicycles', 'transit'],
    position: window.google.maps.ControlPosition.RIGHT_TOP
  },
  mapTypeControl: true,
  fullscreenControl: true,
  fullscreenControlOptions: {
    position: window.google.maps.ControlPosition.RIGHT_BOTTOM
  },
  streetViewControl: true,
  maxZoom: 18,
});

export const singleMapOptions = maps => ({
  styles: mapstyles,
  mapTypeControlOptions: {
    style: maps.MapTypeControlStyle.DROPDOWN_MENU,
    mapTypeIds: ['roadmap', 'terrain', 'satellite', 'hybrid', 'bicycles', 'transit'],
  },
  mapTypeControl: true,
  zoomControlOptions: {
    position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
  },
  streetViewControl: true,
  streetViewControlOptions: {
    position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
  },
});

export const createMapOptionsMobile = maps => ({
  styles: mapstyles,
  mapTypeControlOptions: {
    style: maps.MapTypeControlStyle.DROPDOWN_MENU,
    mapTypeIds: ['roadmap', 'terrain', 'satellite', 'hybrid', 'bicycles', 'transit'],
  },
  mapTypeControl: true,
  zoomControl: false,
  fullscreenControl: false,
});

export const createCompareMapOptions = () => ({
  styles: mapstyles,
  zoomControlOptions: {
    position: window.google.maps.ControlPosition.RIGHT_TOP,
  },
  streetViewControl: true,
  streetViewControlOptions: {
    position: window.google.maps.ControlPosition.RIGHT_TOP,
  },
});

export const handleApiLoaded = (map, maps, bindMapToState) => {
  const bicyclesMapType = new maps.StyledMapType(mapstyles, { name: 'Bicycles' });
  const transitMapType = new maps.StyledMapType(mapstyles, { name: 'Transit' });

  bindMapToState({ map, maps });

  map.mapTypes.set('bicycles', bicyclesMapType);
  map.mapTypes.set('transit', transitMapType);
};

export const getLayerType = (maptype) => {
  if (maptype === 'bicycles') {
    return ['BicyclingLayer'];
  }

  if (maptype === 'transit') {
    return ['TransitLayer'];
  }

  return undefined;
};

export function getBoundsOnChange(maps, listings) {
  const bounds = new maps.LatLngBounds();

  listings.forEach((marker) => {
    const latLng = new maps.LatLng(marker.lat, marker.lng);
    bounds.extend(latLng);
  });

  return bounds;
}

export const getVisibleMarkerIds = (listings, maps, map) => {
  const bounds = map.getBounds();
  const visible = listings.filter(({ latitude, longitude }) => {
    const latLng = new maps.LatLng(latitude, longitude);
    return bounds.contains(latLng);
  });

  return visible.length !== listings.length ? visible.map(({ id }) => id) : [];
};

export const getMapBounds = (map) => {
  const bounds = map.getBounds();
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  return ({
    nw: { lat: ne.lat(), lng: sw.lng() },
    se: { lat: sw.lat(), lng: ne.lng() },
  });
};

export const getPoints = listings => listings.map(item => ({
  lat: item.latitude,
  lng: item.longitude,
  id: item.id,
}));

export const fitToBoundsByCoords = (props, radius) => {
  const { map, maps, locationCoords: { lat, long } } = props;
  const bounds = new maps.LatLngBounds();
  bounds.extend(getPointByDistance({ lat, lng: long }, 270, radius));
  bounds.extend(getPointByDistance({ lat, lng: long }, 90, radius));
  bounds.extend(getPointByDistance({ lat, lng: long }, 180, radius));
  bounds.extend(getPointByDistance({ lat, lng: long }, 0, radius));
  map.fitBounds(bounds, {
    left: 0, bottom: 0, right: 0, top: 0,
  });
};

export const getPlaceCountryByPlaceId = (service, placeId) => {
  const request = {
    placeId,
    fields: ['address_components'],
  };

  return new Promise(resolve => service.getDetails(
    // eslint-disable-next-line camelcase
    request, ({ address_components }) => address_components.forEach((place) => {
      if (place.types.includes('country')) {
        return resolve(place.short_name);
      }

      return null;
    }),
  ));
};

export const getSearchRadiusByBounds = (bounds) => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const verticalRadius = getDistanceByCoords(ne.lat(), sw.lng(), sw.lat(), ne.lng()) / 2;
  const horizontalRadius = getDistanceByCoords(ne.lat(), sw.lng(), sw.lat(), ne.lng()) / 2;

  return (verticalRadius > horizontalRadius ? horizontalRadius : verticalRadius).toFixed(1);
};
