import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.scss';

const Meta = (props) => {
  const {
    className,
    children,
    center,
    color,
    size,
    shift,
  } = props;

  const classes = classnames(
    className,
    'Meta',
    `-${color}`,
    `-${size}`, {
      '-center': center,
      '-shift': shift,
    },
  );

  return (
    <div
      className={classes}
    >
      <span>{children}</span>
    </div>
  );
};

Meta.propTypes = {
  center: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  shift: PropTypes.bool,
};

Meta.defaultProps = {
  center: false,
  className: null,
  color: 'default',
  size: 'small',
  shift: false,
};

export default Meta;
