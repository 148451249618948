import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { ModalBody } from '../../components/modal';
import Heading from '../../components/heading';
import RegisterForm from '../../components/register-form';
import Flex from '../../components/flex';
import StarRating from '../../components/star-rating';

import { registerLead } from '../../actions/auth';
import { openIframeModal, hideModal } from '../../actions/modal';

import { setCookie } from '../../utils/cookies';
import Spinner from '../../components/spinner';
import headshots from '../../assets/ben-wright.png';

const AdvisorBookMeeting = (props) => {
  const dispatch = useDispatch();
  const {
    market, visitorId, people
  } = props;
  const [loading, setLoading] = useState(false);
  const [failure, setFailue] = useState(false);

  return (
    <ModalBody small withHeight>
      {market && (
        <div className="AdvisorCall">
          <div className="AdvisorCall-meta">
            <Heading level="3" size="medium" spaceBelow="small">
              Work with an Upsuite Advisor
            </Heading>
            <img
              className="AdvisorCall-headshot -custom"
              src={headshots}
              alt="Work with an Upsuite Advisor"
            />
            {loading && <Spinner size="medium" inline boxed />}

            <RegisterForm
              initialValues={{
                seats: people,
                isWorkspace: [!!props.isWorkspace],
              }}
              onSubmit={val => {
                setLoading(true);
                props
                  .registerLead({
                    ...val,
                    form: 'upsuite-coworking-advisor',
                    visitorId,
                  })
                  .then(() => {
                    setLoading(false);
                    setCookie('_REG_TEMP_COMPLETED', true, 365);
                    dispatch(hideModal());
                    dispatch(
                      openIframeModal({
                        src: 'https://meetings.hubspot.com/hailey-barnett1',
                        title: 'Work with an Upsuite Advisor',
                        width: '900px',
                        height: '815px',
                      }),
                    );
                  })
                  .catch(() => {
                    setLoading(false);
                    setFailue(true);
                  });
              }}
            />

            {!loading && failure && (
              <p style={{ textAlign: 'center', color: 'red' }}>
                There was a problem with form submit
              </p>
            )}
          </div>
        </div>
      )}

      <Flex center>
        <StarRating rating={5} />
        <span style={{ fontSize: 15 }}>
          &quot;My Upsuite Advisor was very helpful to compare the many options
          in our area&quot; - Ryan
        </span>
      </Flex>
    </ModalBody>
  );
};

const mapStateToProp = (state, ownProps) => ({
  market: state.markets.byName[ownProps.nearestMarket]
    ? state.markets.byName[ownProps.nearestMarket]
    : null,
  visitorId: state.auth.visitorId,
  people: state.filterQuery.people,
});

export default connect(mapStateToProp, { registerLead })(AdvisorBookMeeting);
