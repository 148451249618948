import { createSelector } from 'reselect';

export const getId = state => state.ui.highlightMarkerId;

export const getPinId = state => state.ui.pinMarkerId;

export const selectHighlightId = createSelector(
  getId,
  id => id,
);

export const selectPinId = createSelector(
  getPinId,
  id => id,
);
