import { createReducer } from '../utils';
import {
  BRANDS_GET_REQUEST,
  BRANDS_GET_SUCCESS,
  BRANDS_GET_FAILURE,
} from '../constants';

const initialState = {
  byMarketId: {},
  loading: false,
  error: false,
};

export default createReducer(initialState, {
  [BRANDS_GET_REQUEST]: state => ({
    ...state,
    loading: true,
    error: false,
  }),
  [BRANDS_GET_SUCCESS]: (state, { id, list }) => ({
    ...state,
    byMarketId: {
      ...state.byMarketId,
      [id]: list,
    },
    loading: false,
    error: false,
  }),
  [BRANDS_GET_FAILURE]: state => ({
    ...state,
    loading: false,
    error: true,
  }),
});
