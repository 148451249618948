import reduce from 'lodash/reduce';
import some from 'lodash/some';

const imageAltsMap = {
  listingCardImageId: 'listingCardAltText',
  exteriorImage1Id: 'image1AltText',
  exteriorImage2Id: 'image2AltText',
  interiorImage1Id: 'image3AltText',
  interiorImage2Id: 'image4AltText',
  interiorImage3Id: 'image5AltText',
  image6Id: 'image6AltText',
  image7Id: 'image7AltText',
  image8Id: 'image8AltText',
  image9Id: 'image9AltText',
  image10Id: 'image10AltText',
};

export const toIdbySlug = list => reduce(list,
  (acc, { marketplaceSlug, marketSlug, id }) =>
    ({ ...acc, [`${marketSlug}/${marketplaceSlug}`]: id }),
  {});

export default listing => reduce(listing, (result, value, key) => {
  if (/interiorImage\dId/.test(key) && value) {
    result.push({ alt: listing[imageAltsMap[key]], value });
  }

  if (/image\d{1,2}Id/.test(key) && value) {
    result.push({ alt: listing[imageAltsMap[key]], value });
  }


  return result;
}, []);

export const ifHealthySpace = accolades =>
  some(
    accolades,
    ac => ac === 'Cleaning Policies' || ac === 'Safe 6 Floorplan',
  );
