import React from 'react';
import styled from 'styled-components';
import Heading from '../../components/heading';
import MemberReviews from '../../components/member-reviews';
import { mdUp } from '../../styles/theme/breakpoints';

export const AboutRow = () => {
  return (
    <div className="Container">
      <Row>
        <Col data-col="6" data-styles="about">
          <Heading
            center
            level="2"
            mobileScheme="landing"
            style={{ marginBottom: 10, color: 'black' }}
          >
            About Upsuite
          </Heading>
          <p>
            Are you looking to lease or rent coworking office space? You’ll find
            the most flexible office options on the Upsuite Network. We
            consolidate workspaces from multiple sources to provide a complete
            view of the flexible commercial real estate market. With more than{' '}
            <strong>2,500</strong> locations throughout The United States and
            Canada, you’re sure to find the perfect office match. We offer
            coworking, executive offices, team suites, meeting rooms, and more. 
            Expect to pay around <strong>$378 per person per month</strong>{' '}
            (Q1,2021). Of course, rent prices vary based on city, size, and the
            amenities you choose. You may pay less for a coworking hot desk, and
            more for an executive office suite. Rent office space from one hour
            or up to three years and longer.
          </p>
        </Col>
        <Col data-col="6">
          <Heading
            center
            level="2"
            mobileScheme="landing"
            style={{ marginBottom: 10, color: 'black' }}
          >
            The Upsuite Network
          </Heading>
          <Row>
            <Col data-col="6" data-styles="counter">
              <span>2,500+</span>
              <span>Locations</span>
            </Col>
            <Col data-col="6" data-styles="counter">
              <span>35,000+</span>
              <span>Rentable Spaces</span>
            </Col>
          </Row>
          <Heading
            center
            level="2"
            mobileScheme="landing"
            style={{ marginBottom: 10, marginTop: 10, color: 'black' }}
          >
            The Best Coworking Spaces On Upsuite
          </Heading>
          <Row>
            <Col data-col="12" data-styles="table">
              <table>
                <thead>
                  <tr>
                    <th>Operator</th>
                    <th>Locations</th>
                    <th>Rentable Spaces</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>WeWork</td>
                    <td>372</td>
                    <td>3,500+</td>
                  </tr>
                  <tr>
                    <td>Regus</td>
                    <td>1000+</td>
                    <td>5,000+</td>
                  </tr>
                  <tr>
                    <td>Industrious</td>
                    <td>80+</td>
                    <td>3,000+</td>
                  </tr>
                  <tr>
                    <td>Studio by Tishman Speyer</td>
                    <td>15</td>
                    <td>250+</td>
                  </tr>
                  <tr>
                    <td>… 325 more</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const ReviewsRow = () => {
  return (
    <div className="Container">
      <Row>
        <Col data-col="6" data-styles="benefits">
          <Heading
            center
            level="2"
            mobileScheme="landing"
            style={{ marginBottom: 30, color: 'black' }}
          >
            Benefits of Finding Coworking Space with Upsuite
          </Heading>
          <ul>
            <li>Largest Coworking Space Selection</li>
            <li>No cost Coworking Advisor</li>
            <li>Exclusive discounts for Companies</li>
            <li>Satisfaction Guarantee</li>
          </ul>
        </Col>
        <Col data-col="6">
          <Heading
            center
            level="2"
            mobileScheme="landing"
            style={{ marginBottom: 20, color: 'black' }}
          >
            Member Reviews of Upsuite
          </Heading>
          <MemberReviews
            text="Had a really great experience working with Upsuite! Very professional, fast service – Upsuite helped me find the perfect space within days."
            author="Julia Bricker"
            company="WTax"
          />
        </Col>
      </Row>
    </div>
  );
};

export const FaqRow = () => {
  return (
    <div className="Container">
      <Row>
        <Col data-col="12" data-styles="faq">
          <Heading center level="2" mobileScheme="landing">
            Frequently Asked Questions
          </Heading>
          <ol>
            <li>
              <a href="https://www.upsuite.com/blog/coworking-space-costs-average-membership-pricing/">
                How much does a coworking space cost?
              </a>
            </li>
            <li>
              <a href="https://www.upsuite.com/blog/types-of-coworking-space-available/">
                How many sizes of coworking space are there available?
              </a>
            </li>
            <li>
              <a href="https://www.upsuite.com/blog/coworking-space-agreements-contracts-template-included/">
                What is a coworking space agreement?
              </a>
            </li>
            <li>
              <a href="https://www.upsuite.com/blog/types-of-coworking-space-available/">
                What types of coworking spaces are available?  
              </a>
            </li>
            <li>
              <a href="https://www.upsuite.com/blog/the-largest-coworking-companies-wework-top-competitors/">
                Who are the largest providers of Coworking Spaces?
              </a>
            </li>
          </ol>
        </Col>
      </Row>
    </div>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: -15px;
  margin-left: -15px;

  @media (min-width: 40em) {
    flex-direction: row;
  }
`;

const Col = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 15px;

  &[data-col='6'] {
    @media (min-width: 40em) {
      width: 50%;
      flex: 0 0 50%;
    }
  }

  &[data-col='4'] {
    @media (min-width: 40em) {
      width: 33%;
      flex: 0 0 33%;
    }
  }

  &[data-styles='about'] {
    p {
      font-size: 20px;
      line-height: 1.3;
    }
  }

  &[data-styles='counter'] {
    text-align: center;

    span {
      display: block;
    }

    span:first-child {
      font-size: 50px;
      font-weight: 600;
    }

    span:last-child {
      font-size: 20px;
      font-weight: 600;
    }
  }

  &[data-styles='table'] {
    table {
      width: 100%;
      font-size: 16px;

      @media ${mdUp} {
        font-size: 18px;
      }

      th {
        text-align: left;
        text-decoration: underline;
      }

      th,
      td {
        padding: 2px;
        vertical-align: top;
      }
    }

    h3 {
      text-decoration: underline;
      margin-bottom: 5px;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        font-size: 17px;
        margin-bottom: 3px;
      }
    }
  }

  &[data-styles='benefits'] {
    ul {
      li {
        font-size: 20px;
        margin-bottom: 5px;
      }
    }
  }

  &[data-styles='faq'] {
    ol {
      font-size: 20px;

      @media ${mdUp} {
        margin-left: 20px;
      }
    }

    li {
      margin-bottom: 10px;

      a {
        color: black;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;
