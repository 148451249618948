import React from 'react';
import styled from 'styled-components';

const Hero = () => {
  return (
    <Section>
      <Text>
        <h1>Find Coworking Space</h1>
        <p>Upsuite offers an on-demand network of 35,000+ workspaces that are designed for employee engagement and perfect for small businesses.</p>
      </Text>
      <Overlay />
      <img src="https://www.upsuite.com/wp-content/uploads/2019/03/Alchemy_Creative_Workspace_Denver.jpg" alt="Find Coworking Space" />
    </Section>
  )
}

const Section = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
  }
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000000;
  opacity: 0.5;
  z-index: 2;
  top: 0;
`

// const Btn = styled.a`
//   font-size: 16px;
//   padding: .6em 1.5em;
//   color: #f8f8f8!important;
//   background: #e7483d!important;
//   box-shadow: 0 10px 20px rgb(0 0 0 / 16%);
//   border-radius: 5px;
//   display: inline-block;
// `

const Text = styled.div`
  position: relative;
  z-index: 3;
  color: white;
  text-align: center;
  padding: 28px;
  max-width: 860px;

  h1 {
    font-weight: 600;
    line-height: 1.4;
    font-size: 32px;
    margin-bottom: 10px;
  }

  p {
    font-size: 22px;
    line-height: 1.3;
    margin: 0 0 20px;
  }
`

export default Hero;
