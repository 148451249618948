import React, { Component } from 'react';
import isNil from 'lodash/isNil';

import Select from '../select';
import { currencyFormat, currencyBefore } from '../../utils/format';

import './styles.scss';

const getSpaceLabel = ({ spaceCapacity, spaceType, spacePrice, sqFt }) => {
  const label = [
    `${spaceCapacity > 1 ? `${spaceCapacity} Person` : '1 Person'} ${spaceType}`,
    spacePrice && currencyBefore(currencyFormat(spacePrice)),
    sqFt && `${sqFt} Sq/ft`,
  ];

  return label.filter(l => l).join(', ');
};

class CompareSpaces extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    activeSpaceIndex: this.props.activeSpaceIndex,
  };

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSpaceIndex !== this.state.activeSpaceIndex) {
      this.setState({
        activeSpaceIndex: nextProps.activeSpaceIndex,
      });
    }
  }

  handleChange = (index) => {
    const { onSpaceChanged } = this.props;

    this.setState({
      activeSpaceIndex: index,
    });

    if (onSpaceChanged) onSpaceChanged(index);
  }

  render() {
    const { activeSpaceIndex } = this.state;
    const spaces = (this.props.spaces || []).filter(item => item.isAvailable);
    const activeSpace = !isNil(activeSpaceIndex) && spaces && spaces[activeSpaceIndex];
    const options = spaces && spaces.map((item, index) => ({ label: getSpaceLabel(item), value: index }));

    return (
      <div className="CompareSpaces">
        <Select
          options={options}
          onChange={({ value }) => this.handleChange(value)}
          placeholder="Choose Space"
          clearable={false}
          searchable={false}
          value={activeSpace && activeSpace.name ? { label: getSpaceLabel(activeSpace) } : ''}
        />
        <ul className="CompareSpaces-list">
          <li className="CompareSpaces-item">{activeSpace && activeSpace.spaceCapacity ? activeSpace.spaceCapacity : 'N/A'}</li>
          <li className="CompareSpaces-item">{activeSpace && activeSpace.spacePrice ? activeSpace.spacePrice : 'N/A'}</li>
          <li className="CompareSpaces-item">{activeSpace && activeSpace.sqFt ? activeSpace.sqFt : 'N/A'}</li>
        </ul>
      </div>
    );
  }
}

export default CompareSpaces;
