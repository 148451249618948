import { ROOT, COMPARE } from './routes'

export const verifiedText = 'Upsuite has verified that this location meets Upsuite member standards and is accurately portrayed on Upsuite.com.';
export const verifiedNewText = 'This verified location is New on Upsuite.com.  New locations offer more modern finishes, greater availability, and often offer more value.';
export const healthySpaceText = 'Upsuite has a physical distancing floor plan and cleaning policy on file for this location. Upsuite cannot guarantee the health of individual members in each space.';

// Search page SEO values
export const searchMetaTitle = 'Coworking Spaces Near me';
export const searchMetaDesc = 'The easiest way to find Flexible Coworking Spaces near me is with Upsuite. Check photos, compare & select the next office space for your company.';
export const searchH1 = 'Search for Coworking Spaces Near Me';
export const searchParagraph = 'Best results for Coworking Spaces near me';

// Export mail body
export const getMailBody = (ids = []) => {
  const origin = window.location.origin

  return `I was researching a few Coworking spaces on Upsuite.com and narrowed it down to a couple. If we book a tour or trial day at a verified location through their site we get 5% off our first 12 months.%20%0A %20%0A
  Check them out: %20%0A ${origin}/${ROOT}/${COMPARE}/${ids.join(',')}/`
}
