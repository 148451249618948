import orderBy from 'lodash/orderBy';
import getAllAmenities from './amenities';

const spaceAccoladesCountOrder = space => space.accolades && space.accolades.length;

const spaceAccoladesNotEmpty = space => !!space.accolades;

const spaceMinPriceNotNull = listing => listing.minPrice !== 0;

const spaceMaxPriceNotNull = listing => listing.maxPrice !== 0;

const spaceCapacityNotNull = listing => listing.minSpaceCapacity !== 0;

const spaceAmenitiesCountOrder = listing => getAllAmenities(listing)
  .filter(item => item.disabled).length;

export default spaces => orderBy(spaces, [
  'isIndependent',
  'upsuiteVerified',
  spaceAccoladesNotEmpty,
  spaceAccoladesCountOrder,
],
['asc', 'desc', 'desc', 'desc']);

export const sortByName = listings => orderBy(listings, [
  'name',
  'isIndependent',
  'upsuiteVerified',
  spaceAccoladesNotEmpty,
  spaceAccoladesCountOrder,
],
['asc', 'asc', 'desc', 'desc', 'desc']);

export const sortByNameDesc = listings => orderBy(listings, [
  'name',
  'isIndependent',
  'upsuiteVerified',
  spaceAccoladesNotEmpty,
  spaceAccoladesCountOrder,
],
['desc', 'asc', 'desc', 'desc', 'desc']);

export const sortBySpacesMinPrice = listings => orderBy(listings, [
  'upsuiteVerified',
  'isAvailable',
  spaceMinPriceNotNull,
  'minPrice',
  spaceAccoladesNotEmpty,
  spaceAccoladesCountOrder,
],
['desc', 'desc', 'desc', 'asc', 'desc', 'desc']);

export const sortBySpacesMaxPrice = listings => orderBy(listings, [
  'upsuiteVerified',
  'isAvailable',
  spaceMaxPriceNotNull,
  'maxPrice',
  spaceAccoladesNotEmpty,
  spaceAccoladesCountOrder,
],
['desc', 'desc', 'desc', 'desc', 'desc', 'desc']);

export const sortBySpacesCapacity = listings => orderBy(listings, [
  spaceCapacityNotNull,
  'minSpaceCapacity',
  'isIndependent',
  'upsuiteVerified',
  spaceAccoladesNotEmpty,
  spaceAccoladesCountOrder,
],
['desc', 'asc', 'asc', 'desc', 'desc', 'desc']);

export const sortByTotalofSeats = (listings, order = 'asc') => orderBy(listings, [
  listing => listing.totalofSeats || 0,
], [order]);

export const sortByRecomended = listings => orderBy(listings, [
  'isHealthy',
  'newLocation',
  'upsuiteVerified',
  'isAvailable',
  'dateAvailable',
  'distance',
  spaceAccoladesNotEmpty,
  spaceAccoladesCountOrder,
  spaceAmenitiesCountOrder,
],
['desc', 'desc', 'desc', 'desc', 'asc', 'asc', 'desc', 'desc', 'asc']);

export const sortByDistance = listings => orderBy(listings, [
  'upsuiteVerified',
  'isAvailable',
  'distance',
],
['desc', 'desc', 'asc']);
