import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

export const Wrapper = (props) => {
  const {
    className,
    children,
    width,
    fluid,
    text,
    textAlign,
  } = props;

  const classes = classNames({
    className,
    wrapper: true,
    '-fluid': fluid && !width,
    '-text': text,
    [`-text-${textAlign}`]: textAlign,
  });

  return (
    <div className={classes} style={{ width }}>{children}</div>
  );
};

Wrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  fluid: PropTypes.bool,
  width: PropTypes.string,
  text: PropTypes.bool,
  textAlign: PropTypes.string,
};

Wrapper.defaultProps = {
  className: null,
  children: null,
  fluid: true,
  width: null,
  text: false,
  textAlign: null,
};

export const Landing = (props) => {
  const {
    className,
    children,
    columnReverseMobile,
    columnReverseTablet,
  } = props;

  const classes = classNames({
    className,
    Landing: true,
    '-columnReverseMobile': columnReverseMobile,
    '-columnReverseTablet': columnReverseTablet,
  });

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

Landing.propTypes = {
  className: PropTypes.string,
};

Landing.defaultProps = {
  className: null,
};

export const Container = (props) => {
  const {
    className,
    children,
    withColumns,
    topShift,
    fullWidth,
    style,
    mobileScheme,
    bottomShift,
  } = props;

  const classes = classNames(
    className,
    'Container',
    `-mScheme-${mobileScheme}`, {
      '-withColumns': withColumns,
      '-topShift': topShift,
      '-fullWidth': fullWidth,
      '-bsh': bottomShift,
    },
  );

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

Container.defaultProps = {
  topShift: true,
  withColumns: false,
  fullWidth: false,
  mobileScheme: 'none',
};

export const Cols = (props) => {
  const {
    className,
    children,
    verticalCenter,
    columnMobile,
    columnTablet,
    wrap,
    reverseMobile,
  } = props;

  const classes = classNames({
    className,
    Cols: true,
    '-verticalCenter': verticalCenter,
    '-columnMobile': columnMobile,
    '-columnTablet': columnTablet,
    '-reverseMobile': reverseMobile,
    '-wrap': wrap,
  });

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export const Col = (props) => {
  const {
    className,
    children,
    onethird,
    twothird,
    half,
    onefourth,
    sixten,
    fourten,
    fluid,
    column,
    fullWidthMobile,
    fullWidthTablet,
    fullWidth,
    alignStart,
    center,
  } = props;

  const classes = classNames({
    className,
    Col: true,
    '-onethird': onethird,
    '-twothird': twothird,
    '-fullWidth': fullWidth,
    '-sixten': sixten,
    '-fourten': fourten,
    '-half': half,
    '-onefourth': onefourth,
    '-fluid': fluid,
    '-column': column,
    '-fullWidthMobile': fullWidthMobile,
    '-fullWidthTablet': fullWidthTablet,
    '-alignStart': alignStart,
    '-center': center,
  });

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export const Section = (props) => {
  const {
    className,
    children,
    spaceBelow,
    id,
    landing,
    padded,
    withBg,
    mobileScheme,
    zIndex,
    spaceAbove
  } = props;

  const classes = classNames(
    className,
    { Section: true },
    { '-landing': landing },
    `-spaceBelow-${spaceBelow}`,
    spaceAbove && `-spaceAbove-${spaceAbove}`,
    `-padded-${padded}`,
    `-withBg-${withBg}`,
    `-mScheme-${mobileScheme}`,
  );

  return (
    <section id={id} className={classes} style={{ zIndex }}>
      {children}
    </section>
  );
};

Section.defaultProps = {
  spaceBelow: 'none',
  padded: 'none',
  withBg: 'none',
  id: null,
  landing: false,
  mobileScheme: 'none',
  zIndex: 'auto',
};
