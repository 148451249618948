import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

import 'rc-tooltip/assets/bootstrap.css';

import './styles.scss';

const TooltipComponent = (props) => {
  const {
    overlay, maxWidth, placement, children, overlayStyle, overlayClassName
  } = props;

  const renderOverlay = () => (
    <div className="Tooltip-overlay" style={{ maxWidth, ...overlayStyle }}>
      {overlay}
    </div>
  );

  return (
    <Tooltip
      overlay={renderOverlay}
      trigger="hover"
      overlayClassName={overlayClassName}
      prefixCls="Tooltip"
      mouseEnterDelay={0.5}
      mouseLeaveDelay={0.3}
      placement={placement}
      destroyTooltipOnHide
    >
      {children}
    </Tooltip>
  );
};

TooltipComponent.propTypes = {
  overlay: PropTypes.node,
  children: PropTypes.node,
  placement: PropTypes.string,
  maxWidth: PropTypes.number,
  overlayClassName: PropTypes.string,
};

TooltipComponent.defaultProps = {
  overlay: null,
  children: null,
  placement: 'bottomLeft',
  maxWidth: 300,
  overlayClassName: '',
};

export { TooltipComponent as default };
