import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import amenitiesLookup from '../../settings/amenities-lookup';
import { ROOT } from '../../settings/routes';
import { AMENITIES_ROUTE } from '../../settings';
import Heading from '../../components/heading';
import { dataByMarket } from './market-data';
import { mdUp } from '../../styles/theme/breakpoints';

const Amenities = ({ marketSlug }) => {
  const marketData = dataByMarket[marketSlug];

  return (
    <div className="Container">
      <Heading
        center
        level="2"
        mobileScheme="landing"
        style={{
          color: 'black',
          width: '100%',
          marginTop: 20,
          marginBottom: 30,
        }}
      >
        Find Coworking Space With the Amenities and Your Business Needs in{' '}
        {marketData?.name || ''}
      </Heading>
      <List>
        {Object.keys(amenitiesLookup)
          .filter(
            item =>
              !(amenitiesLookup[item] && amenitiesLookup[item].isWorkspace),
          )
          .map(item => {
            const amenity = amenitiesLookup[item];
            const Icon = amenity?.icon;

            return (
              <li key={item} className="AmenitiesList-item">
                <Link to={`/${ROOT}/${marketSlug}/?${AMENITIES_ROUTE}=${item}`}>
                  {Icon && <Icon iconWidth={amenity?.size?.width} />}
                  <span>{amenity.title}</span>
                </Link>
              </li>
            );
          })}
      </List>
    </div>
  );
};

const List = styled.ul`
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: 18px;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;

  @media ${mdUp} {
    font-size: 24px;
    column-count: 2;
    max-width: 95%;
  }

  li a {
    display: flex;
    align-items: center;
  }

  svg {
    width: 1em;
    height: auto;
    max-height: 1em;

    margin-right: 0.8em;
  }
`

export default Amenities;
