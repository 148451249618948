import React from 'react'
import isEmpty from 'lodash/isEmpty'

import CompareSpaces from './compare-spaces'

import {
  CompareColumn,
  CompareTitleMobile
} from '../../components/compare-page-helpers'

const CompareSpacesMobile = props => {
  const { paginate, listings, spaceByListingId, spaces, isProposal } = props
  const listing =
    paginate >= 0 && paginate <= listings.length - 1 ? listings[paginate] : {}

  return (
    !isEmpty(listing) && (
      <>
        <CompareTitleMobile>Office/Suite</CompareTitleMobile>
        <CompareColumn className="-mobile">
          <CompareSpaces
            isProposal={isProposal}
            spaces={spaces[listing.id]}
            activeId={spaceByListingId[listing.id]}
            onSpaceChanged={(id) => {
              props.setSpaceByListingId({
                ...spaceByListingId,
                [listing.id]: id
              })
            }}
          />
        </CompareColumn>
      </>
    )
  )
}

export default CompareSpacesMobile
