import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';

import BgImage from '../../components/bg-image';
import { VirtualTourButton } from '../../components/virtual-tour-button';
import Slider from '../../components/slider';

import { openInteractiveTourModal } from '../../actions/modal';

import { imageUrl } from '../../utils/crm-links';
import getInteriorImages from '../../utils/listings';
import { trackVirtualTourModal } from '../../utils/analytics';
import { WORKPLACE, ROOT } from '../../settings/routes';
import { BASE_URL } from '../../settings'

export default props => {
  const {
    listing,
    listing: { listingCardImageId, listingCardAltText },
    isWorkplace,
    visitorId,
    isEmbed
  } = props;
  const dispatch = useDispatch();

  const interiourImages = [
    { value: listingCardImageId, alt: listingCardAltText },
    ...getInteriorImages(listing),
  ];

  return (
    !isEmpty(interiourImages) && (
      <Slider listing={listing} autoplay wrapAround>
        {interiourImages.map((item, index) => (
          <Fragment key={`img-${item}`}>
            <div>
              <BgImage
                alt={item.alt}
                url={imageUrl(item.value)}
                hero
                mobileScheme="detailsHero"
              />
              {isEmbed && listing?.marketSlug && listing?.marketplaceSlug && (
                // eslint-disable-next-line
                <a
                  href={`${BASE_URL}/${isWorkplace ? WORKPLACE : ROOT}/${listing.marketSlug}/${listing.marketplaceSlug}/`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: '100%',
                    display: 'block'
                  }}
                ></a>
              )}
              {!isWorkplace && listing.virtualTour && index === 0 && (
                <VirtualTourButton
                  onClick={() => {
                    trackVirtualTourModal(window.location.pathname);
                    dispatch(
                      openInteractiveTourModal({
                        tourUrl: listing.virtualTour,
                        initialValues: {
                          locations: [listing.id],
                          isWorkspace: [false],
                          visitorId,
                        },
                      }),
                    );
                  }}
                />
              )}
            </div>
          </Fragment>
        ))}
      </Slider>
    )
  );
};
