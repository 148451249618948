import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

const Button = (props) => {
  const {
    className,
    style,
    children,
    type,
    loading,
    color,
    disabled,
    onClick,
    fullWidth,
    scheme,
    size,
    spaceBelow,
  } = props;

  const classes = classNames(
    'button',
    `-color-${color}`,
    `-scheme-${scheme}`,
    `-size-${size}`,
    `-spaceBelow-${spaceBelow}`,
    className, {
      '-loading': loading,
      '-fullWidth': fullWidth,
    },
  );

  return (
    <button
      type={type}
      className={classes}
      style={style}
      disabled={disabled}
      onClick={onClick}
    >
      {children &&
        <span className="button-text">{children}</span>
      }
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  scheme: PropTypes.string,
  spaceBelow: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

Button.defaultProps = {
  className: null,
  type: 'button',
  color: 'default',
  loading: false,
  fullWidth: false,
  scheme: 'default',
  size: 'default',
  spaceBelow: 'default',
};

export default Button;
