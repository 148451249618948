import React from 'react';
import styled from 'styled-components';

import Tooltip from '../tooltip';


import { amenitiesWorkplaceLookup } from '../../settings/amenities-lookup';

const List = styled.ul`
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Item = styled.li`
  &:not(:last-child) {
    margin-right: 2px;

    ${props => props.size && props.size === 'medium' && `
      margin-right: 5px;
    `}

    ${props => props.size && props.size === 'large' && `
      margin-right: 5px;
    `}
  }

  svg {
    width: 18px;
    height: 18px;
    fill: #57ab83;
    color: #57ab83;

    ${props => props.size && props.size === 'medium' && `
      width: 20px;
      height: 20px;
    `}

    ${props => props.size && props.size === 'large' && `
      width: 22px;
      height: 22px;
    `}
  }
`;


const AmenitiesList = ({ amenities, size }) => (
  <List>
    {amenities.map(item => {
      if (amenitiesWorkplaceLookup[item.key]) {
        const Icon = amenitiesWorkplaceLookup[item.key];
        return (
          <Item size={size} key={item.key} className="AmenitiesWorkplaceList-item">
            {item.value && (
              <Tooltip
                overlayClassName="AmenitiesWorkplaceList-tooltip"
                overlay={item.value}
                maxWidth={250}
                overlayStyle={{ textAlign: 'center', padding: 13 }}
              >
                <div>
                  <Icon />
                </div>
              </Tooltip>
            )}
            {!item.value && <Icon />}
          </Item>
        );
      }
      return null;
    })}
  </List>
);

export default AmenitiesList;

