import React from 'react';
import classnames from 'classnames';

import Heading from '../heading';
import { imageUrl } from '../../utils/crm-links';

import './styles.scss';

const AdvisorCard = ({ markets = [], nearestMarket, searchPage }) => {
  const market = markets[nearestMarket];

  if (!market) {
    return null;
  }

  return (
    <div className={classnames('AdvisorCard', { '-searchPage': searchPage })}>
      {market.marketRepPhotoId && (
        <img
          className="AdvisorCard-image"
          src={imageUrl(market.marketRepPhotoId)}
          alt={market.marketContactFirstName}
        />
      )}
      <div className="AdvisorCard-meta">
        {market.marketContactFirstName && (
          <Heading level="2" size="medium" spaceBelow="none">
            {`${market.marketContactFirstName}`}
          </Heading>
        )}
        <span className="AdvisorCard-description">
          Coworking Advisor
          <br />
          {market.marketContactPhone && (
            <>
              <a
                className="AdvisorCard-link"
                href={`tel:${market.marketContactPhone}`}
              >
                {market.marketContactPhone}
              </a>
              <br />
            </>
          )}
          {market.marketContactEmail && (
            <>
              <a
                className="AdvisorCard-link"
                href={`mailto:${market.marketContactEmail}`}
              >
                {market.marketContactEmail}
              </a>
              <br />
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default AdvisorCard;
