import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import MainPageList from '../MainPageList';
import Map from './map';

import Flex from '../../components/flex';
import Heading from '../../components/heading';
import { googleAnalytics } from '../../utils/analytics';
import { Desktop, Mobile } from '../../components/responsive-helpers';
import MainLocSelect from '../../components/select-main-loc';

import { ROOT, NEW_LOC } from '../../settings/routes';
import { BASE_URL } from '../../settings';
import { options } from '../../settings/main-select-options';

const NewLocations = ({ location: { pathname }, match: { path } }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    googleAnalytics(pathname, 'Upsuite | New Locations');
  }, [pathname]);

  return (
    <Flex column shrink fullHeight stickyFooter>
      <Helmet>
        <title>New Locations</title>
        <link rel="canonical" href={`${BASE_URL}/${ROOT}/${NEW_LOC}/`} />
      </Helmet>

      <div style={{ paddingLeft: 15, marginTop: 20 }}>
        <Heading level="1" center={false} spaceBelow="small" size="xsmall">
          New Locations
        </Heading>
      </div>

      <MainLocSelect
        value={path}
        options={options}
        onChange={({ value }) => dispatch(push(value))}
      />

      <Desktop>
        <Map style={{ height: 450 }} zoom={4} />
      </Desktop>
      <Mobile>
        <Map style={{ height: 250 }} zoom={3} />
      </Mobile>
      <MainPageList />
    </Flex>
  );
};

export default NewLocations;
