import forIn from 'lodash/forIn';
import fromPairs from 'lodash/fromPairs';
import chunk from 'lodash/chunk';
import slice from 'lodash/slice';
import split from 'lodash/split';
import replace from 'lodash/replace';
import map from 'lodash/map';

import {
  LISTINGS_FILTER_PARAMS as filterParams,
} from '../settings';

export const getFilterString = (matchParams, coords) => {
  let filterString = '';

  forIn(coords, (value, key) => {
    filterString += `&${key}=${value}`;
  });

  forIn(matchParams, (value, key) => {
    if ((value && key !== 'spaces') || key === 'is_workplace') {
      filterString += `&${key}=${value}`;
    }
  });

  return filterString;
};

export const urlToParams = pathname =>
  fromPairs(chunk(map(slice(split(pathname, '/'), 1), item => replace(item, '%2F', '/')), 2));

export const urlFilterQuery = filterQuery => filterParams.reduce((acc, curr) => {
  if (filterQuery[curr]) {
    const escaped = replace(filterQuery[curr], /\//g, '%2F');
    acc.push(`${curr}/${escaped}`);
  }

  return acc;
}, []).join('/');

export const isFilterQueryEmpty = (params) => {
  let empty = true;
  forIn(params, (value, key) => {
    if (value && key !== 'spaces') {
      empty = false;
    }
  });

  return empty;
};
