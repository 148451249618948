import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { ModalBody } from '../../components/modal';
import HubspotForm from '../../components/hubspot-form';
import Spinner from '../../components/spinner';

import { hideModal } from '../../actions/modal';

import gtmEvent from '../../utils/analytics';

import { ANALYTICS_ENABLED as analitics } from '../../settings';

class CTAModal extends Component {
  state = {
    formReady: false,
  }

  render() {
    return (
      <ModalBody small withHeight>
        {!this.state.formReady &&
          <Spinner size="medium" center />
        }
        <HubspotForm
          title="Visit these spaces"
          portalId="4312546"
          formId="25854b78-2ed3-4041-a9bb-d220f590c319"
          withBg
          setFormValues={{
            coworking_location_of_interest: this.props.listingName,
            operating_market: this.props.marketName,
          }}
          onFormSubmit={() => {
            if (analitics) {
              ReactGA.event({
                category: 'Form',
                action: 'submitted',
                label: 'tour request form submitted',
              });

              gtmEvent('Tour Request Form Submitted');
            }
          }}
          onFormStarted={() => {
            if (analitics) {
              ReactGA.event({
                category: 'Form',
                action: 'started',
                label: 'tour request form started',
              });

              gtmEvent('Tour Request Form Started');
            }
          }}
          onFormReady={() => this.setState({ formReady: true })}
        />
      </ModalBody>
    );
  }
}

export default connect(
  null, { hideModal },
)(CTAModal);
