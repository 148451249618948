import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import Button from '../button';
import amenitiesLookup from '../../settings/amenities-lookup';
import { getLocCleaningPoliciesFile } from '../../utils/crm-links';

import './styles.scss';

const AmenitiesList = ({ amenities, route }) => (
  <ul className="AmenitiesList">
    {Object.keys(amenities).map(item =>
      <AmenityItem key={item} link={`${route}/${item}/`} amenity={amenities[item]} />)}
  </ul>
);

const AmenityItem = ({ amenity: { title, icon }, link }) => {
  const Icon = icon;
  return (
    <AmenitiesListItem alignCenter>
      <Link to={link}><Icon /><span>{title}</span></Link>
    </AmenitiesListItem>
  );
};

const AmenitiesListItem = (props) => {
  const {
    className,
    children,
    disabled,
    alignCenter,
    alignAbsolute,
  } = props;

  const classes = classnames(
    className,
    'AmenitiesList-item',
    { '-disabled': disabled },
    { '-alignCenter': alignCenter },
    { '-alignAbsolute': alignAbsolute },
  );

  return (
    <li className={classes}>
      {children}
    </li>
  );
};

AmenitiesListItem.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  alignCenter: PropTypes.bool,
  alignAbsolute: PropTypes.bool,
};

AmenitiesListItem.defaultProps = {
  className: null,
  disabled: false,
  alignCenter: false,
  alignAbsolute: false,
};

const AmenityRender = props => {
  const item = amenitiesLookup[props.item];
  if (!item) return null;

  const Icon = item.icon;

  return (
    <AmenitiesListItem key={item.title} alignAbsolute>
      {item.link && props.cleaningPoliciesPDFLink ? (
        <a
          href={getLocCleaningPoliciesFile(props.locationId)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon linkable iconWidth={item?.size?.width} />
          <span>{item.title}</span>
        </a>
      ) : (
        <>
          <Icon iconWidth={item?.size?.width} />
          <span>{item.title}</span>
        </>
      )}
    </AmenitiesListItem>
  );
};

export const TwoColumnsAmenityList = ({ availableAmenities, cleaningPoliciesPDFLink, locationId }) => {
  const sortedAmenities = availableAmenities.sort();

  return (
    <ul className="ExpandableAmenitiesList">
      {sortedAmenities.map(item => (
        <AmenityRender key={item} item={item} cleaningPoliciesPDFLink={cleaningPoliciesPDFLink} locationId={locationId} />
      ))}
    </ul>
  );
};

export class ExpandableAmenitiesList extends Component {
  constructor() {
    super();
    this.state = {
      expanded: false,
    };
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    if (this.props.availableAmenities !== nextProps.availableAmenities) {
      this.setState({ expanded: false });
    }
  }

  handleExpand() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  render() {
    const { availableAmenities, cleaningPoliciesPDFLink, locationId } = this.props;
    const { expanded } = this.state;
    const sortedAmenities = availableAmenities.sort();

    return (
      <div>
        <ul className="ExpandableAmenitiesList">
          {sortedAmenities.map(
            (item, index) =>
              ((!expanded && index < 6) || expanded) && (
                <CSSTransition
                  key={item}
                  in
                  timeout={0}
                  classNames="fadeIn"
                  mountOnEnter
                  appear
                >
                  <AmenityRender item={item} cleaningPoliciesPDFLink={cleaningPoliciesPDFLink} locationId={locationId} />
                </CSSTransition>
              ),
          )}
        </ul>
        {!expanded && availableAmenities.length > 6 && (
          <Button scheme="bordered-link" onClick={() => this.handleExpand()}>
            See All Amenities
          </Button>
        )}
      </div>
    );
  }
}

export default AmenitiesList;
