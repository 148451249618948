import React from 'react';
import AdvisorBlock from '../advisor-block';

import './styles.scss';

const Panel = ({ id }) => (
  <div className="Panel">
    <div className="Panel-inner">
      <span className="Panel-text" id={id}>
        You are searching for a location that Upsuite isn&apos;t showing any listings online.
        But, with our national relationships and hard working Advisor Team, help is on the way.
        <br /><br />
        Contact an Upsuite Advisor to run a custom search for your preferred location,
        all free of charge to you!
      </span>
    </div>
    <AdvisorBlock />
  </div>
);

export default Panel;
