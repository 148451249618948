import { createReducer } from '../utils';
import {
  CONCIERGE_REVIEWS_GET_REQUEST,
  CONCIERGE_REVIEWS_GET_SUCCESS,
  CONCIERGE_REVIEWS_GET_FAILURE,
  GAPIS_GET_REVIEWS_SUCCESS,
  BIND_MAP_TO_STATE,
} from '../constants';

const initialState = {
  conciergeReviewsById: {},
  greviewsById: {},
  loading: false,
  error: false,
  map: null,
  maps: null,
};

export default createReducer(initialState, {
  [CONCIERGE_REVIEWS_GET_REQUEST]: state => ({
    ...state,
    loading: true,
    error: false,
  }),
  [CONCIERGE_REVIEWS_GET_SUCCESS]: (state, { list }, id) => ({
    ...state,
    conciergeReviewsById: {
      ...state.conciergeReviewsById,
      [id]: list,
    },
    loading: false,
    error: false,
  }),
  [CONCIERGE_REVIEWS_GET_FAILURE]: state => ({
    ...state,
    loading: false,
    error: true,
  }),
  [GAPIS_GET_REVIEWS_SUCCESS]: (state, { detailsResponse, id }) => ({
    ...state,
    greviewsById: {
      ...state.greviewsById,
      [id]: detailsResponse,
    },
  }),
  [BIND_MAP_TO_STATE]: (state, payload) => ({
    ...state,
    ...payload,
  }),
});
