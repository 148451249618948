import React from 'react';
import { Field, reduxForm } from 'redux-form';

import Button from '../../../components/button';
import { renderField } from '../../../components/form-fields';
import { required } from '../../../utils/lead-validation'

const SaveShortlistForm = (props) => {
  const { handleSubmit, disabled, placeholder } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Field
        component={renderField}
        name="shortlistName"
        id="shortlistName"
        type="text"
        placeholder={placeholder || 'Shortlist Name'}
        label="Shortlist Name"
        required
        disabled={disabled}
        validate={[required]}
      />
      <Button type="submit" disabled={disabled}>
        Save Shortlist
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'save-shortlist-form'
})(SaveShortlistForm)
