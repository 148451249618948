import React, { Component } from 'react';
import classnames from 'classnames';

import './styles.scss';

export default class HubspotForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formStarted: false,
      formReady: false,
    };
  }

  componentDidMount() {
    let script = document.getElementById('hubspotFormScript');
    import('./jquery-slim').then(({ $ }) => {
      window.jQuery = window.$ = $; // eslint-disable-line
      if (!script) {
        script = document.createElement('script');
        script.setAttribute('id', 'hubspotFormScript');
        script.src = '//js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
          this.initForm($);
        });
      } else {
        this.initForm($);
      }
    });
  }

  initForm($) {
    // eslint-disable-next-line max-len
    const {
      portalId, formId, onFormSubmit, onFormStarted,
      setFormValues, target, onFormReady, onFormSubmitted, region
    } = this.props;
    const rt = this;

    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId,
        formId,
        region,
        target: target ? `.hubspotForm-${target}` : `.hubspotForm-${formId}`,
        onFormReady($form) {
          $form.find('input').each((dx, el) => $(el).on('change', (e) => {
            if (e.target.value && !rt.state.formStarted) {
              if (onFormStarted) {
                onFormStarted();
              }

              rt.setState({
                formStarted: true,
              });
            }
          }));

          rt.setState({
            formReady: true,
          });

          if (onFormReady) {
            onFormReady();
          }

          if (setFormValues) {
            Object.keys(setFormValues)
              .forEach(name =>
                $(`input[name='${name}']`).val(setFormValues[name]),
              );
          }
        },
        onFormSubmit() {
          if (onFormSubmit) {
            onFormSubmit();
          }
        },
        onFormSubmitted() {
          if (onFormSubmitted) {
            onFormSubmitted();
          }
        },
      });
    }
  }

  render() {
    const {
      className,
      withBg,
      fitContainer,
      title,
      formId,
      oneline,
      singleCta,
      target,
      fixedHeight,
    } = this.props;

    const { formReady } = this.state;

    const classes = classnames(
      className,
      'HubspotForm',
      { '-withBg': withBg },
      { '-fitContainer': fitContainer },
      { '-oneline': oneline },
      { '-singleCta': singleCta },
      { '-fixedHeight': fixedHeight },
    );

    return (
      <div className={classes}>
        {title && formReady &&
          <h2>{title}</h2>
        }
        <div className={target ? `hubspotForm-${target}` : `hubspotForm-${formId}`} />
      </div>
    );
  }
}
