import React from 'react';

import { ModalBody } from '../../components/modal';
import Slider from '../../components/slick-slider';

const SliderModal = (props) => (
  <ModalBody large>
    <Slider
      slidesToShow={1}
      slidesToScroll={1}
      autoplay
      speed={2000}
      infinite
    >
      {props.images.map(item => (
        <div key={item.url} className="slick-image-wrapper">
          <img src={item.url} alt={item.alt} />
        </div>
      ))}
    </Slider>
  </ModalBody>
);

export default SliderModal;
