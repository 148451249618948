import React from 'react';
import { Logo } from '../icons';

import './styles.scss';

const Promo = (props) => {
  const {
    heading,
  } = props;

  return (
    <div className="PromoSingle">
      <span className="PromoSingle-subheading">Exclusive<Logo />Promotion</span>
      <span className="PromoSingle-heading">{heading}</span>
    </div>
  );
};

export default Promo;
