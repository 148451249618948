import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ModalBody } from '../../components/modal';
import HubspotForm from '../../components/hubspot-form';
import Spinner from '../../components/spinner';

import { hideModal } from '../../actions/modal';

class CTAModal extends Component {
  state = {
    formReady: false,
  }

  render() {
    return (
      <ModalBody small withHeight>
        {!this.state.formReady &&
          <Spinner size="medium" center />
        }
        <HubspotForm
          title="Book a Free Day"
          portalId="4312546"
          formId="d0d6cb1a-5dd0-46d1-ae41-5bac00a7c225"
          withBg
          setFormValues={{
            coworking_location_of_interest: this.props.listingName,
            operating_market: this.props.marketName,
          }}
          onFormReady={() => this.setState({ formReady: true })}
        />
      </ModalBody>
    );
  }
}

export default connect(
  null, { hideModal },
)(CTAModal);
