import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import trim from 'lodash/trim';
import some from 'lodash/some';
import min from 'date-fns/min';

const mapAvailable = spaces => some(spaces, 'isAvailable');

const dateAvailableMap = spaces => min(...spaces.map(o => o.dateAvailable));

const mapMinMax = (spaces, by) => {
  const minSpace = minBy(spaces, by);
  const maxSpace = maxBy(spaces, by);

  if (!minSpace || !maxSpace) {
    return null;
  }

  if (!minSpace[by] && !minSpace[by]) {
    return null;
  }

  if (!minSpace && maxSpace && maxSpace[by]) {
    return maxSpace[by];
  }

  if (!maxSpace && minSpace && minSpace[by]) {
    return minSpace[by];
  }

  if (minSpace[by] === maxSpace[by]) {
    return minSpace[by];
  }

  return [minSpace[by], maxSpace[by]];
};

export const groupSpaces = (spaces) => {
  const typeCapacity = groupBy(spaces, ({ spaceType, spaceCapacity }) => `${trim(spaceType)} ${spaceCapacity}`);

  return reduce(typeCapacity, (acc, curr) => {
    if (curr.length <= 1) {
      acc.push(curr[0]);
    } else {
      acc.push(Object.assign({}, curr[0], {
        spacePrice: mapMinMax(curr, 'spacePrice'),
        sqFt: mapMinMax(curr, 'sqFt'),
        isAvailable: mapAvailable(curr),
        dateAvailable: dateAvailableMap(curr),
      }));
    }

    return acc;
  }, []);
};
