import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Container, Cols, Col } from '../../components/wrapper';
import Flex from '../../components/flex';
import Heading from '../../components/heading';
import Box from '../../components/scalable-box';

import BottomNavbar from '../BottomNav';

import teamtypes from '../../settings/teamtypes';

import {
  ROOT,
} from '../../settings/routes';

export default () => (
  <Flex column shrink>
    <Helmet>
      <title>Match</title>
      <meta name="description" content="Finding the perfect fit coworking office space can be challenging. There are many factors to consider and Upsuite has categorized many coworking spaces based on the type of team you have. Browse our coworking space categories to get started." />
      <link rel="canonical" href={`${window.location.origin}/${ROOT}/match/`} />
    </Helmet>
    <Container>
      <Heading
        level="1"
        size="medium"
        spaceBelow="medium"
      >Browse spaces that match your team</Heading>
      <Cols wrap>
        {teamtypes.map(({ title, image }) => (
          <Col key={title} onefourth onethirdMd halfSm fullWidthMobile>
            <Box bgImage={image} spaceBelow="medium">
              <Link to={`/${ROOT}/`}>{title}</Link>
            </Box>
          </Col>
        ))}
      </Cols>
    </Container>
    <BottomNavbar />
  </Flex>
);
