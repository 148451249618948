/* global document, window */
import {
  FIXED_HEADER_ON,
  FIXED_HEADER_OFF,
  HIGHLIGHT_LISTING,
  HIGHLIGHT_LISTING_RESET,
  OPEN_MOBILE_MAIN_NAV,
  CLOSE_MOBILE_MAIN_NAV,
  HIGHLIGHT_MARKER,
  HIGHLIGHT_MARKER_RESET,
  PIN_MARKER,
  PIN_MARKER_RESET,
  COMPARE_MOBILE_PAGINATE,
  COMPARE_SPACE_BY_LISTING,
  RESET_TRANSITION_COUNT,
  BIND_SCROLL_TO,
  CHANGE_SORT_METHOD,
  MOBILE_FIXED_HEADER_ON,
  MOBILE_FIXED_HEADER_OFF,
  SET_COMPARE_CARDS_HEIGHT,
  TOGGLE_FEED_MAP_VISIBILITY,
  TOGGLE_SPACE_TYPE,
  TOGGLE_SPACE_TYPE_RESET
} from '../constants';
import { bodyClassAdd, bodyClassRemove } from '../utils/ui';

export const fixedHeaderOn = () => ({
  type: FIXED_HEADER_ON,
});

export const spaceTypeChange = () => ({
  type: TOGGLE_SPACE_TYPE,
});

export const spaceTypeReset = () => ({
  type: TOGGLE_SPACE_TYPE_RESET,
});

export const fixedHeaderOff = () => ({
  type: FIXED_HEADER_OFF,
});

export const mobileFixedHeaderOn = () => ({
  type: MOBILE_FIXED_HEADER_ON,
});

export const mobileFixedHeaderOff = () => ({
  type: MOBILE_FIXED_HEADER_OFF,
});

export const highlightListing = (payload) => {
  const scrollToElement = document.getElementById(payload);
  window.scrollTo(0, scrollToElement.offsetTop - 145);

  return {
    type: HIGHLIGHT_LISTING,
    payload,
  };
};

export const highlightListingReset = () => ({
  type: HIGHLIGHT_LISTING_RESET,
});

export const highlightMarker = payload => ({
  type: HIGHLIGHT_MARKER,
  payload,
});

export const highlightMarkerReset = () => ({
  type: HIGHLIGHT_MARKER_RESET,
});

export const pinMarker = payload => ({
  type: PIN_MARKER,
  payload,
});

export const pinMarkerReset = () => ({
  type: PIN_MARKER_RESET,
});

export const openMobileMainNav = () => {
  bodyClassAdd('scroll-lock');

  return ({
    type: OPEN_MOBILE_MAIN_NAV,
  });
};

export const сloseMobileMainNav = () => {
  bodyClassRemove('scroll-lock');

  return ({
    type: CLOSE_MOBILE_MAIN_NAV,
  });
};

export const compareMobilePaginate = payload => ({
  type: COMPARE_MOBILE_PAGINATE,
  payload,
});

export const compareSpaceByListing = (listingId, index) => ({
  type: COMPARE_SPACE_BY_LISTING,
  payload: { listingId, index },
});

export const resetTransitionCount = () => ({
  type: RESET_TRANSITION_COUNT,
});

export const bindScrollTo = payload => ({
  type: BIND_SCROLL_TO,
  payload,
});

export const changeSortMethod = payload => ({
  type: CHANGE_SORT_METHOD,
  payload,
});

export const setCompareCardsHeight = payload => ({
  type: SET_COMPARE_CARDS_HEIGHT,
  payload,
});

export const toogleFeedMapVisibility = payload => ({
  type: TOGGLE_FEED_MAP_VISIBILITY,
  payload,
});
