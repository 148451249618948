import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import SpacesList from '../SpacesList'
import ProfileMenu from './menu'
import { Wrap } from './styles'

const SavedSpaces = () => {
  const { slug } = useParams()
  const spaces = useSelector(state => state.lead.savedSpaces.list.find(item => item.id === slug) || {})
  const { status, error } = useSelector(state => state.lead.savedSpaces)
  const { list } = useSelector(state => state.lead.proposal)
  const isProposal = spaces.name === 'Proposed Shortlist'

  return (
    <Wrap>
      <ProfileMenu />
      {spaces && (
        <SpacesList
          isProposal={isProposal}
          ids={isProposal ? list.map(item => item.LocationId) : spaces.spaces || []}
          title={spaces.name}
          slug={slug}
          status={status}
          error={error}
        />
      )}
    </Wrap>
  )
}

export default SavedSpaces
