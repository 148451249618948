/* global localStorage */
import { createReducer } from '../utils';
import {
  TOGGLE_FAVORITES,
  TOGGLE_WORKPLACE_FAVORITES,
  BULK_SET_FAVORITES,
  FAVORITES_GET_REQUEST,
  FAVORITES_GET_SUCCESS,
  FAVORITES_GET_FAILURE,
} from '../constants';

const initialState = {
  ids: JSON.parse(localStorage.getItem('favorites')) || [],
  workplaceIds: JSON.parse(localStorage.getItem('favorite_workplaces')) || [],
  loading: false,
  error: false,
};

export default createReducer(initialState, {
  [TOGGLE_FAVORITES]: (state, { ids }) => ({
    ...state,
    ids,
  }),
  [TOGGLE_WORKPLACE_FAVORITES]: (state, { ids }) => ({
    ...state,
    workplaceIds: ids,
  }),
  [BULK_SET_FAVORITES]: (state, payload) => ({
    ...state,
    ids: payload,
  }),
  [FAVORITES_GET_REQUEST]: state => ({
    ...state,
    loading: true,
    error: false,
  }),
  [FAVORITES_GET_SUCCESS]: state => ({
    ...state,
    loading: false,
    error: false,
  }),
  [FAVORITES_GET_FAILURE]: state => ({
    ...state,
    loading: false,
    error: true,
  }),
});
