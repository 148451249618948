import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Spinner from '../../components/spinner';
import Flex from '../../components/flex';

import {
  USER_MANAGEMENT as userManagement,
} from '../../settings';

class CognitoRedirect extends Component {
  componentDidMount() {
    import('../../actions/suspenseAuth').then(({ initCognitoSDK }) => {
      const auth = initCognitoSDK();
      auth.parseCognitoWebResponse(window.location.href);
      auth.getSession();
      if (auth.isUserSignedIn()) {
        this.props.push(`/${userManagement}`);
      }
    });
  }

  render() {
    return (
      <Flex>
        <Spinner size="large" center />
      </Flex>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth.auth,
  isUserSignedIn: state.auth.isUserSignedIn,
  isOperator: state.auth.isOperator,
  isAdmin: state.auth.isAdmin,
});

export default connect(
  mapStateToProps,
  { push },
)(CognitoRedirect);
