import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

const Flex = (props) => {
  const {
    column,
    reverse,
    wrap,
    start,
    center,
    end,
    top,
    middle,
    bottom,
    around,
    between,
    className,
    children,
    fullHeight,
    fullHeightDesktop,
    fullWidth,
    shrink,
    grow,
    columnMobile,
    columnReverseMobile,
    style,
    stickyFooter,
  } = props;

  const classes = classNames({
    flex: true,
    '-column': column,
    '-reverse': reverse,
    '-wrap': wrap,
    '-start': start,
    '-center': center,
    '-end': end,
    '-top': top,
    '-middle': middle,
    '-bottom': bottom,
    '-around': around,
    '-between': between,
    '-fullHeight': fullHeight,
    '-fullHeightDesktop': fullHeightDesktop,
    '-fullWidth': fullWidth,
    '-shrink': shrink,
    '-grow': grow,
    '-columnMobile': columnMobile,
    '-columnReverseMobile': columnReverseMobile,
    '-sf': stickyFooter,
  }, className);

  return (
    <div className={classes} style={style}>{children}</div>
  );
};

Flex.propTypes = {
  column: PropTypes.bool,
  reverse: PropTypes.bool,
  wrap: PropTypes.bool,
  start: PropTypes.bool,
  center: PropTypes.bool,
  end: PropTypes.bool,
  top: PropTypes.bool,
  middle: PropTypes.bool,
  bottom: PropTypes.bool,
  around: PropTypes.bool,
  between: PropTypes.bool,
  fullHeight: PropTypes.bool,
  fullHeightDesktop: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  shrink: PropTypes.bool,
  grow: PropTypes.bool,
  columnMobile: PropTypes.bool,
  columnReverseMobile: PropTypes.bool,
};

Flex.defaultProps = {
  column: false,
  reverse: false,
  wrap: false,
  start: false,
  center: false,
  end: false,
  top: false,
  middle: false,
  bottom: false,
  around: false,
  between: false,
  fullHeight: false,
  fullHeightDesktop: false,
  fullWidth: false,
  className: null,
  children: null,
  shrink: false,
  grow: false,
  columnMobile: false,
  columnReverseMobile: false,
  stickyFooter: false,
};

export default Flex;
