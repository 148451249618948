import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import format from 'date-fns/format';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ProfileMenu from './menu'
import { Wrap } from './styles'
import Flex from '../../components/flex'
import Heading from '../../components/heading'
import { searchDelete, searchesGet, getSearchUrl } from '../../actions/lead'
import { SearchIcon } from '../../components/icons'
import amenitiesLookup from '../../settings/amenities-lookup';
import teamtypesLookup from '../../settings/teamtypes';

const getFilters = (data) => {
  const amenities = ((data.amenities || '').split(',')) || [];
  const teamtype = ((data.teamtype || '').split(',')) || [];
  const resultAmenities = amenities
    .map(slug => (amenitiesLookup[slug] || {}).title);
  const resultTeamtypes = teamtypesLookup
    .filter(item => teamtype.includes(item.slug))
    .map(({ title }) => title);

  return [...resultAmenities, ...resultTeamtypes]
    .filter(Boolean)
    .join(', ')
}

const Searches = () => {
  const dispatch = useDispatch()
  const lead = useSelector(state => state.lead)
  const title = `Saved Searches${
    lead.firstName || lead.lastName
      ? ` for ${lead.firstName || ''} ${lead.lastName || ''}`
      : ''
  }`

  useEffect(() => {
    dispatch(searchesGet())
  }, [dispatch])

  return (
    <Wrap>
      <ProfileMenu />
      <Flex column fullHeight grow stickyFooter>
        {title && (
          <Heading
            level="1"
            size="xsmall"
            spaceBelow="small"
            spaceAbove="medium"
          >
            {title}
          </Heading>
        )}
        <List>
          {lead.savedSearches.list.map(item => (
            <Item key={item.id}>
              <Icon />
              <Title>{item.name}</Title>
              <Meta>
                {[
                  item.isWorkspace ? 'Private Workspace' : 'Coworking Space',
                  item.seats ? `${item.seats} Seats` : null,
                  getFilters(item),
                  item.date ? format(item.date, 'MM/DD/YYYY') : null
                ]
                  .filter(item => item)
                  .join(' / ')}
              </Meta>
              <BtnGroup>
                <ViewBtn to={getSearchUrl(item)}>View Listings</ViewBtn>
                <DelBtn
                  onClick={() => dispatch(searchDelete(item.id))}
                >
                  Delete Search
                </DelBtn>
              </BtnGroup>
            </Item>
          ))}
        </List>
      </Flex>
    </Wrap>
  )
}

const List = styled.ul`
  list-style: none;

  padding-left: 10px;

  @media (min-width: 64em) {
    padding-left: 30px;
  }
`

const Item = styled.li`
  position: relative;
  padding-left: 26px;
  margin-bottom: 34px;
`

const BtnGroup = styled.div`
  display: flex;
`

const ViewBtn = styled(Link)`
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-weight: 600;
  background-color: var(--brand-color);
  box-shadow: 0 1em 1.9em rgb(0 0 0 / 16%);
  border-radius: 0.36em;
  margin-bottom: 0;
  font-size: 14px;
  margin-right: 20px;
  padding: 0 20px;
`

const DelBtn = styled.button.attrs({ type: 'button' })`
  text-align: center;
  background-color: #fff;
  color: var(--brand-color);
  padding: 0 20px;
  line-height: 1.3;
  border: 2px solid var(--brand-color);
  font-weight: 600;
  box-shadow: 0 0.4em 0.3em rgb(0 0 0 / 16%);
  border-radius: 0.36em;
  font-size: 14px;
  cursor: pointer;
`

const Meta = styled.p`
  color: black;
  margin-top: 0;
  font-weight: 600;
`

const Title = styled.p`
  color: #4F4F4F;
  font-size: 20px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 10px;
`

const Icon = styled(SearchIcon)`
  color: #4F4F4F;
  position: absolute;
  left: 0;
  top: 2px;
`

export default Searches
