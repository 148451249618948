import ReactGA from 'react-ga';
import { ANALYTICS_ENABLED as analitics, GTAG_ID as gtagId, ANALYTICS_DEBUG as analiticsDebug } from '../settings';

export default text => window.dataLayer && window.dataLayer.push({
  'event': text, // eslint-disable-line
});

export const googleAnalytics = (pathname, title) => {
  if (analitics) {
    ReactGA.initialize(gtagId, {
      debug: analiticsDebug
    });
    ReactGA.set({ page: pathname, title });
    ReactGA.pageview(pathname);
  }
};

export const ReactGAEvent = (data) => {
  if (analitics) {
    ReactGA.event(data);
  }
};

export const trackVirtualTourModal = (pathname) => {
  if (analitics) {
    ReactGA.event({
      category: 'Virtual Tour',
      action: 'Viewed',
      label: pathname,
    });
  }
}

export const trackFormsByType = (action, label) => {
  if (analitics) {
    ReactGA.event({
      category: 'Form Views',
      action,
      label
    });
  }
}

export const trackMemberRegistrationViews = () => {
  if (analitics) {
    ReactGA.event({
      category: 'Form Views',
      action: 'user',
      label: 'Member Registration Views'
    });
  }
};

export const trackMemberRegistrationViewsPhoneSeats = () => {
  if (analitics) {
    ReactGA.event({
      category: 'Form Views',
      action: 'user',
      label: 'Registration Form with Phone and Seats Views'
    });
  }
};

export const trackMemberRegistrationSubmissions = () => {
  if (analitics) {
    ReactGA.event({
      category: 'Form Submit',
      action: 'user',
      label: 'Member Registration Submissions'
    });
  }
};

export const trackMemberRegistrationSubmissionsPhoneSeats = () => {
  if (analitics) {
    ReactGA.event({
      category: 'Form Submit',
      action: 'user',
      label: 'Registration Form with Phone and Seats Submissions'
    });
  }
};

export const trackFormOptions = (action, label) => {

  if (analitics) {
    ReactGA.event({
      category: 'Form Options',
      action,
      label
    });
  }
}

export const trackFormSubmit = (action, label) => {

  if (analitics) {
    ReactGA.event({
      category: 'Form Submit',
      action,
      label
    });
  }
}

export const trackAdvisorBooking = (pathname) => {
  if (analitics) {
    ReactGA.event({
      category: 'Work with an Advisor',
      action: 'Viewed',
      label: pathname,
    });
  }
}
