import React from 'react';
import Slider from 'react-slick';

import './styles.scss';

export default ({ children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slider {...rest}>
    {children}
  </Slider>);
