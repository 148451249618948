import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import uniq from 'lodash/uniq';
import trim from 'lodash/trim';
import isArray from 'lodash/isArray';
import { CSSTransition } from 'react-transition-group';

import Button from '../button';
import Flex from '../flex';
import SpacesEditForm from '../spaces-edit-form';
import { Plus } from '../icons';
import { Default, Mobile } from '../responsive-helpers';

import {
  spaceAvailabilityFormat,
  spaceCapacityFormat,
  currencyBefore,
  spaceSqFtFormat,
  currencyFormat,
  applyPromo,
} from '../../utils/format';
import { sortSpacesMain, orderSpacesBy } from '../../utils/sort-spaces';

import './styles.scss';

export const Wrap = (props) => {
  const {
    className,
    children,
  } = props;

  const classes = classnames(
    className,
    'SpaceWrap',
  );

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

Wrap.propTypes = {
  className: PropTypes.string,
};

Wrap.defaultProps = {
  className: null,
};

export const Item = (props) => {
  const {
    className,
    name,
    spacePrice,
  } = props;

  const classes = classnames(
    className,
    'SpaceItem',
  );

  return (
    <div className={classes}>
      <div className="SpaceItem-inner">
        <h3 className="SpaceItem-heading">{name}</h3>
        {spacePrice &&
          <span className="SpaceItem-meta">Starting from ${spacePrice}</span>
        }
      </div>
    </div>
  );
};

Item.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  spacePrice: PropTypes.number,
};

Item.defaultProps = {
  className: null,
  spacePrice: null,
};

export class SpaceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'All',
      expanded: false,
      spaces: sortSpacesMain(props.spaces, props.spacesOwner),
      orderBy: undefined,
      orderDesc: true,
      editFormItemId: null,
      spacesUpdated: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.spacesOwner !== this.props.spacesOwner) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        orderBy: undefined,
        orderDesc: true,
        spaces: sortSpacesMain(this.props.spaces, this.props.spacesOwner),
      });
    }
  }

  activeTabHandler(activeTab) {
    if (this.state.activeTab !== activeTab) {
      this.setState({ activeTab });
    }
  }

  handleExpand() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  closeForm() {
    this.props.onClose();
    return this.props.mobile ? this.props.hideModal() : this.closeEditForm();
  }

  updateAfterChange() {
    this.setState(
      (state, props) => ({ spaces: state.orderBy
        ? orderSpacesBy(props.spaces, state.orderBy, state.orderDesc)
        : sortSpacesMain(props.spaces, props.spacesOwner),
      }),
      this.closeForm,
    );
  }

  updateAfterCreate() {
    this.setState(
      (state, props) => ({ spaces: sortSpacesMain(props.spaces, props.spacesOwner) }),
      this.closeForm,
    );
  }

  sortBy(by) {
    const { orderBy, orderDesc, spaces } = this.state;
    const isDesc = by === orderBy && !orderDesc ? !orderDesc : false;

    this.setState({
      orderBy: by,
      orderDesc: isDesc,
      spaces: orderSpacesBy(spaces, by, isDesc),
    });
  }

  openEditForm(id) {
    this.setState({ editFormItemId: id });
  }

  openDuplicateForm(currentItem) {
    this.setState({ editFormItemId: 'duplicate', currentItem });
  }

  closeEditForm() {
    this.setState({ editFormItemId: null });
  }

  filterBtnClasses(by) {
    return classnames(
      'SpaceList-filterBtn',
      { '-active': by === this.state.orderBy },
      { '-desc': this.state.orderDesc },
    );
  }

  spacesUpdatingEnd() {
    this.setState({ spacesUpdated: true });
  }

  renderItemForm(item) {
    const { mobile, openCreateModal } = this.props;

    return (
      <li className="SpaceList-item -editForm" key={item.id}>
        <SpacesEditForm
          onSubmit={formData => this.props.spaceUpdate(formData)}
          onClose={() => this.closeForm()}
          onDuplicate={() =>
            (mobile
              ? openCreateModal()
              : this.openDuplicateForm({
                ...item,
                name: `${item.name}_copy`,
                modifiedAt: null,
              }))
          }
          initialValues={item}
          form={`spaces-edit-form-${item.id}`}
          onRemove={() => this.props.spaceDelete(item.id, () => this.closeEditForm())}
        />
      </li>
    );
  }

  renderNewItemForm(initialValues = {}) {
    return (
      <li className="SpaceList-item -editForm" key="new">
        <SpacesEditForm
          onSubmit={formData => this.props.spaceCreate(formData)}
          onClose={() => this.closeForm()}
          initialValues={{
            isAvailable: true,
            units: 'sqFt',
            ...initialValues,
          }}
          form="spaces-edit-form-new"
        />
      </li>
    );
  }

  render() {
    const {
      className, canExpand, spaces, spacesOwner,
      mobile, openEditModal, openCreateModal, onAction,
      numberOfPeople, locked, onLockClick, onInteractiveTour,
      showSpaceTypeToggle, onSpaceTypeChange = () => {}, isClosed
    } = this.props;
    const {
      activeTab, expanded, spaces: items, editFormItemId, currentItem, spacesUpdated,
    } = this.state;
    const allTypes = ['All', ...uniq(spaces.map(item => trim(item.spaceType)))];
    const classes = classnames(
      className,
      'SpaceList',
    );

    const activeSpaces = items.filter(item => trim(item.spaceType) === activeTab || activeTab === 'All');
    const isFastOffice = items.some(item => item.isFastOffice && item.fastOfficeUrl);
    const isNotFastOffice = items.some(item => !item.isFastOffice);
    const showPerHour = activeTab === 'Meeting Space';

    return (
      <div className={classes}>
        {spacesOwner && (
          <Flex center end>
            <Button
              scheme="green"
              style={{ marginBottom: 10 }}
              onClick={() =>
                mobile ? openCreateModal() : this.openEditForm('new')
              }
            >
              <Plus />
              Add Space
            </Button>

            <Button
              scheme="green"
              style={{ marginBottom: 10, marginLeft: 10 }}
              onClick={() => {
                Promise.all(
                  activeSpaces.map(space => this.props.spaceUpdate(space)),
                ).then(() => this.spacesUpdatingEnd());
              }}
            >
              {spacesUpdated ? 'Confirmed' : 'Confirm Data'}
            </Button>
          </Flex>
        )}
        <div className="SpaceList-tabs">
          {allTypes.map(item => (
            <button
              type="button"
              className={classnames('SpaceList-tab', {
                '-active': item === activeTab,
              })}
              key={item}
              onClick={() => this.activeTabHandler(item)}
            >
              {item}
            </button>
          ))}
          {showSpaceTypeToggle && !isClosed && (
            <button
              type="button"
              className="SpaceList-typeToggle"
              onClick={() => onSpaceTypeChange()}
            >
              Show me Private Workspace Options
            </button>
          )}
        </div>
        <ul className="SpaceList-list">
          <li className="SpaceList-item">
            <div className="SpaceList-col">
              <button
                type="button"
                className={this.filterBtnClasses('type')}
                onClick={() => this.sortBy('type')}
              >
                Type
              </button>
            </div>
            <div className="SpaceList-col">
              <button
                type="button"
                className={this.filterBtnClasses('interior')}
                onClick={() => this.sortBy('interior')}
              >
                Window / <br />
                Interior
              </button>
            </div>
            <div className="SpaceList-col">
              <button
                type="button"
                className={this.filterBtnClasses('capacity')}
                onClick={() => this.sortBy('capacity')}
              >
                Suitable <br />
                For
              </button>
            </div>
            <div className="SpaceList-col">
              <button
                type="button"
                className={this.filterBtnClasses('price')}
                onClick={() => this.sortBy('price')}
              >
                Price <br />
                per Mo.
              </button>
            </div>
            <div className="SpaceList-col">
              <button
                type="button"
                className={this.filterBtnClasses('pricePromo')}
                onClick={() => this.sortBy('pricePromo')}
              >
                Upsuite <br /> Price
              </button>
            </div>
            {showPerHour ? (
              <div className="SpaceList-col">
                <button
                  type="button"
                  className={this.filterBtnClasses('pricePerHour')}
                  onClick={() => this.sortBy('pricePerHour')}
                >
                  Price <br />
                  per Hour
                </button>
              </div>
            ) : (
              <div className="SpaceList-col">
                <button
                  type="button"
                  className={this.filterBtnClasses('pricePerDay')}
                  onClick={() => this.sortBy('pricePerDay')}
                >
                  Price <br />
                  per Day
                </button>
              </div>
            )}
            <div className="SpaceList-col">
              <button
                type="button"
                className={this.filterBtnClasses('sqft')}
                onClick={() => this.sortBy('sqft')}
              >
                Sq/Ft
              </button>
            </div>
            <div className="SpaceList-col">
              <button
                type="button"
                className={this.filterBtnClasses('date')}
                onClick={() => this.sortBy('date')}
              >
                Date <br /> Available
              </button>
            </div>
            {!spacesOwner && !isClosed && (
              <div className="SpaceList-col -center">
                <span className="SpaceList-headText">
                  {!isFastOffice && isNotFastOffice && 'Tours'}
                  {isFastOffice && !isNotFastOffice && 'Virtual Tour'}
                </span>
              </div>
            )}
            {spacesOwner && <div className="SpaceList-col" />}
          </li>
          {editFormItemId === 'new' && !mobile && this.renderNewItemForm()}
          {activeSpaces.map(
            (item, index) =>
              ((!expanded && index < 7) || expanded || !canExpand) && (
                <CSSTransition
                  key={item.id}
                  in
                  timeout={0}
                  classNames="fadeIn"
                  mountOnEnter
                  appear
                >
                  <>
                    {(editFormItemId !== item.id || mobile) && (
                      <li
                        className={classnames('SpaceList-item', {
                          '-highlight':
                            (item.spaceCapacity || 1) === numberOfPeople,
                        })}
                        key={item.id}
                      >
                        {spacesOwner && (
                          <div className="SpaceList-col">
                            <span>{item.name}</span>
                          </div>
                        )}
                        <div className="SpaceList-col">
                          <span>{item.spaceType}</span>
                        </div>
                        <div className="SpaceList-col">
                          <span>{!item.interior ? 'Window' : 'Interior'}</span>
                        </div>
                        <div className="SpaceList-col">
                          <span>
                            <Mobile>
                              {spacesOwner && item.spaceCapacity}
                              {!spacesOwner && spaceCapacityFormat(item)}
                            </Mobile>
                            <Default>{spaceCapacityFormat(item)}</Default>
                          </span>
                        </div>
                        <div
                          className={classnames('SpaceList-col ', {
                            '-line-through': !spacesOwner,
                          })}
                        >
                          {!isArray(item.spacePrice) && (
                            <span>
                              {currencyBefore(currencyFormat(item.spacePrice))}
                            </span>
                          )}
                          {isArray(item.spacePrice) && (
                            <span>
                              {`${currencyBefore(
                                currencyFormat(item.spacePrice[0]),
                              )} -
                            ${currencyBefore(
                              currencyFormat(item.spacePrice[1]),
                            )}`}
                            </span>
                          )}
                        </div>
                        {!spacesOwner && (
                          <div className="SpaceList-col">
                            {locked ? (
                              <button
                                onClick={() => onLockClick(item)}
                                className="SpaceList-actionBtn"
                                type="button"
                              >
                                Unlock
                              </button>
                            ) : (
                              <>
                                {!isArray(item.spacePrice) && (
                                  <span>
                                    {currencyBefore(
                                      currencyFormat(
                                        applyPromo(item.spacePrice),
                                      ),
                                    )}
                                  </span>
                                )}
                                {isArray(item.spacePrice) && (
                                  <span>
                                    {`${currencyBefore(
                                      currencyFormat(
                                        applyPromo(item.spacePrice[0]),
                                      ),
                                    )} -
                                  ${currencyBefore(
                                    currencyFormat(
                                      applyPromo(item.spacePrice[1]),
                                    ),
                                  )}`}
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        )}
                        {showPerHour ? (
                          <div className={classnames('SpaceList-col')}>
                            {!isArray(item.pricePerHour) && (
                              <span>
                                {currencyBefore(
                                  currencyFormat(item.pricePerHour),
                                )}
                              </span>
                            )}
                            {isArray(item.pricePerHour) && (
                              <span>
                                {`${currencyBefore(
                                  currencyFormat(item.pricePerHour[0]),
                                )} -
                            ${currencyBefore(
                              currencyFormat(item.pricePerHour[1]),
                            )}`}
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className={classnames('SpaceList-col')}>
                            {!isArray(item.pricePerDay) && (
                              <span>
                                {currencyBefore(
                                  currencyFormat(item.pricePerDay),
                                )}
                              </span>
                            )}
                            {isArray(item.pricePerDay) && (
                              <span>
                                {`${currencyBefore(
                                  currencyFormat(item.pricePerDay[0]),
                                )} -
                            ${currencyBefore(
                              currencyFormat(item.pricePerDay[1]),
                            )}`}
                              </span>
                            )}
                          </div>
                        )}

                        <div className="SpaceList-col">
                          <span>{spaceSqFtFormat(item)}</span>
                        </div>
                        <div className="SpaceList-col">
                          <span>{isClosed ? 'Not Available' : spaceAvailabilityFormat(item)}</span>
                        </div>
                        {!spacesOwner && !isClosed && (
                          <div className="SpaceList-col -center">
                            {item.isFastOffice && item.fastOfficeUrl ? (
                              <button
                                type="button"
                                className="SpaceList-actionBtn"
                                onClick={() =>
                                  onInteractiveTour({
                                    tourUrl: item.fastOfficeUrl,
                                    isFastOffice: true,
                                  })
                                }
                              >
                                Virtual Tour
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="SpaceList-actionBtn"
                                onClick={() => onAction()}
                              >
                                Book Tour
                              </button>
                            )}
                          </div>
                        )}
                        {spacesOwner && (
                          <div className="SpaceList-col">
                            <Button
                              scheme="link-underline"
                              onClick={() =>
                                mobile
                                  ? openEditModal(item)
                                  : this.openEditForm(item.id)
                              }
                            >
                              Edit
                            </Button>
                          </div>
                        )}
                      </li>
                    )}
                    {editFormItemId === item.id &&
                      !mobile &&
                      this.renderItemForm(item)}
                    {editFormItemId === 'duplicate' &&
                      !mobile &&
                      currentItem.id === item.id &&
                      this.renderNewItemForm(currentItem)}
                  </>
                </CSSTransition>
              ),
          )}
        </ul>
        {!expanded && canExpand && activeSpaces.length > 7 && (
          <Flex center justify>
            <Button scheme="bordered-link" onClick={() => this.handleExpand()}>
              See All Spaces
            </Button>
          </Flex>
        )}
      </div>
    );
  }
}
