import fetch from 'isomorphic-fetch';
import {
  LISTINGS_API_URL as url,
  LISTINGS_API_BASE as base,
  LISTINGS_API_KEY as apiKey,
} from '../settings';

export const checkPermissions = (token, id) =>
  fetch(`${url}${base}/authcheck?location=${id}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
    },
  });

export const userGetInfo = token =>
  fetch(`${url}${base}/authcheck?info`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
    },
  });

export const operatorLocationsGet = token =>
  fetch(`${url}${base}/location/operator`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
    },
  });

export const registerLead = body => fetch(
  `${url}${base}/lead`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey,
    },
    body: JSON.stringify(body),
  },
);

export const registerModalOpen = body => fetch(
  `${url}${base}/registration`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey,
    },
    body: JSON.stringify(body),
  },
);

export const getUsers = token =>
  fetch(`${url}${base}/user`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
    },
  });

export const createUser = (token, data) =>
  fetch(`${url}${base}/user/`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
    },
    body: JSON.stringify(data),
  });

export const updateUser = (token, data) =>
  fetch(`${url}${base}/user/${data.username}`, {
    method: 'put',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
    },
    body: JSON.stringify(data),
  });

export const deleteUser = (token, username) =>
  fetch(`${url}${base}/user/${username}`, {
    method: 'delete',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
    },
  });

export const bunchDeleteUsers = (token, users) =>
  fetch(`${url}${base}/user`, {
    method: 'delete',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
    },
    body: JSON.stringify(users),
  });

export const registerLeadByAdmin = body =>
  fetch(`${url}${base}/lead/register`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey,
    },
    body: JSON.stringify(body),
  });

export const virtualtourRequested = body =>
  fetch(`${url}${base}/lead/virtualtourrequested`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey,
    },
    body: JSON.stringify(body),
  });

export const unsubscribe = (id, email) =>
  fetch(`${url}${base}/unsubscribe/?id=${id}&email=${email}`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey,
    },
  });
