import React, { Component } from 'react'
import { connect } from 'react-redux'
import throttle from 'lodash/throttle'
import isEmpty from 'lodash/isEmpty'
import { push } from 'connected-react-router'

import ButtonGroup from '../../components/button-group'
import Button from '../../components/button'
import SingleCtaMobile from '../../components/single-cta-mobile'

import { openCtaActionsModal, openIframeModal } from '../../actions/modal'
import { virtualtourRequested } from '../../api/users'
import { getCookie } from '../../utils/cookies'
import { ROOT, WORKPLACE } from '../../settings/routes'

class SingleCTA extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false
    }
    this.handleScroll = throttle(this.handleScroll.bind(this), 100)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, false)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false)
  }

  handleScroll(event) {
    const { scrollHeight, clientHeight } = event.srcElement.documentElement

    const scrollTop =
      event.srcElement.scrollingElement.scrollTop ||
      event.srcElement.documentElement.scrollTop
    const tenthPart = (scrollHeight - clientHeight) * 0.1

    this.setState({
      visible: tenthPart < scrollTop
    })
  }

  render() {
    const { visible } = this.state
    const {
      listingId,
      visitorId,
      isWorkplace,
      isClosed,
      searchFilterQueryUrl,
      marketSlug,
      marketName
    } = this.props

    return (
      <SingleCtaMobile visible={visible}>
        {!isClosed ? (
          <ButtonGroup
            fullWidth
            column={false}
            mobileScheme="singleCta"
            gutter="small"
          >
            {isWorkplace && (
              <Button
                scheme="singleCta"
                onClick={() => {
                  this.props.openIframeModal({
                    src: 'https://meetings.hubspot.com/hailey-barnett1',
                    title: 'Book a Guided Virtual Tour',
                    width: '900px',
                    height: '815px'
                  })
                  virtualtourRequested({
                    email: getCookie('email'),
                    leadid: visitorId,
                    location: [listingId]
                  })
                }}
                className="schedule-a-tour-button"
                style={{ textTransform: 'unset' }}
              >
                Book a Guided Virtual Tour
              </Button>
            )}
            <Button
              scheme="singleCta"
              onClick={() =>
                this.props.openCtaActionsModal({
                  heading: 'Visit This Space',
                  buttonName: 'Visit This Space',
                  fields: [
                    'firstName',
                    'email',
                    'seats',
                    'phoneNumber',
                    'preferredTourDate',
                    'preferredTourTime',
                    'moveinDate',
                  ],
                  initialValues: {
                    locations: [listingId],
                    visitorId,
                    isWorkspace: [!!isWorkplace],
                    form: 'visit-this-space'
                  },
                  isWorkspace: !!isWorkplace,
                  successText:
                    'Thanks for booking your tour through Upsuite! We will be in touch shortly!'
                })
              }
              className="schedule-a-tour-button"
              style={{ textTransform: 'unset' }}
            >
              Visit This Space
            </Button>
            <Button
              scheme="singleCta"
              onClick={() =>
                this.props.openCtaActionsModal({
                  heading: 'Request More Info',
                  fields: [
                    'requestInfo',
                    'firstName',
                    'email',
                    'seats',
                    'phoneNumber'
                  ],
                  initialValues: {
                    locations: [listingId],
                    visitorId,
                    isWorkspace: [!!isWorkplace],
                    form: 'request-more-info'
                  },
                  isWorkspace: !!isWorkplace,
                  successText:
                    'Thanks for reaching out! We will get back to you quickly regarding your inquiry!'
                })
              }
              className="request-more-info-button"
            >
              Request More Information
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup fullWidth>
            <Button
              onClick={() => {
                if (!isEmpty(searchFilterQueryUrl)) {
                  this.props.push(searchFilterQueryUrl)
                  return
                }

                this.props.push(
                  `/${isWorkplace ? WORKPLACE : ROOT}/${marketSlug}/`
                )
              }}
              className="schedule-a-tour-button"
              style={{ textTransform: 'unset' }}
            >
              {`Find Space in ${marketName}`}
            </Button>
          </ButtonGroup>
        )}
      </SingleCtaMobile>
    )
  }
}

export default connect(null, {
  openCtaActionsModal,
  openIframeModal,
  push
})(SingleCTA)
