import React from 'react';

import './styles.scss';

const InfoBar = ({ total, h1, paragraph, market, children, isWorkplace, seoPage }) => (
  <div className="InfoBar" data-as-section={seoPage}>
    <div className="InfoBar-top">
      {h1 && <h1>{h1}</h1>}
      {paragraph && (
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: paragraph,
          }}
        />
      )}
    </div>
    <div className="InfoBar-bottom">
      <div className="InfoBar-col">
        <div className="InfoBar-inner">
          {market && total > 0 && (
            <h2>
              Viewing
              <span className="InfoBar-total"> {total}</span>
              {` ${isWorkplace ? ' Workspace' : ' Location'}${
                total > 1 ? 's' : ''
              } ${market !== 'all' ? `in or near ${market}` : ''}`}
            </h2>
          )}
        </div>
      </div>
      {total > 0 && <div className="InfoBar-col">{children}</div>}
    </div>
  </div>
);

export default InfoBar;
