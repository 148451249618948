import React from 'react';
import Carousel from 'nuka-carousel';

import './styles.scss';

export default (props) => {
  const { children, ...restProps } = props;

  return (children &&
    <Carousel {...restProps}>
      {children}
    </Carousel>);
};
