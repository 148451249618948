import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import range from 'lodash/range';
import classnames from 'classnames';

import { compareMobilePaginate } from '../../actions/ui';

const MobilePaginate = (props) => {
  const { listings, paginate, isSticky } = props;
  const length = listings.length + 2;
  const pagination = range(1, length);

  const classes = classnames(
    'MobilePaginate',
    { '-isSticky': isSticky },
  );

  return (
    <div className={classes}>
      <div className="MobilePaginate-prev">
        <button
          className="MobilePaginate-btn -prev"
          type="button"
          disabled={paginate <= 0}
          onClick={() => props.compareMobilePaginate(paginate - 1)}
        >{'<'}</button>
      </div>
      <div className="MobilePaginate-track">
        {pagination.map(item => (
          <Fragment key={`mp-${item}`}>
            <button
              className={item === (paginate + 1) ? 'MobilePaginate-btn -num -active' : 'MobilePaginate-btn -num'}
              type="button"
              onClick={() => props.compareMobilePaginate(item - 1)}
            >{item}</button>
          </Fragment>
        ))}
      </div>
      <div>
        <button
          className="MobilePaginate-btn -next"
          type="button"
          disabled={paginate >= (length - 2)}
          onClick={() => props.compareMobilePaginate(paginate + 1)}
        >{'>'}</button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  paginate: state.ui.compareMobilePaginate,
});

export default connect(
  mapStateToProps, { compareMobilePaginate },
)(MobilePaginate);
