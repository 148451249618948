import fetch from 'isomorphic-fetch';
import queryString from 'query-string';
import {
  LISTINGS_API_URL as url,
  LISTINGS_API_BASE as base,
  LISTINGS_API_KEY as key,
  LISTINGS_API_HEAD as head,
  LISTINGS_API_URL as apiUrl,
} from '../settings';

export const listingsGet = (where, params, track) => {
  const paramsString = queryString.stringify(params);
  const headers = {
    [head]: key,
  };

  return fetch(`${apiUrl}/v1/location/geosearch?${paramsString}${where}${track ? '&track=true' : ''}`, {
    method: 'get',
    headers,
  });
};

export const trackRelated = (id) => {
  const headers = {
    [head]: key,
  };

  return fetch(`${apiUrl}/v1/trackrelated/?locationid=${id}`, {
    method: 'get',
    headers,
  });
};

export const listingsByMarketGet = (market) => {
  const params = queryString.stringify({
    sortBy: 'upsuiteVerified',
    asc: false,
    // maxSize,
  });

  const marketQuery = market !== 'all' ? {
    'where[0][type]': 'equals',
    'where[0][attribute]': 'marketName',
    'where[0][value]': market,
    'where[2][type]': 'isTrue',
    'where[2][attribute]': 'isOpen',
  } : {};

  const where = queryString.stringify(marketQuery, { encode: false });

  return fetch(`${url}${base}/location?${params}&${where}`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  });
};

export const listingsTopMarketGet = (market, maxSize) => {
  const params = queryString.stringify({
    sortBy: 'upsuiteVerified',
    asc: false,
    maxSize,
  });

  const marketQuery = market !== 'all' ? {
    'where[0][type]': 'equals',
    'where[0][attribute]': 'marketName',
    'where[0][value]': market,
  } : {};

  const restAttributes = {
    // 'where[1][type]': 'notEquals',
    // 'where[1][attribute]': 'accolades',
    // 'where[1][value]': 'null',
    'where[2][type]': 'isTrue',
    'where[2][attribute]': 'isOpen',
  };

  const queryObj = { ...marketQuery, ...restAttributes };

  const where = queryString.stringify(queryObj, { encode: false });

  return fetch(`${url}${base}/location?${params}&${where}`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  });
};

export const listingsWithNoSpacesGet = (value) => {
  const params = queryString.stringify({
    'where[0][type]': 'equals',
    'where[0][attribute]': 'spaceTypes',
    'where[0][value]': '[]',
    'where[1][type]': 'lessThanOrEquals',
    'where[1][attribute]': 'capacityMin',
    'where[1][value]': value,
    'where[2][type]': 'greaterThanOrEquals',
    'where[2][attribute]': 'capacityMax',
    'where[2][value]': value,
  }, { encode: false });

  return fetch(`${url}${base}/location?${params}`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  });
};

export const marketsGet = () => {
  const params = queryString.stringify({
    sortBy: 'name',
    asc: true,
    'where[0][type]': 'isFalse',
    'where[0][attribute]': 'hideMarketOnWeb',
  });

  return fetch(`${url}${base}/market?${params}`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  });
};

export const listingBySlugGet = (slug, marketSlug, email, token, isWorkplace) => {
  const params = queryString.stringify({
    marketSlug,
    slug,
    is_workplace: isWorkplace ? isWorkplace : null,
  }, { skipNull: true });

  const lead = email ? `, ${email}` : '';

  const headers = {
    [head]: key,
    'x-upsuite-info': `detail-view${lead}`,
    ...token ? { Authorization: token } : {},
  };

  return fetch(`${url}${base}/location/detail?${params}`, {
    method: 'get',
    headers,
  });
};

export const brandsGet = () => {
  const params = queryString.stringify({
    sortBy: 'name',
    asc: true,
    'where[0][type]': 'isFalse',
    'where[0][attribute]': 'hideBrandOnWeb',
  }, { encode: false });

  return fetch(`${url}${base}/brand?${params}`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  });
};

export const brandsByMarketIdGet = (id) => {
  const params = queryString.stringify({
    sortBy: 'name',
    asc: true,
    'where[0][type]': 'isFalse',
    'where[0][attribute]': 'hideBrandOnWeb',
  }, { encode: false });

  return fetch(`${url}${base}/market/${id}/brands?${params}`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  });
};

export const favoriteListingsGet = (ids) => {
  const config = queryString.stringify({
    sortBy: 'name',
    asc: true,
    'where[0][type]': 'in',
    'where[0][attribute]': 'id',
  }, { encode: false });

  const params = queryString.stringify({
    'where[0][value]': ids,
  }, {
    arrayFormat: 'index',
    encode: false,
  });

  return fetch(`${url}${base}/location?${config}&${params}`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  });
};

export const neighborhoodsGet = () => {
  const params = queryString.stringify({
    sortBy: 'name',
    asc: true,
    'where[0][type]': 'isFalse',
    'where[0][attribute]': 'hideHoodOnWeb',
  }, { encode: false });

  return fetch(`${url}${base}/neighborhood?${params}`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  });
};

export const neighborhoodGet = id =>
  fetch(`${url}${base}/neighborhood/${id}`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  });

export const spacesByLocationIdGet = (ids) => {
  const config = queryString.stringify({
    sortBy: 'spaceCapacity',
    asc: true,
    'where[1][type]': 'in',
    'where[1][attribute]': 'locationId',
  }, { encode: false });

  const params = queryString.stringify({
    'where[1][value]': ids,
  }, {
    arrayFormat: 'index',
    encode: false,
  });

  return fetch(`${url}${base}/space?${config}&${params}`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  });
};

export const spacesByProposalGet = (ids) => {
  const config = queryString.stringify({
    sortBy: 'spaceCapacity',
    asc: true,
    'where[1][type]': 'in',
    'where[1][attribute]': 'id'
  }, { encode: false });

  const params = queryString.stringify({
    'where[1][value]': ids
  }, {
    arrayFormat: 'index',
    encode: false
  });

  return fetch(`${url}${base}/space?${config}&${params}`, {
    method: 'get',
    headers: {
      [head]: key
    }
  });
};

export const spacesGet = where =>
  fetch(`${url}${base}/space?${where}`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  });

export const spacesGetPaginate = (offset, spaces, resolve, reject) => {
  const params = queryString.stringify({
    maxSize: 200,
    offset,
    'where[0][type]': 'isTrue',
    'where[0][attribute]': 'isAvailable',
  }, { encode: false });

  return fetch(`${url}${base}/space?${params}`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    const error = new Error(response.statusText);
    error.response = response;
    console.log(error);
    reject();
    throw error;
  })
    .then(response => response.json())
    .then((response) => {
      const retrivedSpaces = spaces.concat(response.list);
      const nextOffset = offset + 200;
      if (retrivedSpaces.length < response.total) {
        spacesGetPaginate(nextOffset, retrivedSpaces, resolve, reject);
      } else {
        resolve(retrivedSpaces);
      }
    })
    .catch((error) => {
      console.log(error);
      reject();
    });
};

export const listingsRelatedGet = (marketId) => {
  const params = queryString.stringify({
    sortBy: 'upsuiteVerified',
    asc: false,
    'where[0][type]': 'equals',
    'where[0][attribute]': 'marketId',
    'where[0][value]': marketId,
    'where[1][type]': 'isTrue',
    'where[1][attribute]': 'upsuiteVerified',
  }, { encode: false });

  return fetch(`${url}${base}/location?${params}`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  });
};

export const conciergeReviewsGet = id =>
  fetch(`${url}${base}/location/${id}/conciergeReviews`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  });

export const closestLocationsGet = (id, isWorkspace, minResults = 3) =>
  fetch(`${url}${base}/location/closest?is_workplace=${isWorkspace}&location_id=${id}&minresults=${minResults}`, {
    method: 'get',
    headers: {
      [head]: key,
    },
  });
