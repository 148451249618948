import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ModalBody } from '../../components/modal';
import HubspotForm from '../../components/hubspot-form';
import Spinner from '../../components/spinner';

import { hideModal } from '../../actions/modal';

class CTAModal extends Component {
  state = {
    formReady: false,
  }

  render() {
    const { formReady } = this.state;

    return (
      <ModalBody small withHeight>
        {!formReady
          && <Spinner size="medium" center />
        }
        <HubspotForm
          portalId="4312546"
          formId="32597032-afd8-4810-b333-a36308b5b1c9"
          withBg
          onFormReady={() => this.setState({ formReady: true })}
        />
      </ModalBody>
    );
  }
}

export default connect(
  null, { hideModal },
)(CTAModal);
