import isEmpty from 'lodash/isEmpty';
import {
  sortBySpacesMinPrice,
  sortByTotalofSeats,
  sortByDistance,
} from '../../utils/sort-listings';


export const listingBySlug = (bySlug, byId, path) => {
  const id = bySlug[path];
  const listing = byId[id];
  if (!id || !listing) {
    return {};
  }

  return listing;
};

export const listingById = (byId, id) => byId[id];

export const relatedListingsBySlug = (relatedBySlug, byId, path) => (relatedBySlug[path] || []).map(id => byId[id]);

export const listingsByMarket = (byMarket, byId, market) => {
  const ids = byMarket[market] || [];

  return ids.map(id => listingById(byId, id));
};

export const listingsByRoute = (byRoute, byId, route) => {
  const ids = byRoute[route] || [];

  return ids.map(id => listingById(byId, id));
};

export const latLonByRoute = (byRoute, byId, route) => {
  const ids = byRoute[route] || [];

  return ids.map(id => listingById(byId, id)).filter(l =>
    l.latitude && l.longitude);
};

export const compareById = (compareIds, shareIds, byId, marketsByName) => {
  const ids = shareIds || compareIds;

  return ids.map((id) => {
    const loc = listingById(byId, id);
    if (!isEmpty(loc) && !isEmpty(marketsByName)) {
      const marketSlug = marketsByName[loc.marketName] && marketsByName[loc.marketName].marketSlug;
      return ({
        ...loc,
        marketSlug,
      });
    }

    return loc;
  }).filter(i => i);
};

export const compareSavedIds = (ids = [], byId, marketsByName) => {
  return ids.map((id) => {
    const loc = listingById(byId, id);
    if (!isEmpty(loc) && !isEmpty(marketsByName)) {
      const marketSlug = marketsByName[loc.marketName] && marketsByName[loc.marketName].marketSlug;
      return ({
        ...loc,
        marketSlug,
      });
    }

    return loc;
  }).filter(i => i);
};

export const selectSpaceMarkets = (ids = [], byId) => {
  return ids.map((id) => {
    const loc = listingById(byId, id);

    return loc?.marketName
  }).filter((v, i, a) => v && a.indexOf(v) === i);
};

export const latLonByLastRequest = (byId, lastRequestIds) => lastRequestIds.map(id => byId[id]);

export const latLonByLastRequestSorted = (sortMethod, byId, lastRequestIds) => {
  const listings = lastRequestIds.map(id => byId[id]);

  switch (sortMethod) {
    case 'price-asc':
      return sortBySpacesMinPrice(listings);
    case 'totalofseats-desc':
      return sortByTotalofSeats(listings, 'desc');
    case 'recomended':
      return listings;
    case 'distance':
      return sortByDistance(listings);
    default:
      break;
  }

  return listings;
}

export const operatorLocById = (ids, byId) => ids.map(id => listingById(byId, id)).filter(i => i);

export const getSyncedMarkers = (syncMarkers, listings) =>
  !isEmpty(syncMarkers) ? listings.filter(listing => syncMarkers.includes(listing.id)) : listings;


