import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import { createReducer } from '../utils';
import {
  SINGLE_HOOD_GET_REQUEST,
  SINGLE_HOOD_GET_SUCCESS,
  SINGLE_HOOD_GET_FAILURE,
  NEIGHBORHOODS_GET_REQUEST,
  NEIGHBORHOODS_GET_SUCCESS,
  NEIGHBORHOODS_GET_FAILURE,
} from '../constants';

const initialState = {
  byId: {},
  byMarket: {},
  loading: false,
  error: false,
  singleLoading: false,
  singleError: false,
};

export default createReducer(initialState, {
  [SINGLE_HOOD_GET_REQUEST]: state => ({
    ...state,
    singleLoading: true,
    singleError: false,
  }),
  [SINGLE_HOOD_GET_SUCCESS]: (state, payload) => ({
    ...state,
    byId: {
      ...state.byId,
      [payload.id]: payload,
    },
    singleLoading: false,
    singleError: false,
  }),
  [SINGLE_HOOD_GET_FAILURE]: state => ({
    ...state,
    singleLoading: false,
    singleError: true,
  }),
  [NEIGHBORHOODS_GET_REQUEST]: state => ({
    ...state,
    loading: true,
    error: false,
  }),
  [NEIGHBORHOODS_GET_SUCCESS]: (state, payload) => ({
    ...state,
    byId: {
      ...state.byId,
      ...keyBy(payload.list, 'id'),
    },
    byMarket: groupBy(payload.list, 'marketName'),
    loading: false,
    error: false,
  }),
  [NEIGHBORHOODS_GET_FAILURE]: state => ({
    ...state,
    loading: false,
    error: true,
  }),
});
