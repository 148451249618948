import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import Heading from '../../components/heading'
import ButtonGroup from '../../components/button-group'
import ButtonMeta from '../../components/button-meta'
import MailTo from '../../components/mailto-link'
import Flex from '../../components/flex'
import {
  CompareLayoutHelper,
  CompareRow as Row
} from '../../components/compare-page-helpers'
import { Default, Mobile } from '../../components/responsive-helpers'
import Swipeable from '../../components/swipeable'
import { Download } from '../../components/icons'
import CompareAddSpaces from '../../components/compare-add-spaces'

import { favoritesGet } from '../../actions/favorites'
import { spacesByLocationIdsGet } from '../../actions/spaces'
import marketsGet from '../../actions/markets'

import { exportFileGet } from '../../api/export'

import CompareCardsMobile from './compare-cards-mobile'
import CompareSpacesMobile from './compare-spaces-mobile'
import CompareBestForMobile from './compare-best-for-mobile'
import CompareAmenitiesMobile from './compare-amenities-mobile'
import MobilePaginate from './compare-mobile-paginate'
import CompareMap from './compare-map'
import LeftColumn from './compare-left-col'
import SlideColumns from './compare-slide-col'

import { getFilterQueryLink } from '../../actions/filter-query'

import { selectSavedListings } from '../../selectors/listings'
import { selectByLocationId, selectByProposal } from '../../selectors/spaces'

import { ROOT } from '../../settings/routes'
import { getMailBody } from '../../settings/text'
import usePrevious from '../../hooks/use-previous'
import Spinner from '../../components/spinner'
import EmptyPanel from '../Favorites/compare-empty-listins'
import { openSaveShortlistModal } from '../../actions/modal'
import { removeSavedSpaces } from '../../actions/favorites'
import { spacesUpdate } from '../../actions/lead'
import ShortlistName from './shortlist-name'

const SpacesList = props => {
  const {
    ids,
    showSaveBtn,
    title,
    allowAdding,
    isShortlist,
    placeholder,
    slug,
    status,
    error,
    isProposal
  } = props
  const idsString = ids.join(',')
  const prevIdsString = usePrevious(idsString)
  const dispatch = useDispatch()
  const filterQuery = useSelector(state => state.filterQuery)
  const { loading } = useSelector(state => state.favorites)
  const searchFilterQueryUrl = getFilterQueryLink(filterQuery) || `/${ROOT}/`
  const spaces = useSelector(state => selectByLocationId(state))
  const proposalSpaces = useSelector(state => selectByProposal(state))
  const [paginate, setPaginate] = useState(0)
  const [compareCardsHeight, setCompareCardsHeight] = useState(0)
  const [spaceByListingId, setSpaceByListingId] = useState({})
  const [removedSpaces, setRemovedSpaces] = useState([])
  const compareListings = useSelector(state =>
    selectSavedListings(state, props)
  ).filter(item => !removedSpaces.includes(item.id))

  useEffect(() => {
    if (idsString !== prevIdsString && idsString && !isShortlist) {
      if (isProposal) {
        dispatch(favoritesGet(ids))
      } else {
        dispatch(favoritesGet(ids)).then(dispatch(spacesByLocationIdsGet(ids)))
      }
      setPaginate(0)
      setSpaceByListingId({})
      setRemovedSpaces([])
    }
  }, [dispatch, ids, idsString, prevIdsString, isShortlist, isProposal])

  useEffect(() => {
    dispatch(marketsGet())
  }, [dispatch])

  useEffect(() => {
    if (isShortlist) {
      dispatch(favoritesGet(ids)).then(dispatch(spacesByLocationIdsGet(ids)))
      setPaginate(0)
      setSpaceByListingId({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isShortlist])

  if (loading) {
    return (
      <Flex column fullHeight grow stickyFooter>
        {title && (
          <Heading
            level="1"
            size="xsmall"
            spaceBelow="small"
            spaceAbove="medium"
          >
            {title}
          </Heading>
        )}
        <LoadingWrap>
          <Spinner size="small" />
        </LoadingWrap>
      </Flex>
    )
  }

  if (ids.length === 0) {
    return (
      <Flex column fullHeight grow stickyFooter>
        {title && (
          <Heading
            level="1"
            size="xsmall"
            spaceBelow="small"
            spaceAbove="medium"
          >
            {title}
          </Heading>
        )}
        {isShortlist && <EmptyPanel />}
      </Flex>
    )
  }

  return (
    <Flex column fullHeight grow middle stickyFooter>
      {isShortlist || isProposal ? (
        <Heading level="1" size="xsmall" spaceBelow="small" spaceAbove="medium">
          {title || ''}
        </Heading>
      ) : (
        <ShortlistName title={title} id={slug} ids={ids} isProposal={isProposal} />
      )}
      {compareListings.length > 0 && (
        <TopWrap>
          <ButtonGroup column={false} gutter="small" fullWidth={false} wrap>
            <ButtonMeta
              svgIcon={<Download />}
              scheme="gray"
              onClick={() => {
                const chosenSpaceIds = ids.map(item => {
                  if (spaceByListingId[item]) {
                    return spaceByListingId[item];
                  }

                  return ((spaces[item] || [])[0] || {}).id
                })

                exportFileGet(chosenSpaceIds.filter(item => item).join(','), (ids || []).join(','))
              }}
              className="export-list-button button"
            >
              Export List
            </ButtonMeta>
            <MailTo
              subject="Check out these Coworking Spaces"
              body={getMailBody(ids)}
              className="share-list-button"
            >
              Share List
            </MailTo>
            {(showSaveBtn || removedSpaces.length > 0) && (
              <ButtonMeta
                scheme="cta"
                onClick={() => {
                  if (showSaveBtn) {
                    dispatch(openSaveShortlistModal({ placeholder }))
                  } else {
                    dispatch(
                      spacesUpdate({
                        id: slug,
                        name: title,
                        spaces: compareListings.map(item => item.id)
                      })
                    )
                  }
                }}
                style={{ marginLeft: 5, marginRight: 5 }}
                disabled={status?.updating}
              >
                Save Shortlist
              </ButtonMeta>
            )}
          </ButtonGroup>
          {(status?.updating || error?.updating) && (
            <UpdatingWrap>
              {status?.updating && (
                <Spinner size="small" />
              )}
              {error?.updating && (
                <ErrorMessage>Error saving the shortlist</ErrorMessage>
              )}
            </UpdatingWrap>
          )}
        </TopWrap>
      )}
      <CompareLayoutHelper>
        <Default>
          <Row>
            {compareListings.length > 0 && (
              <LeftColumn
                isProposal={isProposal}
                compareCardsHeight={compareCardsHeight}
                compareListings={compareListings}
              />
            )}
            <SlideColumns
              searchFilterQueryUrl={searchFilterQueryUrl}
              listings={compareListings}
              spaces={isProposal ? proposalSpaces : spaces}
              isProposal={isProposal}
              setCompareCardsHeight={height => setCompareCardsHeight(height)}
              compareCardsHeight={compareCardsHeight}
              setSpaceByListingId={item => setSpaceByListingId(item)}
              spaceByListingId={spaceByListingId}
              allowAdding={allowAdding}
              onRemoveSaved={id => {
                if (isShortlist) {
                  dispatch(removeSavedSpaces(id))
                } else {
                  setRemovedSpaces([...removedSpaces, id])
                }
              }}
            />
          </Row>
        </Default>
        <Mobile>
          <Row>
            <CompareMap listings={compareListings} mobile />
          </Row>
          <MobilePaginate
            paginate={paginate}
            listings={compareListings}
            onPaginate={page => setPaginate(page)}
            isSticky
            allowAdding={allowAdding}
          />
          <Swipeable
            onSwipedLeft={
              paginate < ids.length ? () => setPaginate(paginate + 1) : null
            }
            onSwipedRight={
              paginate > 0 ? () => setPaginate(paginate - 1) : null
            }
          >
            {!isEmpty(compareListings) && (
              <Row>
                <CompareCardsMobile
                  paginate={paginate}
                  listings={compareListings}
                />
              </Row>
            )}
            {paginate === ids.length && (
              <CompareAddSpaces
                link={searchFilterQueryUrl}
                label="Add Another Space"
              />
            )}
            <Row>
              <CompareSpacesMobile
                paginate={paginate}
                listings={compareListings}
                spaces={isProposal ? proposalSpaces : spaces}
                isProposal={isProposal}
                setSpaceByListingId={item => setSpaceByListingId(item)}
                spaceByListingId={spaceByListingId}
              />
            </Row>
            <Row>
              <CompareBestForMobile
                paginate={paginate}
                listings={compareListings}
              />
            </Row>
            <Row>
              <CompareAmenitiesMobile
                paginate={paginate}
                listings={compareListings}
              />
            </Row>
          </Swipeable>
        </Mobile>
      </CompareLayoutHelper>
    </Flex>
  )
}

const LoadingWrap = styled.div`
  position: relative;
  padding: 50px;
  width: 100%;
`

const TopWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`

const UpdatingWrap = styled.div`
  position: relative;
  margin-bottom: 10px;
  margin-left: 25px;
`

const ErrorMessage = styled.span`
  color: red;
  line-height: 1.6;
`

export default SpacesList
