import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { ListingsFeedList } from '../../components/listings-feed'
import Spinner from '../../components/spinner'
import AdCard from '../../components/ad-card'
import Banner from '../../components/banner'

import InfoBar from './info-bar'
import FilterList from './filter-list'
import ListingCard from './listing-card'
import NotFoundLocations from './locations404'
import SearchPage from './search-page'
import { BreadcrumbsFeed } from '../../components/breadcrumbs'

import { openIframeModal } from '../../actions/modal'
import { selectSyncedMarkers } from '../../selectors/listings'
import { selectIfIsSearch } from '../../selectors/filter-query'

const Feed = props => {
  const {
    loading,
    completed,
    withSearchBar,
    listings,
    nearestMarket,
    markets,
    isSearch,
    filterQuery: { is_workplace: isWorkplace },
    seoPage
  } = props

  const render = !isEmpty(listings) && !loading && completed
  const noListings = !loading && completed && isEmpty(listings)
  const total = listings.length

  return (
    <ListingsFeedList
      seoPage={seoPage}
      withSearchBar={withSearchBar && !isSearch}
    >
      {loading && <Spinner size="large" center absolute pageHeight />}
      <FilterList />
      <BreadcrumbsFeed />
      <Banner>
        Upsuite wants you to have full information about your office choice.
        Click{' '}
        <a
          href="https://www.upsuite.com/blog/coworking-space-costs-average-membership-pricing/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>{' '}
        to read our comprehensive pricing guide.
      </Banner>
      {!loading && !noListings && !isSearch && (
        <InfoBar seoPage={seoPage} total={total} isWorkplace={isWorkplace} />
      )}
      {render &&
        !isSearch &&
        listings.map((listing, index) => (
          <Fragment key={listing.id}>
            <ListingCard listing={listing} />
            {index % 3 === 2 && (
              <AdCard
                onButtonClick={() => {
                  props.openIframeModal({
                    src: 'https://meetings.hubspot.com/hailey-barnett1',
                    title: 'Work with an Upsuite Advisor',
                    width: '900px',
                    height: '815px'
                  })
                }}
              />
            )}
          </Fragment>
        ))}
      {isSearch && <SearchPage markets={markets} />}
      {noListings && !isSearch && (
        <NotFoundLocations
          id="no-listings-text"
          markets={markets}
          nearestMarket={nearestMarket}
          isWorkplace={isWorkplace}
        />
      )}
    </ListingsFeedList>
  )
}

const mapStateToProps = state => ({
  loading: state.listings.loading,
  completed: state.listings.completed,
  listings: selectSyncedMarkers(state),
  isSearch: selectIfIsSearch(state),
  nearestMarket: state.location.nearestMarket,
  markets: state.markets.byName,
  visitorId: state.auth.visitorId,
  filterQuery: state.filterQuery
})

export default connect(mapStateToProps, {
  openIframeModal
})(Feed)
