import React, { Component } from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import Marker from '../../components/location-marker';

import { MAPS_API_KEY as mapsKey } from '../../settings';
import {
  singleMapOptions,
  handleApiLoaded,
  getLayerType,
} from '../../utils/map-helpers';

class Map extends Component {
  state = {
    maptype: 'roadmap',
  }

  onMapTypeChangeHandler = (type) => {
    this.setState({ maptype: type });
  }

  handleMapLoaded = ({ map, maps }) => {
    const { bindMapToState } = this.props;

    handleApiLoaded(map, maps, bindMapToState);
  }

  render() {
    const {
      lat, lng, highlight, expandable,
    } = this.props;
    const { maptype } = this.state;

    return (
      <div style={{ width: '100%', height: expandable ? '250px' : '450px' }}>
        <GoogleMapReact
          options={singleMapOptions}
          bootstrapURLKeys={{ key: mapsKey }}
          center={{ lat, lng }}
          defaultZoom={15}
          layerTypes={getLayerType(maptype)}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.handleMapLoaded}
          onMapTypeIdChange={this.onMapTypeChangeHandler}
        >
          <Marker
            lat={lat}
            lng={lng}
            isFavorite={highlight}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default connect(
  state => ({
    map: state.reviews.map,
    maps: state.reviews.maps,
  }),
)(Map);
