import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import sumBy from 'lodash/sumBy';
import { createSelector } from 'reselect';

const marketsList = state => state.markets.list;

const getSubmarkets = markets =>
         groupBy(
           markets.filter(market => market.isSubmarket),
           'marketParentId',
         );

export const groupSubmarkets = (markets) => {
  const parent = markets.filter(market => !market.isSubmarket);
  const submarkets = getSubmarkets(markets);

  return reduce(parent, (groupedMarkets, parentMarket) => {
    if (submarkets[parentMarket.id]) {
      const parentMarketFlag = { ...parentMarket, isParent: true, submarkets: submarkets[parentMarket.id] };
      return [...groupedMarkets, parentMarketFlag, ...submarkets[parentMarket.id]];
    }

    return [...groupedMarkets, parentMarket];
  }, []);
};

export const selectMarketsWithCoords = createSelector(marketsList, markets =>
  groupSubmarkets(markets || []).map(market => ({
           label: market.name,
           location: { lat: market.centerLat, lng: market.centerLon },
           slug: market.marketSlug,
           isSubmarket: market.isSubmarket,
           id: market.id,
           marketParentId: market.marketParentId,
           isFixture: true,
         })),
       );

export const updateSuggests = (suggests = [], fixtures = []) => {
  if (!suggests || !fixtures) return [];
  const firstSuggest = suggests[0];

  if (firstSuggest && firstSuggest.isSubmarket && firstSuggest.isFixture) {
    const injectSuggest = fixtures.find(el => el.id === firstSuggest.marketParentId);
    return [{ ...injectSuggest, placeId: injectSuggest.label }, ...suggests];
  }

  if (!suggests.every((el => el.isFixture)) && firstSuggest && firstSuggest.isFixture && getSubmarkets(fixtures)[firstSuggest.id]) {
    return [firstSuggest, ...getSubmarkets(fixtures)[firstSuggest.id].map(el => ({ ...el, placeId: el.label, })), ...suggests.slice(1)];
  }

  return suggests;
};

export const selectParentMarketsWithCount = createSelector(marketsList, markets => {
  if (!markets) return null;

  const submarketsLocCountByParent = reduce(
    groupBy(
      markets.filter(market => market.isSubmarket && market.marketParentId),
      'marketParentId',
    ),
    (result, submarkets, parentMarketId) => ({...result, [parentMarketId]: sumBy(submarkets, 'locationCount')}),
    {},
  );

  return markets
    .filter(market => !market.isSubmarket)
    .map(market => {
      if (submarketsLocCountByParent[market.id]) {
        return ({
          ...market,
          locationCount: (market.locationCount || 0) + submarketsLocCountByParent[market.id]
        });
      }

      return market;
    });
});
