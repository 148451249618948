import React from 'react'
import ProfileMenu from './menu'
import { Wrap } from './styles'

const Profile = () => {
  return (
    <Wrap>
      <ProfileMenu />
    </Wrap>
  )
}

export default Profile
