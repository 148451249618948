import fetch from 'isomorphic-fetch'
import {
  LISTINGS_API_URL as url,
  LISTINGS_API_BASE as base,
  LISTINGS_API_KEY as apiKey
} from '../settings'

export const getSearches = token =>
  fetch(`${url}${base}/savedsearch`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const addSearch = (token, data) =>
  fetch(`${url}${base}/savedsearch`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    },
    body: JSON.stringify(data)
  })

export const updateSearch = (token, data) =>
  fetch(`${url}${base}/savedsearch`, {
    method: 'put',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    },
    body: JSON.stringify(data)
  })

export const deleteSearch = (token, id) =>
  fetch(`${url}${base}/savedsearch?id=${id}`, {
    method: 'delete',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const getSpaces = token =>
  fetch(`${url}${base}/shortlist`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const getProposal = token =>
  fetch(`${url}${base}/proposal`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const addSpaces = (token, data) =>
  fetch(`${url}${base}/shortlist`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    },
    body: JSON.stringify(data)
  })

export const updateSpaces = (token, data) =>
  fetch(`${url}${base}/shortlist`, {
    method: 'put',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    },
    body: JSON.stringify(data)
  })

export const getAccount = token =>
  fetch(`${url}${base}/account`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const updateAccount = (token, data) =>
  fetch(`${url}${base}/account`, {
    method: 'put',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    },
    body: JSON.stringify(data)
  })

export const passwordStartReset = (email) =>
  fetch(`${url}${base}/password/startreset?email=${window.encodeURIComponent(email)}`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey
    }
  })

export const passwordEndReset = ({ email, code }) =>
  fetch(`${url}${base}/password/endreset?email=${email}&code=${code}`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey
    }
  })
