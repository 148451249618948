import React, { Fragment } from 'react'
import range from 'lodash/range'
import classnames from 'classnames'

const MobilePaginate = props => {
  const { listings, paginate, isSticky, allowAdding } = props
  const length = allowAdding ? listings.length + 2 : listings.length + 1
  const pagination = range(1, length)

  const classes = classnames('MobilePaginate', { '-isSticky': isSticky })

  return (
    <div className={classes}>
      <div className="MobilePaginate-prev">
        <button
          className="MobilePaginate-btn -prev"
          type="button"
          disabled={paginate <= 0}
          onClick={() => props.onPaginate(paginate - 1)}
        >
          {'<'}
        </button>
      </div>
      <div className="MobilePaginate-track">
        {pagination.map(item => (
          <Fragment key={`mp-${item}`}>
            <button
              className={
                item === paginate + 1
                  ? 'MobilePaginate-btn -num -active'
                  : 'MobilePaginate-btn -num'
              }
              type="button"
              onClick={() => props.onPaginate(item - 1)}
            >
              {item}
            </button>
          </Fragment>
        ))}
      </div>
      <div>
        <button
          className="MobilePaginate-btn -next"
          type="button"
          disabled={paginate >= length - 2}
          onClick={() => props.onPaginate(paginate + 1)}
        >
          {'>'}
        </button>
      </div>
    </div>
  )
}

export default MobilePaginate
