import { BASE_URL } from './index';
import { ROOT, WORKPLACE } from './routes';
import { imageUrl } from '../utils/crm-links';
import getInteriorImages from '../utils/listings';
import { spaceCapacityFormat } from '../utils/format';

const getInteriourImages = (listing) => [
  { value: listing.listingCardImageId, alt: listing.listingCardAltText },
  ...getInteriorImages(listing),
];

const getSpaces = (spaces, isWorkspace) => {
  if (!spaces) return null;

  return spaces.filter(space => space.isAvailable && space.spacePrice && ((isWorkspace && space.isWorkplace) || (!isWorkspace && !space.isWorkplace)))
    .map(space => ({
      '@type': 'Offer',
      itemOffered: {
        '@type': 'Product',
        name: space.name || space.spaceType,
        sku: space.id,
        offers: {
          '@type': 'Offer',
          priceCurrency: space.spacePriceCurrency,
          price: space.spacePrice,
          // priceValidUntil: '2021-11-05',
          availability: 'https://schema.org/InStock',
        },
        description: `Suitable for ${spaceCapacityFormat(space)}`,
      },
    }));

};

export const getSchemaLocation = (location, market, spaces, isWorkspace = false) => {
  return {
    '@context': 'http://schema.org',
    '@graph': [
      {
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            name: 'Coworking Spaces',
            position: 1,
            item: `${BASE_URL}/${isWorkspace ? WORKPLACE : ROOT}/`,
          },
          {
            '@type': 'ListItem',
            name: market.metaTitle,
            position: 2,
            item: `${BASE_URL}/${isWorkspace ? WORKPLACE : ROOT}/${market.marketSlug}/`,
          },
          {
            '@type': 'ListItem',
            name: location.name,
            position: 3,
            item: `${BASE_URL}/${isWorkspace ? WORKPLACE : ROOT}/${market.marketSlug}/${location.marketplaceSlug}/`,
          },
        ],
      },
      {
        '@type': 'LocalBusiness',
        name: location.name,
        url: `${BASE_URL}/${isWorkspace ? WORKPLACE : ROOT}/${market.marketSlug}/${location.marketplaceSlug}/`,
        address: {
          '@type': 'PostalAddress',
          addressCountry: location.addressCountry,
          addressLocality: location.addressCity,
          streetAddress: location.addressStreet,
          postalCode: location.addressPostalCode,
        },
        image: getInteriourImages(location).map(item => imageUrl(item.value)),
        telephone: '+18002077269',
        geo: {
          '@type': 'GeoCoordinates',
          latitude: location.latitude,
          longitude: location.longitude,
        },
        hasOfferCatalog: {
          '@type': 'OfferCatalog',
          name: 'Spaces',
          itemListElement: getSpaces(spaces, isWorkspace),
        },
      },
    ],
  };
};
