import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Amenities from '../../components/compare-amenities';

import { getAllWorkplaceAmenitiesFavorites } from '../../utils/amenities';

import { CompareColumn, CompareTitleMobile } from '../../components/compare-page-helpers';

class CompareAmenitiesMobile extends Component {
  constructor() {
    super();
    this.state = {
      expanded: false,
    };
  }

  handleExpand() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  render() {
    const { listings, paginate } = this.props;
    const { expanded } = this.state;
    const listing = paginate >= 0 && (paginate <= (listings.length - 1)) ? listings[paginate] : {};

    return (
      !isEmpty(listing) &&
      <>
        <CompareTitleMobile>Amenities</CompareTitleMobile>
        <CompareColumn className="-mobile">
          <Amenities
            expanded={expanded}
            amenities={getAllWorkplaceAmenitiesFavorites(listing)}
            onExpand={() => this.handleExpand()}
            locationId={listing.id}
            cleaningPoliciesPDFLink={listing.cleaningPoliciesPDFLinkId}
          />
        </CompareColumn>
      </>
    );
  }
}

const mapStateToProps = state => ({
  paginate: state.ui.compareMobilePaginate,
});

export default connect(
  mapStateToProps,
)(CompareAmenitiesMobile);
