import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BestOfLabel as BestOfLabelIcon } from '../icons';
import Tooltip from '../tooltip';

import './styles.scss';

export const BestOfLabel = ({ size, scheme }) => (
  <div className={classnames('BestOfLabel', [`-size-${size}`, `-scheme-${scheme}`])}>
    <BestOfLabelIcon />
    <p className="BestOfLabel-text">Best of</p>
  </div>
);

BestOfLabel.propTypes = {
  size: PropTypes.string,
};

BestOfLabel.defaultProps = {
  location: '',
  size: 'default',
  scheme: 'none',
};

const classes = classnames('BestOf');

const BestOf = ({ features, location, tooltip, close }) => (
  <div className={classes}>
    {features &&
      <ul className="BestOf-features">
        {features.map((item, index) => (
          <li key={`${item}-${index}`}>{item}</li>
        ))}
      </ul>
    }
    {close &&
      <span className="BestOf-close">x</span>}

    {location &&
      <Tooltip
        placement="top"
        overlay={tooltip}
        maxWidth={250}
        overlayStyle={{ textAlign: 'center', padding: 13 }}
      >
        <div>
          <div className="BestOf-shadow" />
          <BestOfLabel location={location} />
        </div>
      </Tooltip>
    }
  </div>
);

BestOf.propTypes = {
  location: PropTypes.string,
  tooltip: PropTypes.string,
  close: PropTypes.bool,
  features: PropTypes.arrayOf(PropTypes.string),
};

BestOf.defaultProps = {
  location: '',
  tooltip: '',
  close: false,
  features: [],
};

export default BestOf;
