import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { submit } from 'redux-form'
import styled from 'styled-components'
import { Auth } from 'aws-amplify'

import { ModalBody } from '../../../components/modal'
import Heading from '../../../components/heading'
import '../../../components/register-form-steps/styles.scss'
import RegisterMessage from '../../../components/register-message'
import Flex from '../../../components/flex'
import Form from '../../../components/register-form-steps/register-user-2'
import Login from '../../../components/register-form-steps/login-form-2'
import PasswordRecovery from '../../../components/register-form-steps/password-recovery-form'
import ForgotPass from '../../../components/register-form-steps/forgot-pass-form'
import GoogleBtn from '../../../components/google-btn'
import {
  trackMemberRegistrationSubmissionsPhoneSeats,
  trackMemberRegistrationViewsPhoneSeats
} from '../../../utils/analytics'
import Button from '../../../components/button'

import { registerLead } from '../../../actions/auth'
import { hideModal } from '../../../actions/modal'

import { setCookie } from '../../../utils/cookies'
import { authSignIn, authCompleteNewPassword } from '../../../actions/cognito'
import { passwordStartReset } from '../../../api/leads'
import { checkHttpResponse } from '../../../utils'
import Text from '../../../components/text-box'

const RegisterModal = props => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.cognito)
  const [showLogin, setShowLogin] = useState(false)
  const [showResetSuccess, setShowResetSuccess] = useState(false)
  const [showForgotPass, setShowForgotPass] = useState(false)
  const { onSubmit, initialValues, form, visitorId } = props

  useEffect(() => {
    trackMemberRegistrationViewsPhoneSeats()
  }, [form])

  return (
    <ModalBody small withHeight>
      <Heading level="3" size="xsmall" center>
        Sign in or Register with Upsuite to Save and Compare Office Spaces
      </Heading>
      <Flex columnMobile>
        <RegisterMessage heading="I’d like to" className="-left -half">
          <ol>
            <li>Access Details Like Pricing</li>
            <li>Save Searches</li>
            <li>Save Spaces</li>
          </ol>
        </RegisterMessage>
        <Col>
          <Tabs>
            <button
              className="SpaceList-actionBtn"
              onClick={() => setShowLogin(false)}
              type="button"
              data-active={!showLogin}
            >
              Register
            </button>
            <button
              className="SpaceList-actionBtn"
              onClick={() => [setShowForgotPass(false), setShowLogin(true)]}
              type="button"
              data-active={showLogin}
            >
              Sign into Account
            </button>
          </Tabs>
          <div>
            {showLogin ? (
              <>
                {auth.status.error && !showForgotPass && (
                  <span
                    style={{ marginBottom: 10, display: 'block', color: 'red' }}
                  >
                    {auth.status.errorText}
                  </span>
                )}
                {auth.newPasswordRequired ? (
                  <PasswordRecovery
                    onSubmit={({ password }) => {
                      dispatch(authCompleteNewPassword(password)).then(
                        data => data && onSubmit()
                      )
                    }}
                  />
                ) : (
                  <>
                    {showForgotPass ? (
                      <>
                        {showResetSuccess ? (
                          <Text
                            withLink
                            spaceBelow="middle"
                            size="medium"
                            center
                            color="dark"
                          >
                            An email was sent to with password reset
                            instructions to the submitted email. If you still
                            experience problems, please contact{' '}
                            <a href="mailto:support@upsuite.com">
                              support@upsuite.com
                            </a>
                          </Text>
                        ) : (
                          <ForgotPass
                            onSubmit={({ email }) => {
                              passwordStartReset(email)
                                .then(checkHttpResponse)
                                .then(() => setShowResetSuccess(true))
                                .catch(() => setShowResetSuccess(false))
                            }}
                            onCancel={() => setShowForgotPass(false)}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <Login
                          onSubmit={({ email, password }) => {
                            dispatch(authSignIn(email, password)).then(
                              data => data && onSubmit()
                            )
                          }}
                        />
                        <GoogleBtnMain
                          style={{ marginLeft: 'auto' }}
                          onClick={() =>
                            Auth.federatedSignIn({ provider: 'Google' })
                          }
                        >
                          Continue with Google
                        </GoogleBtnMain>
                        <ForgotPassBtn
                          className="SpaceList-actionBtn"
                          onClick={() => setShowForgotPass(true)}
                          type="button"
                        >
                          Forgot your Password?
                        </ForgotPassBtn>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <Flex column fullWidth>
                <Form
                  buttonName="Register"
                  initialValues={{
                    ...initialValues
                  }}
                  onSubmit={val => {
                    dispatch(registerLead({ ...val, form, visitorId })).then(
                      () => {
                        setCookie('_REG_TEMP_COMPLETED', true, 365)
                        setCookie('firstName', val.firstName, 365)
                        setCookie('upsuite_user_last_name', val.lastName, 365)

                        trackMemberRegistrationSubmissionsPhoneSeats()

                        if (props.closeOnSubmit) {
                          dispatch(hideModal())
                        }
                        onSubmit()
                      }
                    )
                  }}
                />
              </Flex>
            )}
          </div>
        </Col>
      </Flex>
      {!showLogin && (
        <Flex middle column>
          <SubmitButton onClick={() => dispatch(submit('register-form'))}>
            Register
          </SubmitButton>
          <GoogleBtnMain
            onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
          >
            Continue with Google
          </GoogleBtnMain>
          <span style={{ fontSize: 14, margin: '15px 0' }}>
            I accept Upsuite’s{' '}
            <a
              className="SpaceList-actionBtn"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.upsuite.com/terms-of-service/"
            >
              Terms of Use
            </a>{' '}
            and{' '}
            <a
              className="SpaceList-actionBtn"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.upsuite.com/privacy-policy/"
            >
              Privacy Policy
            </a>
            {'.'}
          </span>
        </Flex>
      )}
      <FlexOffice>
        Are you a coworking or flexible office operator? Sign in{' '}
        <a
          href="https://upsuite.flexofficepro.com/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>{' '}
        to manage your listing
      </FlexOffice>
    </ModalBody>
  )
}

const Col = styled.div`
  width: 100%;
`

const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  & button {
    color: inherit;
    text-decoration: none;
    font-size: 18px;

    &:hover,
    &[data-active='true'] {
      text-decoration: underline;
      text-decoration-color: var(--brand-color);
      text-decoration-thickness: 2px;
    }
  }
`

const SubmitButton = styled(Button)`
  box-shadow: none !important;
  max-width: 279px;
  width: 100%;
  height: 42px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 400 !important;
`

const GoogleBtnMain = styled(GoogleBtn)`
  max-width: 279px !important;
  width: 100% !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
`

const FlexOffice = styled.span`
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  display: block;

  a {
    color: var(--brand-color);
  }
`

const ForgotPassBtn = styled.button`
  text-align: center;
  width: 100%;
  margin-top: 20px !important;
  margin-bottom: 30px !important;
`

RegisterModal.defaultProps = {
  onSubmit: () => {},
  closeOnSubmit: false
}

export default RegisterModal
