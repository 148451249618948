import React from 'react';

import { Container } from '../wrapper';
import { LeftArrow } from '../icons';

import './styles.scss';

export default ({ image, children }) => (
  <Container topShift={false} bottomShift>
    <div className="ComparePanel">
      <div className="ComparePanel-col -image">
        <img className="ComparePanel-card" src={image} alt="" />
      </div>
      <div className="ComparePanel-arrowWrap">
        <LeftArrow className="ComparePanel-arrow" />
      </div>
      <div className="ComparePanel-col">
        <div className="ComparePanel-content">{children}</div>
      </div>
    </div>
  </Container>
);
