import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectMarketBySlug } from '../../selectors/filter-query';
import { Link } from 'react-router-dom';
import { ROOT, WORKPLACE } from '../../settings/routes';
import { SEARCH_QUERY, BASE_URL } from '../../settings';
import * as breakpoints from '../../styles/theme/breakpoints';

export const BreadcrumbsFeed = () => {
  const market = useSelector(state => selectMarketBySlug(state));
  const isWorkspace = useSelector(state => state.filterQuery.is_workplace);
  const isSearch = useSelector(state => state.filterQuery[SEARCH_QUERY]);

  if (market && !isSearch) {
    return (
      <FeedList>
        <Item>
          <a href={BASE_URL}>Home</a>
        </Item>
        <Item>
          <Link to={`/${isWorkspace ? WORKPLACE : ROOT}/`}>
            {isWorkspace ? 'Workspace' : 'Coworking'}
          </Link>
        </Item>
        <Item>
          <a href={window.location.href}>
            {`${market || ''} ${
              isWorkspace ? 'Workspace' : 'Coworking Spaces'
            }`}
          </a>
        </Item>
      </FeedList>
    );
  }

  return null;
};

export const BreadcrumbsLocation = ({ marketName, to, isWorkspace, name }) => {
  return (
    <LocList>
      <Item>
        <a href={BASE_URL}>Home</a>
      </Item>
      <Item>
        <Link to={`/${isWorkspace ? WORKPLACE : ROOT}/`}>
          {isWorkspace ? 'Workspace' : 'Coworking'}
        </Link>
      </Item>
      <Item>
        <Link to={to}>
          {`${marketName || ''} ${
            isWorkspace ? 'Workspace' : 'Coworking Spaces'
          }`}
        </Link>
      </Item>
      <Item>
        <a href={window.location.href}>{name}</a>
      </Item>
    </LocList>
  );
};

const FeedList = styled.ul`
  list-style: none;
  width: 100%;
  margin: 9px 9px 9px 0;
  padding: 0;

  @media ${breakpoints.mdUp} {
    margin: 9px 0 0 15px;
  }
`;

const LocList = styled.ul`
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
`

const Item = styled.li`
  font-weight: 600;
  font-size: 16px;
  float: left;
  line-height: 1.3;

  a, span {
    color: black;
  }

  a:hover {
    color: var(--brand-color);
  }

  &:not(:last-child)::after {
    font-size: 17px;
    content: '>';
    padding: 0 7px;
  }
`;
