import React from 'react'
// import PropTypes from 'prop-types';
import classnames from 'classnames'

import './styles.scss'

const Text = props => {
  const {
    size,
    center,
    style,
    color,
    children,
    className,
    margin,
    inherit,
    spaceBelow,
    cut,
    withLink
  } = props

  const textClassName = classnames(
    className,
    'Text',
    `Text-${color}`,
    `-spaceBelow-${spaceBelow}`,
    `Text-${size}`,
    {
      'Text-center': center,
      'Text-margin': margin,
      'Text-inherit': inherit,
      'Text-cut': cut,
      'Text-with-link': withLink
    }
  )

  return (
    <div style={style} className={textClassName}>
      <p>{children}</p>
    </div>
  )
}

Text.defaultProps = {
  center: false,
  color: 'default',
  size: 'small',
  spaceBelow: 'large'
}

export default Text
