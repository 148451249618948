import React from 'react';
import { useDispatch } from 'react-redux';

import { SpaceList } from '../../components/workplace-space-list';
import { spaceTypeChange } from '../../actions/ui';

import { openInteractiveTourModal, openSliderModal } from '../../actions/modal';

export default (props) => {
  const { spaces, showSpaceTypeToggle } = props;
  const dispatch = useDispatch();

  return (
    <SpaceList
      spaces={spaces}
      showSpaceTypeToggle={showSpaceTypeToggle}
      onInteractiveTour={iframeUrl => dispatch(openInteractiveTourModal(iframeUrl))}
      onOpenSlider={(images) => dispatch(openSliderModal({ images }))}
      onSpaceTypeChange={() => dispatch(spaceTypeChange())}
    />
  );
}
