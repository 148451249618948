import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Button from '../button'
import { renderField } from '../form-fields'
import { required } from '../../utils/lead-validation'

import './styles.scss'

let ForgotPass = props => {
  const { handleSubmit } = props

  return (
    <form id="forgot-pass-form" className="RegisterForm" onSubmit={handleSubmit}>
      <>
        <Field
          component={renderField}
          name="email"
          id="email"
          type="email"
          placeholder=""
          label="Email"
          required
          validate={required}
        />
        <SubmitButton type="submit">Reset your password</SubmitButton>
      </>
    </form>
  )
}

const SubmitButton = styled(Button)`
  box-shadow: none !important;
  width: 100% !important;
  max-width: 279px !important;
  height: 42px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
  margin-left: auto !important;
  margin-right: auto !important;
`

const selector = formValueSelector('login-form');

ForgotPass = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'forgot-pass-form'
})(ForgotPass)

ForgotPass = connect(state => {
  const email = selector(state, 'email');

  return {
    initialValues: {
      email
    }
  }
})(ForgotPass)

export default ForgotPass
