import React, { useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import { getAllWorkplaceSpaceAmenities } from '../../utils/amenities'
import { imageUrl } from '../../utils/crm-links';
import { getSpaceImages } from '../../utils/spaces';

import AmenitiesList from '../amenities-workplace';
import Tooltip from '../tooltip';

import {
  spaceAvailabilityFormat,
  spaceCapacityFormat,
  currencyBefore,
  spaceSqFtFormat,
  currencyFormat,
} from '../../utils/format';
import { sortWorkplacesMain, orderSpacesBy } from '../../utils/sort-spaces';
import { mdDown } from '../../styles/theme/breakpoints';

const TableWrap = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  font-size: 13px;
  overflow: auto;
`;

const Item = styled.li`
  padding: 13px 5px;
  display: flex;
  border: 1px solid #707070;

  & + & {
    border-top: none;
  }

  @media ${mdDown} {
    min-width: 900px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Col = styled.div`
  width: ${props => props.width}%;
  padding: 0 5px;

  ${props => props.center && 'text-align: center;'}

  ${props => props.bold && `
    font-weight: 600;
    color: #585756;
  `}
`;

const AmenitiesWrap = styled.div`
  display: flex;
  margin-top: 20px;

  label {
    margin-right: 10px;
  }
`;

const SpaceImageWrap = styled.button`
  max-width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 0;
  padding-right: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  img {
    max-width: 100%;
    margin-bottom: 10px;
  }
`;

const TooltipWrapper = styled.div`
  h3 {
    font-size: 14px;
  }

  p {
    font-size: 14px;
    margin-top: 6px;
  }
`;

const TooltipOverlay = () => (
  <TooltipWrapper>
    <h3>What's in the Virtual Tour</h3>
    <p>- Photographic Virtual Tour of the workplace</p>
    <p>- Request a Virtual Tour Guide</p>
    <p>- Pick out your office furniture</p>
  </TooltipWrapper>
);

export const SpaceList = (props) => {
  const [orderBy, setOrderBy] = useState(undefined);
  const [orderDesc, setOrderDesc] = useState(true);
  const [spaces, setSpaces] = useState(sortWorkplacesMain(props.spaces));
  const { onInteractiveTour, onOpenSlider, showSpaceTypeToggle, onSpaceTypeChange = () => {} } = props;
  const sortBy = (by) => {
    const isDesc = by === orderBy && !orderDesc ? !orderDesc : false;
    setOrderBy(by)
    setOrderDesc(isDesc)
    setSpaces(orderSpacesBy(spaces, by, isDesc))
  }

  const filterBtnClasses = (by) => {
    return classnames(
      'SpaceList-filterBtn',
      { '-active': by === orderBy },
      { '-desc': orderDesc },
    );
  }

  return (
    <>
      {showSpaceTypeToggle && (
        <button
          type="button"
          className="SpaceList-typeToggle"
          onClick={() => onSpaceTypeChange()}
        >
          Show me Coworking Options
        </button>
      )}
      <TableWrap>
        <Item>
          <Col width={70 / 6}>
            <button
              type="button"
              className={filterBtnClasses('name')}
              onClick={() => sortBy('name')}
            >
              Suite #
            </button>
          </Col>
          <Col width={70 / 6}>
            <button
              type="button"
              className={filterBtnClasses('capacity')}
              onClick={() => sortBy('capacity')}
            >
              Suitable <br />
              For
            </button>
          </Col>
          <Col width={70 / 6}>
            <button
              type="button"
              className={filterBtnClasses('price')}
              onClick={() => sortBy('price')}
            >
              Price <br />
              per Mo.
            </button>
          </Col>
          <Col width={70 / 6}>
            <button
              type="button"
              className={filterBtnClasses('promotionalPrice')}
              onClick={() => sortBy('promotionalPrice')}
            >
              Promotional {<br />} Price
            </button>
          </Col>
          <Col width={70 / 6}>
            <button
              type="button"
              className={filterBtnClasses('sqft')}
              onClick={() => sortBy('sqft')}
            >
              Sq/Ft
            </button>
          </Col>
          <Col width={70 / 6}>
            <button
              type="button"
              className={filterBtnClasses('date')}
              onClick={() => sortBy('date')}
            >
              Date {<br />} Available
            </button>
          </Col>

          <Col width={20} center bold>
            Unit Layout/Photography
          </Col>
          <Col width={10} bold>
            Virtual Tour
          </Col>
        </Item>
        {spaces.map(space => {
          const amenities = space.isWorkplace
            ? getAllWorkplaceSpaceAmenities(space)
            : false;
          return (
            space.isWorkplace && (
              <Item key={space.id}>
                <Col width={70}>
                  <Row>
                    <Col width={100 / 6}>
                      <span>{space.name}</span>
                    </Col>
                    <Col width={100 / 6}>
                      <span>{spaceCapacityFormat(space)}</span>
                    </Col>
                    <Col width={100 / 6}>
                      <span>
                        {currencyBefore(currencyFormat(space.spacePrice))}
                      </span>
                    </Col>
                    <Col width={100 / 6}>
                      <span>
                        {currencyBefore(currencyFormat(space.promotionalPrice))}
                      </span>
                    </Col>
                    <Col width={100 / 6}>
                      <span>{spaceSqFtFormat(space)}</span>
                    </Col>
                    <Col width={100 / 6}>
                      <span>
                        {space.dateAvailable && spaceAvailabilityFormat(space)}
                      </span>
                    </Col>
                    {amenities && (
                      <Col width={100}>
                        <AmenitiesWrap>
                          <label>
                            This Unit <br />
                            Offers:
                          </label>
                          <AmenitiesList size="medium" amenities={amenities} />
                        </AmenitiesWrap>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col width={20} center>
                  {space.dollhouseImageId && (
                    <SpaceImageWrap
                      onClick={() => onOpenSlider(getSpaceImages(space))}
                    >
                      <img
                        src={imageUrl(space.dollhouseImageId)}
                        alt={space.dollhouseImageName}
                      />
                      Click to enlarge
                    </SpaceImageWrap>
                  )}
                </Col>
                <Col width={10}>
                  {space.spaceVirtualTourUrl && (
                    <Tooltip
                      overlayClassName="SpacesTable-tooltip"
                      overlay={<TooltipOverlay />}
                      maxWidth={250}
                      overlayStyle={{ padding: 13 }}
                    >
                      <button
                        type="button"
                        className="SpaceList-actionBtn"
                        onClick={() =>
                          onInteractiveTour({
                            tourUrl: space.spaceVirtualTourUrl,
                          })
                        }
                      >
                        Virtual Tour
                      </button>
                    </Tooltip>
                  )}
                </Col>
              </Item>
            )
          );
        })}
      </TableWrap>
    </>
  );
};
