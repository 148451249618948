import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import supercluster from 'points-cluster';

import Marker from '../../components/location-marker';
import {
  MAPS_API_KEY as mapsKey,
  SEARCH_QUERY,
} from '../../settings';
import { ROOT, WORKPLACE } from '../../settings/routes';
import Wrapper from '../../components/filter-map-helpers';
import InfoBox from '../../components/info-box-mobile';
import Cluster from '../../components/cluster-marker';

import { syncVisibleMarkersWithListings, listingsGetByCoords, listingsGetByAddress } from '../../actions/listings';
import { bindMapToState } from '../../actions/reviews';
import toggleFavorites from '../../actions/favorites';
import { pinMarker } from '../../actions/ui';
import { setDistanceUnits, setCurrentZoom, setNearestMarket } from '../../actions/location';
import { openRegisterModal, openVerifyPassModal } from '../../actions/modal';
import { selectLatLonByLastRequest, selectPin } from '../../selectors/listings';
import { urlFilterQuery } from '../../utils/filter-url';
import { getNearestMarket } from '../../utils/markets';
import { getAllWorkplaceAmenities } from '../../utils/amenities';


import { imageUrl } from '../../utils/crm-links';
import {
  createMapOptionsMobile,
  handleApiLoaded,
  getLayerType,
  getVisibleMarkerIds, getBoundsOnChange,
  getPoints,
  getMapBounds,
} from '../../utils/map-helpers';
import { getCoordsByLabel } from '../../utils/coords-by-label';

class Markers extends Component {
  constructor() {
    super();
    this.state = {
      maptype: 'roadmap',
      clusters: [],
    };
  }

  componentDidUpdate(prevProps) {
    const {
      map, maps, filterQuery, listings, markets,
    } = this.props;
    const queryLabel = filterQuery[ROOT] || filterQuery[SEARCH_QUERY];
    const prevQueryLabel = prevProps.filterQuery[ROOT] || prevProps.filterQuery[SEARCH_QUERY];

    if (map) {
      if (queryLabel !== prevQueryLabel) {
        getCoordsByLabel(this.props, (list) => {
          const bounds = new maps.LatLngBounds();

          list.forEach(({ latitude, longitude }) => {
            const latLng = new maps.LatLng(latitude, longitude);
            bounds.extend(latLng);
          });
          map.fitBounds(bounds);
          this.props.setCurrentZoom({ zoom: map.getZoom() });
          this.markersVisibilityChanged();
        });
      } else if (urlFilterQuery(filterQuery) !== urlFilterQuery(prevProps.filterQuery)) {
        const center = map.getCenter();

        this.props.listingsGetByCoords({ lat: center.lat(), long: center.lng() })
          .then(() => this.markersVisibilityChanged());
      }

      if (map.getCenter() && markets) {
        this.props.setNearestMarket(getNearestMarket({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }, markets));
      }
    }

    if (!isEqual(prevProps.listings, listings)) {
      this.markersVisibilityChanged();
    }
  }

  onMapTypeChangeHandler = (type) => {
    this.setState({ maptype: type });
  }

  getMarker = (listing, index) => {
    const { favorites } = this.props;
    return (
      <Marker
        key={`marker-${listing.id}`}
        onClick={() => {
          this.props.pinMarker(listing.id);
        }}
        zIndex={index}
        lat={listing.latitude}
        lng={listing.longitude}
        isFavorite={favorites.includes(listing.id)}
      />
    );
  }

  setNewLocationCoords = (map) => {
    const newCenter = map.getCenter();
    this.props.setCurrentZoom({ zoom: map.getZoom() });

    this.props.listingsGetByCoords({ lat: newCenter.lat(), long: newCenter.lng() })
      .then(() => this.markersVisibilityChanged());
  }

  setNewZoom = (map) => {
    if (map.getZoom() < this.props.currentZoom) {
      const center = map.getCenter();
      this.props.listingsGetByCoords({ lat: center.lat(), long: center.lng() })
        .then(() => this.markersVisibilityChanged());
    } else {
      this.markersVisibilityChanged();
    }
    this.props.setCurrentZoom({ zoom: map.getZoom() });
  }

  handleMapLoaded = ({ map, maps }) => {
    const {
      center, zoom, route, lastRoute, listings
    } = this.props;
    handleApiLoaded(map, maps, this.props.bindMapToState);

    map.addListener('dragend', () => {
      this.setNewLocationCoords(map);
    });
    maps.event.addListenerOnce(map, 'mousemove', () => map.addListener('zoom_changed', () => this.setNewZoom(map))); // do event only if user change zoom, not fitBounds

    const filterProps = this.props.filterQuery.coworking === "san-francisco-bay-area" // Fix for San Francisco bug
      ? {
        ...this.props,
        filterQuery: { ...this.props.filterQuery, coworking: 'san-francisco' }
      }
      : this.props;

    if (route !== lastRoute) {
      getCoordsByLabel(filterProps, (list) => {
        const bounds = new maps.LatLngBounds();

        list.forEach(({ latitude, longitude }) => {
          const latLng = new maps.LatLng(latitude, longitude);
          bounds.extend(latLng);
        });
        map.fitBounds(bounds);
        this.props.setCurrentZoom({ zoom: map.getZoom() });
        this.markersVisibilityChanged();
      });
    } else {
      (() => {
        const bounds = new maps.LatLngBounds();

        listings.forEach(({ latitude, longitude }) => {
          const latLng = new maps.LatLng(latitude, longitude);
          bounds.extend(latLng);
        });
        map.fitBounds(bounds);
        this.props.setCurrentZoom({ zoom: map.getZoom() });
        this.markersVisibilityChanged();
      })()
    }

    if (center && route === lastRoute) {
      map.setCenter(center);
      map.setZoom(zoom);
    }
  }

  markersVisibilityChanged = () => {
    const { listings, maps, map } = this.props;

    if (maps) {
      this.createClusters();
      const ids = getVisibleMarkerIds(listings, maps, map);
      this.props.syncVisibleMarkersWithListings(ids);
    }
  }

  clusterClickHandler = (cluster) => {
    const { map, maps } = this.props;
    map.fitBounds(getBoundsOnChange(maps, cluster.points));
    this.markersVisibilityChanged();
  }

  createClusters() {
    const { listings, map } = this.props;

    if (map && !isEmpty(listings)) {
      const center = map.getCenter();
      const zoom = map.getZoom();
      const bounds = getMapBounds(map);

      const clusterSettings = {
        minZoom: 0,
        maxZoom: 15,
        radius: 30,
      };

      const clusters = supercluster(getPoints(listings), clusterSettings)({
        center,
        zoom,
        bounds,
      });

      this.setState({
        clusters: clusters.map(({
          wx, wy, numPoints, points,
        }) => ({
          lat: wy,
          lng: wx,
          numPoints,
          id: `${points[0].id}`,
          points,
        })),
      });
    }
  }

  render() {
    const { listingsById } = this.props;
    const { clusters, maptype } = this.state;

    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapsKey }}
        mapTypeControl={false}
        defaultCenter={{ lat: 38, lng: -91 }}
        zoom={4}
        options={createMapOptionsMobile}
        layerTypes={getLayerType(maptype)}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={this.handleMapLoaded}
        onMapTypeIdChange={this.onMapTypeChangeHandler}
      >
        {clusters.map((cluster, index) =>
          cluster.numPoints === 1 ? (
            this.getMarker(listingsById[cluster.points[0].id], index)
          ) : (
            <Cluster
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...cluster}
              key={cluster.id}
              onClick={() => this.clusterClickHandler(cluster)}
            />
          ),
        )}
      </GoogleMapReact>
    );
  }
}

const mapStateToProps = state => ({
  listings: selectLatLonByLastRequest(state),
  listingsById: state.listings.byId,
  favorites: state.favorites.ids,
  greviews: state.reviews.greviewsById,
  center: state.listings.center,
  zoom: state.listings.zoom,
  bounds: state.listings.bounds,
  lastRoute: state.listings.lastRoute,
  accoladesRoute: state.filterQuery.bestof,
  map: state.reviews.map,
  maps: state.reviews.maps,
  route: state.router.location.pathname,
  distanceUnits: state.location.distanceUnits,
  filterQuery: state.filterQuery,
  markets: state.markets.list,
});

const Map = connect(
  mapStateToProps, {
    bindMapToState,
    syncVisibleMarkersWithListings,
    toggleFavorites,
    pinMarker,
    push,
    setCurrentZoom,
    setDistanceUnits,
    listingsGetByCoords,
    listingsGetByAddress,
    setNearestMarket,
  })(Markers);


const FilterMapMobile = (props) => {
  const {
    withSearchBar,
    listing,
    isNotVisible,
    isCognitoAuth,
    userIsAuthenticated,
    isCrawler,
    people,
    visitorId,
    filterQuery: { is_workplace: isWorkplace },
    seoPage
  } = props;
  const isHighlighted = !isEmpty(listing);
  const expandedStyle = {
    height: !isHighlighted ? 250 : 430,
    position: !seoPage && 'fixed',
    width: '100%',
  };

  return (
    <Wrapper withSearchBar={withSearchBar} expanded={isHighlighted} isNotVisible={isNotVisible}>
      <div style={expandedStyle}>
        <Map />
        {isHighlighted && (
          <InfoBox
            zIndex={2}
            visible
            name={listing.name}
            essenceStatementMapPin={isWorkplace ? listing.essenceStatementMapPinWorkspace : listing.essenceStatementMapPin}
            marketplaceSlug={listing.marketplaceSlug}
            marketName={listing.marketName}
            formattedCardImage={listing.id && imageUrl(listing.listingCardImageId)}
            onFirstClick={!(isCognitoAuth || isCrawler ) ? () => {
              if (userIsAuthenticated && !isCognitoAuth) {
                props.openVerifyPassModal()
              } else {
                props.openRegisterModal({
                  heading: 'For specific suite pricing, availability, reviews, and more.',
                  closeOnSubmit: true,
                  initialValues: { seats: people, locations: [listing.id], isWorkspace: [!!isWorkplace] },
                  onSubmit: () => props.push(`/${isWorkplace ? WORKPLACE : ROOT}/${listing.marketSlug}/${listing.marketplaceSlug}`),
                  form: 'map-pin-registration',
                  visitorId,
                  seatsRequired: true,
                  isWorkspace: !!isWorkplace
                })
              }
            } : null}
            isWorkplace={isWorkplace}
            amenities={getAllWorkplaceAmenities(listing)}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default connect(state => ({
  greviews: state.reviews.greviewsById,
  listing: selectPin(state),
  userIsAuthenticated: state.auth.userIsAuthenticated,
  isCrawler: state.auth.isCrawler,
  people: state.filterQuery.people,
  visitorId: state.auth.visitorId,
  filterQuery: state.filterQuery,
  isCognitoAuth: state.cognito.token,
}), {
  openRegisterModal,
  push,
  openVerifyPassModal
})(FilterMapMobile);
