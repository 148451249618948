import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const ToggleButton = (props) => {
  const classes = classNames(
    'toggleButton',
    { '-active': props.active },
  );

  return (
    <button
      type="button"
      className={classes}
      onClick={props.onClick}
      aria-label={props.label}
    >
      <span>
        MENU
        <span className="toggleButton-lines" />
      </span>
    </button>
  );
};

ToggleButton.defaultProps = {
  active: false,
};

export default ToggleButton;
