import React from 'react';
import styled from 'styled-components';
import * as colors from '../../styles/theme/colors';
import * as breakpoints from '../../styles/theme/breakpoints';

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1 1;
  background-color: ${colors.secondary};
  line-height: 1.4;
  padding: 11px 16px;
  font-size: 16px;

  @media ${breakpoints.mdUp} {
    margin-top: 15px;
    margin-bottom: 15px;
    height: 60px;
    border-radius: 3px;
    margin-left: 15px;
  }

  @media ${breakpoints.smOnly} {
    margin-left: -15px;
    margin-right: -15px;
  }

  a {
    color: black;
    text-decoration: underline;
  }
`;

const Banner = ({ children }) => (
  <Wrap>
    <span>{children}</span>
  </Wrap>
)

export default Banner;
