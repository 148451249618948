import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import BottomNavbar from '../BottomNav';

import PagesList from '../../components/pages-list';
import Flex from '../../components/flex';
import { groupSubmarkets } from '../../selectors/markets';

import {
  TERMS_OF_SERVICE_ROUTE as termsOfService,
  PRIVACY_POLICY_ROUTE as privacyPolicy,
  BASE_URL as baseUrl,
} from '../../settings';

import { ROOT, WORKPLACE } from '../../settings/routes';

import { openFindSpaceModal } from '../../actions/modal';

const MainPageList = (props) => {
  const markets = useSelector(state => state.markets.list);
  const dispatch = useDispatch();

  return (
    <>
      <Flex wrap>
        {markets && (
          <PagesList title="Browse all locations">
            <ul>
              {groupSubmarkets(markets).map(market => (
                <li className={market.isSubmarket ? 'isSubmarket' : undefined} key={market.id}><Link to={`/${props.isWorkplace ? WORKPLACE : ROOT}/${market.marketSlug}/`}>{market.name}</Link></li>
              ))}
            </ul>
          </PagesList>
        )}
        <PagesList title="Search for space">
          <ul>
            <li><button onClick={() => dispatch(openFindSpaceModal())} type="button">Search Form</button></li>
          </ul>
        </PagesList>
        <PagesList title="List space">
          <ul>
            <li><a href={`${baseUrl}/verified-coworking-operator/`}>Get Verified</a></li>
            <li><a href={`${baseUrl}/list-your-space/`}>Register Your Space</a></li>
            <li><a href={`${baseUrl}/flexible-office-pro-software/`}>Flex Office Pro Software</a></li>
            <li><a href={`${baseUrl}/coworking-marketing/`}>Coworking Marketing, Sales, and Consulting Services</a></li>
            <li><a href={`${baseUrl}/list-your-coworking-space-with-upsuite/upsuite-healthy-spaces/`}>Upsuite Healthy Spaces</a></li>
            <li><a href={`${baseUrl}/coworking-operator-faq/`}>FAQ</a></li>
          </ul>
        </PagesList>
        <PagesList title="About us">
          <ul>
            <li><a href={`${baseUrl}/upsuite-team/`}>Upsuite Team</a></li>
            <li><a href={`${baseUrl}/about-us/upsuite-partners-help-us-serve-member-and-operator-needs/`}>Upsuite Partner Companies</a></li>
            <li><a href={`${baseUrl}/about-us/economic-development-partners/`}>Economic Development</a></li>
            <li><a href={`${baseUrl}/about-us/startup-accelerator-partners/`}>Startup Accelerators</a></li>
            <li><a href={`${baseUrl}/coworking-news/`}>Media</a></li>
          </ul>
        </PagesList>
        <PagesList title="Get more information">
          <ul>
            <li><a href={`${baseUrl}/${termsOfService}/`}>Terms of Service</a></li>
            <li><a href={`${baseUrl}/${privacyPolicy}/`}>Privacy Policy</a></li>
            <li><a href={`${baseUrl}/broker-registration/`}>Broker Program</a></li>
          </ul>
        </PagesList>
      </Flex>
      <BottomNavbar />
    </>
  );
};

export default MainPageList;
