import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import includes from 'lodash/includes';
import { getSchemaMarket } from '../../settings/schema-market';
import { selectMarketObject } from '../../selectors/filter-query';

import {
  BASE_URL,
} from '../../settings';
import {
  searchMetaTitle, searchMetaDesc,
} from '../../settings/text';
import { getLocByMarket } from '../../actions/seo';

import { ROOT, WORKPLACE, SEARCH } from '../../settings/routes';

const SeoHead = ({ marketSlug }) => {
  const market = useSelector(state => selectMarketObject(state));
  const filterQuery = useSelector(state => state.filterQuery);
  const dispatch = useDispatch();
  const pathname = useSelector(state => state.router.location.pathname);
  const isWorkspace = includes(pathname, WORKPLACE);
  const locations = useSelector(state => isWorkspace
    ? state.seo.workspacesByMarketSlug[marketSlug]
    : state.seo.locByMarketSlug[marketSlug]
  );

  useEffect(() => {
    dispatch(getLocByMarket(marketSlug, isWorkspace))
  }, [marketSlug, isWorkspace, dispatch]);

  return (
    <Helmet titleTemplate="%s">
      {marketSlug && market && (
        <link
          rel="canonical"
          href={`${BASE_URL}/${isWorkspace ? WORKPLACE : ROOT}/${market.marketSlug}/`}
        />
      )}
      {!marketSlug && (
        <link rel="canonical" href={`${BASE_URL}/${isWorkspace ? WORKPLACE : ROOT}/${SEARCH}/`} />
      )}

      {marketSlug && market && locations && (
        <script type="application/ld+json">
          {JSON.stringify(getSchemaMarket(market, locations, isWorkspace))}
        </script>
      )}

      {!isWorkspace && market && market.metaTitle && <title>{market.metaTitle}</title>}
      {isWorkspace && market && market.metaTitleWorkspace && <title>{market.metaTitleWorkspace}</title>}

      {!isWorkspace && market && market.metaDescription && (
        <meta name="description" content={market.metaDescription} />
      )}
      {isWorkspace && market && market.metaDescriptionWorkspace && (
        <meta name="description" content={market.metaDescriptionWorkspace} />
      )}

      {!filterQuery[ROOT] && <title>{searchMetaTitle}</title>}
      {!filterQuery[ROOT] && (
        <meta name="description" content={searchMetaDesc} />
      )}
    </Helmet>
  );
};

export default SeoHead;
