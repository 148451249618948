import { push } from 'connected-react-router'
import { checkHttpStatus, parseJSON } from '../utils'
import {
  TOGGLE_FAVORITES,
  TOGGLE_WORKPLACE_FAVORITES,
  BULK_SET_FAVORITES,
  FAVORITES_GET_REQUEST,
  FAVORITES_GET_SUCCESS,
  FAVORITES_GET_FAILURE
} from '../constants'
import * as api from '../api/listings'
import { ROOT, COMPARE, WORKPLACE } from '../settings/routes'

const getFavorites = () => JSON.parse(localStorage.getItem('favorites')) || []

const getWorkplaces = () =>
  JSON.parse(localStorage.getItem('favorite_workplaces')) || []

const toggleFavorites = (id, workplace = false) => {
  const favorites = workplace ? getWorkplaces() : getFavorites()
  let ids = []

  if (!favorites) {
    ids = [id]
  } else if (favorites.includes(id)) {
    ids = favorites.filter(item => id !== item)
  } else {
    ids = [...favorites, id]
  }

  localStorage.setItem(
    workplace ? 'favorite_workplaces' : 'favorites',
    JSON.stringify(ids)
  )

  return {
    type: workplace ? TOGGLE_WORKPLACE_FAVORITES : TOGGLE_FAVORITES,
    payload: { ids }
  }
}

export default toggleFavorites

export const bulkSetFavorites = payload => {
  localStorage.setItem('favorites', JSON.stringify(payload))

  return {
    type: BULK_SET_FAVORITES,
    payload
  }
}

const favoritesGetRequest = () => ({
  type: FAVORITES_GET_REQUEST
})

const favoritesGetSuccess = payload => ({
  type: FAVORITES_GET_SUCCESS,
  payload
})

const favoritesGetFailure = () => ({
  type: FAVORITES_GET_FAILURE
})

export const favoritesGet = ids => dispatch => {
  dispatch(favoritesGetRequest())

  return api
    .favoriteListingsGet(ids)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => {
      dispatch(favoritesGetSuccess(response))
    })
    .catch(error => {
      dispatch(favoritesGetFailure(error))
    })
}

export const removeCompareListings = (
  listings,
  listing,
  isWorkspace = false
) => dispatch => {
  const favorites = isWorkspace ? getWorkplaces() : getFavorites()

  if (favorites.includes(listing.id)) {
    dispatch(toggleFavorites(listing.id, isWorkspace))
  }

  const shareIds = listings
    .map(el => el.id)
    .filter(id => id !== listing.id)
    .join(',')

  return dispatch(
    push(`/${isWorkspace ? WORKPLACE : ROOT}/${COMPARE}/${shareIds}`)
  )
}

export const removeSavedSpaces = (id, isWorkspace = false) => dispatch => {
  const favorites = isWorkspace ? getWorkplaces() : getFavorites()

  if (favorites.includes(id)) {
    dispatch(toggleFavorites(id, isWorkspace))
  }
}
