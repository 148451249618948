import { createSelector } from 'reselect';
import {
  getUsers,
} from './mappers';

const byUsername = state => state.users.byUsername;
const userNames = state => state.users.userNames;

export const selectUsers = createSelector(
  userNames,
  byUsername,
  getUsers,
);
