import React from 'react'
import classnames from 'classnames'

import Heading from '../heading'

import './style.scss'

const RegisterMessage = ({ heading, children, className }) => {
  const classes = classnames('RegisterMessage', className)

  return (
    <div className={classes}>
      {heading && (
        <Heading weight="semibold" level="2" size="middle">
          {heading}
        </Heading>
      )}
      {children}
    </div>
  )
}

export default RegisterMessage
