import React from 'react';
import Flatpickr from 'react-flatpickr';

// eslint-disable-next-line
import 'flatpickr/dist/themes/light.css';
import './styles.scss';

export default props =>
  (<Flatpickr
    options={{
      minDate: 'today',
      dateFormat: 'Y-m-d',
      altInput: true,
    }}
    {...props}
    value={props.value !== '0000-00-00' && props.value}
    onChange={(dateString, formatedDate) => {
      props.onChange(formatedDate === '' ? null : formatedDate);
    }}
  />);

