import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Marker = (props) => {
  const {
    hover, highlight, onMouseOver, onClick,
    onMouseOut, listingUrl, isFavorite, children, zIndex,
  } = props;

  const color = (hover || highlight) ? '#63aa83' : 'var(--brand-color)';
  const settings = {
    width: 24,
    height: 33,
    viewBox: '0 0 450 620',
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
  };

  return (
    <div className="LocationMarker" style={{ zIndex }} onMouseOver={onMouseOver} onMouseOut={onMouseOut} >
      <MarkerWrapper listingUrl={listingUrl} onClick={onClick}>
        <svg {...settings}>
          <path fillOpacity="1" fill={color} stroke="#63aa83" strokeOpacity={hover || highlight ? 1 : 0} strokeWidth="20" d="M10.05 243.43a452.657 452.657 0 0 0 63.26 162.69c32.37 51.13 54.16 84.11 65.33 98.94C177.25 556.3 241.4 620.59 240.66 620c-.15-.13 9.91-12.94 30.22-38.42a1129.498 1129.498 0 0 0 107.9-162.38c9.64-17.65-8.94 16.35.55-1.02a615.462 615.462 0 0 0 65.41-184.94c3.01-16.54-3.53 19.43.03-.14 9.6-52.84-2.39-107.34-33.26-151.28-5.23-7.44 9.15 13.02-.63-.89a157.182 157.182 0 0 0-66.2-53.9c-8.81-3.82 6.68 2.88-1.83-.8a332.555 332.555 0 0 0-87.88-24.41c-2.86-.38 16.43 2.2 9.14 1.22a194.165 194.165 0 0 0-46.96-.55c-9.93 1.09 3.65-.4-1.32.14a430.987 430.987 0 0 0-94.15 21.23c-14.15 4.91-28.48 13.03-43 24.37-43.35 33.85-59.2 64.41-69.76 102.28-6.29 22.55-5.9 53.51 1.13 92.92z" />
          {isFavorite && (
            <Fragment>
              <path fill="#fff" d="M228.19 61.52c82.39 0 149.18 64.68 149.18 144.46 0 79.77-66.79 144.45-149.18 144.45S79.01 285.75 79.01 205.98c0-79.78 66.79-144.46 149.18-144.46z" />
              <path fill="#e7483d" d="M226.23 148.87c-4.09-4.41-6.37-6.86-6.82-7.34-17.39-18.79-45.73-21.83-64.72-4.93-21.77 19.39-22.9 54.17-3.43 75.17 6.71 7.23 60.39 65.06 67.09 72.28 4.35 4.67 11.38 4.67 15.72 0 6.71-7.22 60.39-65.03 67.1-72.26 19.53-21.02 18.38-55.58-3.39-75.18-21.77-19.59-47.29-13.87-64.72 4.92-.46.48-2.73 2.93-6.83 7.34z" />
            </Fragment>
          )}
        </svg>
      </MarkerWrapper>
      {children}
    </div>
  );
};

const MarkerWrapper = ({ children, listingUrl, onClick }) => (
  <Fragment>
    {onClick ? (
      <button className="LocationMarker-link" onClick={onClick} type="button">
        {children}
      </button>
    ) : (
      <Link className="LocationMarker-link" to={listingUrl || window.location.pathname}>
        {children}
      </Link>
    )}
  </Fragment>
);

export default Marker;
