import React from 'react';

import Heading from '../heading';

import './styles.scss';

export default ({ children, title }) => (
  <div className="PagesList">
    {title && <Heading level="2" center={false} size="small" spaceAbove="small">{title}</Heading>}
    {children}
  </div>
);
