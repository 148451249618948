import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import Heading from '../../components/heading';
import ButtonGroup from '../../components/button-group';
import ButtonMeta from '../../components/button-meta';
import MailTo from '../../components/mailto-link';
import Flex from '../../components/flex';
import { CompareLayoutHelper, CompareRow as Row } from '../../components/compare-page-helpers';
import { Default, Mobile } from '../../components/responsive-helpers';
import Swipeable from '../../components/swipeable';
import { Download } from '../../components/icons';
import CompareAddSpaces from '../../components/compare-add-spaces';

import { favoritesGet } from '../../actions/favorites';
import { openCtaActionsModal, openExportListModal, openLeadRegisterModal } from '../../actions/modal';
import { spacesByLocationIdsGet } from '../../actions/spaces';
import { compareMobilePaginate } from '../../actions/ui';
import marketsGet from '../../actions/markets';

import { exportFileGet } from '../../api/export';

import CompareCardsMobile from './compare-cards-mobile';
import CompareSpacesMobile from './compare-spaces-mobile';
import CompareBestForMobile from './compare-best-for-mobile';
import CompareAmenitiesMobile from './compare-amenities-mobile';
import MobilePaginate from './compare-mobile-paginate';
import CompareMap from './compare-map';
import BottomNavbar from '../BottomNav';
import LeftColumn from './compare-left-col';
import SlideColumns from './compare-slide-col';
import EmptyPanel from './compare-empty-listins';

import { getFilterQueryLink } from '../../actions/filter-query';
import { googleAnalytics } from '../../utils/analytics';

import { selectCompareIds, selectCompareListings } from '../../selectors/listings';
import { selectByLocationId, selectChosenSpaceIds } from '../../selectors/spaces';

import { ROOT, COMPARE } from '../../settings/routes';

const loadData = (props) => {
  const { ids } = props;

  props.marketsGet();

  props.favoritesGet(ids).then(
    props.spacesByLocationIdsGet(ids),
  );
};

class Favorites extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    isUserSignedIn: false,
    token: null,
  }

  componentDidMount() {
    if (!this.props.match.params.ids && !isEmpty(this.props.ids)) {
      this.props.push(`/${ROOT}/${COMPARE}/${this.props.ids.join(',')}/`);
    }

    loadData(this.props);
    googleAnalytics(this.props.location.pathname, 'Compare & Tour Coworking Spaces | Upsuite');
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.ids, nextProps.ids)) {
      loadData(nextProps);
    }
  }

  render() {
    const {
      ids, compareListings, paginate, filterQuery, spaces, chosenSpaceIds,
    } = this.props;
    const searchFilterQueryUrl = getFilterQueryLink(filterQuery) || `/${ROOT}/`;

    const mailBody = `I was researching a few Coworking spaces on Upsuite.com and narrowed it down to a couple. If we book a tour or trial day at a verified location through their site we get 5% off our first 12 months.%20%0A %20%0A
Check them out: %20%0A ${window.location.origin}/${ROOT}/${COMPARE}/${ids.join(',')}/`;

    return (
      <Flex column fullHeight grow stickyFooter>
        <Helmet
          titleTemplate="%s | Upsuite"
          defaultTitle="Upsuite"
        >
          <title>Compare & Tour Coworking Spaces</title>
          <meta name="description"
            content="Compare coworking office spaces in major metro markets like Chicago, Toronto and Denver using Upsuite. Upsuite provides easy search and compare for coworking space. Upsuite helps teams find the perfect fit coworking office." />
          <link rel="canonical" href={`${window.location.origin}/${ROOT}/${COMPARE}/`} />
        </Helmet>
        <Heading
          level="1"
          size="xsmall"
          spaceBelow="small"
          spaceAbove="medium"
        >Compare, Share, and Book Tours at Your Top Spaces</Heading>
        {!isEmpty(ids) && (
          <>
            <ButtonGroup column={false} gutter="small" fullWidth={false} wrap>
              {chosenSpaceIds && this.props.canRegisterLeads && (
                <ButtonMeta
                  scheme="gray"
                  onClick={() => this.props.openLeadRegisterModal({
                    ids,
                    isWorkspace: (ids || []).map(() => false)
                  })}
                  className="export-list-button button"
                >
                  Register Lead
                </ButtonMeta>
              )}

              {chosenSpaceIds && (
                <ButtonMeta
                  svgIcon={<Download />}
                  scheme="gray"
                  onClick={() => {
                    if (this.state.isUserSignedIn) {
                      exportFileGet(chosenSpaceIds.join(','), (ids || []).join(','), this.state.token);
                    } else {
                      this.props.openExportListModal({
                        onFormSubmit: () => exportFileGet(chosenSpaceIds.join(','), (ids || []).join(','), null),
                        listingName: compareListings.map(item => item.name).join(', '),
                      });
                    }
                  }}
                  className="export-list-button button"
                >Export List</ButtonMeta>
              )}
              <MailTo
                subject="Check out these Coworking Spaces"
                body={mailBody}
                className="share-list-button"
              >Share List</MailTo>
              <ButtonMeta
                scheme="cta"
                onClick={() => this.props.openCtaActionsModal({
                  heading: 'Visit These Spaces',
                  buttonName: 'Visit These Spaces',
                  fields: ['firstName', 'email', 'seats', 'phoneNumber', 'preferredTourDate', 'preferredTourTime', 'moveinDate'],
                  initialValues: {
                    locations: ids,
                    isWorkspace: (ids || []).map(() => false),
                    visitorId: this.props.visitorId,
                    form: 'visit-these-spaces',
                  },
                  isWorkspace: false,
                  successText: 'Thanks for booking your tour through Upsuite! We will be in touch shortly!',
                })
                }
                style={{ marginLeft: 5, marginRight: 5 }}
              >Visit These Spaces</ButtonMeta>
            </ButtonGroup>
            <CompareLayoutHelper>
              <Default>
                <Row>
                  <LeftColumn compareListings={compareListings} />
                  <SlideColumns
                    listings={compareListings}
                    spaces={spaces}
                    searchFilterQueryUrl={searchFilterQueryUrl}
                  />
                </Row>
              </Default>
              <Mobile>
                <Row>
                  <CompareMap listings={compareListings} mobile />
                </Row>
                <MobilePaginate listings={compareListings} isSticky />
                <Swipeable
                  onSwipedLeft={paginate < ids.length ? () => this.props.compareMobilePaginate(paginate + 1) : null}
                  onSwipedRight={paginate > 0 ? () => this.props.compareMobilePaginate(paginate - 1) : null}
                >
                  {!isEmpty(compareListings) &&
                    <Row>
                      <CompareCardsMobile listings={compareListings} />
                    </Row>
                  }
                  {paginate === ids.length &&
                    <CompareAddSpaces
                      link={searchFilterQueryUrl}
                      label="Add Another Space"
                    />}
                  <Row>
                    <CompareSpacesMobile listings={compareListings} spaces={spaces} />
                  </Row>
                  <Row>
                    <CompareBestForMobile listings={compareListings} />
                  </Row>
                  <Row>
                    <CompareAmenitiesMobile listings={compareListings} />
                  </Row>
                </Swipeable>
              </Mobile>
            </CompareLayoutHelper>
          </>
        )}
        {isEmpty(ids) && <EmptyPanel />}
        <BottomNavbar />
      </Flex>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ids: selectCompareIds(state, ownProps),
  compareListings: selectCompareListings(state, ownProps),
  spaces: selectByLocationId(state),
  paginate: state.ui.compareMobilePaginate,
  filterQuery: state.filterQuery,
  greviews: state.reviews.greviewsById,
  chosenSpaceIds: selectChosenSpaceIds(state, ownProps),
  compareCardsHeight: state.ui.compareCardsHeight,
  isUserSignedIn: state.auth.isUserSignedIn,
  firstName: state.lead.firstName,
  email: state.lead.email,
  seats: state.lead.seats,
  phoneNumber: state.lead.phoneNumber,
  preferredTourDate: state.lead.preferredTourDate,
  moveinDate: state.lead.moveinDate,
  visitorId: state.auth.visitorId,
  canRegisterLeads: state.auth.canRegisterLeads
});

export default connect(
  mapStateToProps, {
  favoritesGet,
  openCtaActionsModal,
  spacesByLocationIdsGet,
  compareMobilePaginate,
  openExportListModal,
  openLeadRegisterModal,
  push,
  marketsGet,
},
)(Favorites);
