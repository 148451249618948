import React from 'react';
import { Field, reduxForm } from 'redux-form';

import Button from '../button';
import { renderField, dateField } from '../form-fields';
import { positiveNumber } from '../../utils/validation';

import './styles.scss';

const required = value => (value ? undefined : 'Please complete this required field.');
const email = value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? 'Email must be formatted correctly.'
  : undefined);

const CtaForm = (props) => {
  const { handleSubmit, buttonName, fields } = props;

  return (
    <form className="CtaForm" onSubmit={handleSubmit}>
      {fields.requestInfo && (
        <Field
          component={renderField}
          name="requestInfo"
          id="requestInfo"
          type="text"
          placeholder=""
          label="What else would you like to know about this location?"
          required
          validate={required}
        />
      )}
      {fields.firstName && (
        <Field
          component={renderField}
          name="firstName"
          id="firstName"
          type="text"
          placeholder=""
          label="First Name"
          required
          validate={required}
        />
      )}
      {fields.email && (
        <Field
          component={renderField}
          name="email"
          id="email"
          type="text"
          placeholder=""
          label="Work Email"
          required
          validate={[required, email]}
        />
      )}
      {fields.seats && (
        <Field
          component={renderField}
          name="seats"
          id="seats"
          type="number"
          placeholder=""
          label="Number of People"
          required
          validate={[required, positiveNumber]}
        />
      )}
      {fields.phoneNumber && (
        <Field
          component={renderField}
          name="phoneNumber"
          id="phoneNumber"
          type="text"
          placeholder=""
          label="What is the phone number to coordinate the visit?"
          required
          validate={required}
        />
      )}
      <div className="FormField-columns">
        {fields.preferredTourDate && (
          <Field
            component={dateField}
            name="preferredTourDate"
            id="preferredTourDate"
            type="text"
            placeholder=""
            label="Your preferred date to visit"
          />
        )}
        {fields.preferredTourTime && (
          <div className="FormField">
            <label className="FormField-label">
              <span>What time of day?</span>
              <Field
                name="preferredTourTime"
                id="preferredTourTime"
                component="select"
                className="FormField-input"
              >
                <option value=""></option>
                <option value="Early morning">Early morning</option>
                <option value="Late morning">Late morning</option>
                <option value="Early afternoon">Early afternoon</option>
                <option value="Late afternoon">Late afternoon</option>
              </Field>
            </label>
          </div>
        )}
      </div>

      {fields.moveinDate && (
        <Field
          component={dateField}
          name="moveinDate"
          id="moveinDate"
          type="text"
          placeholder=""
          label="What date do you expect to move in?"
        />
      )}
      <Button type="submit">{buttonName || 'Submit'}</Button>
    </form>
  );
};

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  form: 'cta-form',
})(CtaForm);
