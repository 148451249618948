import React, { Fragment, useState, useEffect } from 'react';
import classnames from 'classnames';

import './styles.scss';

const FilterList = (props) => {
  const {
    className,
    amenitiesByCategory,
    amenitiesLookup,
    teamtypes,
    onApplyFilters,
    amenitiesChecked,
    teamTypesChecked,
  } = props;
  const classes = classnames(
    className,
    'FilterList',
  );

  const [checkedAmenities, setAmenities] = useState(amenitiesChecked || []);
  const [checkedTeamTypes, setTeamTypes] = useState(teamTypesChecked || []);

  useEffect(() => {
    setAmenities(amenitiesChecked || []);
  }, [amenitiesChecked]);

  useEffect(() => {
    setTeamTypes(teamTypesChecked || []);
  }, [teamTypesChecked]);

  return (
    <div className={classes}>
      {amenitiesByCategory.map(({ category, amenities }) => (
        <Fragment key={category}>
          <h3>{category}</h3>
          <ul className="FilterList-list">
            {amenities.map(amenity => (
              <li className="FilterList-item" key={amenity}>
                <label
                  htmlFor={amenity}
                  className="FilterList-label"
                >
                  <input
                    id={amenity}
                    type="checkbox"
                    checked={checkedAmenities && checkedAmenities.includes(amenity)}
                    onChange={() => {
                      if (!checkedAmenities) {
                        return setAmenities([amenity]);
                      }

                      return setAmenities(
                        checkedAmenities.includes(amenity)
                          ? checkedAmenities.filter(val => val !== amenity)
                          : [...checkedAmenities, amenity],
                      );
                    }}
                  />
                  {amenitiesLookup[amenity].title}
                </label>
              </li>
            ))}
          </ul>
        </Fragment>

      ))}
      <h3>Team type</h3>
      <ul className="FilterList-list">
        {teamtypes.map(({ title, slug }) => (
          <li className="FilterList-item" key={slug}>
            <label
              htmlFor={slug}
              className="FilterList-label"
            >
              <input
                id={slug}
                type="checkbox"
                checked={checkedTeamTypes && checkedTeamTypes.includes(slug)}
                onChange={() => {
                  if (!checkedTeamTypes) {
                    return setTeamTypes([slug]);
                  }

                  return setTeamTypes(
                    checkedTeamTypes.includes(slug) ?
                      checkedTeamTypes.filter(val => val !== slug) :
                      [...checkedTeamTypes, slug],
                  );
                }}
              />
              {title}
            </label>
          </li>
        ))}
      </ul>

      <button
        className="FilterList-button"
        type="button"
        onClick={() => onApplyFilters({
          amenities: checkedAmenities,
          teamtypes: checkedTeamTypes,
        })}
      >
        Apply
      </button>
    </div>
  );
};

export default FilterList;
