import React from 'react'

import Select from '../../components/select'
import { currencyFormat, currencyBefore } from '../../utils/format'

import '../../components/compare-spaces/styles.scss'

const getSpaceLabel = ({ spaceCapacity, spaceType, spacePrice, sqFt }) => {
  const label = [
    `${
      spaceCapacity > 1 ? `${spaceCapacity} Person` : '1 Person'
    } ${spaceType}`,
    spacePrice && currencyBefore(currencyFormat(spacePrice)),
    sqFt && `${sqFt} Sq/ft`
  ]

  return label.filter(l => l).join(', ')
}

const getSpaceTerm = ({ spaceCapacity, spaceType, spacePrice }) => {
  const label = [
    `${
      spaceCapacity > 1 ? `${spaceCapacity} Person` : '1 Person'
    } ${spaceType}`,
    spacePrice && currencyBefore(currencyFormat(spacePrice)),
    '12 Months'
  ]

  return label.filter(l => l).join(', ')
}

const CompareSpaces = (props) => {
  const { onSpaceChanged, activeId, isProposal } = props

  const spaces = (props.spaces || []).filter(item => item?.isAvailable)
  const activeSpace = spaces.find(item => item.id === activeId)
  const options =
    spaces &&
    spaces.map((item) => ({
      label: isProposal ? getSpaceTerm(item) : getSpaceLabel(item),
      value: item.id
    }))

  return (
    <div className="CompareSpaces">
      <Select
        options={options}
        onChange={({ value }) => onSpaceChanged(value)}
        placeholder="Choose Space"
        clearable={false}
        searchable={false}
        value={
          activeSpace
            ? { label: isProposal ? getSpaceTerm(activeSpace) : getSpaceLabel(activeSpace), value: activeId }
            : ''
        }
      />
      <ul className="CompareSpaces-list">
        <li className="CompareSpaces-item">
          {activeSpace && activeSpace.spaceCapacity
            ? activeSpace.spaceCapacity
            : 'N/A'}
        </li>
        <li className="CompareSpaces-item">
          {activeSpace && activeSpace.spacePrice
            ? activeSpace.spacePrice
            : 'N/A'}
        </li>
        {isProposal && (
          <>
            <li className="CompareSpaces-item">
              {activeSpace && activeSpace.spacePrice
                ? activeSpace.spacePrice
                : 'N/A'}
            </li>
            <li className="CompareSpaces-item">
              {activeSpace && activeSpace.spacePrice
                ? Math.round(activeSpace.spacePrice * 0.95)
                : 'N/A'}
            </li>
          </>
        )}
        <li className="CompareSpaces-item">
          {activeSpace && activeSpace.sqFt ? activeSpace.sqFt : 'N/A'}
        </li>
      </ul>
    </div>
  )
}

export default CompareSpaces
