import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import queryString from 'query-string';

import FilterMap from '../FilterMap';
import FilterMapMobile from '../FilterMapMobile';
import ListingsFeed from '../ListingsFeed';

import Flex from '../../components/flex';
import { ListingsFeedWrap } from '../../components/listings-feed';
import { Desktop, TabletDown } from '../../components/responsive-helpers';

import { filterQuerySet } from '../../actions/filter-query';
import marketsGet from '../../actions/markets';
import { fixedHeaderOn, fixedHeaderOff, highlightListingReset, pinMarkerReset, bindScrollTo } from '../../actions/ui';
import { setMapBounds } from '../../actions/listings';
import { mapExtraFilterQuery } from '../../actions/filter-query-extra-params';
import { scrollTop, scrollTo } from '../../utils/ui';
import { googleAnalytics } from '../../utils/analytics';
import { selectNoLocations } from '../../selectors/listings';
import { selectIfIsSearch } from '../../selectors/filter-query';

import SearchBar from '../SearchBar';
import { ROOT } from '../../settings/routes';
import SeoHead from './seo-head';

class Listings extends Component {
  constructor(props) {
    super(props);

    this.handleScroll = debounce(this.handleScroll, 500);
  }

  componentDidMount() {
    const { dispatch, location: { search, pathname }, match: { params: { marketSlug }} } = this.props;
    const params = {
      [ROOT]: marketSlug,
      ...mapExtraFilterQuery(this.props.match.url),
      ...queryString.parse(search),
    };

    if (this.props.scroll) {
      scrollTo(this.props.scroll);
    }

    dispatch(fixedHeaderOn());
    dispatch(marketsGet());
    dispatch(highlightListingReset());
    dispatch(filterQuerySet(params));
    window.addEventListener('scroll', this.handleScroll, false);

    googleAnalytics(pathname, 'Upsuite | Find Space');
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props;
    if (this.props.location.search !== nextProps.location.search ||
      this.props.match.params.marketSlug !== nextProps.match.params.marketSlug
      || this.props.location.pathname !== nextProps.location.pathname
    ) {
      const params = {
        [ROOT]: nextProps.match.params.marketSlug,
        ...mapExtraFilterQuery(nextProps.match.url),
        ...queryString.parse(nextProps.location.search),
      };

      dispatch(filterQuerySet(params));
      dispatch(highlightListingReset());
      dispatch(pinMarkerReset());

      scrollTop();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.location.search !== nextProps.location.search ||
      this.props.match.params.marketSlug !== nextProps.match.params.marketSlug ||
      this.props.scroll !== nextProps.scroll
    ) {
      return false;
    }

    return true;
  }

  componentWillUnmount() {
    const { map, route, dispatch } = this.props;

    this.props.dispatch(fixedHeaderOff());
    window.removeEventListener('scroll', this.handleScroll, false);

    if (map) {
      dispatch(setMapBounds({
        center: {
          lat: map.getCenter().lat(),
          lng: map.getCenter().lng(),
        },
        zoom: map.getZoom(),
        lastRoute: route,
      }));
    }
  }

  handleScroll = () => this.props.dispatch(bindScrollTo(window.pageYOffset))

  render() {
    const { mapIsHidden, ifIsSearch, match: { params: { marketSlug }} } = this.props;

    return (
      <Flex column fullHeight>
        <SeoHead marketSlug={marketSlug} />
        <TabletDown>
          <FilterMapMobile isNotVisible={mapIsHidden} />
        </TabletDown>
        {!ifIsSearch &&
          <SearchBar />
        }
        <ListingsFeedWrap fullWidth={mapIsHidden}>
          <ListingsFeed withSearchBar fullWidth={mapIsHidden} />
          <Desktop>
            <FilterMap withSearchBar isNotVisible={mapIsHidden} />
          </Desktop>
        </ListingsFeedWrap>
      </Flex>
    );
  }
}

export default connect(state => ({
  scroll: state.ui.scroll,
  mapIsHidden: !state.ui.isFeedMapVisible || selectNoLocations(state) || selectIfIsSearch(state),
  ifIsSearch: selectIfIsSearch(state),
  map: state.reviews.map,
  route: state.router.location.pathname,
}))(Listings);
