import React from 'react';
import { useParams } from 'react-router-dom'
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Hero from './hero';
import {
  AboutRow,
  ReviewsRow,
  FaqRow,
  PopularRow,
  FilterSectionTitle,
  BestOfSection,
} from './text-sections';
import BottomNavbar from '../BottomNav';
import BlogPosts from '../../components/blog-posts';
import { posts } from './posts';
import Amenities from './amenities';
import Map from './map';
import { mdUp } from '../../styles/theme/breakpoints';
import { dataByMarket } from './market-data';

const LandingPage = () => {
  const { marketSlug } = useParams();

  return (
    <>
      {dataByMarket[marketSlug] && (
        <Helmet titleTemplate="%s">
          <title>{`Find Coworking Spaces in ${dataByMarket[marketSlug].name} | Upsuite`}</title>
          <meta name="description" content={`Find top coworking spaces in ${dataByMarket[marketSlug].name}, United States. See reviews, prices, photos, and more. Schedule a virtual tour or book your work space on Upsuite today.`} />
          <link
            rel="canonical"
            href={window.location.href}
          />
        </Helmet>
      )}
      <Wrap>
        {dataByMarket[marketSlug] ? (
          <>
            <Hero marketSlug={marketSlug} />
            <AboutRow marketSlug={marketSlug} />
            <FilterSectionTitle marketSlug={marketSlug} />
            <FilterSection>
              <Map style={{ width: '100%', height: '100%' }} marketSlug={marketSlug} />
            </FilterSection>
            <PopularRow marketSlug={marketSlug} />
            <Amenities marketSlug={marketSlug} />
            <BestOfSection marketSlug={marketSlug} />
            <ReviewsRow />
            <FaqRow marketSlug={marketSlug} />
            <BlogPosts
              posts={posts}
              sectionTitle="Upblog: Coworking, Flexible Offices, and the Future of Work"
            />
          </>
        ) : (
          <NotFound>
            <h1>404 Page Not Found</h1>
          </NotFound>
        )}
        <BottomNavbar />
      </Wrap>
    </>
  );
};

const Wrap = styled.div`
  width: 100%;
`;

const NotFound = styled.div`
  display: flex;
  height: calc(100vh - 200px);
  width: 100%;
  justify-content: center;
  align-items: center;
`

const FilterSection = styled.div`
  position: relative;
  background-color: #f8f8f8;
  height: 300px;

  @media ${mdUp} {
    height: 500px;
  }
`;

export default LandingPage;
