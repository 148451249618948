import styled from 'styled-components';

export const InteractiveTourWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${props => !props.isFastOffice && `
    display: flex;
    flex-direction: column;
  `}

  iframe {
    width: 100%;
    height: 100%;
    border: none;

    ${props => !props.isFastOffice && `
      margin-top: 15px;
      margin-bottom: 15px;
    `}
  }
`;
