import { Crawler } from 'es6-crawler-detect/src';
import isEmpty from 'lodash/isEmpty';
import uuid from 'uuid/v4';
import { createReducer } from '../utils';
import { getCookie, setCookie } from '../utils/cookies';
import { canRegister } from '../utils/register-code';
import {
  AUTH_IDENTITY,
  AUTH_USER_SUCCESS,
  AUTOCHECK_INFO,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  COGNITO_AUTH_FAILURE,
  USER_REGISTER_RESET
} from '../constants';

const CrawlerDetector = new Crawler();
const userAgentString = window.navigator.userAgent;
const setVisitorId = () => {
  const visitorId = getCookie('visitor_id');
  if (isEmpty(visitorId)) {
    const id = uuid();
    setCookie('visitor_id', id, 365);
    return id;
  }

  return visitorId;
};


const initialState = {
  auth: null,
  isUserSignedIn: false,
  firstName: null,
  lastName: null,
  isAuthenticated: false,
  isSuperuser: false,
  isAdmin: false,
  isOperator: false,
  token: null,
  userInfoTaken: false,
  userIsAuthenticated:
    getCookie('_REG_TEMP_COMPLETED') === 'true' || false,
  isCrawler: CrawlerDetector.isCrawler(userAgentString),
  visitorId: setVisitorId(),
  cognitoError: null,
  canRegisterLeads: canRegister()
};

export default createReducer(initialState, {
  [AUTH_IDENTITY]: (state, payload) => ({
    ...state,
    auth: payload,
    token: null,
  }),
  [AUTH_USER_SUCCESS]: (state, payload) => ({
    ...state,
    isUserSignedIn: true,
    token: payload,
  }),
  [AUTOCHECK_INFO]: (state, payload) => ({
    ...state,
    userInfoTaken: true,
    ...payload,
  }),
  [USER_REGISTER_REQUEST]: state => ({
    ...state,
  }),
  [USER_REGISTER_SUCCESS]: state => ({
    ...state,
    userIsAuthenticated: true,
  }),
  [USER_REGISTER_RESET]: state => ({
    ...state,
    userIsAuthenticated: false,
  }),
  [USER_REGISTER_FAILURE]: state => ({
    ...state,
  }),
  [COGNITO_AUTH_FAILURE]: (state, payload) => ({
    ...state,
    cognitoError: payload,
  }),
});
