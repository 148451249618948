import fetch from 'isomorphic-fetch';
import {
  LISTINGS_API_URL as url,
  LISTINGS_API_BASE as base,
  LISTINGS_API_KEY as apiKey,
} from '../settings';

export const getLocations = ({ isHealthy, isClosed, isNew, isBest, isWorkplace, isCoworking }) => {
  const fields = isCoworking ? 'id,marketSlug,latitude,longitude' : 'id,name,marketplaceSlug,marketSlug,latitude,longitude';

  return fetch(
    `${url}${base}/location/bymarketslug?${isHealthy ? 'is_healthy=true' : ''}${isClosed ? 'is_closed=true' : ''}${isNew ? 'is_new=true' : ''}${isBest ? 'is_best_of=true' : ''}${isWorkplace ? 'is_workplace=true' : ''}${isCoworking ? 'is_workplace=false' : ''}&fields=${fields}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    },
  );
}

export const getLocCount = () => {
  return fetch(
    `${url}${base}/market/marketlocationcount`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    },
  );
}

export const getMarketAverageCost = () => {
  return fetch(
    `${url}${base}/market/averagecost`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    },
  );
}
