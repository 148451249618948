import isEmpty from 'lodash/isEmpty';
import { checkHttpStatus, parseJSON } from '../utils';
import {
  SINGLE_HOOD_GET_REQUEST,
  SINGLE_HOOD_GET_SUCCESS,
  SINGLE_HOOD_GET_FAILURE,
  NEIGHBORHOODS_GET_REQUEST,
  NEIGHBORHOODS_GET_SUCCESS,
  NEIGHBORHOODS_GET_FAILURE,
} from '../constants';
import * as api from '../api/listings';

const neighborhoodGetRequest = () => ({
  type: SINGLE_HOOD_GET_REQUEST,
});

const neighborhoodGetSuccess = payload => ({
  type: SINGLE_HOOD_GET_SUCCESS,
  payload,
});

const neighborhoodGetFailure = () => ({
  type: SINGLE_HOOD_GET_FAILURE,
});

export const neighborhoodGet = id =>
  (dispatch, getState) => {
    if (getState().neighborhood.byId[id]) {
      return null;
    }

    dispatch(neighborhoodGetRequest());

    return api.neighborhoodGet(id)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(neighborhoodGetSuccess(response));
      })
      .catch((error) => {
        dispatch(neighborhoodGetFailure(error));
      });
  };

const neighborhoodsGetRequest = () => ({
  type: NEIGHBORHOODS_GET_REQUEST,
});

const neighborhoodsGetSuccess = payload => ({
  type: NEIGHBORHOODS_GET_SUCCESS,
  payload,
});

const neighborhoodsGetFailure = () => ({
  type: NEIGHBORHOODS_GET_FAILURE,
});

export const neighborhoodsGet = () =>
  (dispatch, getState) => {
    if (!isEmpty(getState().neighborhood.byMarket)) {
      return null;
    }

    dispatch(neighborhoodsGetRequest());

    return api.neighborhoodsGet()
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(neighborhoodsGetSuccess(response));
      })
      .catch((error) => {
        dispatch(neighborhoodsGetFailure(error));
      });
  };
