export const pricePeriodOptions = [
  {
    value: 'hour',
    label: 'A few hours',
  },
  {
    value: 'day',
    label: 'One day',
  },
  {
    value: 'week',
    label: 'A few days per week',
  },
  {
    value: 'month',
    label: 'A month to a year',
  },
  {
    value: 'year',
    label: 'A year or more',
  },
];

export const typeOptions = [
  {
    value: 'office',
    label: 'Office',
  },
  {
    value: 'desk',
    label: 'Desk',
  },
  {
    value: 'meeting-space',
    label: 'Meeting Space',
  },
  {
    value: 'virtual-office',
    label: 'Virtual Office',
  },
];

export const getTypeValue = (typeValue) => {
  if (!typeValue) return undefined;

  const activeTypeOption = typeOptions.find(option => option.value === typeValue);

  return activeTypeOption
}

export const getPeriodByPrice = (price) => {
  if (price && price >= 250) {
    return pricePeriodOptions[0]
  }
  if (price && price < 250) {
    return pricePeriodOptions[1]
  }
  return null
}

export const getPricePeriodVal = (priceVal, pricePeriodVal) => {
  if (!pricePeriodVal) return undefined;

  const activePriceOption = pricePeriodOptions.find(option => option.value === pricePeriodVal);

  if (!pricePeriodVal || !activePriceOption) {
    return getPeriodByPrice(priceVal);
  }

  return activePriceOption
}

export const getHowLongVal = (pricePeriodVal) => {
  if (!pricePeriodVal) return undefined;

  const activePriceOption = pricePeriodOptions.find(option => option.value === pricePeriodVal);

  return activePriceOption
};

export default {
  25: '$25',
  50: '$50',
  100: '$100',
  150: '$150',
  250: '$250',
  500: '$500',
  750: '$750',
  1000: '$1,000',
  1250: '$1,250',
  1500: '$1,500',
  2000: '$2,000',
  2500: '$2,500',
  3000: '$3,000',
  3500: '$3,500',
  4000: '$4,000',
  5000: '$5,000',
  6000: '$6,000',
  7000: '$7,000',
  8000: '$8,000',
  9000: '$9,000',
  10000: '$10,000',
};
