// CRM API
export const LISTINGS_API_URL = process.env.REACT_APP_DEV_URL || 'https://api.upsuite.com';
export const LISTINGS_API_BASE = '/v1';
export const LISTINGS_API_KEY = process.env.REACT_APP_DEV_KEY || 'CSTVVt38LV4wl8IW5Os5x6z6MOc7hFYxGOCR26S5';
export const LISTINGS_API_HEAD = 'x-api-key';
export const IMAGES_URL_BASE = process.env.REACT_APP_IMAGES_URL || 'https://images.upsuite.com';

// Cognito
export const COGNITO_APPWEBDOMAIN = process.env.REACT_APP_COGNITO_APPWEBDOMAIN || 'upsuite.auth.us-east-2.amazoncognito.com';
export const COGNITO_CLIENTID = process.env.REACT_APP_COGNITO_CLIENTID || '1i0n5la38vre87jr8jkmibdka2';

// URLs
export const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://www.upsuite.com';
export const FLEX_MARKETPLACE = process.env.REACT_APP_FLEX_MARKETPLACE || 'https://upsuite.flexofficepro.com';
export const FLEX_PRO = process.env.REACT_APP_FLEX_PRO || 'https://flexofficepro.com';

// Maps
// export const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY_DEV ?
//   `${process.env.REACT_APP_MAPS_API_KEY_DEV}s&v=3.53` :
//   'AIzaSyDjJUreIBxSPMI4UaDwnUhNrVabfwvyNqY&v=3.53';

export const MAPS_API_KEY = 'AIzaSyDjJUreIBxSPMI4UaDwnUhNrVabfwvyNqY&v=weekly'

// Analytics
export const GTAG_ID = 'UA-114654703-1';
export const GTM_ID = 'GTM-M76H6X5';
export const HOTJAR_ID = 793792;
export const HOTJAR_SV = 6;
export const ANALYTICS_ENABLED = process.env.REACT_APP_ANALYTICS;
export const ANALYTICS_DEBUG = process.env.REACT_APP_ANALYTICS_DEBUG;
export const BUGHERD_ENABLED = process.env.REACT_APP_BUGHERD;

// Listings
export const NEIGHBORHOOD_ROUTE = 'neighborhood';
export const BRAND_ROUTE = 'brand';
export const CAPACITY_ROUTE = 'people';
export const SUITE_ROUTE = 'suite';
export const DISTANCE_ROUTE = 'distance';
export const SEARCH_ROUTE = 'search';
export const PRICE_ROUTE = 'price';
export const PRICE_PERIOD = 'priceperiod';
export const SORT_ROUTE = 'sort';
export const PAGINATION_ROUTE = 'page';
export const AMENITIES_ROUTE = 'amenities';
export const TEAM_TYPE_ROUTE = 'teamtype';
export const BESTOF_ROUTE = 'bestof';
export const LISTINGS_FILTER_PARAMS = [
  'coworking',
  'is_workplace',
  NEIGHBORHOOD_ROUTE,
  BRAND_ROUTE,
  CAPACITY_ROUTE,
  PRICE_ROUTE,
  PRICE_PERIOD,
  SORT_ROUTE,
  AMENITIES_ROUTE,
  SEARCH_ROUTE,
  PAGINATION_ROUTE,
  TEAM_TYPE_ROUTE,
  BESTOF_ROUTE,
  SUITE_ROUTE,
  DISTANCE_ROUTE,
];
export const SINGLE_LISTING_BASE_ROUTE = 'coworking';
export const SINGLE_LISTING_WORKPLACE_BASE_ROUTE = 'workspace';
export const FIND_MY_SPACE_ROUTE = 'findmyspace';
export const REQUEST_LOCATION_ROUTE = 'list-your-space';
export const ABOUT_US_ROUTE = 'about-us';
export const TERMS_OF_SERVICE_ROUTE = 'terms-of-service';
export const TOS_OPERATORS = 'for-operators';
export const TOS_BROKERS = 'for-brokers';
export const PRIVACY_POLICY_ROUTE = 'privacy-policy';
export const USER_MANAGEMENT = 'user-management';
export const SEARCH_QUERY = 's';

export const FILES_DEV = process.env.REACT_APP_FILES_DEV;
