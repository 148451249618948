import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import styled from 'styled-components'
import { EyeVisible, EyeHidden } from '../../components/icons'
import Button from '../../components/button'

const validationInitial = {
  oldIsEmpty: true,
  newIsEmpty: true,
  charactersLength: true,
  containsNumber: true,
  containsUppercase: true,
  containsLowercase: true
}

const PasswordChange = () => {
  const [isEdit, setIsEdit] = useState(false)
  const [oldPassVisible, setOldPassVisible] = useState(false)
  const [newPassVisible, setNewPassVisible] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [validation, setValidation] = useState(validationInitial)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    setValidation({
      oldIsEmpty: oldPassword.length === 0,
      newIsEmpty: newPassword.length === 0,
      charactersLength: newPassword.length < 8,
      containsNumber: !/\d/.test(newPassword),
      containsUppercase: !/[A-Z]/g.test(newPassword),
      containsLowercase: !/[a-z]/g.test(newPassword)
    })
  }, [oldPassword, newPassword])

  const handleSubmit = () => {
    setLoading(true)
    setError(null)

    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword)
      })
      .then(() => {
        setLoading(false)
        setIsEdit(false)
        setValidation(validationInitial)
        setOldPassword('')
        setNewPassword('')
      })
      .catch(err => {
        setLoading(false)
        setError(err?.message || null)
      })
  }

  return (
    <Wrap>
      {isEdit ? (
        <form onSubmit={e => e.preventDefault()}>
          <FieldRow>
            <Label htmlFor="oldPassword">Old Password</Label>
            <Field>
              <Input
                id="oldPassword"
                name="oldPassword"
                type={oldPassVisible ? 'text' : 'password'}
                value={oldPassword}
                disabled={loading}
                onChange={e => setOldPassword(e.target.value)}
              />
              <Icon onClick={() => setOldPassVisible(!oldPassVisible)}>
                {oldPassVisible ? <EyeVisible /> : <EyeHidden />}
              </Icon>
            </Field>
            <Validation>
              {validation.oldIsEmpty && <span>Required</span>}
            </Validation>
          </FieldRow>
          <FieldRow>
            <Label htmlFor="newPassword">New Password</Label>
            <Field>
              <Input
                id="newPassword"
                name="newPassword"
                type={newPassVisible ? 'text' : 'password'}
                value={newPassword}
                disabled={loading}
                onChange={e => {
                  setNewPassword(e.target.value)
                }}
              />
              <Icon
                type="button"
                onClick={() => setNewPassVisible(!newPassVisible)}
              >
                {newPassVisible ? <EyeVisible /> : <EyeHidden />}
              </Icon>
            </Field>
            <Validation>
              {validation.newIsEmpty && <span>Required</span>}
              {validation.charactersLength && (
                <span>Password is at least 8 characters long</span>
              )}
              {validation.containsNumber && (
                <span>Password contains at least 1 number</span>
              )}
              {validation.containsUppercase && (
                <span>Password contains at least 1 uppercase letter</span>
              )}
              {validation.containsLowercase && (
                <span>Password contains at least 1 lowercase letter</span>
              )}
            </Validation>
          </FieldRow>
          {error && (
            <Validation data-margin>
              <span>{error}</span>
            </Validation>
          )}
          <BtnGroup>
            <Button
              type="button"
              disabled={
                Object.values(validation).filter(item => item).length > 0 ||
                loading
              }
              onClick={handleSubmit}
            >
              Save
            </Button>
            <LinkBtn onClick={() => setIsEdit(false)}>Cancel</LinkBtn>
          </BtnGroup>
        </form>
      ) : (
        <Init>
          <span>Password</span>
          <InitRow>
            <span>********</span>
            <LinkBtn onClick={() => setIsEdit(true)}>Edit</LinkBtn>
          </InitRow>
        </Init>
      )}
    </Wrap>
  )
}

const Wrap = styled.div`
  margin-bottom: 50px;
`

const LinkBtn = styled.button.attrs({ type: 'button' })`
  background: none;
  border: none;
  color: navy;
  cursor: pointer;
`

const Init = styled.div`
  > span {
    display: block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
`

const InitRow = styled.div`
  display: flex;

  > span {
    display: block;
    margin-right: 15px;
  }
`

const Field = styled.div`
  height: 40px;
  max-width: 100%;
  width: 100%;
  box-shadow: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  background: #f2f2f2;
  margin-bottom: 5px;
  max-width: 250px;
  display: flex;
  align-items: center;
`

const Input = styled.input`
  border: none;
  padding: 10px 12px;
  font-size: 14px;
  font-family: 'Euclid Circular B', sans-serif;
  font-size: 14px;
  background: transparent;
  width: 100%;
`

const Icon = styled.button.attrs({ type: 'button' })`
  border: none;
  background: none;
  box-shadow: none;
  cursor: pointer;
  padding: 0;
  width: 46px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Label = styled.label`
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
`

const Validation = styled.div`
  color: red;

  > span {
    display: block;
  }

  &[data-margin] {
    margin-bottom: 20px;
  }
`

const FieldRow = styled.div`
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 25px;
  }
`

const BtnGroup = styled.div`
  button {
    margin-right: 20px;
  }
`

export default PasswordChange
