import React from 'react'

import './styles.scss'

const FilterRemoveList = ({
  amenities,
  teamtypes,
  onRemoveAmenity,
  onRemoveTeamType
}) => (
  <ul className="FilterRemoveList">
    {amenities.map(item => (
      <li className="FilterRemoveList-item" key={item.slug}>
        <button
          className="FilterRemoveList-button"
          type="button"
          onClick={() =>
            onRemoveAmenity(
              amenities
                .filter(amenity => amenity.slug !== item.slug)
                .map(amenity => amenity.slug)
                .join(',')
            )
          }
        >
          x
        </button>
        <span>{item.title}</span>
      </li>
    ))}
    {teamtypes.map(item => (
      <li className="FilterRemoveList-item" key={item.slug}>
        <button
          className="FilterRemoveList-button"
          type="button"
          onClick={() =>
            onRemoveTeamType(
              teamtypes
                .filter(teamtype => teamtype.slug !== item.slug)
                .map(teamtype => teamtype.slug)
                .join(',')
            )
          }
        >
          x
        </button>
        <span>{item.title}</span>
      </li>
    ))}
  </ul>
)

export default FilterRemoveList
