import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { profile } from '../../settings/routes'
import { spacesGet, proposalGet } from '../../actions/lead'
import { spacesProposalGet } from '../../actions/spaces'

const ProfileMenu = () => {
  const dispatch = useDispatch()
  const savedSpaces = useSelector(state => state.lead.savedSpaces.list)
  const lead = useSelector(state => state.lead)

  useEffect(() => {
    dispatch(spacesGet())

    if (savedSpaces.some(item => item.name === 'Proposed Shortlist')) {
      dispatch(proposalGet())
        .then((success) => success && dispatch(spacesProposalGet()))
    }
  }, [dispatch, savedSpaces])

  return (
    <Wrap>
      <List>
        {(lead.firstName || lead.lastName) && (
          <li>{`${lead.firstName || ''} ${lead.lastName || ''}`}</li>
        )}
        <li>
          <NavLink to={`/${profile.main}/${profile.spaces}/`} exact>
            Saved Spaces
          </NavLink>
        </li>
        <li>
          {savedSpaces.length > 0 && (
            <>
              <span>
                Shortlists
              </span>
              <ul>
                {savedSpaces.map(item => (
                  <li key={item.id}>
                    <NavLink
                      to={`/${profile.main}/${profile.spaces}/${item.id}`}
                      exact
                    >
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </>
          )}
        </li>
        <li>
          <NavLink to={`/${profile.main}/${profile.searches}`}>
            Saved Searches
          </NavLink>
        </li>
        <li>
          <NavLink to={`/${profile.main}/${profile.settings}`}>Profile</NavLink>
        </li>
      </List>
    </Wrap>
  )
}

const Wrap = styled.div`
  margin-bottom: 20px;
  padding: 30px 15px 0;
  display: flex;
  justify-content: center;
  flex-shrink: 0;

  @media (min-width: 64em) {
    justify-content: flex-start;
    padding-left: 20px;
    width: 250px;
    padding-top: 90px;
  }
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  font-size: 17px;
  color: black;

  &, & ul {
    list-style: none;
  }

  ul {
    padding-left: 20px;
    margin-top: 10px;
  }

  li {
    margin-bottom: 10px;
  }

  a {
    color: inherit;
  }

  a:hover, a.active {
    color: var(--brand-color);
  }
`

export default ProfileMenu
