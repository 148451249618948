import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { dataByMarket } from './market-data';

const Hero = ({ marketSlug }) => {
  const marketData = dataByMarket[marketSlug]

  return (
    <Section>
      <Text>
        <h1>Find Coworking Space in {marketData?.name || ''}</h1>
        <p>
          Upsuite offers an on demand network of {marketData?.spacesCount || ''}{' '}
          coworking spaces in {marketData?.name || ''} that are engaged and
          inspire you and your team
        </p>
      </Text>
      <Btn to={`/coworking/${marketSlug}/`}>Find Space in {marketData?.name || ''}</Btn>
      <Row>
        <Col data-col="6" data-styles="counter">
          <span>{marketData?.locCount || ''}+</span>
          <span>Locations</span>
        </Col>
        <Col data-col="6" data-styles="counter">
          <span>{marketData?.spacesCount || ''}+</span>
          <span>Rentable Spaces</span>
        </Col>
      </Row>
      <Overlay />
      <img
        src="https://www.upsuite.com/wp-content/uploads/2019/03/Alchemy_Creative_Workspace_Denver.jpg"
        alt="Find Coworking Space"
      />
    </Section>
  );
}


const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: -15px;
  margin-left: -15px;
  z-index: 3;
  width: 100%;
  max-width: 860px;
  margin-top: 20px;

  @media (min-width: 40em) {
    flex-direction: row;
  }
`;

const Col = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 15px;

  &[data-col='6'] {
    @media (min-width: 40em) {
      width: 50%;
      flex: 0 0 50%;
    }
  }

  &[data-styles='counter'] {
    text-align: center;
    color: white;

    span {
      display: block;
      font-weight: 600;
    }

    span:first-child {
      font-size: 40px;
    }

    span:last-child {
      font-size: 28px;
    }
  }
`

const Section = styled.div`
  width: 100%;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 40em) {
    padding-top: 100px;
  }

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
  }
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000000;
  opacity: 0.5;
  z-index: 2;
  top: 0;
`

const Btn = styled(Link)`
  font-size: 18px;
  padding: .6em 1.5em;
  color: white;
  background: var(--brand-color);
  box-shadow: 0 10px 20px rgb(0 0 0 / 16%);
  border-radius: 5px;
  display: inline-block;
  z-index: 3;
`

const Text = styled.div`
  position: relative;
  z-index: 3;
  color: white;
  text-align: center;
  padding-left: 28px;
  padding-right: 28px;
  max-width: 860px;

  h1 {
    font-weight: 600;
    line-height: 1.4;
    font-size: 32px;
    margin-bottom: 10px;
  }

  p {
    font-size: 22px;
    line-height: 1.3;
    margin: 0 0 20px;
  }
`

export default Hero;
