import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import { createReducer } from '../utils';
import {
  LISTINGS_GET_REQUEST,
  LISTINGS_GET_SUCCESS,
  LISTINGS_GET_FAILURE,
  SINGLE_LISTING_GET_REQUEST,
  SINGLE_LISTING_GET_SUCCESS,
  SINGLE_LISTING_GET_FAILURE,
  SYNC_VISIBLE_MARKERS_WITH_LISTINGS,
  LISTINGS_RELATED_GET_SUCCESS,
  LISTINGS_FILTER_BY_SPACES,
  LISTINGS_TOP_MARKET_GET_REQUEST,
  LISTINGS_TOP_MARKET_GET_SUCCESS,
  LISTINGS_TOP_MARKET_GET_FAILURE,
  SET_MAP_BOUNDS,
  PIN_MARKER_RESET,
  FAVORITES_GET_SUCCESS,
  OPERATOR_LOCATIONS_SUCCESS,
} from '../constants';
import { sortByRecomended } from '../utils/sort-listings';
import { filterBySpaces } from '../utils/filter-listings';
import { toIdbySlug } from '../utils/listings';
import { getRelatedIds } from '../utils/related';
import { urlFilterQuery } from '../utils/filter-url';

const initialState = {
  byId: {},
  byRoute: {},
  syncMarkers: [],
  byLastRequest: [],
  bySlug: {},
  loading: false,
  error: false,
  singleLoading: false,
  singleError: false,
  relatedBySlug: {},
  byMarketId: {},
  topByMarket: {},
  center: null,
  zoom: null,
  bounds: null,
  lastRoute: null,
  completed: false,
};

export default createReducer(initialState, {
  [LISTINGS_GET_REQUEST]: state => ({
    ...state,
    loading: true,
    error: false,
    completed: false,
  }),
  [LISTINGS_GET_SUCCESS]: (state, { list }, route) => ({
    ...state,
    byId: {
      ...state.byId,
      ...keyBy(list, 'id'),
    },
    bySlug: {
      ...state.bySlug,
      ...toIdbySlug(list),
    },
    byLastRequest: sortByRecomended(list).map(listing => listing.id),
    byRoute: {
      ...state.byRoute,
      [route]: sortByRecomended(list).map(listing => listing.id),
    },
    latlonByRoute: {
      ...state.latlonByRoute,
      [route]: sortByRecomended(list).map(listing => listing.id),
    },
    loading: false,
    error: false,
    completed: true,
  }),
  [LISTINGS_GET_FAILURE]: state => ({
    ...state,
    loading: false,
    error: true,
    completed: true,
  }),
  [FAVORITES_GET_SUCCESS]: (state, { list }) => ({
    ...state,
    byId: {
      ...state.byId,
      ...keyBy(list, 'id'),
    },
    bySlug: {
      ...state.bySlug,
      ...toIdbySlug(list),
    },
  }),
  [LISTINGS_FILTER_BY_SPACES]: (state, payload, config) => {
    const route = urlFilterQuery(config);

    return {
      ...state,
      byRoute: {
        ...state.byRoute,
        [route]: filterBySpaces(payload, state, config),
      },
      latlonByRoute: {
        ...state.latlonByRoute,
        [route]: filterBySpaces(payload, state, config),
      },
    };
  },
  [SINGLE_LISTING_GET_REQUEST]: state => ({
    ...state,
    singleLoading: true,
    singleError: false,
  }),
  [SINGLE_LISTING_GET_SUCCESS]: (state, { list }) => ({
    ...state,
    byId: {
      ...state.byId,
      ...keyBy(list, 'id'),
    },
    bySlug: {
      ...state.bySlug,
      ...toIdbySlug(list),
    },
    singleLoading: false,
    singleError: false,
  }),
  [SINGLE_LISTING_GET_FAILURE]: state => ({
    ...state,
    singleLoading: false,
    singleError: true,
  }),
  [SYNC_VISIBLE_MARKERS_WITH_LISTINGS]: (state, payload) => {
    return ({
      ...state,
      byLastRequest: (payload || []).length > 0 ? payload : state.byLastRequest,
      syncMarkers: isEqual(payload, state.syncMarkers)
        ? state.syncMarkers
        : payload,
    })
  },
  [LISTINGS_RELATED_GET_SUCCESS]: (state, list, slug) => ({
    ...state,
    relatedById: {
      ...state.relatedById,
      ...keyBy(list, 'id'),
    },
    relatedBySlug: {
      ...state.relatedBySlug,
      [slug]: getRelatedIds(state, list, slug),
    },
  }),
  [LISTINGS_TOP_MARKET_GET_REQUEST]: state => ({
    ...state,
    loading: true,
    error: false,
  }),
  [LISTINGS_TOP_MARKET_GET_SUCCESS]: (state, { list }, market) => ({
    ...state,
    byId: {
      ...state.byId,
      ...keyBy(list, 'id'),
    },
    bySlug: {
      ...state.bySlug,
      ...toIdbySlug(list),
    },
    topByMarket: {
      ...state.topByMarket,
      [market]: sortByRecomended(list).map(listing => listing.id),
    },
    loading: false,
    error: false,
    nearestMarket: market,
  }),
  [LISTINGS_TOP_MARKET_GET_FAILURE]: state => ({
    ...state,
    loading: false,
    error: true,
  }),
  [SET_MAP_BOUNDS]: (state, payload) => ({
    ...state,
    center: payload.center,
    zoom: payload.zoom,
    bounds: payload.bounds,
    lastRoute: payload.lastRoute,
  }),
  [PIN_MARKER_RESET]: state => ({
    ...state,
    center: null,
    zoom: null,
    bounds: null,
    lastRoute: null,
  }),
  [OPERATOR_LOCATIONS_SUCCESS]: (state, { list }) => ({
    ...state,
    byId: {
      ...state.byId,
      ...keyBy(list, 'id'),
    },
    bySlug: {
      ...state.bySlug,
      ...toIdbySlug(list),
    },
  }),
});
