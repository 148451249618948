import Amplify, { Auth } from 'aws-amplify'
import { differenceInMinutes } from 'date-fns'
import {
  AUTH_SIGNIN_REQUEST,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNIN_FAILURE,
  AUTH_SESSION_REQUEST,
  AUTH_SESSION_SUCCESS,
  AUTH_SESSION_FAILURE,
  NEW_PASSWORD_REQUIRED,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  PASSWORD_RECOVERY_REQUEST,
  PASSWORD_RECOVERY_SUCCESS,
  PASSWORD_RECOVERY_FAILURE
} from '../constants'
import { AWS_POOL_ID, AWS_CLIENT_ID, AWS_DOMAIN } from '../settings/cognito'
import { accountGet } from './lead'

export const configureAmplify = () => {
  if (!(window.location.pathname.includes('redirect') || window.location.pathname.includes('redirect'))) {
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: 'us-east-2',
        userPoolId: AWS_POOL_ID,
        userPoolWebClientId: AWS_CLIENT_ID
      },
      aws_project_region: 'us-east-2',
      aws_cognito_region: 'us-east-2',
      aws_user_pools_id: AWS_POOL_ID,
      aws_user_pools_web_client_id: AWS_CLIENT_ID,
      oauth: {
        domain: AWS_DOMAIN,
        scope: [
          'aws.cognito.signin.user.admin',
          'email',
          'openid',
          'phone',
          'profile'
        ],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'code'
      },
      federationTarget: 'COGNITO_USER_POOLS'
    })
  }
}

const authSigninRequest = () => ({
  type: AUTH_SIGNIN_REQUEST
})

export const authSigninSuccess = payload => ({
  type: AUTH_SIGNIN_SUCCESS,
  payload
})

const authSigninFailure = payload => ({
  type: AUTH_SIGNIN_FAILURE,
  payload
})

const authSessionRequest = () => ({
  type: AUTH_SESSION_REQUEST
})

export const authSessionSuccess = payload => ({
  type: AUTH_SESSION_SUCCESS,
  payload
})

const authSessionFailure = payload => ({
  type: AUTH_SESSION_FAILURE,
  payload
})

const authNewPasswordRequired = payload => ({
  type: NEW_PASSWORD_REQUIRED,
  payload
})

export const authSignIn = (email, password) => dispatch => {
  dispatch(authSigninRequest())

  return Auth.signIn(email, password)
    .then(response => {
      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(authNewPasswordRequired(response))

        return false
      }

      if (response.signInUserSession) {
        dispatch(
          authSessionSuccess(response.signInUserSession.idToken.jwtToken)
        )
        dispatch(authSigninSuccess(response.signInUserSession.idToken.jwtToken))
        dispatch(accountGet(response.signInUserSession.idToken.jwtToken))

        return true
      }
    })
    .catch(error => {
      dispatch(
        authSigninFailure(error && error.message ? error.message : error)
      )

      return false
    })
}

export const getAuthCurrentSession = () => dispatch => {
  dispatch(authSessionRequest())

  return Auth.currentSession()
    .then(session => {
      dispatch(authSessionSuccess(session.idToken.jwtToken))
      dispatch(accountGet(session.idToken.jwtToken))
    })
    .catch(error => {
      dispatch(
        authSessionFailure(error && error.message ? error.message : error)
      )
    })
}

export const getTokenWithExpiration = state => dispatch => {
  if (
    state.cognito.tokenDate &&
    differenceInMinutes(new Date(), state.cognito.tokenDate) < 10
  ) {
    return new Promise(resolve => {
      resolve(state.cognito.token)
    })
  }

  return Auth.currentSession()
    .then(session => {
      dispatch(authSessionSuccess(session.idToken.jwtToken))

      return session.idToken.jwtToken
    })
    .catch(error => {
      dispatch(
        authSessionFailure(error && error.message ? error.message : error)
      )

      return error
    })
}

export const logout = () => dispatch => {
  return Auth.signOut()
    .then(() => {
      dispatch({ type: LOGOUT_SUCCESS })
      // Redirect on logout
      window.location.href = '/'
    })
    .catch(() => dispatch({ type: LOGOUT_FAILURE }))
}

export const changePassword = values => () => {
  return Auth.currentAuthenticatedUser().then(user => {
    return Auth.changePassword(user, values.oldPassword, values.newPassword)
  })
}

const authPasswordRecoveryRequest = () => ({
  type: PASSWORD_RECOVERY_REQUEST
})

const authPasswordRecoverySuccess = payload => ({
  type: PASSWORD_RECOVERY_SUCCESS,
  payload
})

const authPasswordRecoveryFailure = payload => ({
  type: PASSWORD_RECOVERY_FAILURE,
  payload
})

export const authPasswordRecovery = email => dispatch => {
  dispatch(authPasswordRecoveryRequest())

  return Auth.forgotPassword(email)
    .then(() => {
      dispatch(authPasswordRecoverySuccess(email))
    })
    .catch(error => {
      dispatch(
        authPasswordRecoveryFailure(
          error && error.message ? error.message : error
        )
      )
    })
}

export const authCompleteNewPassword = password => (dispatch, getState) => {
  const cognitoUser = getState().cognito.cognitoUser

  return Auth.completeNewPassword(cognitoUser, password)
    .then(response => {
      if (response.signInUserSession) {
        dispatch(
          authSessionSuccess(response.signInUserSession.idToken.jwtToken)
        )
        dispatch(authSigninSuccess(response.signInUserSession.idToken.jwtToken))
        dispatch(accountGet(response.signInUserSession.idToken.jwtToken))

        return true
      }
    })
    .catch(error => {
      dispatch(
        authSigninFailure(error && error.message ? error.message : error)
      )

      return false
    })
}
