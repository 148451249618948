import React from 'react';
import styled, { css } from 'styled-components';
import * as colors from '../../styles/theme/colors';
import { Building } from '../icons';

const Button = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 108px;
  height: 100px;
  background-color: ${colors.brand};
  border: none;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 2;
  transition: opacity 0.15s ease;

  &:hover {
    opacity: 0.9;
  }

  > svg {
    height: 45px;
  }

  ${({ small }) => small && css`
    top: 30px;
    width: 92px;
    height: 78px;

    > svg {
      height: 28px;
    }

    span {
      font-size: 13px;
    }
  `}

  ${({ tiny }) => tiny && css`
    width: 60px;
    height: 40px;
    padding: 5px;
    top: 0;
    left: 40px;
    width: auto;

    svg {
      display: none;
    }
  `}

  ${({ card }) => card && css`
    z-index: 1;
  `}
`;

const Text = styled.div`
  color: white;

  span {
    display: block;
  }
`;

export const VirtualTourCardWrap = styled.div`
  position: absolute;
  height: 220px;
  width: 100%;
`;

export const VirtualTourButton = ({ onClick, small, tiny, card }) => (
  <Button onClick={onClick} small={small} tiny={tiny} card={card}>
    <Building />
    <Text>
      <span>360°</span>
      <span>Virtual Tour</span>
    </Text>
  </Button>
);
