const deg2rad = deg => deg * (Math.PI / 180);
const rad2deg = rad => rad * 180 / Math.PI;

export default (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    (Math.sin(dLat / 2) * Math.sin(dLat / 2)) +
    (Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2));
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km

  return d;
};

export const getPointByDistance = (coords, brng, dist) => {
  const distantion = dist / 6371;

  const lat1 = deg2rad(coords.lat);
  const lon1 = deg2rad(coords.lng);

  const lat2 = Math.asin(Math.sin(lat1) * Math.cos(distantion)
    + Math.cos(lat1) * Math.sin(distantion) * Math.cos(deg2rad(brng)));

  const lon2 = lon1 + Math.atan2(Math.sin(deg2rad(brng)) * Math.sin(distantion)
    * Math.cos(lat1), Math.cos(distantion) - Math.sin(lat1) * Math.sin(lat2));

  if (isNaN(lat2) || isNaN(lon2)) return null;

  return ({ lat: rad2deg(lat2), lng: rad2deg(lon2) });
};
