import React from 'react';
import Heading from '../heading';

import './styles.scss';

export default ({ heading, items }) => (
  <div className="CompareTable">
    {heading &&
      <Heading
        level="3"
        size="tiny"
      >
        {heading}
      </Heading>}
    {items &&
      <ul className="CompareTable-list">
        {items.map(item => (
          <li key={item} className="CompareTable-item">
            <span>{item}</span>
          </li>
        ))}
      </ul>
    }
  </div>
);
