import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module';
import { Crawler } from 'es6-crawler-detect/src';

import Root from './containers/Root';
import configureStore from './store/configureStore';
import { сloseMobileMainNav } from './actions/ui';
import {
  GTM_ID as gtmId,
  HOTJAR_ID as hjid,
  HOTJAR_SV as hjsv,
  ANALYTICS_ENABLED as analitics,
  BUGHERD_ENABLED as bugherd,
} from './settings';
import { loadScriptFooter } from './utils/load-script';

import './styles/main.scss';

const history = createBrowserHistory();
const initialState = {};
const store = configureStore(initialState, history);

history.listen(() => {
  store.dispatch(сloseMobileMainNav());
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Root />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

if (analitics) {
  ReactGA.ga('require', 'GTM-58FFB8C');
  hotjar.initialize(hjid, hjsv);
  TagManager.initialize({ gtmId });
}

if (bugherd) {
  const bh = document.createElement('script');
  const s = document.getElementsByTagName('script')[0];
  bh.type = 'text/javascript';
  bh.src = 'https://www.bugherd.com/sidebarv2.js?apikey=9x0mjq8lcvibqcejjftdda';
  s.parentNode.insertBefore(bh, s);
}

const CrawlerDetector = new Crawler();
const userAgentString = window.navigator.userAgent;

if (!CrawlerDetector.isCrawler(userAgentString) && !process.env.REACT_APP_NOINDEX && !window.location.pathname.includes('/embed')) {
  loadScriptFooter('//js.hs-scripts.com/4175778.js', 'hs-script-loader');
}
