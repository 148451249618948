import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import './styles.scss';

const BgImage = (props) => {
  const {
    url,
    alt,
    className,
    landing,
    hero,
    mobileScheme,
  } = props;

  const classes = classnames(
    className,
    'BgImage',
    `-mScheme-${mobileScheme}`,
    { '-landing': landing },
    { '-hero': hero },
  );

  return (
    !isEmpty(url)
      && <img src={url} alt={alt} className={classes} />
  );
};

BgImage.propTypes = {
  url: PropTypes.string,
  landing: PropTypes.bool,
  hero: PropTypes.bool,
  className: PropTypes.string,
};

BgImage.defaultProps = {
  url: '',
  alt: '',
  landing: false,
  hero: false,
  className: null,
  mobileScheme: 'none',
};

export default BgImage;
