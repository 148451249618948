import { createReducer } from '../utils';
import {
  GET_BEST_OF_REQUEST,
  GET_BEST_OF_SUCCESS,
  GET_BEST_OF_FAILURE,
} from '../constants';

const initialState = {
  loading: false,
  failure: false,
  byMarket: {},
};

export default createReducer(initialState, {
  [GET_BEST_OF_REQUEST]: state => ({
    ...state,
    loading: true,
  }),
  [GET_BEST_OF_SUCCESS]: (state, payload, config) => ({
    ...state,
    loading: false,
    failure: false,
    byMarket: {
      ...state.byMarket,
      [config]: payload
    },
  }),
  [GET_BEST_OF_FAILURE]: state => ({
    ...state,
    loading: false,
    failure: true,
  }),
});
