import React from 'react';
import classnames from 'classnames';

import './styles.scss';

export const SearchBarWrapper = ({ seoPage, children, navOpened, isSearch }) => (
  <div className={classnames('SearchBar-wrap', { '-navOpened': navOpened, '-isSearch': isSearch, '-asSection': seoPage })}>
    {children}
  </div>
);

export const SearchBarRow = ({ children, expanded }) => (
  <div className={classnames('SearchBar-row', { '-expanded': expanded })}>
    {children}
  </div>
);

export const SearchBarCol = (props) => {
  const {
    size,
    children,
    className,
    center,
    hideMobile,
    fullWidthMobile,
  } = props;

  const classes = classnames(
    'SearchBar-col',
    `-${size}`,
    className, {
      '-center': center,
      '-hideMobile': hideMobile,
      '-fullWidthMobile': fullWidthMobile,
    },
  );

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export const SearchBarMeta = (props) => {
  const {
    children,
    className,
    justify,
    gutter,
  } = props;

  const classes = classnames(
    'SearchBar-meta',
    className, {
      '-justify': justify,
    },
    gutter && `-gutter-${gutter}`,
  );

  return (
    <div className={classes}>
      {children}
    </div>
  );
};
