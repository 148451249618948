import { createReducer } from '../utils';
import {
  FILTER_QUERY_SET,
  FILTER_QUERY_RESET,
} from '../constants';
import { LISTINGS_FILTER_PARAMS as filterParams } from '../settings';

const initialState = filterParams.reduce((acc, curr) => ({
  ...acc,
  ...{ [curr]: null },
}), {});

export default createReducer(initialState, {
  [FILTER_QUERY_SET]: (state, payload) => ({
    ...initialState,
    ...payload,
  }),
  [FILTER_QUERY_RESET]: () => initialState,
});
