import React, { Component } from 'react';
import classnames from 'classnames';
import { ChevronRight } from '../icons'
import Account from '../../components/account'

import './styles.scss';

export class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: props.navOpened,
      heightOfContent: props.navOpened ? 'auto' : 0,
    };
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    if (nextProps.navOpened !== this.props.navOpened) {
      this.toggleExpanded();
    }
  }

  openNav() {
    this.setState({
      heightOfContent: this.refs.content.offsetHeight,
      isExpanded: true,
    });
  }

  heightAuto() {
    this.setState({
      heightOfContent: 'auto',
    });
  }

  closeNav() {
    this.setState({
      heightOfContent: 0,
    });
  }

  heightStatic() {
    this.setState({
      heightOfContent: this.refs.content.offsetHeight,
      isExpanded: false,
    });
  }

  toggleExpanded() {
    if (this.state.isExpanded) {
      this.heightStatic();
      window.setTimeout(() => {
        this.closeNav();
      }, 50);
    } else {
      this.openNav();
    }
  }

  toggleHeight() {
    if (this.state.isExpanded) {
      this.heightAuto();
    }
  }

  render() {
    return (
      <div className={classnames("MainNav-wrap", { '-active': this.props.navOpened })}  >
        <div
          className="MainNav"
          style={{ height: this.state.heightOfContent }}
          onTransitionEnd={() => this.toggleHeight()}
        >
          <ul
            className="MainNav-list"
            ref="content"
          >
            {this.props.children}
          </ul>

          {this.props.showAccount && (
            <Account />
          )}
        </div>
      </div>
    );
  }
}

export const Item = (props) => {
  const {
    children,
    featured,
  } = props;

  const classes = classnames(
    'MainNav-item', {
      '-featured': featured,
    },
  );

  return (
    <li className={classes}>
      {children}
    </li>
  );
};

export const ParentItem = (props) => {
  const {
    children,
    title,
    parent,
    highlight,
    href,
  } = props;

  const classes = classnames(
    'MainNav-item', {
      '-parent': parent,
      '-highlight': highlight,
    },
  );

  return (
    <li className={classes}>
      {href &&
        <a href={href}>{title}</a>
      }
      {!href &&
        <span>{title}</span>
      }
      <ul className="MainNav-dropdown">
        {children}
      </ul>
    </li>
  );
};

export const SubItem = ({ children, hasList }) => (
  <li className={classnames('MainNav-item -sub', { '-hasList': hasList })}>
    {children}
    {hasList && (
      <ChevronRight />
    )}
  </li>
);
