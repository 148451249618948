import React from 'react';
import { CompareColumn as Col } from '../../components/compare-page-helpers';
import CompareMap from './compare-map';
import CompareTable from '../../components/compare-table';

export default ({ compareListings }) => {
  return (
    <Col>
      <CompareMap listings={compareListings} />
      <CompareTable
        heading="Office/Suite"
        items={['People', 'Price', 'Size']}
      />
      <CompareTable heading="Best For" />
      <CompareTable heading="Amenities" />
    </Col>
  );
};
