import React from 'react'
// import { Route, Redirect } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Flex from '../../components/flex'
import Spinner from '../../components/spinner'

const PrivateRoute = ({
  component: Component,
  ...rest
}) => {
  const { token: isCognitoAuth, session: { completed, error }} = useSelector(state => state.cognito)

  // Private route or cognito auth error
  if (!isCognitoAuth && completed && error) {
    // Temporary disabling redirect to test on production
    // return <Redirect to={`/coworking/`} />
  }

  // Logged in user
  if (isCognitoAuth && completed && !error) {
    return (
      <Route {...rest} render={props => <Component {...props} />} />
    )
  }

  // Show loading spinner during auth and auth check
  return (
    <Flex fullWidth fullScreenHeight center middle>
      <Spinner size="medium" />
    </Flex>
  )
}

export default PrivateRoute
