import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import CompareSpaces from '../../components/compare-spaces';

import { CompareColumn, CompareTitleMobile } from '../../components/compare-page-helpers';

import { compareSpaceByListing } from '../../actions/ui';

const CompareSpacesMobile = (props) => {
  const { paginate, listings, spaceByListingId, spaces } = props;
  const listing = paginate >= 0 && (paginate <= (listings.length - 1)) ? listings[paginate] : {};
  const activeSpaceIndex = spaceByListingId[listing.id];

  return (
    !isEmpty(listing) &&
    <Fragment>
      <CompareTitleMobile>Office/Suite</CompareTitleMobile>
      <CompareColumn className="-mobile">
        <CompareSpaces
          activeSpaceIndex={activeSpaceIndex}
          spaces={(spaces[listing.id] || []).filter(item => item.isWorkplace)}
          onSpaceChanged={index =>
            props.dispatch(compareSpaceByListing(listing.id, index))}
        />
      </CompareColumn>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  paginate: state.ui.compareMobilePaginate,
  spaceByListingId: state.ui.spaceByListingId,
});

export default connect(
  mapStateToProps,
)(CompareSpacesMobile);
