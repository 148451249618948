import React, { useMemo, useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import findIndex from 'lodash/findIndex'
import reduce from 'lodash/reduce'
import Slider from 'react-slick'
import { Section } from '../wrapper'
import Heading from '../heading'
import { trackVirtualTourModal } from '../../utils/analytics'
import ListingCardObservable from '../listing-card-observable'
import toggleFavorites from '../../actions/favorites'
import {
  openRegisterModal,
  openInteractiveTourModal,
  openVerifyPassModal
} from '../../actions/modal'
import { listingsGetRelated } from '../../actions/listings'
import { getLocBestOf } from '../../actions/bestof'
import { ROOT, WORKPLACE } from '../../settings/routes'
import {
  selectRelatedSlug,
  selectSortedListingsByLastRequest
} from '../../selectors/listings'
import { trackRelated } from '../../api/listings'

import './style.scss'

const ArrowPrev = ({ className }) => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      clipRule="evenodd"
      d="M21.5 30.5L7 16L21.5 1.5V30.5Z"
      fillRule="evenodd"
      stroke="#606066"
      fill="gray"
    />
  </svg>
)

const ArrowNext = ({ className }) => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      clipRule="evenodd"
      d="M10.5 30.5L25 16L10.5 1.5V30.5Z"
      fillRule="evenodd"
      stroke="#606066"
      fill="gray"
    />
  </svg>
)

const RelatedLocations = ({
  slug,
  market,
  marketSlug,
  isWorkspace,
  heading
}) => {
  const dispatch = useDispatch()
  const favorites = useSelector(state =>
    isWorkspace ? state.favorites.workplaceIds : state.favorites.ids,
  );
  const related = useSelector(state =>
    selectRelatedSlug(state, `${market?.marketSlug}/${slug}`)
  )
  const bestofloc = useSelector(state => {
    const marketBest = state.bestof.byMarket[marketSlug]
    if (!marketBest) {
      return []
    }

    return marketBest.filter(item => item.marketplaceSlug !== slug)
  })
  const { userIsAuthenticated, isCrawler, visitorId } = useSelector(
    state => state.auth
  )
  const isCognitoAuth = useSelector(state => state.cognito.token)
  const slider = useRef()
  const listings = useSelector(state =>
    selectSortedListingsByLastRequest(state)
  )
  const [searchIndex, setSearchIndex] = useState(-1)
  const [currentSlide, setCurrentSlide] = useState(null)
  const [toggle, setToggle] = useState(false)
  const items = useMemo(() => {
    if (!toggle) {
      return reduce(
        listings,
        (acc, current, index) => {
          if (index !== searchIndex) {
            return [...acc, current]
          }

          return [...acc, ...related]
        },
        []
      )
    }

    return bestofloc
  }, [listings, related, bestofloc, toggle, searchIndex])

  useEffect(() => {
    if (slider.current) {
      slider.current.slickGoTo(currentSlide)
    }
  }, [currentSlide])

  useEffect(() => {
    if (toggle) {
      setCurrentSlide(0)
    }
  }, [toggle])

  useEffect(() => {
    setCurrentSlide(searchIndex !== -1 ? searchIndex : 0)
  }, [searchIndex])

  useEffect(() => {
    if (marketSlug && listings.length === 0) {
      dispatch(listingsGetRelated(marketSlug, isWorkspace, slug))
    }

    const searchIndex = findIndex(
      listings,
      item => item.marketplaceSlug === slug
    )

    setSearchIndex(searchIndex)

    if (marketSlug) {
      dispatch(getLocBestOf(marketSlug, isWorkspace))
    }
  }, [dispatch, isWorkspace, slug, marketSlug, listings])

  if (related.length === 0 || items.length === 0) {
    return null
  }

  return (
    <Section spaceBelow="small">
      <div className={`Related-nav -wrap${heading ? ' -closed' : ''}`}>
        <Heading
          level="2"
          size="small"
          center={false}
          withBar
          mobileScheme="detailsSecondary"
          className={`Related-nav-heading${heading ? ' -closed' : ''}`}
        >
          {heading || 'You Might Also Like'}
        </Heading>
        {!isWorkspace && bestofloc && bestofloc.length > 0 && (
          <div className="Related-nav-actions">
            <button
              type="button"
              onClick={() => setToggle(false)}
              className={`Related-nav-toggle ${!toggle ? '-active' : ''}`}
            >
              Closest Locations
            </button>
            <button
              type="button"
              onClick={() => setToggle(true)}
              className={`Related-nav-toggle ${toggle ? '-active' : ''}`}
            >
              Best of {market?.name || ''}
            </button>
            {market?.name && market?.bestOfLink && (
              <a
                className="Related-nav-link"
                target="_blank"
                rel="noopener noreferrer"
                href={market.bestOfLink}
              >
                <span>Read &quot;Best Coworking Spaces</span>
                <span>{`in ${market?.name || ''}" Blog Post`}</span>
              </a>
            )}
          </div>
        )}
      </div>
      <div className="Related-nav-track">
        {currentSlide !== items.length - 2 && (
          <button
            type="button"
            className="Related-nav-arrows -next"
            onClick={() => setCurrentSlide(currentSlide + 1)}
          >
            <ArrowNext className="Related-nav-arrow-icon" />
          </button>
        )}
        <Slider
          ref={slider}
          slidesToShow={2}
          slidesToScroll={1}
          infinite={false}
          className="Related-nav-slider"
          swipe={false}
          initialSlide={toggle ? 0 : currentSlide}
          arrows={false}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                swipe: true
              }
            }
          ]}
        >
          {items.map((item, index) => (
            <ListingCardObservable
              // eslint-disable-next-line react/no-array-index-key
              key={`${item.id}-${index}`}
              isSlide
              isWorkplace={isWorkspace}
              toggleFavorites={() => dispatch(toggleFavorites(item.id, false))}
              isFavorite={favorites && favorites.includes(item.id)}
              distanceUnits="mi"
              intersectionAction={() => {
                if (!(related || []).map(({ id }) => id).includes(item.id)) {
                  trackRelated(item.id)
                }
              }}
              onFirstClick={
                !(isCognitoAuth || isCrawler || window.sessionStorage.getItem('registerSession') === 'true')
                  ? () => {
                      if (userIsAuthenticated && !isCognitoAuth) {
                        dispatch(openVerifyPassModal())
                      } else {
                        dispatch(
                          openRegisterModal({
                            heading:
                              'For specific suite pricing, availability, reviews, and more.',
                            closeOnSubmit: true,
                            initialValues: {
                              locations: [item.id],
                              isWorkspace: [isWorkspace]
                            },
                            onSubmit: () =>
                              dispatch(
                                push(
                                  `/${isWorkspace ? WORKPLACE : ROOT}/${
                                    market?.marketSlug
                                  }/${item.marketplaceSlug}/`
                                )
                              ),
                            form: 'listing-card-registration',
                            visitorId,
                            isWorkspace
                          })
                        )
                      }
                    }
                  : null
              }
              onVirtualTourClick={url => {
                if (isCognitoAuth || isCrawler || window.sessionStorage.getItem('registerSession') === 'true') {
                  trackVirtualTourModal(
                    `/${isWorkspace ? WORKPLACE : ROOT}/${market?.marketSlug}/${
                      item.marketplaceSlug
                    }/`
                  )
                  dispatch(
                    push(
                      `/${isWorkspace ? WORKPLACE : ROOT}/${
                        market?.marketSlug
                      }/${item.marketplaceSlug}/`
                    )
                  )
                  dispatch(
                    openInteractiveTourModal({
                      tourUrl: url,
                      initialValues: {
                        locations: [item.id],
                        isWorkspace: [false],
                        visitorId
                      }
                    })
                  )
                } else {
                  if (userIsAuthenticated && !isCognitoAuth) {
                    dispatch(openVerifyPassModal())
                  } else {
                    dispatch(
                      openRegisterModal({
                        heading:
                          'For specific suite pricing, availability, reviews, and more.',
                        closeOnSubmit: true,
                        initialValues: {
                          locations: [item.id],
                          isWorkspace: [isWorkspace]
                        },
                        onSubmit: () => {
                          trackVirtualTourModal(
                            `/${isWorkspace ? WORKPLACE : ROOT}/${
                              market?.marketSlug
                            }/${item.marketplaceSlug}/`
                          )
                          dispatch(
                            push(
                              `/${isWorkspace ? WORKPLACE : ROOT}/${
                                market?.marketSlug
                              }/${item.marketplaceSlug}/`
                            )
                          )
                          dispatch(
                            openInteractiveTourModal({
                              tourUrl: url,
                              initialValues: {
                                locations: [item.id],
                                isWorkspace: [isWorkspace],
                                visitorId
                              }
                            })
                          )
                        },
                        form: 'listing-card-registration',
                        isWorkspace,
                        visitorId,
                        seatsRequired: true
                      })
                    )
                  }
                }
              }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...item}
            />
          ))}
        </Slider>
        {currentSlide !== 0 && (
          <button
            type="button"
            className="Related-nav-arrows -prev"
            onClick={() => setCurrentSlide(currentSlide - 1)}
          >
            <ArrowPrev className="Related-nav-arrow-icon" />
          </button>
        )}
      </div>
    </Section>
  )
}

export default RelatedLocations
