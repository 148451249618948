import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import Button from '../button';
import { SEARCH } from '../../settings/routes';
import { SEARCH_QUERY, CAPACITY_ROUTE } from '../../settings';
import { hideModal } from '../../actions/modal';

import './styles.scss';

const isNum = val => !/[^[0-9]]*/.test(val) && val > 0;

const FindSpaceForm = () => {
  const dispatch = useDispatch();
  const [people, setPeople] = useState(null);
  const [searchQuery] = useState(null);
  const [formSubmited, setFormSubmiting] = useState(false);
  const [officeType, setOfficeType] = useState(null);

  const submitForm = (e) => {
    e.preventDefault();
    setFormSubmiting(true);
    if (!searchQuery) {
      return null;
    }

    dispatch(hideModal());

    if (searchQuery.isFixture) {
      return dispatch(push(`/${officeType}/${searchQuery.slug}/${people ? `?${CAPACITY_ROUTE}=${people}` : ''}`));
    }

    return dispatch(push(`/${officeType}/${SEARCH}/?${SEARCH_QUERY}=${searchQuery.label}${people ? `&${CAPACITY_ROUTE}=${people}` : ''}`));
  };

  return (
    <form>
      <div className="FindSpaceForm-label">
        <span className="-reqired">Where</span>
        {(formSubmited && !searchQuery) && (
          <span className="FindSpaceForm-error">Please enter the location you are interested in.</span>
        )}
      </div>
      <div className="FindSpaceForm-label">
        <span className="-reqired">Office Type</span>
        <select onBlur={(e) => setOfficeType(e.currentTarget.value)}>
          {!officeType && (
            <option value="" disabled selected>
              Select Office Type
            </option>
          )}
          <option value="coworking">Coworking</option>
          <option value="workspace">Private Workspace</option>
        </select>
        {formSubmited && !officeType && (
          <span className="FindSpaceForm-error">Please select office type</span>
        )}
      </div>
      <div className="FindSpaceForm-label">
        <span className="-reqired">How many people?</span>
        <input
          onChange={e => setPeople(e.currentTarget.value)}
          type="number"
          min="1"
          max="1000"
          placeholder="10"
        />
        {(formSubmited && !isNum(people)) && (
          <span className="FindSpaceForm-error">The number of seats is required</span>
        )}
      </div>
      <Button
        className="FindSpaceForm-submit"
        type="submit"
        onClick={submitForm}
      >
        Find space
      </Button>
    </form>
  );
};

export default FindSpaceForm;
