import { checkHttpStatus, parseJSON } from '../utils';
import {
  SPACES_BY_LOC_ID_GET_REQUEST,
  SPACES_BY_LOC_ID_GET_SUCCESS,
  SPACES_BY_LOC_ID_GET_FAILURE,
  SPACES_GET_REQUEST,
  SPACES_GET_SUCCESS,
  SPACES_GET_FAILURE,
  SPACES_CREATE_REQUEST,
  SPACES_CREATE_SUCCESS,
  SPACES_CREATE_FAILURE,
  SPACES_UPDATE_REQUEST,
  SPACES_UPDATE_SUCCESS,
  SPACES_UPDATE_FAILURE,
  SPACES_DELETE_REQUEST,
  SPACES_DELETE_SUCCESS,
  SPACES_DELETE_FAILURE,
  SPACES_CLEAR_ERROR_MESSAGE,
  SPACES_PROPOSAL_GET_REQUEST,
  SPACES_PROPOSAL_GET_SUCCESS,
  SPACES_PROPOSAL_GET_FAILURE
} from '../constants';
import * as api from '../api/listings';
import * as spacesApi from '../api/spaces';
import { errorHandler } from '../utils/spaces';
import isFuture from 'date-fns/is_future';
import isToday from 'date-fns/is_today';

const spacesByLocationIdRequest = () => ({
  type: SPACES_BY_LOC_ID_GET_REQUEST,
});

const spacesByLocationIdSuccess = payload => ({
  type: SPACES_BY_LOC_ID_GET_SUCCESS,
  payload,
});

const spacesByLocationIdFailure = () => ({
  type: SPACES_BY_LOC_ID_GET_FAILURE,
});

export const spacesByLocationIdGet = id =>
  (dispatch, getState) => {
    const state = getState();
    if (state.spaces.byLocationId[id]) {
      return null;
    }

    dispatch(spacesByLocationIdRequest());

    return api.spacesByLocationIdGet(id)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(spacesByLocationIdSuccess(response.list));
      })
      .catch((error) => {
        dispatch(spacesByLocationIdFailure(error));
      });
  };

export const spacesByLocationIdsGet = ids =>
  (dispatch) => {
    dispatch(spacesByLocationIdRequest());

    return api.spacesByLocationIdGet(ids)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(spacesByLocationIdSuccess(response.list));

        return response.list;
      })
      .catch((error) => {
        dispatch(spacesByLocationIdFailure(error));
      });
  };

const spacesGetRequest = () => ({
  type: SPACES_GET_REQUEST,
});

const spacesGetSuccess = payload => ({
  type: SPACES_GET_SUCCESS,
  payload,
});

const spacesGetFailure = () => ({
  type: SPACES_GET_FAILURE,
});

export const spacesGet = where =>
  (dispatch) => {
    dispatch(spacesGetRequest());

    return api.spacesGet(where)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(spacesGetSuccess(response));

        return response;
      })
      .catch((error) => {
        dispatch(spacesGetFailure(error));
      });
  };

export const spacesGetPaginate = () =>
  (dispatch, getState) => {
    if (getState().spaces.list) {
      return Promise.resolve(getState().spaces.list);
    }

    dispatch(spacesGetRequest());

    return new Promise((resolve, reject) => {
      api.spacesGetPaginate(0, [], resolve, reject);
    })
      .then((response) => {
        dispatch(spacesGetSuccess(response));

        return response;
      });
  };

const spacesCreateRequest = () => ({
  type: SPACES_CREATE_REQUEST,
});

const spacesCreateSuccess = payload => ({
  type: SPACES_CREATE_SUCCESS,
  payload,
});

const spacesCreateFailure = payload => ({
  type: SPACES_CREATE_FAILURE,
  payload,
});

export const spaceCreate = (token, data) =>
  (dispatch, getState) => {
    dispatch(spacesCreateRequest());
    Promise.resolve(() => {
      if (getState().auth.isUserSignedIn) {
        getState().auth.auth.getSession();
      }
    })
    .then(() =>
      spacesApi.spaceCreate(token, data)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then((response) => {
          dispatch(spacesCreateSuccess(response));

          return response;
        })
        .catch((error) => {
          dispatch(spacesCreateFailure(errorHandler(error)));
        }));
  };

const spacesUpdateRequest = () => ({
  type: SPACES_UPDATE_REQUEST,
});

const spacesUpdateSuccess = payload => ({
  type: SPACES_UPDATE_SUCCESS,
  payload,
});

const spacesUpdateFailure = payload => ({
  type: SPACES_UPDATE_FAILURE,
  payload,
});

export const spaceUpdate = (token, id, data) =>
  (dispatch, getState) => {
    dispatch(spacesUpdateRequest());
    return Promise.resolve(() => {
      if (getState().auth.isUserSignedIn) {
        getState().auth.auth.getSession();
      }
    })
      .then(() =>
        spacesApi.spaceUpdate(token, id, {
          ...data,
          dateAvailable: isFuture(data.dateAvailable) || isToday(data.dateAvailable) ? data.dateAvailable : null,
        })
          .then(checkHttpStatus)
          .then(parseJSON)
          .then((response) => {
            dispatch(spacesUpdateSuccess(response));

            return response;
          })
      .catch((error) => {
        dispatch(spacesUpdateFailure(errorHandler(error)));
      }));
  };

const spacesDeleteRequest = () => ({
  type: SPACES_DELETE_REQUEST,
});

const spacesDeleteSuccess = payload => ({
  type: SPACES_DELETE_SUCCESS,
  payload,
});

const spacesDeleteFailure = () => ({
  type: SPACES_DELETE_FAILURE,
});

export const spaceDelete = (token, spaceId, locationId, closeForm) =>
  (dispatch) => {
    dispatch(spacesDeleteRequest());

    return spacesApi.spaceDelete(token, spaceId, closeForm)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(() => {
        dispatch(spacesDeleteSuccess({ spaceId, locationId }));
        closeForm();
      })
      .catch((error) => {
        dispatch(spacesDeleteFailure(error));
      });
  };

export const spacesClearErrorMessage = () => ({
  type: SPACES_CLEAR_ERROR_MESSAGE
});

const spacesProposalRequest = () => ({
  type: SPACES_PROPOSAL_GET_REQUEST
});

const spacesProposalSuccess = payload => ({
  type: SPACES_PROPOSAL_GET_SUCCESS,
  payload
});

const spacesProposalFailure = () => ({
  type: SPACES_PROPOSAL_GET_FAILURE
});

export const spacesProposalGet = () =>
  (dispatch, getState) => {
    const proposals = getState().lead.proposal.list;
    const ids = proposals.reduce((acc, proposal) => {
      return [...acc, ...proposal.SpaceIds]
    }, [])
    if (ids.every((id) => getState().spaces.byId[id])) {
      return null;
    }

    dispatch(spacesProposalRequest());

    return api.spacesByProposalGet(ids)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(spacesProposalSuccess(response.list));
      })
      .catch((error) => {
        dispatch(spacesProposalFailure(error));
      });
  };
