import queryString from 'query-string';
import {
  LISTINGS_API_URL as crmUrl,
  IMAGES_URL_BASE as imagesUrl,
  FILES_DEV,
} from '../settings';

export const imageUrl = (id) => {
  if (!id) return null;

  return `${imagesUrl}/${id}`;
};

export const imageUrlEntry = (size = 'large', id) => {
  if (!id) return null;

  const params = queryString.stringify(
    {
      entryPoint: 'image',
      size,
      id,
    },
    { encode: false },
  );

  return `${crmUrl}/?${params}`;
};

export const getLocCleaningPoliciesFile = (locationId) =>
  `https://8eymn36ycg.execute-api.us-east-2.amazonaws.com/default/EspoFilesRelayLambda?locationId=${locationId}${FILES_DEV ? '&dev=true' : ''}`;

