import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import { Container } from '../../components/wrapper';
import Flex from '../../components/flex';
import Spinner from '../../components/spinner';

import BottomNavbar from '../BottomNav';

import {
  usersGet,
  userCreate,
  userUpdate,
  userDelete,
  usersBunchDelete,
} from '../../actions/users';
import { selectUsers } from '../../selectors/users';
import { UsersTable } from '../../components/user-management';
import Text from '../../components/text-box';

export default () => {
  const dispatch = useDispatch();
  const {
    users,
    status,
    error,
    userInfoTaken,
  } = useSelector(state => ({
    users: selectUsers(state),
    status: state.users.status,
    error: state.users.error,
    userInfoTaken: state.auth.userInfoTaken,
  }));
  const [search, setSearch] = useState('');
  const [filtredUsers, filterUsers] = useState([]);
  const [token, setToken] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [cognitoError, setCognitoError] = useState(null);
  const isSuperuser = true;

  useEffect(() => {
    import('../../actions/suspenseAuth').then(({ initCognitoSDK }) => {
      const auth = initCognitoSDK();
      auth.parseCognitoWebResponse(window.location.href);
      if (!auth.isUserSignedIn()) {
        auth.getSession();
      }
      setToken(auth.signInUserSession.idToken.jwtToken);
    });
  }, []);

  useEffect(() => {
    if (token && isSuperuser) {
      dispatch(usersGet(token));
    }
  }, [token, dispatch, isSuperuser]);

  useEffect(() => {
    filterUsers(users);
  }, [users]);

  useEffect(() => {
    if (error.deleting) {
      // eslint-disable-next-line no-alert
      window.alert(error.deleting);
    }
    if (error.updating) {
      // eslint-disable-next-line no-alert
      window.alert(error.updating);
    }
    if (error.creating) {
      // eslint-disable-next-line no-alert
      window.alert(error.creating);
    }
  }, [error]);

  const filterHandler = searchPhrase =>
    (isEmpty(searchPhrase)
      ? users
      : users.filter(
        user =>
          user.username.toLowerCase().includes(searchPhrase) ||
          user.firstName.toLowerCase().includes(searchPhrase) ||
          user.lastName.toLowerCase().includes(searchPhrase),
      ));

  const delayedQuery = useRef(debounce(filterUsers, 500)).current;
  const handleFilterChange = (e) => {
    setSearch(e.target.value);
    delayedQuery(filterHandler(e.target.value.toLowerCase()));
  };

  return (
    <Flex column shrink stickyFooter fullHeightDesktop between>
      <Container>
        <Text size="large" spaceBelow="small" color="dark">
          User Management
        </Text>
        {userInfoTaken && !isSuperuser &&
          <Text size="large" spaceBelow="small" color="dark">
            Permission Denied (superuser)
          </Text>
        }
        {cognitoError &&
          <Text size="large" spaceBelow="small" color="dark">
            {cognitoError}
          </Text>
        }
        {!isEmpty(users) && (
          <UsersTable
            users={filtredUsers}
            isSuperuser={isSuperuser}
            onCreate={data => dispatch(userCreate([data], token))}
            onUpdate={data => dispatch(userUpdate(data, token))}
            onRemove={username => dispatch(userDelete(username, token))}
            onBunchRemove={usersBunch => dispatch(usersBunchDelete(usersBunch, token))}
            onSearch={handleFilterChange}
            search={search}
            updating={status.updating}
            creating={status.creating}
            deleting={status.deleting}
          />
        )}
        {status.loading && <Spinner size="large" />}
      </Container>
      <BottomNavbar />
    </Flex>
  );
};
