import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const ButtonGroupMeta = (props) => {
  const {
    fullWidth,
    gutter,
    justify,
    children,
    style,
    className,
    column,
    position,
  } = props;

  const buttonGroupClass = classNames(
    className,
    'ButtonGroupMeta',
    `-gutter-${gutter}`,
    `-position-${position}`,
    `-justify-${justify}`, {
      '-fullWidth': fullWidth,
      '-column': column,
    });

  return (
    <div
      style={style}
      className={buttonGroupClass}
    >
      {children}
    </div>
  );
};

ButtonGroupMeta.defaultProps = {
  gutter: 'none',
  justify: 'center',
  fullWidth: false,
  column: true,
  position: 'default',
};

export default ButtonGroupMeta;
