import React from 'react';
import { connect } from 'react-redux';

import { SpaceList } from '../../components/space-list';

import { spaceCreate, spaceUpdate, spacesClearErrorMessage } from '../../actions/spaces';
import {
  openSpacesDeleteModal,
  openRegisterModal,
  openInteractiveTourModal,
  openCtaActionsModal,
  openVerifyPassModal
} from '../../actions/modal';
import { spaceTypeChange } from '../../actions/ui';
import { normalizeSpaceObject } from '../../utils/spaces';

const SpacesTable = (props) => {
  const { spaces, isUserSignedIn, userAllowedEditSpaces, id, token, filterQuery, userIsAuthenticated, isCrawler,
    visitorId, showSpaceTypeToggle, isClosed, isCognitoAuth } = props;

  return (
    <SpaceList
      spaces={spaces}
      spacesOwner={isUserSignedIn && userAllowedEditSpaces}
      spaceCreate={formData =>
        props.spaceCreate(
          token,
          normalizeSpaceObject({ ...formData, locationId: id }),
        )}
      spaceUpdate={formData =>
        props.spaceUpdate(
          token,
          formData.id,
          normalizeSpaceObject(formData),
        )}
      spaceDelete={(spaceId, closeForm) =>
        props.openSpacesDeleteModal({ token, spaceId, locationId: id, closeForm })}
      onAction={() => props.openCtaActionsModal({
        heading: 'Visit This Space',
        buttonName: 'Visit This Space',
        fields: ['firstName', 'email', 'seats', 'phoneNumber', 'preferredTourDate', 'preferredTourTime', 'moveinDate'],
        initialValues: {
          locations: [id],
          visitorId,
          isWorkspace: [false],
          form: 'visit-this-space',
        },
        isWorkspace: false,
        successText: 'Thanks for booking your tour through Upsuite! We will be in touch shortly!',
      })}
      onInteractiveTour={iframeUrl => props.openInteractiveTourModal(iframeUrl)}
      onClose={props.spacesClearErrorMessage}
      numberOfPeople={filterQuery.people && parseInt(filterQuery.people, 10)}
      locked={!(isCognitoAuth || isCrawler)}
      onLockClick={item => {
        if (userIsAuthenticated && !isCognitoAuth) {
          props.openVerifyPassModal()
        } else {
          props.openRegisterModal({
            heading: 'We have pricing and avialibality for this location!',
            closeOnSubmit: true,
            initialValues: { seats: item.spaceCapacity, locations: [id], isWorkspace: [false] },
            onSubmit: () => { },
            form: 'unlock-click-registration',
            visitorId,
            isWorkspace: false
          })
        }
      }}
      showSpaceTypeToggle={showSpaceTypeToggle}
      onSpaceTypeChange={() => props.spaceTypeChange()}
      isClosed={isClosed}
    />
  );
}

export default connect(
  null,
  {
    spaceCreate,
    spaceUpdate,
    openSpacesDeleteModal,
    openCtaActionsModal,
    openInteractiveTourModal,
    spacesClearErrorMessage,
    openRegisterModal,
    spaceTypeChange,
    openVerifyPassModal
  },
)(SpacesTable);
