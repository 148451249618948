import React from 'react'
import { Link } from 'react-router-dom'

import './styles.scss'

const CompareAddSpaces = ({ link, label, style }) => (
  <div className="CompareAddSpaces" style={style}>
    <Link className="CompareAddSpaces-button" to={link}>
      {label}
    </Link>
  </div>
)

export default CompareAddSpaces
