import React from 'react';
import ReactSelect from 'react-select';

import '../select/react-select.scss';
import './styles.scss';

const MainLocSelect = ({ onChange = () => {}, value, options }) => {
  return (
    <div className="MapSelect">
      <ReactSelect
        options={options}
        onChange={(option) => onChange(option)}
        clearable={false}
        searchable={false}
        value={value}
      />
    </div>
  );
}

export default MainLocSelect;
