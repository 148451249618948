import fetch from 'isomorphic-fetch';
import {
  LISTINGS_API_URL as url,
  LISTINGS_API_BASE as base,
  LISTINGS_API_KEY as apiKey,
} from '../settings';

export const spaceCreate = (token, body) =>
  fetch(`${url}${base}/space`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
      Authorization: token,
    },
    body: JSON.stringify(body),
  });

export const spaceUpdate = (token, id, body) =>
  fetch(`${url}${base}/space/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
      Authorization: token,
    },
    body: JSON.stringify(body),
  });

export const spaceDelete = (token, id) =>
  fetch(`${url}${base}/space/${id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
      Authorization: token,
    },
  });
