import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

const ButtonMeta = (props) => {
  const {
    className,
    style,
    children,
    type,
    color,
    disabled,
    onClick,
    fullWidth,
    scheme,
    size,
    svgIcon,
    isActive,
  } = props;

  const classes = classNames(
    'button-meta',
    className,
    `-color-${color}`,
    `-scheme-${scheme}`,
    `-size-${size}`, {
      '-fullWidth': fullWidth,
      '-state-active': isActive,
    },
  );

  return (
    <button
      type={type}
      className={classes}
      style={style}
      disabled={disabled}
      onClick={onClick}
    >
      {svgIcon}
      <div>
        <span>
          {children}
        </span>
      </div>
    </button>
  );
};

ButtonMeta.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  scheme: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
};

ButtonMeta.defaultProps = {
  className: null,
  type: 'button',
  color: 'default',
  fullWidth: false,
  scheme: 'default',
  size: 'default',
  isActive: false,
};

export default ButtonMeta;
