import { checkHttpStatus, parseJSON } from '../utils';
import {
  GET_LOC_BY_STATUS_REQUEST,
  GET_LOC_BY_STATUS_SUCCESS,
  GET_LOC_BY_STATUS_FAILURE,
  GET_MARKET_AVERAGE_COST_REQUEST,
  GET_MARKET_AVERAGE_COST_SUCCESS,
  GET_MARKET_AVERAGE_COST_FAILURE,
} from '../constants';
import * as api from '../api/main-map';

const getLocByStatusRequest = () => ({
  type: GET_LOC_BY_STATUS_REQUEST,
});

const getLocByStatusSuccess = (payload, config) => ({
  type: GET_LOC_BY_STATUS_SUCCESS,
  payload,
  config,
});

const getLocByStatusFailure = () => ({
  type: GET_LOC_BY_STATUS_FAILURE,
});

export const getLocByStatus = (status) =>
  (dispatch, getState) => {
    if (getState().mainMap[status]) {
      return null;
    }

    dispatch(getLocByStatusRequest());

    return api.getLocations({ [status]: true })
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(getLocByStatusSuccess(response, status));
      })
      .catch((error) => {
        dispatch(getLocByStatusFailure(error));
      });
  };

export const getLocCount = (status) =>
  (dispatch, getState) => {
    if (getState().mainMap[status]) {
      return null;
    }

    dispatch(getLocByStatusRequest());

    return api.getLocCount()
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(getLocByStatusSuccess(response, status));
      })
      .catch((error) => {
        dispatch(getLocByStatusFailure(error));
      });
  };

const getMarketAverageCostRequest = () => ({
  type: GET_MARKET_AVERAGE_COST_REQUEST,
});

const getMarketAverageCostSuccess = (payload) => ({
  type: GET_MARKET_AVERAGE_COST_SUCCESS,
  payload,
});

const getMarketAverageCostFailure = () => ({
  type: GET_MARKET_AVERAGE_COST_FAILURE,
});

export const getMarketAverageCost = () =>
  (dispatch, getState) => {
    if (getState().mainMap.averageCost) {
      return null;
    }

    dispatch(getMarketAverageCostRequest());

    return api.getMarketAverageCost()
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(getMarketAverageCostSuccess(response));
      })
      .catch((error) => {
        dispatch(getMarketAverageCostFailure(error));
      });
  };
