import React from 'react';
import { Field, reduxForm } from 'redux-form';

import Button from '../button';
import { renderField } from '../form-fields';
import { required, email, isNum } from '../../utils/lead-validation';

import './styles.scss';

const RegisterFormSteps = props => {
  const { handleSubmit, buttonName } = props;

  return (
    <form id="registration-form-main" className="RegisterForm" onSubmit={handleSubmit}>
      <>
        <Field
          component={renderField}
          name="firstName"
          id="firstName"
          type="text"
          placeholder=""
          label="First Name"
          required
          validate={required}
        />
        <Field
          component={renderField}
          name="email"
          id="email"
          type="text"
          placeholder=""
          label="Work Email"
          required
          validate={[required, email]}
        />
        <Field
          component={renderField}
          name="seats"
          id="seats"
          type="number"
          placeholder=""
          label="How Many People?"
          required
          validate={[required, isNum]}
        />
        <Button type="submit">{buttonName || 'Register'}</Button>
      </>
    </form>
  );
};

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'register-form',
})(RegisterFormSteps);
