import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { renderField, slideToggleField, selectField, datapickerField } from '../form-fields';
import Spinner from '../spinner';
import Button from '../button';
import { Mobile, Default } from '../responsive-helpers';
import { spaceModifiedDate } from '../../utils/format';
import { spaceTypes } from '../../utils/sort-spaces';

import './styles.scss';

const currencyOptions = [
  { label: 'USD', value: 'USD' },
  { label: 'CAD', value: 'CAD' },
];

const typeOptions = spaceTypes.map(type => ({ label: type, value: type }));

const unitOptions = [
  { label: 'Sq/Ft', value: 'sqFt' },
];

const SpaceEditForm = ({
  handleSubmit,
  onClose,
  onRemove,
  onDuplicate,
  errorMessage,
  creating,
  updating,
  initialValues,
  formValueIsAvailable,
  change,
}) => (
  <form onSubmit={handleSubmit} className="SpacesEditForm">
    <Mobile>
      {initialValues && initialValues.modifiedAt &&
        <span className="SpacesEditForm-modified -mobile">Updated: {spaceModifiedDate(initialValues.modifiedAt)}</span>}
    </Mobile>
    <Col>
      <Field
        component={renderField}
        name="name"
        id="name"
        type="text"
        placeholder="_"
        label="Unit"
        spacesEditForm
      />
    </Col>
    <Col oneThird>
      <Field
        component={slideToggleField}
        name="isAvailable"
        id="isAvailable"
        label="Available"
        onChange={(e) => e.target.value ? change('dateAvailable', null) : null}
      />
    </Col>
    <Col twoThird dateAvailable>
      <Field
        component={datapickerField}
        name="dateAvailable"
        id="dateAvailable"
        type="text"
        label="Next Available"
        spacesEditForm
        inlineLabel
        disabled={formValueIsAvailable}
      />
    </Col>
    <Col dateAvailableNext />
    <Col />
    <Col>
      <Field
        component={selectField}
        name="spaceType"
        id="spaceType"
        options={typeOptions}
        placeholder="_"
        label="Type"
        spacesEditForm
      />
    </Col>
    <Col>
      <Field
        component={renderField}
        name="spaceCapacity"
        id="spaceCapacity"
        type="text"
        placeholder="_"
        label="Capacity"
        spacesEditForm
      />
    </Col>
    <Col row>
      <Field
        component={renderField}
        name="spacePrice"
        id="spacePrice"
        type="text"
        placeholder="_"
        label="Price per Month"
        spacesEditForm
        half
        inlineLabel
      />
      <Field
        component={selectField}
        name="spacePriceCurrency"
        id="spacePriceCurrency"
        type="text"
        options={currencyOptions}
        placeholder="_"
        label="  "
        spacesEditForm
        half
      />
    </Col>
    <Col row>
      <Field
        component={renderField}
        name="sqFt"
        id="sqFt"
        type="text"
        placeholder="_"
        label="Area"
        spacesEditForm
        half
      />
      <Field
        component={selectField}
        name="units"
        id="units"
        type="text"
        options={unitOptions}
        placeholder="Sq/Ft"
        label="Units"
        spacesEditForm
        half
        inlineLabel
      />
    </Col>
    <Col />
    <Col>
      <Field
        component={renderField}
        name="spaceFloor"
        id="spaceFloor"
        type="text"
        placeholder="_"
        label="Floor"
        spacesEditForm
      />
    </Col>
    <Default>
      <Col>
        <Field
          component={slideToggleField}
          name="spaceWindows"
          id="spaceWindows"
          label="Has Windows"
        />
      </Col>
      <Col>
        <Field
          component={slideToggleField}
          name="spaceView"
          id="spaceView"
          label="Has View"
        />
      </Col>
    </Default>
    <Mobile>
      <Col row>
        <Col half>
          <Field
            component={slideToggleField}
            name="spaceWindows"
            id="spaceWindows"
            label="Has Windows"
          />
        </Col>
        <Col half>
          <Field
            component={slideToggleField}
            name="spaceView"
            id="spaceView"
            label="Has View"
          />
        </Col>
      </Col>
    </Mobile>
    <div className="SpacesEditForm-footer">
      <Default>
        {initialValues && initialValues.modifiedAt &&
          <span className="SpacesEditForm-modified">Updated: {spaceModifiedDate(initialValues.modifiedAt)}</span>}
      </Default>

      {(creating || updating) && <Spinner size="small" right />}
      {errorMessage && <span className="SpacesEditForm-error">{errorMessage}</span>}
      <Mobile>
        {onDuplicate &&
          <Button
            type="submit"
            fullWidth
            onClick={onDuplicate}
            scheme="default-normal"
          >Duplicate Space</Button>}
        <Button
          type="submit"
          fullWidth
          scheme="default-normal"
        >Save Space</Button>
        {onRemove &&
          <Button
            fullWidth
            scheme="default-normal"
            onClick={onRemove}
          >Remove Space</Button>}
      </Mobile>
      <Default>
        {onDuplicate &&
          <Button
            onClick={onDuplicate}
            scheme="link-underline"
          >Duplicate</Button>}
        {onRemove &&
          <Button
            onClick={onRemove}
            scheme="link-underline"
          >Remove</Button>}
        <Button
          onClick={onClose}
          scheme="link-underline"
        >Cancel</Button>
        <Button
          type="submit"
          scheme="link-underline"
        >Save</Button>
      </Default>
    </div>
  </form>
);


const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.form);

  return {
    errorMessage: state.spaces.errorMessage,
    creating: state.spaces.creating,
    updating: state.spaces.updating,
    formValueIsAvailable: selector(state, 'isAvailable'),
  };
};

export default connect(mapStateToProps)(reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SpaceEditForm));

const Col = ({ children, row, half, oneThird, twoThird, dateAvailable, dateAvailableNext }) => {
  const classes = classNames(
    'SpacesEditForm-col',
    {
      '-row': row,
      '-half': half,
      '-oneThird': oneThird,
      '-twoThird': twoThird,
      '-da': dateAvailable,
      '-daNext': dateAvailableNext,
    },
  );

  return (
    <div className={classes}>{children}</div>
  );
};
