import keyBy from 'lodash/keyBy';
import { createReducer } from '../utils';
import {
  MARKETS_GET_REQUEST,
  MARKETS_GET_SUCCESS,
  MARKETS_GET_FAILURE,
} from '../constants';

const initialState = {
  total: null,
  list: null,
  byName: {},
  bySlug: {},
  loading: false,
  error: false,
};

export default createReducer(initialState, {
  [MARKETS_GET_REQUEST]: state => ({
    ...state,
    total: null,
    list: null,
    loading: true,
    error: false,
  }),
  [MARKETS_GET_SUCCESS]: (state, payload) => ({
    ...state,
    ...payload,
    byName: {
      ...state.byName,
      ...keyBy(payload.list, 'name'),
    },
    bySlug: {
      ...state.bySlug,
      ...keyBy(payload.list, 'marketSlug'),
    },
    loading: false,
    error: false,
  }),
  [MARKETS_GET_FAILURE]: state => ({
    ...state,
    total: null,
    list: null,
    loading: false,
    error: true,
  }),
});
