import React from 'react';
import { connect } from 'react-redux';

import { ModalBody } from '../../components/modal';
import FindSpaceForm from '../../components/find-space-form';
import { selectMarketsWithCoords } from '../../selectors/markets';

const FindSpaceModal = ({ fixtures }) => (
  <ModalBody>
    {window.google.maps && (
      <FindSpaceForm fixtures={fixtures} />
    )}
  </ModalBody>
);

export default connect(state => ({ fixtures: selectMarketsWithCoords(state) }))(FindSpaceModal);
