import React from 'react';
import {
  Twitter, Facebook, Instagram, Linkedin,
} from '../icons';

import './styles.scss';

const Icons = {
  twitter: Twitter,
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
};

export default ({ network, link }) => {
  const Icon = Icons[network];
  return (
    <a
      href={link}
      target="_blank"
      className={`SocialIcon -${network}`}
      rel="noopener noreferrer"
      aria-label={network}
    >
      <Icon />
    </a>
  );
};
