import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import ListingCardObservable from '../../components/listing-card-observable';

import { highlightMarker, highlightMarkerReset } from '../../actions/ui';
import toggleFavorites from '../../actions/favorites';
import { openRegisterModal, openInteractiveTourModal, openVerifyPassModal } from '../../actions/modal';

import { selectNearestMarket } from '../../selectors/filter-query';
import {
  SINGLE_LISTING_BASE_ROUTE as singleRoute,
  SINGLE_LISTING_WORKPLACE_BASE_ROUTE as singleWokrplaceRoute,
} from '../../settings';

import { trackVirtualTourModal } from '../../utils/analytics';

const ListingCard = (props) => {
  const {
    listing, favorites, highlightMarkerId,
    fullWidth, distanceUnits,
    userIsAuthenticated, isCrawler, filterQuery: { people, is_workplace: isWorkplace },
    listing: { marketSlug, marketplaceSlug }, visitorId, isCognitoAuth
  } = props;
  return (
    <ListingCardObservable
      toggleFavorites={() => props.toggleFavorites(listing.id, isWorkplace)}
      isHighlighted={listing.id === highlightMarkerId}
      isFavorite={favorites && favorites.includes(listing.id)}
      numeric={listing.numeric}
      onMouseEnter={() => props.highlightMarker(listing.id)}
      onMouseLeave={() => props.highlightMarkerReset()}
      fullWidthWrap={fullWidth}
      distanceUnits={distanceUnits}
      onFirstClick={!(isCognitoAuth || isCrawler || window.sessionStorage.getItem('registerSession') === 'true') ? () => {
        if (userIsAuthenticated && !isCognitoAuth) {
          props.openVerifyPassModal()
        } else {
          props.openRegisterModal({
            heading: 'For specific suite pricing, availability, reviews, and more.',
            closeOnSubmit: true,
            initialValues: { seats: people, locations: [listing.id], isWorkspace: [!!isWorkplace] },
            onSubmit: () => props.push(`/${isWorkplace ? singleWokrplaceRoute : singleRoute}/${marketSlug}/${marketplaceSlug}/`),
            form: 'listing-card-registration',
            visitorId,
            seatsRequired: true,
            isWorkspace: !!isWorkplace
          })
        }
      } : null}
      onVirtualTourClick={url => {
        if (isCognitoAuth || isCrawler || window.sessionStorage.getItem('registerSession') === 'true') {
          trackVirtualTourModal(`/${isWorkplace ? singleWokrplaceRoute : singleRoute}/${marketSlug}/${marketplaceSlug}/`);
          props.push(`/${isWorkplace ? singleWokrplaceRoute : singleRoute}/${marketSlug}/${marketplaceSlug}/`);
          props.openInteractiveTourModal({
            tourUrl: url,
            initialValues: { locations: [listing.id], isWorkspace: [!!isWorkplace], visitorId },
          });
        } else {
          if (userIsAuthenticated && !isCognitoAuth) {
            props.openVerifyPassModal()
          } else {
            props.openRegisterModal({
              heading: 'For specific suite pricing, availability, reviews, and more.',
              closeOnSubmit: true,
              initialValues: { seats: people, locations: [listing.id], isWorkspace: [!!isWorkplace] },
              onSubmit: () => {
                trackVirtualTourModal(`/${isWorkplace ? singleWokrplaceRoute : singleRoute}/${marketSlug}/${marketplaceSlug}/`);
                props.push(`/${isWorkplace ? singleWokrplaceRoute : singleRoute}/${marketSlug}/${marketplaceSlug}/`);
                props.openInteractiveTourModal({
                  tourUrl: url,
                  initialValues: { locations: [listing.id], isWorkspace: [!!isWorkplace], visitorId },
                });
              },
              form: 'listing-card-registration',
              visitorId,
              seatsRequired: true,
              isWorkspace: !!isWorkplace
            })
          }
        }
      }}
      isWorkplace={isWorkplace}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...listing}
    />
  );
};

const mapStateToProps = state => ({
  greviews: state.reviews.greviewsById,
  map: state.reviews.map,
  highlightMarkerId: state.ui.highlightMarkerId,
  distanceUnits: state.location.distanceUnits,
  favorites: state.filterQuery.is_workplace ? state.favorites.workplaceIds : state.favorites.ids,
  market: selectNearestMarket(state),
  userIsAuthenticated: state.auth.userIsAuthenticated,
  isCrawler: state.auth.isCrawler,
  filterQuery: state.filterQuery,
  visitorId: state.auth.visitorId,
  isCognitoAuth: state.cognito.token
});

export default connect(
  mapStateToProps,
  {
    highlightMarker,
    highlightMarkerReset,
    toggleFavorites,
    openRegisterModal,
    openInteractiveTourModal,
    openVerifyPassModal,
    push,
  },
)(ListingCard);
