import { checkHttpResponse, parseJSON } from '../utils'
import {
  LEAD_SEARCHES_GET_REQUEST,
  LEAD_SEARCHES_GET_SUCCESS,
  LEAD_SEARCHES_GET_FAILURE,
  LEAD_SEARCHES_ADD_REQUEST,
  LEAD_SEARCHES_ADD_SUCCESS,
  LEAD_SEARCHES_ADD_FAILURE,
  LEAD_SEARCHES_DELETE_REQUEST,
  LEAD_SEARCHES_DELETE_SUCCESS,
  LEAD_SEARCHES_DELETE_FAILURE,
  LEAD_SPACES_GET_REQUEST,
  LEAD_SPACES_GET_SUCCESS,
  LEAD_SPACES_GET_FAILURE,
  LEAD_SPACES_ADD_REQUEST,
  LEAD_SPACES_ADD_SUCCESS,
  LEAD_SPACES_ADD_FAILURE,
  LEAD_SPACES_UPDATE_REQUEST,
  LEAD_SPACES_UPDATE_SUCCESS,
  LEAD_SPACES_UPDATE_FAILURE,
  LEAD_ACCOUNT_GET_REQUEST,
  LEAD_ACCOUNT_GET_SUCCESS,
  LEAD_ACCOUNT_GET_FAILURE,
  LEAD_ACCOUNT_UPDATE_REQUEST,
  LEAD_ACCOUNT_UPDATE_SUCCESS,
  LEAD_ACCOUNT_UPDATE_FAILURE,
  LEAD_PROPOSAL_GET_REQUEST,
  LEAD_PROPOSAL_GET_SUCCESS,
  LEAD_PROPOSAL_GET_FAILURE
} from '../constants'
import * as api from '../api/leads'
import { getFilterQueryLink } from './filter-query'
import { getTokenWithExpiration } from './cognito'
import { setCookie } from '../utils/cookies'
import { userRegisterReset } from './auth'

const searchesGetRequest = () => ({
  type: LEAD_SEARCHES_GET_REQUEST
})

const searchesGetSuccess = payload => ({
  type: LEAD_SEARCHES_GET_SUCCESS,
  payload
})

const searchesGetFailure = () => ({
  type: LEAD_SEARCHES_GET_FAILURE
})

export const searchesGet = () => (dispatch, getState) => {
  dispatch(searchesGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getSearches(token)
        .then(checkHttpResponse)
        .then(parseJSON)
        .then(response => {
          dispatch(searchesGetSuccess(response))
        })
        .catch(error => {
          dispatch(searchesGetFailure(error))
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const searchAddRequest = () => ({
  type: LEAD_SEARCHES_ADD_REQUEST
})

const searchAddSuccess = payload => ({
  type: LEAD_SEARCHES_ADD_SUCCESS,
  payload
})

const searchAddFailure = payload => ({
  type: LEAD_SEARCHES_ADD_FAILURE,
  payload
})

export const getSearchUrl = item => {
  const filterQuery = {
    coworking: item.market || null,
    is_workplace: item.isWorkspace,
    people: item.seats || null,
    price: item.price || null,
    priceperiod: item.priceperiod || null,
    amenities: item.amenities || null,
    s: item.search || null,
    teamtype: item.teamtype || null
  }

  return getFilterQueryLink(filterQuery)
}

export const searchAdd = data => (dispatch, getState) => {
  const normalizeData = {
    name: data.name || null,
    date: new Date(),
    market: data.coworking || null,
    isWorkspace: data.is_workplace,
    seats: data.people || null,
    price: data.price || null,
    priceperiod: data.priceperiod || null,
    amenities: data.amenities || null,
    search: data.s || null,
    teamtype: data.teamtype || null
  }

  dispatch(searchAddRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .addSearch(token, normalizeData)
        .then(checkHttpResponse)
        .then(() => {
          dispatch(searchAddSuccess(normalizeData))
        })
        .catch(error => {
          dispatch(searchAddFailure(error))
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const searchDeleteRequest = payload => ({
  type: LEAD_SEARCHES_DELETE_REQUEST,
  payload
})

const searchDeleteSuccess = payload => ({
  type: LEAD_SEARCHES_DELETE_SUCCESS,
  payload
})

const searchDeleteFailure = payload => ({
  type: LEAD_SEARCHES_DELETE_FAILURE,
  payload
})

export const searchDelete = id => (dispatch, getState) => {
  dispatch(searchDeleteRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .deleteSearch(token, id)
        .then(checkHttpResponse)
        .then(() => {
          dispatch(searchDeleteSuccess(id))
        })
        .catch(error => {
          dispatch(searchDeleteFailure(error))
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

// Saved Spaces
const spacesGetRequest = () => ({
  type: LEAD_SPACES_GET_REQUEST
})

const spacesGetSuccess = payload => ({
  type: LEAD_SPACES_GET_SUCCESS,
  payload
})

const spacesGetFailure = () => ({
  type: LEAD_SPACES_GET_FAILURE
})

export const spacesGet = () => (dispatch, getState) => {
  if (getState().lead.savedSpaces.status.completed) {
    return null
  }

  dispatch(spacesGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getSpaces(token)
        .then(checkHttpResponse)
        .then(parseJSON)
        .then(response => {
          dispatch(spacesGetSuccess(response))
        })
        .catch(error => {
          dispatch(spacesGetFailure(error))
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

// Spaces POST
const spacesAddRequest = () => ({
  type: LEAD_SPACES_ADD_REQUEST
})

const spacesAddSuccess = payload => ({
  type: LEAD_SPACES_ADD_SUCCESS,
  payload
})

const spacesAddFailure = payload => ({
  type: LEAD_SPACES_ADD_FAILURE,
  payload
})

export const spacesAdd = data => (dispatch, getState) => {
  dispatch(spacesAddRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .addSpaces(token, data)
        .then(checkHttpResponse)
        .then(parseJSON)
        .then(response => {
          dispatch(spacesAddSuccess(response))

          return true
        })
        .catch(error => {
          dispatch(spacesAddFailure(error))

          return false
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')

      return false
    })
}

// Spaces PUT
const spacesUpdateRequest = () => ({
  type: LEAD_SPACES_UPDATE_REQUEST
})

const spacesUpdateSuccess = payload => ({
  type: LEAD_SPACES_UPDATE_SUCCESS,
  payload
})

const spacesUpdateFailure = payload => ({
  type: LEAD_SPACES_UPDATE_FAILURE,
  payload
})

export const spacesUpdate = data => (dispatch, getState) => {
  dispatch(spacesUpdateRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .updateSpaces(token, data)
        .then(checkHttpResponse)
        .then(parseJSON)
        .then(response => {
          dispatch(spacesUpdateSuccess(response))
        })
        .catch(error => {
          dispatch(spacesUpdateFailure(error))
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')

      return false
    })
}

// Account
const accountGetRequest = () => ({
  type: LEAD_ACCOUNT_GET_REQUEST
})

const accountGetSuccess = payload => ({
  type: LEAD_ACCOUNT_GET_SUCCESS,
  payload
})

const accountGetFailure = () => ({
  type: LEAD_ACCOUNT_GET_FAILURE
})

const resetCookiesOnLogin = () => {
  setCookie('_REG_TEMP_COMPLETED', '', -1)
  setCookie('firstName', '', -1)
  setCookie('upsuite_user_last_name', '', -1)
  setCookie('email', '', -1)
  setCookie('seats', '', -1)
  setCookie('visitorId', '', -1)
  setCookie('visitor_id', '', -1)
  setCookie('phoneNumber', '', -1)
  setCookie('preferredTourDate', '', -1)
  setCookie('moveinDate', '', -1)
  setCookie('_REG_TEMP_TOKEN', '', -1)
  setCookie('upsuite_user_is_authenticated', '', -1)
}

export const accountGet = token => dispatch => {
  dispatch(accountGetRequest())

  return api
    .getAccount(token)
    .then(checkHttpResponse)
    .then(parseJSON)
    .then(response => {
      resetCookiesOnLogin()
      dispatch(accountGetSuccess(response))
      dispatch(userRegisterReset())
    })
    .catch(error => {
      dispatch(accountGetFailure(error))
    })
}

const accountUpdateRequest = () => ({
  type: LEAD_ACCOUNT_UPDATE_REQUEST
})

const accountUpdateSuccess = payload => ({
  type: LEAD_ACCOUNT_UPDATE_SUCCESS,
  payload
})

const accountUpdateFailure = () => ({
  type: LEAD_ACCOUNT_UPDATE_FAILURE
})

export const accountUpdate = data => (dispatch, getState) => {
  dispatch(accountUpdateRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .updateAccount(token, data)
        .then(checkHttpResponse)
        .then(() => {
          dispatch(accountUpdateSuccess(data))
        })
        .catch(error => {
          dispatch(accountUpdateFailure(error))
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')

      return false
    })
}

const proposalGetRequest = () => ({
  type: LEAD_PROPOSAL_GET_REQUEST
})

const proposalGetSuccess = payload => ({
  type: LEAD_PROPOSAL_GET_SUCCESS,
  payload
})

const proposalGetFailure = () => ({
  type: LEAD_PROPOSAL_GET_FAILURE
})

export const proposalGet = () => (dispatch, getState) => {
  if (getState().lead.proposal.status.completed) {
    return new Promise(resolve => resolve(false))
  }

  dispatch(proposalGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getProposal(token)
        .then(checkHttpResponse)
        .then(parseJSON)
        .then(response => {
          dispatch(proposalGetSuccess(response))

          return true
        })
        .catch(error => {
          dispatch(proposalGetFailure(error))

          return false
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')

      return false
    })
}
