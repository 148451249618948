import {
  Parking,
  MeetingRooms,
  Wifi,
  PublicTransportation,
  DogFriendly,
  ShortTermLease,
  MailPackage,
  BuildingAccess,
  FreePrinting,
  ConferenceRooms,
  PrivatePhone,
  FreeBeer,
  FreeSnacks,
  FitnessCenter,
  FrontDeskReception,
  Showers,
  MothersRoom,
  EventSpace,
  EducationalProgramming,
  Makerspace,
  BikeStorage,
  FreeMultiLocationAccess,
  IconHealthyHeartAmenity,
  IconPdf,
  IconPrivate,
  IconSecure,
  IconFurnished,
  IconfurnitureAvailableToRentOrPurchase,
  IconConferenceRoom,
  IconSixMonthTermAvailable,
  IconTwelveMonthTermAvailable,
  IconCleaningServices,
  IconKitchenInUnit,
  IconMonthToMonthLeaseTerm,
  IconHvac,
  IconPodcasting
} from '../components/icons';

export default {
  MeetingRooms: {
    title: 'Meeting Rooms',
    icon: MeetingRooms,
  },
  ShortTermLeaseOptions: {
    title: 'Short-Term Options',
    icon: ShortTermLease,
  },
  Parking: {
    title: 'Free Parking',
    icon: Parking,
  },
  DogFriendly: {
    title: 'Dog-Friendly',
    icon: DogFriendly,
  },
  BikeStorage: {
    title: 'Bike Storage',
    icon: BikeStorage,
  },
  '1GBWiFi': {
    title: '1 GB+ WiFi',
    icon: Wifi,
  },
  MailPackageHandling: {
    title: 'Mail & Package Handling',
    icon: MailPackage,
  },
  '247BuildingAccess': {
    title: '24/7 Building Access',
    icon: BuildingAccess,
  },
  PublicTransportation: {
    title: '5-Minute Walk from Public Transportation',
    icon: PublicTransportation,
  },
  FreePrinting: {
    title: 'Free Printing',
    icon: FreePrinting,
  },
  HighTechConferenceRooms: {
    title: 'High Tech Conference Rooms',
    icon: ConferenceRooms,
  },
  PrivatePhoneBooths: {
    title: 'Private Phone Booths',
    icon: PrivatePhone,
  },
  FreeBeer: {
    title: 'Free Beer',
    icon: FreeBeer,
  },
  FreeSnacks: {
    title: 'Free Snacks',
    icon: FreeSnacks,
  },
  FitnessCenter: {
    title: 'On-Site Fitness Center',
    icon: FitnessCenter,
  },
  FrontDeskReceiption: {
    title: 'Front Desk Reception',
    icon: FrontDeskReception,
  },
  Showers: {
    title: 'Showers',
    icon: Showers,
  },
  MothersRoom: {
    title: 'Mother’s Room',
    icon: MothersRoom,
  },
  EventSpace: {
    title: 'Event Space',
    icon: EventSpace,
  },
  EducationalProgramming: {
    title: 'Educational Programming',
    icon: EducationalProgramming,
  },
  Makerspace: {
    title: 'Makerspace',
    icon: Makerspace,
  },
  FreeMultiLocationAccess: {
    title: 'Free Multi-Location Access',
    icon: FreeMultiLocationAccess,
  },
  CleaningPolicies: {
    title: 'Cleaning Policies',
    icon: IconPdf,
    link: true,
  },
  Hvac: {
    title: '24/7 HVAC',
    icon: IconHvac,
    size: {
      width: 17
    }
  },
  Podcasting: {
    title: 'Podcasting Booth',
    icon: IconPodcasting,
    size: {
      width: 17
    }
  },
  Safe6Floorplan: {
    title: 'Safe 6 Floorplan',
    icon: IconHealthyHeartAmenity,
    size: {
      width: 17
    }
  },
  Private: {
    title: 'Private',
    icon: IconPrivate,
    isWorkspace: true,
    size: {
      width: 17
    }
  },
  Secure: {
    title: 'Secure',
    icon: IconSecure,
    isWorkspace: true,
    size: {
      width: 17
    }
  },
  CleaningServicesProvided: {
    title: 'Cleaning Services Provided',
    icon: IconCleaningServices,
    isWorkspace: true,
    size: {
      width: 11
    }
  },
  CleaningServicesAvailable: {
    title: 'Cleaning Services Available',
    icon: IconCleaningServices,
    isWorkspace: true,
    size: {
      width: 11
    }
  },
  Furnished: {
    title: 'Furnished',
    icon: IconFurnished,
    isWorkspace: true,
    size: {
      width: 17
    }
  },
  FurnitureAvailableToRentOrPurchase: {
    title: 'Furniture Available to Rent or Purchase',
    icon: IconfurnitureAvailableToRentOrPurchase,
    isWorkspace: true,
    size: {
      width: 17
    }
  },
  PrivateConferenceRooms: {
    title: 'Private Conference Rooms',
    icon: IconConferenceRoom,
    isWorkspace: true,
    size: {
      width: 17
    }
  },
  MonthToMonthAvailable: {
    title: 'Month to Month Available',
    icon: IconMonthToMonthLeaseTerm,
    isWorkspace: true,
    size: {
      width: 17
    }
  },
  '6MonthTermAvailable': {
    title: '6 Month Term Available',
    icon: IconSixMonthTermAvailable,
    isWorkspace: true,
    size: {
      width: 17
    }
  },
  '12MonthTermAvailable': {
    title: '12 Month Term Available',
    icon: IconTwelveMonthTermAvailable,
    isWorkspace: true,
    size: {
      width: 17
    }
  },
  'KitchenInUnit': {
    title: 'Kitchen in Unit',
    icon: IconKitchenInUnit,
    isWorkspace: true,
    size: {
      width: 17
    }
  }
};

export const amenitiesByCategory = [{
  category: 'Accessibility',
  amenities: ['Parking', 'FreeMultiLocationAccess', 'BikeStorage', 'PublicTransportation'],
}, {
  category: 'Facilities',
  amenities: ['CleaningPolicies', 'Safe6Floorplan', 'MeetingRooms', 'ShortTermLeaseOptions', '1GBWiFi', 'MailPackageHandling', '247BuildingAccess', 'FreePrinting', 'HighTechConferenceRooms', 'PrivatePhoneBooths', 'FrontDeskReceiption', 'EventSpace', 'Makerspace', 'Podcasting', 'Hvac'],
}, {
  category: 'Lifestyle',
  amenities: ['FreeBeer', 'FreeSnacks', 'FitnessCenter', 'Showers', 'MothersRoom', 'EducationalProgramming', 'DogFriendly'],
}];

export const amenitiesWorkplaceLookup = {
  wifi: Wifi,
  kitchenInUnit: IconKitchenInUnit,
  private: IconPrivate,
  secure: IconSecure,
  secureEntry: IconSecure,
  cleaningServicesProvided: IconCleaningServices,
  cleaningServicesAvailable: IconCleaningServices,
  furnished: IconFurnished,
  furnitureAvailableToRentOrPurchase: IconfurnitureAvailableToRentOrPurchase,
  privateConferenceRooms: IconConferenceRoom,
  conferenceRooms: IconConferenceRoom,
  monthToMonthLeaseTerm: IconMonthToMonthLeaseTerm,
  monthToMonthTermAvailable: IconMonthToMonthLeaseTerm,
  sixMonthTermAvailable: IconSixMonthTermAvailable,
  twelveMonthTermAvailable: IconTwelveMonthTermAvailable,
};

export const amenitiesWorkplaceMap = {
  wifi: '1GBWiFi',
  private: 'Private',
  secure: 'Secure',
  cleaningServicesProvided: 'CleaningServicesProvided',
  cleaningServicesAvailable: 'CleaningServicesAvailable',
  furnished: 'Furnished',
  furnitureAvailableToRentOrPurchase: 'FurnitureAvailableToRentOrPurchase',
  privateConferenceRooms: 'PrivateConferenceRooms',
  monthToMonthLeaseTerm: 'MonthToMonthAvailable',
  sixMonthTermAvailable: '6MonthTermAvailable',
  twelveMonthTermAvailable: '12MonthTermAvailable',
  kitchenInUnit: 'KitchenInUnit',
}
