import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { push } from 'connected-react-router';
import FilterRemoveList from '../../components/filter-remove-list';
import amenitiesLookup from '../../settings/amenities-lookup';
import teamtypesLookup from '../../settings/teamtypes';
import {
  AMENITIES_ROUTE as amenitiesRoute,
  TEAM_TYPE_ROUTE as teamTypeRoute,
} from '../../settings';
import { changeFilterQuery } from '../../actions/filter-query';

const FilterList = (props) => {
  const { filterQuery } = props;

  const amenities = (filterQuery[amenitiesRoute] && filterQuery[amenitiesRoute].split(',')) || [];
  const teamtypes = (filterQuery[teamTypeRoute] && filterQuery[teamTypeRoute].split(',')) || [];
  const selectedAmenities = amenities.map(amenity =>
    ({ slug: amenity, title: amenitiesLookup[amenity].title }));
  const selectedTeamtypes = teamtypesLookup.filter(teamtype => teamtypes.includes(teamtype.slug));

  return (
    (!isEmpty(amenities) || !isEmpty(teamtypes)) && (
      <FilterRemoveList
        amenities={selectedAmenities}
        teamtypes={selectedTeamtypes}
        onRemoveAmenity={amenity => changeFilterQuery(props, {
          [amenitiesRoute]: !isEmpty(amenity) ? amenity : null,
        })}
        onRemoveTeamType={teamType => changeFilterQuery(props, {
          [teamTypeRoute]: !isEmpty(teamType) ? teamType : null,
        })}
      />
    )
  );
}

const mapStateToProps = state => ({
  filterQuery: state.filterQuery,
});

export default connect(
  mapStateToProps,
  { push },
)(FilterList);
