import { createReducer } from '../utils';
import {
  LOCATION_ACTION_REQUEST,
  LOCATION_ACTION_SET_POSITION,
  LOCATION_ACTION_SET_ERROR,
  LOCATION_SET_NEAREST_MARKET,
  SET_DISTANCE_UNITS,
  SET_CURRENT_ZOOM,
} from '../constants';

const initialState = {
  position: {},
  error: null,
  fetching: false,
  nearestMarket: null,
  coords: null,
  countryCode: 'US',
  distanceUnits: 'mi',
  zoom: null,
};

export default createReducer(initialState, {
  [LOCATION_ACTION_REQUEST]: state => ({
    ...state,
    fetching: true,
  }),
  [LOCATION_ACTION_SET_POSITION]: (state, position) => ({
    ...state,
    position,
    error: null,
    fetching: false,
  }),
  [LOCATION_ACTION_SET_ERROR]: (state, error) => ({
    ...state,
    error,
    fetching: false,
  }),
  [LOCATION_SET_NEAREST_MARKET]: (state, nearestMarket) => ({
    ...state,
    nearestMarket,
  }),
  [SET_DISTANCE_UNITS]: (state, payload) => ({
    ...state,
    distanceUnits: payload.distanceUnits ? payload.distanceUnits : state.distanceUnits,
  }),
  [SET_CURRENT_ZOOM]: (state, payload) => ({
    ...state,
    zoom: payload.zoom,
  }),
});
