import startupsImg from '../assets/startups.jpg';
import salesteamsImg from '../assets/sales-teams.jpg';
import entrepreneursImg from '../assets/entrepreneurs.jpg';
import creativeteamsImg from '../assets/creativeteams.jpg';
import techteamsImg from '../assets/techteams.jpg';
import remoteworkersImg from '../assets/remoteworkers.jpg';
import executivesImg from '../assets/executives.jpg';
import nonprofitImg from '../assets/nonprofit.jpg';
import attorneysImg from '../assets/attorneys.jpg';
import financialservicesImg from '../assets/financialservices.jpg';

// teamTypeAttorneys
// teamTypeCreativeTeams
// teamTypeExecutives
// teamTypeFinancialServices
// teamTypeNonProfit
// teamTypeRemoteWorkers
// teamTypeSalesTeams
// teamTypeSoloEntrepreneurs
// teamTypeStartups
// teamTypeTechTeams

export default [{
  title: 'Startups',
  image: startupsImg,
  slug: 'Startups',
}, {
  title: 'Sales Teams',
  image: salesteamsImg,
  slug: 'SalesTeams',
}, {
  title: 'Solo Entrepreneurs',
  image: entrepreneursImg,
  slug: 'SoloEntrepreneurs',
}, {
  title: 'Creative Teams',
  image: creativeteamsImg,
  slug: 'CreativeTeams',
}, {
  title: 'Tech Teams',
  image: techteamsImg,
  slug: 'TechTeams',
}, {
  title: 'Remote Workers',
  image: remoteworkersImg,
  slug: 'RemoteWorkers',
}, {
  title: 'Executives',
  image: executivesImg,
  slug: 'Executives',
}, {
  title: 'Non-profit Teams',
  image: nonprofitImg,
  slug: 'NonProfit',
}, {
  title: 'Attorneys',
  image: attorneysImg,
  slug: 'Attorneys',
}, {
  title: 'Financial Services Teams',
  image: financialservicesImg,
  slug: 'FinancialServices',
}];
