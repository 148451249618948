import React from 'react';
import { Link } from 'react-router-dom';
import { ROOT, WORKPLACE } from '../../settings/routes';

import AmenitiesList from '../amenities-workplace';

import './styles.scss';

export default (props) => {
  const {
    formattedCardImage,
    marketSlug,
    marketplaceSlug,
    name,
    essenceStatementMapPin,
    onFirstClick,
    isWorkplace,
    amenities
  } = props;

  return (
    <div className="InfoBoxMobile">
      <div className="InfoBoxMobile-wrapper">
        {formattedCardImage && (
          <div className="InfoBoxMobile-image" style={{ backgroundImage: `url("${formattedCardImage}")` }} />
        )}

        <div className="InfoBoxMobile-info">
          <div className="InfoBoxMobile-heading">
            {essenceStatementMapPin || name}
          </div>
          <div className="InfoBoxMobile-bottom">
            <div className="InfoBox-rating">
              {isWorkplace && <AmenitiesList amenities={amenities} />}
            </div>
            {marketplaceSlug && (
              onFirstClick ? (
                <button
                  className="InfoBox-link"
                  onClick={onFirstClick}
                  type="button"
                />
              ) : (
                <Link
                  className="InfoBox-link"
                  to={`/${isWorkplace ? WORKPLACE : ROOT}/${marketSlug}/${marketplaceSlug}/`}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
