import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Header, {
  HeaderPhone,
  HeaderPhoneMobile,
} from '../../components/header';
import Logo from '../../components/logo';
import { Nav, Item, ParentItem, SubItem } from '../../components/main-nav';
import ToggleButton from '../../components/toggle-button';
import { PhoneIcon } from '../../components/icons';

import { BASE_URL as baseUrl } from '../../settings';
import { ROOT, WORKPLACE } from '../../settings/routes';

import { openMobileMainNav, сloseMobileMainNav } from '../../actions/ui';

const toggleMainNav = (dispatch, opened) => {
  if (opened) {
    dispatch(сloseMobileMainNav());
  } else if (!opened && window.innerWidth < 1024) {
    dispatch(openMobileMainNav());
  }
};

const SiteHeader = props => {
  const { fixed, fixedMobile, mobileNavOpened } = props;

  if (window.location.pathname.includes('/embed')) {
    return null;
  }

  return (
    <Header fixed={fixed} fixedMobile={fixedMobile} navOpened={mobileNavOpened}>
      <Logo linkTo={baseUrl} />
      <HeaderPhone>
        Call Upsuite <a href="tel:213.814.5402">213.814.5402</a>
      </HeaderPhone>
      <HeaderPhoneMobile>
        <a href="tel:213.814.5402">
          <PhoneIcon />
        </a>
      </HeaderPhoneMobile>
      <ToggleButton
        active={mobileNavOpened}
        onClick={() => toggleMainNav(props.dispatch, mobileNavOpened)}
        label="Toggle mobile menu"
      />
      <Nav navOpened={mobileNavOpened} showAccount>
        <ParentItem title="Find Space" parent>
          <SubItem>
            <Link to={`/${ROOT}/`}>Coworking Space</Link>
          </SubItem>
          <SubItem>
            <Link to={`/${WORKPLACE}/`}>Private Workspace</Link>
          </SubItem>
          <SubItem>
            <a href={`${baseUrl}/find-virtual-office/`}>
              Find Virtual Office Space
            </a>
          </SubItem>
          <SubItem>
            <a href={`${baseUrl}/find-meeting-space/`}>Find Meeting Space</a>
          </SubItem>
          <SubItem>
            <a href={`${baseUrl}/find-office-furniture/`}>
              Find Office Furniture
            </a>
          </SubItem>
          <SubItem>
            <a href={`${baseUrl}/coworking-members-faq/`}>FAQ</a>
          </SubItem>
        </ParentItem>

        <ParentItem
          title="How To…"
          href={`${baseUrl}/how-upsuite-works-with-teams/`}
          parent
        >
          <SubItem hasList>
            <a
            href={`${baseUrl}/why-work-with-upsuite/`}>Work with Upsuite</a>
            <ul>
              <li>
                <a href={`${baseUrl}/book-coworking-space/`}>
                  Book Coworking Space
                </a>
              </li>
            </ul>
          </SubItem>
          <SubItem hasList>
            <a
              href={`${baseUrl}/your-guide-to-different-types-of-office-space/`}
            >
              Find Office Space by Type
            </a>
            <ul>
              <li>
                <a href={`${baseUrl}/down-to-basics-what-is-coworking-space/`}>
                  Down to Basics: What Is Coworking Space?
                </a>
              </li>
              <li>
                <a href={`${baseUrl}/find-private-office-space-for-rent/`}>
                  Find Private Office Space For Rent
                </a>
              </li>
            </ul>
          </SubItem>
          <SubItem hasList>
            <a href={`${baseUrl}/best-business-office-space-for-your-team/`}>
              Find Office Space by Company Size
            </a>
            <ul>
              <li>
                <a href={`${baseUrl}/flexible-offices-for-small-companies/`}>
                  Find Workspace for Small Business
                </a>
              </li>
              <li>
                <a href={`${baseUrl}/flexible-offices-midsized-companies/`}>
                  Find Workspace for Midsized Companies
                </a>
              </li>
              <li>
                <a
                  href={`${baseUrl}/private-workspace-for-enterprise-companies/`}
                >
                  Find Workspace for Enterprise Companies
                </a>
              </li>
              <li>
                <a href={`${baseUrl}/startup-office-space-for-your-team/`}>
                  Startup Office Space For Your Team
                </a>
              </li>
            </ul>
          </SubItem>
          <SubItem hasList>
            <a
              href={`${baseUrl}/what-is-there-to-know-about-office-space-agreements/`}
            >
              Read Office Space Agreements
            </a>
            <ul>
              <li>
                <a
                  href={`${baseUrl}/all-you-need-to-know-about-coworking-space-agreements/`}
                >
                  All You Need to Know About Coworking Space Agreements
                </a>
              </li>
            </ul>
          </SubItem>
          <SubItem hasList>
            <a href={`${baseUrl}/what-is-the-average-coworking-space-cost/`}>
              Understand Office Space Cost
            </a>
            <ul>
              <li>
                <a
                  href={`${baseUrl}/introducing-office-space-cost-calculator/`}
                >
                  Introducing: Office Space Cost Calculator
                </a>
              </li>
            </ul>
          </SubItem>
          <SubItem>
            <a href={`${baseUrl}/how-to-work-from-home-properly/`}>
              Work From Home
            </a>
          </SubItem>
        </ParentItem>
        <ParentItem
          title="List Space"
          href={`${baseUrl}/list-your-coworking-space-with-upsuite/`}
          parent
        >
          <SubItem>
            <a href={`${baseUrl}/verified-coworking-operator/`}>Get Verified</a>
          </SubItem>
          <SubItem>
            <a href={`${baseUrl}/list-your-space/`}>Register Your Space</a>
          </SubItem>
          <SubItem>
            <a href={`${baseUrl}/coworking-marketplace/`}>
              Upsuite Marketplace For Operators
            </a>
          </SubItem>
          <SubItem>
            <a
              href={`${baseUrl}/flex-office-pro-coworking-trends-market-data-and-insights-platform/`}
            >
              Flex Office Pro Market Data and Insights Platform
            </a>
          </SubItem>
          <SubItem>
            <a href={`${baseUrl}/coworking-marketing/`}>
              Coworking Marketing, Sales, and Consulting Services
            </a>
          </SubItem>
          <SubItem>
            <a
              href={`${baseUrl}/list-your-coworking-space-with-upsuite/upsuite-healthy-spaces/`}
            >
              Upsuite Healthy Spaces
            </a>
          </SubItem>
          <SubItem>
            <a href={`${baseUrl}/coworking-operator-faq/`}>FAQ</a>
          </SubItem>
        </ParentItem>
        <Item>
          <a href={`${baseUrl}/blog/`}>Blog</a>
        </Item>
      </Nav>
    </Header>
  );
};

const mapStateToProps = state => ({
  fixed: state.ui.headerFixed,
  fixedMobile: state.ui.headerFixedMobile,
  mobileNavOpened: state.ui.mobileNavOpened,
});

export default connect(mapStateToProps)(SiteHeader);
