import React, { Component } from 'react';
import copy from 'copy-to-clipboard';

import { Copy as CopyIcon } from '../icons';

import './styles.scss';

export default class CopyLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copy: false,
    };
  }

  copyHandler() {
    this.setState({
      copy: true,
    });

    copy(this.props.link);
  }

  render() {
    return (
      <div className="copyLink-wrap">
        <button
          onClick={() => this.copyHandler()}
          type="button"
          className="share-link button button-scheme-link -copy"
        >
          <CopyIcon />
          {!this.state.copy && <span>Copy link</span>}
          {this.state.copy && <span>Copied</span>}
        </button>
        {this.state.copy &&
          <input
            className="copyLink-input"
            readOnly
            type="text"
            value={this.props.link}
            autoFocus
            onFocus={e => e.target.select()}
          />
        }
      </div>
    );
  }
}

