import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.scss';

const VisualSlider = (props) => {
  const {
    minValue, maxValue, label: { min, max }, value,
  } = props;

  const valuePosition = ((maxValue - minValue) / 100) * value;
  return (
    <div className={classnames('VisualSlider', { '-moreThenHalf': valuePosition > 50 })}>
      <span className="VisualSlider-label -min">{min}</span>
      <div className="VisualSlider-slider">
        <div className="VisualSlider-pointer" style={{ left: `${valuePosition}%` }} />
      </div>
      <span className="VisualSlider-label -max">{max}</span>
    </div>
  );
};

VisualSlider.propTypes = {
  label: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  value: PropTypes.number,
};

VisualSlider.defaultProps = {
  minValue: 0,
  maxValue: 100,
  value: 0,
  label: {
    min: 'Min',
    max: 'Max',
  },
};

export { VisualSlider as default };
