import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Wrapper, Top, Bottom, TopLeft, TopRight, BottomRight, BottomLeft, BlogHeading } from '../../components/search-page';
import AdvisorCard from '../../components/advisor-card';
import { selectMarketsWithCoords } from '../../selectors/markets';
import { ROOT } from '../../settings/routes';
import { BASE_URL } from '../../settings';

const SearchPage = () => {
  const marketsByName = useSelector(state => state.markets.byName);
  const fixtures = useSelector(state => selectMarketsWithCoords(state));

  return (
    <Wrapper>
      <Top>
        <TopLeft>
          <h1>Find the Best Coworking and Flexible Office Spaces</h1>
          <p>Upsuite. The easiest way to office.</p>
          <p>
            Upsuite offers coworking and flexible offices that are conveniently
            located, meet your budget, and on your terms.
          </p>
          <p>
            Unlike doing time-consuming Google searches and tours yourself,
            Upsuite helps you find and secure an office in 90% less time, and
            save 15 to 50% by providing 99% of the market inventory, actual
            suite availability and pricing, and a helpful Coworking Advisor.
          </p>
          <p>
            Also, Upsuite is taking the lead in identifying spaces that are
            practicing physical distancing, and employing approved cleaning
            policies to keep members healthy during the Coronavirus pandemic.
          </p>
        </TopLeft>
        <TopRight>
          <h3>Work with a Coworking Advisor</h3>
          <AdvisorCard markets={marketsByName} nearestMarket="Atlanta" searchPage />
        </TopRight>
      </Top>
      <Bottom>
        {fixtures && (
          <BottomLeft>
            <ul>
              {fixtures.map(market => (
                <li key={market.id} className={market.isSubmarket ? 'isSubmarket' : ''}>
                  <Link to={`/${ROOT}/${market.slug}/`}>
                    {market.label}
                  </Link>
                </li>
              ))}
            </ul>
          </BottomLeft>
        )}
        <BottomRight>
          <BlogHeading>
            <h3>Upblog:</h3>
            <span>Coworking Space, Flexible Offices and the Future of Work</span>
          </BlogHeading>

          <a href={`${BASE_URL}/blog`}>Visit Upsuite Blog Now</a>
        </BottomRight>
      </Bottom>
    </Wrapper>
  );
}

export default SearchPage;
