import isEmpty from 'lodash/isEmpty';
import { checkHttpStatus, parseJSON } from '../utils';
import {
  USER_PERMISSIONS_CHECK_REQUEST,
  USER_PERMISSIONS_CHECK_SUCCESS,
  USER_PERMISSIONS_CHECK_FAILURE,
  OPERATOR_LOCATIONS_REQUEST,
  OPERATOR_LOCATIONS_SUCCESS,
  OPERATOR_LOCATIONS_FAILURE,
} from '../constants';
import * as api from '../api/users';

const checkPermissionsRequest = () => ({
  type: USER_PERMISSIONS_CHECK_REQUEST,
});

const checkPermissionsSuccess = payload => ({
  type: USER_PERMISSIONS_CHECK_SUCCESS,
  payload,
});

const checkPermissionsFailure = () => ({
  type: USER_PERMISSIONS_CHECK_FAILURE,
});

export const checkPermissions = (token, id) =>
  (dispatch) => {
    dispatch(checkPermissionsRequest());

    return api.checkPermissions(token, id)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(checkPermissionsSuccess(id));

        return response;
      })
      .catch(() => {
        dispatch(checkPermissionsFailure());
      });
  };

const operatorLocationsRequest = () => ({
  type: OPERATOR_LOCATIONS_REQUEST,
});

const operatorLocationsSuccess = payload => ({
  type: OPERATOR_LOCATIONS_SUCCESS,
  payload,
});

const operatorLocationsFailure = () => ({
  type: OPERATOR_LOCATIONS_FAILURE,
});

export const operatorLocationsGet = token =>
  (dispatch, getState) => {
    if (!isEmpty(getState().userPermissions.operatorLocations)) {
      return null;
    }

    dispatch(operatorLocationsRequest());

    return api.operatorLocationsGet(token)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(operatorLocationsSuccess(response));
      })
      .catch(() => {
        dispatch(operatorLocationsFailure());
      });
  };
