import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { renderField, slideToggleField } from '../form-fields';
import Spinner from '../spinner';

import { Col, Row, Form, FormFooter, Error } from './styles';
import Button from '../button';

const UserEditForm = ({ handleSubmit, onClose, errorMessage, updating }) => (
  <Form onSubmit={handleSubmit}>
    <Row>
      <Col colWidth={4} />
      <Col colWidth={30}>
        <Field
          component={renderField}
          name="username"
          id="username"
          label="Username"
          type="text"
          disabled
          userEditForm
        />
      </Col>
      <Col colWidth={23}>
        <Field
          component={renderField}
          name="firstName"
          id="firstName"
          label="First Name"
          type="text"
          userEditForm
        />
      </Col>
      <Col colWidth={23}>
        <Field
          component={renderField}
          name="lastName"
          id="lastName"
          label="Last Name"
          type="text"
          userEditForm
        />
      </Col>
      <Col colWidth={10}>
        <Field
          component={slideToggleField}
          name="isAdmin"
          id="isAdmin"
          label="Admin"
        />
      </Col>
      <Col colWidth={10}>
        <Field
          component={slideToggleField}
          name="isOperator"
          id="isOperator"
          label="Operator"
        />
      </Col>
    </Row>

    <Row>
      <Col colWidth={4} />
      <Col colWidth={20}>
        <Field
          component={slideToggleField}
          name="isListingsManager"
          id="isListingsManager"
          label="Listings Manager"
        />
      </Col>
      <Col colWidth={20}>
        <Field
          component={slideToggleField}
          name="isFlexOfficeProAdmin"
          id="isFlexOfficeProAdmin"
          label="Flex Office Pro Administer"
        />
      </Col>
      <Col colWidth={20}>
        <Field
          component={slideToggleField}
          name="isSalesLead"
          id="isSalesLead"
          label="Sales Lead"
        />
      </Col>
      <Col colWidth={20}>
        <Field
          component={slideToggleField}
          name="isSalesPerson"
          id="isSalesPerson"
          label="Sales Person"
        />
      </Col>
    </Row>

    <FormFooter>
      {errorMessage && <Error>{errorMessage}</Error>}
      {updating && <Spinner size="small" right />}
      <Button onClick={onClose} scheme="link-underline">
        Cancel
      </Button>
      <Button type="submit" scheme="link-underline">
        Save
      </Button>
    </FormFooter>
  </Form>
);

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  form: 'userEdit',
})(UserEditForm);
