import React from 'react'
import classnames from 'classnames'

import { Logo as LogoIcon } from '../icons'
import logoImg from '../../assets/logo.png'

import './styles.scss'

const Logo = props => {
  const { size, className, linkTo = '/', isImage, alt } = props

  const classes = classnames(className, 'Logo', `-${size}`, isImage && '_image')

  return (
    <div className={classes}>
      <a href={linkTo}>
        {isImage ? (
          <img src={logoImg} alt={alt} />
        ) : (
          <LogoIcon />
        )}
      </a>
    </div>
  )
}

Logo.defaultProps = {
  size: 'medium',
  className: '',
  isImage: false
}

export default Logo
