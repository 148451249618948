import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.scss';

const SingleCtaMobile = (props) => {
  const {
    className,
    children,
    visible,
  } = props;

  const classes = classnames(
    className,
    'SingleCtaMobile',
    {
      '-visible': visible,
    },
  );

  return (
    <div
      className={classes}
    >{children}</div>
  );
};

SingleCtaMobile.propTypes = {
  className: PropTypes.string,
};

SingleCtaMobile.defaultProps = {
  className: null,
};

export default SingleCtaMobile;
