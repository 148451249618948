import React from 'react'
import { Field, reduxForm } from 'redux-form'
import styled from 'styled-components'

import Button from '../button'
import { renderField } from '../form-fields'
import { required, email } from '../../utils/lead-validation'
import Flex from '../../components/flex'

import './styles.scss'

const LoginForm = props => {
  const { handleSubmit } = props

  return (
    <form id="login-form-main" className="RegisterForm" onSubmit={handleSubmit}>
      <>
        <Field
          component={renderField}
          name="email"
          id="email"
          type="text"
          placeholder=""
          label="Email"
          required
          validate={[required, email]}
        />
        <Field
          component={renderField}
          name="password"
          id="password"
          type="password"
          placeholder=""
          label="Password"
          required
          validate={required}
        />
        <Flex between middle>
          <SubmitButton type="submit">Sign In</SubmitButton>
        </Flex>
      </>
    </form>
  )
}

const SubmitButton = styled(Button)`
  box-shadow: none !important;
  width: 100% !important;
  max-width: 279px !important;
  height: 42px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
  margin-left: auto !important;
  margin-right: auto !important;
`

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'login-form'
})(LoginForm)
