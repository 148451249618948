import React, { useState } from 'react';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import classnames from 'classnames';

import ListingCard from '../listing-card';

const ListingCardObservable = props => {
  const [isIntersecting, setIntersecting] = useState(false);

  const handleIntersection = event => {
    if (event.isIntersecting) {
      setIntersecting(true);

      if (props.intersectionAction) {
        props.intersectionAction();
      }
    }
  };

  const wrapClasses = classnames('ListingCard-wrap', {
    '-dynamicCols': props.fullWidthWrap,
    '-slide': props.isSlide
  });

  return (
    <Observer onChange={handleIntersection} disabled={isIntersecting}>
      <div className={wrapClasses}>
        <ListingCard
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          observable
          isVisible={isIntersecting}
        />
      </div>
    </Observer>
  );
};

export default ListingCardObservable;
