import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import { createReducer } from '../utils';
import {
  SPACES_BY_LOC_ID_GET_REQUEST,
  SPACES_BY_LOC_ID_GET_SUCCESS,
  SPACES_BY_LOC_ID_GET_FAILURE,
  SPACES_GET_REQUEST,
  SPACES_GET_SUCCESS,
  SPACES_GET_FAILURE,
  SPACES_CREATE_REQUEST,
  SPACES_CREATE_SUCCESS,
  SPACES_CREATE_FAILURE,
  SPACES_UPDATE_REQUEST,
  SPACES_UPDATE_SUCCESS,
  SPACES_UPDATE_FAILURE,
  SPACES_DELETE_REQUEST,
  SPACES_DELETE_SUCCESS,
  SPACES_DELETE_FAILURE,
  SPACES_CLEAR_ERROR_MESSAGE,
  SPACES_PROPOSAL_GET_SUCCESS
} from '../constants';

const initialState = {
  byLocationId: {},
  list: null,
  loading: false,
  error: false,
  creating: false,
  updating: false,
  deleting: false,
  errorMessage: null,
  byId: []
};

export default createReducer(initialState, {
  [SPACES_BY_LOC_ID_GET_REQUEST]: state => ({
    ...state,
    loading: true,
    error: false,
  }),
  [SPACES_BY_LOC_ID_GET_SUCCESS]: (state, payload) => ({
    ...state,
    byLocationId: {
      ...state.byLocationId,
      ...groupBy(payload, 'locationId'),
    },
    loading: false,
    error: false,
  }),
  [SPACES_BY_LOC_ID_GET_FAILURE]: state => ({
    ...state,
    loading: false,
    error: true,
  }),
  [SPACES_GET_REQUEST]: state => ({
    ...state,
    loading: true,
    error: false,
  }),
  [SPACES_GET_SUCCESS]: (state, payload) => ({
    ...state,
    list: payload,
    loading: false,
    error: false,
  }),
  [SPACES_GET_FAILURE]: state => ({
    ...state,
    list: null,
    loading: false,
    error: true,
  }),
  [SPACES_CREATE_REQUEST]: state => ({
    ...state,
    creating: true,
  }),
  [SPACES_CREATE_SUCCESS]: (state, payload) => ({
    ...state,
    creating: false,
    byLocationId: {
      ...state.byLocationId,
      [payload.locationId]: [...state.byLocationId[payload.locationId], payload],
    },
  }),
  [SPACES_CREATE_FAILURE]: (state, payload) => ({
    ...state,
    creating: false,
    error: true,
    errorMessage: payload,
  }),
  [SPACES_UPDATE_REQUEST]: state => ({
    ...state,
    updating: true,
  }),
  [SPACES_UPDATE_SUCCESS]: (state, payload) => ({
    ...state,
    updating: false,
    byLocationId: {
      ...state.byLocationId,
      [payload.locationId]:
        state.byLocationId[payload.locationId].map(item => (
          payload.id === item.id ? payload : item
        )),
    },
  }),
  [SPACES_UPDATE_FAILURE]: (state, payload) => ({
    ...state,
    updating: false,
    errorMessage: payload,
  }),
  [SPACES_DELETE_REQUEST]: state => ({
    ...state,
    deleting: true,
  }),
  [SPACES_DELETE_SUCCESS]: (state, payload) => ({
    ...state,
    byLocationId: {
      ...state.byLocationId,
      [payload.locationId]:
        state.byLocationId[payload.locationId].filter(item => item.id !== payload.spaceId),
    },
    deleting: false,
  }),
  [SPACES_DELETE_FAILURE]: state => ({
    ...state,
    deleting: false,
  }),
  [SPACES_CLEAR_ERROR_MESSAGE]: state => ({
    ...state,
    errorMessage: null,
  }),
  [SPACES_PROPOSAL_GET_SUCCESS]: (state, payload) => ({
    ...state,
    byId: keyBy(payload, 'id')
  })
});
