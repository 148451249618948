import React from 'react';
import { useDispatch } from 'react-redux';

import ButtonGroup from '../../components/button-group';
import Button from '../../components/button';
import { ModalBody } from '../../components/modal';
import { InteractiveTourWrapper } from '../../components/interactive-tour';
import { openCtaActionsModal, openIframeModal } from '../../actions/modal';
import { HeaderPhone } from '../../components/header';

const InteractiveTour = ({ tourUrl, isFastOffice, initialValues }) => {
  const dispatch = useDispatch();

  return (
    <ModalBody large>
      <InteractiveTourWrapper isFastOffice={isFastOffice}>
        <HeaderPhone>
          Call Upsuite <a href="tel:213.814.5402">213.814.5402</a>
        </HeaderPhone>
        <iframe title="Interactive Tour" src={tourUrl} />
        {!isFastOffice && (
          <ButtonGroup justify="right" fullWidth column={false} gutter="nono" center={false}>
            <Button
              spaceBelow="none"
              onClick={() => {
                dispatch(
                  openIframeModal({
                    src: 'https://meetings.hubspot.com/hailey-barnett1',
                    title: 'Book a Guided Virtual Tour',
                    width: '900px',
                    height: '815px',
                  }),
                );
              }}
              className="schedule-a-tour-button"
              style={{ textTransform: 'unset', marginRight: 15 }}
            >
              Book a Guided Virtual Tour
            </Button>
            <Button
              spaceBelow="none"
              onClick={() =>
                dispatch(
                  openCtaActionsModal({
                    heading: 'Visit This Space',
                    buttonName: 'Visit This Space',
                    fields: [
                      'firstName',
                      'email',
                      'seats',
                      'phoneNumber',
                      'preferredTourDate',
                      'preferredTourTime',
                      'moveinDate',
                    ],
                    initialValues: {
                      locations: initialValues?.locations,
                      visitorId: initialValues?.visitorId,
                      isWorkspace: initialValues?.isWorkspace,
                      form: 'visit-this-space',
                    },
                    successText:
                      'Thanks for booking your tour through Upsuite! We will be in touch shortly!',
                  }),
                )
              }
              className="schedule-a-tour-button"
              style={{ textTransform: 'unset' }}
            >
              Visit This Space
            </Button>
          </ButtonGroup>
        )}
      </InteractiveTourWrapper>
    </ModalBody>
  );
};

export default InteractiveTour;
