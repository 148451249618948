import { ROOT, HEALTHY, CLOSED_LOC, NEW_LOC, BEST, AVERAGE } from './routes';

export const options = [
  { label: 'All Locations', value: `/${ROOT}/` },
  { label: 'Healthy Spaces', value: `/${ROOT}/${HEALTHY}/` },
  { label: 'Locations Closed', value: `/${ROOT}/${CLOSED_LOC}/` },
  { label: 'New Locations', value: `/${ROOT}/${NEW_LOC}/` },
  { label: 'Best Coworking Locations', value: `/${ROOT}/${BEST}/` },
  { label: 'Average Cost Per Seat', value: `/${ROOT}/${AVERAGE}/` },
];
