import React from 'react';

import './styles.scss';

import { Container } from '../../components/wrapper';

export default ({ children }) => (
  <div className="bottomNav">
    <Container topShift={false} style={{ maxWidth: 1260 }}>
      {children}
    </Container>
  </div>
);
