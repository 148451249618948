import React from 'react';
import { Link } from 'react-router-dom';

import SocialIcon from '../../components/social-icon';
import Logo from '../../components/logo';
import BottomNav from '../../components/bottom-nav';
import TextBox from '../../components/text-box';
import { Desktop, TabletDown } from '../../components/responsive-helpers';
import { Nav, Item } from '../../components/main-nav';
import { Cols, Col } from '../../components/wrapper';

import {
  TERMS_OF_SERVICE_ROUTE as termsOfService,
  PRIVACY_POLICY_ROUTE as privacyPolicy,
  BASE_URL as baseUrl,
  FLEX_MARKETPLACE,
} from '../../settings';

import { ROOT } from '../../settings/routes';

const BottomNavigation = () => (
  <BottomNav>
    <Cols verticalCenter columnTablet>
      <Col column>
        <Logo linkTo={baseUrl} />
        <TabletDown>
          <TextBox spaceBelow="small" center>
            1900 Dartmouth Avenue{<br />} Boulder, CO 80305
          </TextBox>
        </TabletDown>
        <Desktop>
          <TextBox spaceBelow="small">
            1900 Dartmouth Avenue{<br />} Boulder, CO 80305
          </TextBox>
        </Desktop>
      </Col>
      <Desktop>
        <Col>
          <Link to={`/${ROOT}/`}>Site Index</Link>
          <a href={`${baseUrl}/${termsOfService}/`}>Terms of Service</a>
          <a href={`${baseUrl}/${privacyPolicy}/`}>Privacy Policy</a>
          <a href={`${baseUrl}/broker-registration/`}>Broker Program</a>
          <a href={`${baseUrl}/about-us/`}>About Us</a>
          <a href={`${baseUrl}/contact-us/`}>Contact Us</a>
          <a href={FLEX_MARKETPLACE}>Operator Sign In</a>
        </Col>
      </Desktop>
      <Col>
        <SocialIcon network="twitter" link="https://twitter.com/upsuite" />
        <SocialIcon
          network="facebook"
          link="https://www.facebook.com/upsuite.coworking"
        />
        <SocialIcon
          network="instagram"
          link="https://www.instagram.com/Upsuite_Coworking/"
        />
        <SocialIcon
          network="linkedin"
          link="https://www.linkedin.com/company/upsuite/"
        />
      </Col>
      <TabletDown>
        <Col fullWidthMobile fullWidthTablet>
          <div className="MobileNav">
            <Nav navOpened>
              <Item>
                <Link to={`/${ROOT}/`}>Site Index</Link>
              </Item>
              <Item>
                <a href={`${baseUrl}/${termsOfService}/`}>Terms of Service</a>
              </Item>
              <Item>
                <a href={`${baseUrl}/${privacyPolicy}/`}>Privacy Policy</a>
              </Item>
              <Item>
                <a href={`${baseUrl}/broker-registration/`}>Broker Program</a>
              </Item>
              <Item>
                <a href={`${baseUrl}/about-us/`}>About Us</a>
              </Item>
              <Item>
                <a href={`${baseUrl}/contact-us/`}>Contact Us</a>
              </Item>
              <Item>
                <a href={FLEX_MARKETPLACE}>Operator Sign In</a>
              </Item>
            </Nav>
          </div>
        </Col>
      </TabletDown>
    </Cols>
  </BottomNav>
);

export default BottomNavigation;
