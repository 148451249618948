import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './react-select.scss';
import './styles.scss';


const Select = (props) => {
  const { scheme, className, ...rest } = props;

  const classes = classNames(
    className,
    `-scheme-${scheme}`,
  );

  return <ReactSelect className={classes} {...rest} />;
};

Select.propTypes = {
  className: PropTypes.string,
  scheme: PropTypes.string,
};

Select.defaultProps = {
  className: null,
  scheme: 'default',
};

export default Select;
