import React from 'react';
import closedBg from '../../assets/image-example-min.jpg';
import './styles.scss';

const LocClosedHero = ({ name }) => {
  return (
    <div
      className="LocClosed-wrap"
      style={{ backgroundImage: `linear-gradient(rgba(0,0,0,0.35),rgba(0,0,0,0.35)), url(${closedBg})` }}
    >
      <div className="LocClosed-text -top">
        <h1>Upsuite helps teams engage anywhere.</h1>
      </div>
      <div className="LocClosed-text -bottom">
        <p>But you can&apos;t engage here.</p>
        <p>{name} has closed.</p>
      </div>
    </div>
  );
};

export default LocClosedHero;
