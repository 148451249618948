import React from 'react';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';

import Datepicker from '../datepicker';

import { ArrowDown, ChevronDown, Calendar } from '../icons';

import './styles.scss';

export const renderField = (props) => {
  const {
    input,
    id,
    label,
    hideLabelMobile,
    type,
    placeholder,
    spacesEditForm,
    userEditForm,
    inlineLabel,
    meta: { touched, error },
    half,
    required,
    disabled,
  } = props;
  const baseClass = 'FormField';

  const wrapClasses = classNames(baseClass, {
    '-spacesEditForm': spacesEditForm,
    '-userEditForm': userEditForm,
    '-half': half,
    '-inlineLabel': inlineLabel,
  });

  const inputClasses = classNames(
    `${baseClass}-input`,
  );

  const labelClasses = classNames(
    `${baseClass}-label`, {
      '-hideMobile': hideLabelMobile,
      '-required': required,
    },
  );

  const validationClasses = classNames(
    `${baseClass}-validation`,
  );

  return (
    <div className={wrapClasses}>
      <label
        className={labelClasses}
        htmlFor={id}
      >
        <span>{label}</span>
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...input}
          id={id}
          placeholder={placeholder}
          type={type}
          className={inputClasses}
          disabled={disabled}
        />
      </label>
      {touched && error &&
        <div className={validationClasses}>
          <span>{error}</span>
        </div>
      }
    </div>
  );
};

export const slideToggleField = (props) => {
  const {
    input,
    id,
    label,
  } = props;

  const baseClass = 'FormField';

  const wrapClasses = classNames(
    baseClass,
    '-spacesEditForm',
  );

  const labelClasses = classNames(
    `${baseClass}-label`,
    '-toggle',
  );

  return (
    <div className={wrapClasses}>
      <span>{label}</span>
      <label
        className={labelClasses}
        htmlFor={id}
      >
        <input
          {...input}
          type="checkbox"
          checked={input.value}
          id={id}
        />
        <div className="slide-toggle" />
      </label>
    </div>
  );
};

export const selectField = (props) => {
  const {
    input,
    id,
    label,
    options,
    arrow,
    half,
    placeholder,
    inlineLabel,
  } = props;

  const baseClass = 'FormField';

  const wrapClasses = classNames(
    baseClass,
    '-spacesEditForm',
    {
      '-half': half,
      '-inlineLabel': inlineLabel,
    },
  );

  const labelClasses = classNames(
    `${baseClass}-label`,
  );

  return (
    <div className={wrapClasses}>
      <label
        className={labelClasses}
        htmlFor={id}
      >
        <span>{label}{arrow && <ArrowDown />}</span>
        <ReactSelect
          type="select"
          id={id}
          {...input}
          arrowRenderer={() => <ChevronDown />}
          options={options}
          clearable={false}
          simpleValue
          searchable={false}
          placeholder={placeholder}
        />
      </label>
    </div>
  );
};

export const selectBaseField = (props) => {
  const {
    input,
    id,
    label,
    options,
    arrow,
    placeholder,
  } = props;

  const baseClass = 'FormField';

  const wrapClasses = classNames(
    baseClass,
    '-base'
  );

  const labelClasses = classNames(
    `${baseClass}-label`,
  );

  return (
    <div className={wrapClasses}>
      <label
        className={labelClasses}
        htmlFor={id}
      >
        <span>{label}{arrow && <ArrowDown />}</span>
        <ReactSelect
          type="select"
          id={id}
          {...input}
          arrowRenderer={() => <ChevronDown />}
          options={options}
          clearable={false}
          simpleValue
          searchable={false}
          placeholder={placeholder}
        />
      </label>
    </div>
  );
};

export const datapickerField = (props) => {
  const {
    input,
    id,
    label,
    half,
    inlineLabel,
    disabled,
  } = props;

  const baseClass = 'FormField';

  const wrapClasses = classNames(
    baseClass,
    '-spacesEditForm', {
      '-half': half,
      '-inlineLabel': inlineLabel,
      '-disabled': disabled,
    },
  );

  const placeholder = isEmpty(input.value) || input.value === '0000-00-00' ? '_' : format(input.value, 'MMMM DD, YYYY');

  const labelClasses = classNames(
    `${baseClass}-label`,
    '-datepicker',
  );

  return (
    <div className={wrapClasses}>
      <label
        className={labelClasses}
        htmlFor={id}
      >
        <span>{label}</span>
        {disabled &&
          <Datepicker
            {...input}
            placeholder={placeholder}
            disabled
          />
        }
        {!disabled &&
          <Datepicker
            {...input}
            placeholder={placeholder}
          />
        }
        <Calendar className="FormField-calendar" />
      </label>
    </div>
  );
};

export const dateField = (props) => {
  const {
    input,
    id,
    label,
    required
  } = props;

  const baseClass = 'FormField';

  const labelClasses = classNames(
    `${baseClass}-label`,
    '-datepicker',
    { '-required': required }
  );

  return (
    <div className="FormField -formDate">
      <label
        className={labelClasses}
        htmlFor={id}
      >
        <span>{label}</span>
        <Datepicker
          {...input}
        />
      </label>
    </div>
  );
};
