import React, { useEffect, useState, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';

import './styles.scss';

const DropDown = forwardRef((props, ref) => {
  const { title, highlight, disabled, className, children, arrow, onClose, onOpen } = props;
  const [isActive, setIsActive] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef && !wrapperRef?.current?.contains(event.target)) {
        if (isActive) {
          onClose();
        }

        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeDropdown = () => {
    onClose();
    setIsActive(false);
  };

  useImperativeHandle(ref, () => {
    return {
      closeDropdown
    }
  });

  const toggleActive = useCallback(() => {
    if (isActive) {
      onClose();
    } else {
      onOpen();
    }

    setIsActive(disabled ? false : !isActive);
  }, [isActive, onClose, onOpen, disabled]);

  const classes = classNames(
    'DropDown',
    {
      '-active': isActive,
      '-disabled': disabled,
      '-highlight': highlight,
    },
    className,
  );

  return (
    <div className={classes} ref={wrapperRef}>
      <button className="DropDown-control" type="button" onClick={toggleActive}>
        <span className="DropDown-value">{title}</span>
        {arrow && (
          <div className="DropDown-arrow-zone">
            <div className="DropDown-arrow" />
          </div>
        )}
      </button>
      <div className="DropDown-helper" />
      <div className="DropDown-dropdown">{children}</div>
    </div>
  );
});

DropDown.defaultProps = {
  disabled: false,
  highlight: false,
  arrow: false,
  onClose: () => {},
  onOpen: () => {},
};

export default DropDown;
