export const getRadius = slug => {
  const bySlug = {
    soho: {
      minradius: 500,
      maxradius: 20000,
    },
    'dallas-fort-worth': {
      minradius: 60000,
      maxradius: 100000,
    },
    'san-francisco': {
      minradius: 20000,
      maxradius: 50000,
    },
    'san-francisco-bay-area': {
      minradius: 20000,
      maxradius: 50000,
    },
    miami: {
      minradius: 50000,
      maxradius: 70000,
    },
    phoenix: {
      minradius: 50000,
      maxradius: 70000,
    },
    'raleigh-durham': {
      minradius: 30000,
      maxradius: 50000,
    },
    'salt-lake-city': {
      minradius: 50000,
      maxradius: 70000,
    },
    philadelphia: {
      minradius: 50000,
      maxradius: 70000,
    },
    'san-diego': {
      minradius: 50000,
      maxradius: 70000,
    },
    tampa: {
      minradius: 50000,
      maxradius: 70000,
    },
    seattle: {
      minradius: 35000,
      maxradius: 70000,
    },
  };

  return (
    bySlug[slug] || {
      minradius: 10000,
      maxradius: 20000,
    }
  );
};
