import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { UpsuiteLogo, Star } from '../icons';

import './styles.scss';

const Verified = ({ size, newLocation }) => (
  <div className={classnames('Verified', `-size-${size}`)}>
    {newLocation ? (
      <div className={classnames('Verified-newIcon', `-size-${size}`)}>
        <Star size={size} />
        <span>New</span>
      </div>
    ) : (
      <div className="Verified-icon">
        <UpsuiteLogo />
      </div>
    )}
    <div className="Verified-label">
      <span>Verified</span>
    </div>
  </div>
);

Verified.propTypes = {
  size: PropTypes.string,
  newLocation: PropTypes.bool,
};

Verified.defaultProps = {
  size: 'default',
  newLocation: false,
};


export default Verified;
