import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import round from 'lodash/round';
import GoogleMapReact from 'google-map-react';
import { CostMarkerMainMap } from '../../components/cluster-marker';

import marketsGet from '../../actions/markets';
import { getMarketAverageCost } from '../../actions/main-map';
import {
  MAPS_API_KEY as mapsKey,
} from '../../settings';

import {
  createMainMapOptions,
} from '../../utils/map-helpers';
import { ROOT } from '../../settings/routes';

const Map = (props) => {
  const dispatch = useDispatch();
  const markets = useSelector(state => state.mainMap.averageCost);

  useEffect(() => {
    dispatch(getMarketAverageCost());
    dispatch(marketsGet());
  }, [dispatch]);

  return (
    <div style={props.style} className="MainMap">
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapsKey }}
        defaultCenter={{ lat: 41.08013, lng: -98.20684 }}
        defaultZoom={props.zoom}
        options={createMainMapOptions}
      >
        {markets && markets.map(market => (
          <CostMarkerMainMap
            key={market.id}
            href={market.market_slug && `/${ROOT}/${market.market_slug}/`}
            lat={market.center_lat}
            lng={market.center_lon}
            cost={market.price && round(market.price)}
            zIndex={market.price && round(market.price)}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
