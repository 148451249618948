import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import supercluster from 'points-cluster';

import { ClusterMarkerLanding as Cluster } from '../../components/cluster-marker';

import {
  MAPS_API_KEY as mapsKey,
} from '../../settings';

import {
  createMainMapOptions, getMapBounds,
} from '../../utils/map-helpers';
import { getLocByMarket } from '../../actions/seo';

const Map = (props) => {
  const dispatch = useDispatch();
  const { marketSlug } = props;
  const [clusters, setClusters] = useState(null);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const locations = useSelector(state => state.seo.locByMarketSlug[marketSlug]);

  useEffect(() => {
    dispatch(getLocByMarket(marketSlug, false))
  }, [dispatch, marketSlug])

  useEffect(() => {
    if (map && maps && locations) {
      const bounds = new maps.LatLngBounds();

      locations.filter(item => item.latitude && item.longitude).forEach(({ latitude, longitude }) => {
        const latLng = new maps.LatLng(latitude, longitude);
        bounds.extend(latLng);
      });

      map.fitBounds(bounds);
      const center = map.getCenter();
      const zoom = map.getZoom();

      const clusterSettings = {
        minZoom: 0,
        maxZoom: 15,
        radius: 30,
      };

      const mapClusters = supercluster(
        locations
          .filter(item => item.latitude && item.longitude)
          .map(item => ({
            lat: item.latitude,
            lng: item.longitude,
            id: item.id,
          })),
        clusterSettings,
      )({
        center,
        zoom,
        bounds: getMapBounds(map),
      });

      setClusters(mapClusters.map(({
        wx, wy, numPoints, points,
      }) => ({
        lat: wy,
        lng: wx,
        numPoints,
        id: `${points[0].id}`,
        points,
      })));
    }
  }, [map, maps, locations]);

  return (
    <div style={props.style} className="MainMap">
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapsKey }}
        defaultCenter={{ lat: 41.08013, lng: -98.20684 }}
        defaultZoom={3}
        options={createMainMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={(api) => {
          setMap(api.map);
          setMaps(api.maps);
        }}
      >
        {clusters && clusters.map((cluster) => (
          <Cluster
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...cluster}
            key={cluster.id}
            href={`/coworking/${marketSlug}`}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
