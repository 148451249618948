import { LOCATION_CHANGE } from 'connected-react-router';
import { createReducer } from '../utils';
import {
  FIXED_HEADER_ON,
  FIXED_HEADER_OFF,
  HIGHLIGHT_LISTING,
  HIGHLIGHT_LISTING_RESET,
  OPEN_MOBILE_MAIN_NAV,
  CLOSE_MOBILE_MAIN_NAV,
  HIGHLIGHT_MARKER,
  HIGHLIGHT_MARKER_RESET,
  PIN_MARKER,
  PIN_MARKER_RESET,
  COMPARE_MOBILE_PAGINATE,
  COMPARE_SPACE_BY_LISTING,
  RESET_TRANSITION_COUNT,
  BIND_SCROLL_TO,
  CHANGE_SORT_METHOD,
  MOBILE_FIXED_HEADER_OFF,
  MOBILE_FIXED_HEADER_ON,
  SET_COMPARE_CARDS_HEIGHT,
  TOGGLE_FEED_MAP_VISIBILITY,
  TOGGLE_SPACE_TYPE,
  TOGGLE_SPACE_TYPE_RESET
} from '../constants';

const initialState = {
  headerFixed: false,
  headerFixedMobile: true,
  highlightListingId: null,
  mobileNavOpened: false,
  highlightMarkerId: null,
  pinMarkerId: null,
  compareMobilePaginate: 0,
  spaceByListingId: {},
  transitionCount: -1,
  scroll: null,
  sortMethod: 'recomended',
  compareCardsHeight: 0,
  isFeedMapVisible: true,
  spaceTypeWorkspace: false
};

export default createReducer(initialState, {
  [FIXED_HEADER_ON]: state => ({
    ...state,
    headerFixed: true,
  }),
  [FIXED_HEADER_OFF]: state => ({
    ...state,
    headerFixed: false,
  }),
  [MOBILE_FIXED_HEADER_ON]: state => ({
    ...state,
    headerFixedMobile: true,
  }),
  [MOBILE_FIXED_HEADER_OFF]: state => ({
    ...state,
    headerFixedMobile: false,
  }),
  [HIGHLIGHT_LISTING]: (state, payload) => ({
    ...state,
    highlightListingId: payload,
  }),
  [HIGHLIGHT_LISTING_RESET]: state => ({
    ...state,
    highlightListingId: null,
  }),
  [HIGHLIGHT_MARKER]: (state, payload) => ({
    ...state,
    highlightMarkerId: payload,
  }),
  [HIGHLIGHT_MARKER_RESET]: state => ({
    ...state,
    highlightMarkerId: null,
  }),
  [PIN_MARKER]: (state, payload) => ({
    ...state,
    pinMarkerId: payload,
  }),
  [PIN_MARKER_RESET]: state => ({
    ...state,
    pinMarkerId: null,
  }),
  [OPEN_MOBILE_MAIN_NAV]: state => ({
    ...state,
    mobileNavOpened: true,
  }),
  [CLOSE_MOBILE_MAIN_NAV]: state => ({
    ...state,
    mobileNavOpened: false,
  }),
  [COMPARE_MOBILE_PAGINATE]: (state, payload) => ({
    ...state,
    compareMobilePaginate: payload,
  }),
  [COMPARE_SPACE_BY_LISTING]: (state, payload) => ({
    ...state,
    spaceByListingId: {
      ...state.spaceByListingId,
      [payload.listingId]: payload.index,
    },
  }),
  [LOCATION_CHANGE]: state => ({
    ...state,
    transitionCount: state.transitionCount + 1,
  }),
  [RESET_TRANSITION_COUNT]: state => ({
    ...state,
    transitionCount: 0,
  }),
  [BIND_SCROLL_TO]: (state, payload) => ({
    ...state,
    scroll: payload,
  }),
  [CHANGE_SORT_METHOD]: (state, payload) => ({
    ...state,
    sortMethod: payload,
  }),
  [SET_COMPARE_CARDS_HEIGHT]: (state, payload) => ({
    ...state,
    compareCardsHeight: payload,
  }),
  [TOGGLE_FEED_MAP_VISIBILITY]: (state, payload) => ({
    ...state,
    isFeedMapVisible: payload,
  }),
  [TOGGLE_SPACE_TYPE]: state => ({
    ...state,
    spaceTypeWorkspace: !state.spaceTypeWorkspace,
  }),
  [TOGGLE_SPACE_TYPE_RESET]: state => ({
    ...state,
    spaceTypeWorkspace: false,
  }),
});
