import React from 'react';
import { ShareAlt } from '../icons';

import './styles.scss';

export default ({ children, email, subject, body }) => {
  let url = 'mailto:';
  url = email ? `${url}${email}?` : `${url}?`;
  url = subject ? `${url}subject=${subject}&` : '';
  url = body ? `${url}body=${body}` : '';

  return (
    <a
      className="MailTo button"
      href={url}
    >
      {<ShareAlt />}
      {children}
    </a>
  );
};
