import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import supercluster from 'points-cluster';

import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Marker from '../../components/location-marker';
import Cluster from '../../components/cluster-marker';
import { MAPS_API_KEY as mapsKey, SEARCH_QUERY } from '../../settings';
import { ROOT, WORKPLACE } from '../../settings/routes';
import Wrapper from '../../components/filter-map-helpers';
import InfoBox from '../../components/info-box';

import { syncVisibleMarkersWithListings, listingsGetByCoords, listingsGetByAddress } from '../../actions/listings';
import { bindMapToState } from '../../actions/reviews';
import toggleFavorites from '../../actions/favorites';
import { setDistanceUnits, setCurrentZoom, setNearestMarket } from '../../actions/location';
import { openRegisterModal, openVerifyPassModal } from '../../actions/modal';
import { selectLatLonByLastRequest } from '../../selectors/listings';
import { selectHighlightId } from '../../selectors/ui';
import { selectNearestMarket } from '../../selectors/filter-query';
import { urlFilterQuery } from '../../utils/filter-url';
import { imageUrl } from '../../utils/crm-links';
import {
  createMapOptions,
  handleApiLoaded,
  getLayerType,
  getBoundsOnChange,
  getMapBounds,
  getVisibleMarkerIds,
  getPoints,
} from '../../utils/map-helpers';
import { getCoordsByLabel } from '../../utils/coords-by-label';
import { getNearestMarket } from '../../utils/markets';
import { getAllWorkplaceAmenities } from '../../utils/amenities';

class Markers extends Component {
  constructor() {
    super();
    this.state = {
      maptype: 'roadmap',
      markerHovered: undefined,
      clusters: [],
    };
  }

  componentDidUpdate(prevProps) {
    const {
      map, maps, filterQuery, listings, markets,
    } = this.props;
    const queryLabel = filterQuery[ROOT] || filterQuery[SEARCH_QUERY];
    const prevQueryLabel = prevProps.filterQuery[ROOT] || prevProps.filterQuery[SEARCH_QUERY];

    if (map) {
      if (queryLabel !== prevQueryLabel) {
        getCoordsByLabel(this.props, (list) => {
          const bounds = new maps.LatLngBounds();

          list.forEach(({ latitude, longitude }) => {
            const latLng = new maps.LatLng(latitude, longitude);
            bounds.extend(latLng);
          });
          map.fitBounds(bounds);
          this.props.setCurrentZoom({ zoom: map.getZoom() });
          this.markersVisibilityChanged();
        });
      } else if (urlFilterQuery(filterQuery) !== urlFilterQuery(prevProps.filterQuery)) {
        const center = map.getCenter();

        this.props.listingsGetByCoords({ lat: center.lat(), long: center.lng() })
          .then(() => this.markersVisibilityChanged());
      }

      if (map.getCenter() && markets) {
        this.props.setNearestMarket(getNearestMarket({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }, markets));
      }
    }

    if (!isEqual(prevProps.listings, listings)) {
      this.markersVisibilityChanged();
    }
  }

  onMapTypeChangeHandler = (type) => {
    this.setState({ maptype: type });
  }

  getMarker = (listing, index) => {
    const {
      listings, highlightMarkerId, favorites,
      userIsAuthenticated, isCrawler,
      filterQuery: { people, is_workplace: isWorkplace }, visitorId, isCognitoAuth
    } = this.props;

    const { marketSlug, marketplaceSlug } = listing;
    const { markerHighlight, markerHovered } = this.state;
    const markerProps = !(isCognitoAuth || isCrawler || window.sessionStorage.getItem('registerSession') === 'true')
      ? {
        onClick: () => {
          if (userIsAuthenticated && !isCognitoAuth) {
            this.props.openVerifyPassModal()
          } else {
            this.props.openRegisterModal({
              heading: 'For specific suite pricing, availability, reviews, and more.',
              closeOnSubmit: true,
              initialValues: { seats: people, locations: [listing.id], isWorkspace: [!!isWorkplace] },
              onSubmit: () => this.props.push(`/${isWorkplace ? WORKPLACE : ROOT}/${marketSlug}/${marketplaceSlug}`),
              form: 'map-pin-registration',
              visitorId,
              seatsRequired: true,
              isWorkspace: !!isWorkplace
            })
          }
        },
      }
      : { listingUrl: `/${isWorkplace ? WORKPLACE : ROOT}/${listing.marketSlug}/${listing.marketplaceSlug}` };

    return (
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <Marker
        key={`marker-${listing.id}`}
        zIndex={markerHovered === listing.id
          || markerHighlight === listing.id
          ? listings.length : listings.length - index}
        lat={listing.latitude}
        lng={listing.longitude}
        onMouseOver={() => {
          this.markerHovered(listing.id);
        }}
        onMouseOut={() => this.setState({
          markerHovered: null,
        })}
        isFavorite={favorites.includes(listing.id)}
        hover={markerHovered === listing.id}
        highlight={listing.id === highlightMarkerId}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...markerProps}
      >
        <InfoBox
          name={listing.name}
          essenceStatementMapPin={isWorkplace ? listing.essenceStatementMapPinWorkspace : listing.essenceStatementMapPin}
          marketplaceSlug={listing.marketplaceSlug}
          marketName={listing.marketName}
          marketSlug={listing.marketSlug}
          accolades={listing.accolades}
          formattedCardImage={imageUrl(listing.listingCardImageId)}
          toggleFavorites={() => this.props.toggleFavorites(listing.id, isWorkplace)}
          isFavorite={favorites.includes(listing.id)}
          verified={listing.upsuiteVerified}
          newLocation={listing.newLocation}
          isWorkplace={isWorkplace}
          onFirstClick={!(isCognitoAuth || isCrawler|| window.sessionStorage.getItem('registerSession') === 'true') ? () => {
            if (userIsAuthenticated && !isCognitoAuth) {
              this.props.openVerifyPassModal()
            } else {
              this.props.openRegisterModal({
                heading: 'For specific suite pricing, availability, reviews, and more.',
                closeOnSubmit: true,
                initialValues: { seats: people, locations: [listing.id], isWorkspace: [!!isWorkplace] },
                onSubmit: () => this.props.push(`/${isWorkplace ? WORKPLACE : ROOT}/${marketSlug}/${marketplaceSlug}/`),
                form: 'map-pin-registration',
                visitorId,
                seatsRequired: true,
                isWorkspace: !!isWorkplace
              })
            }
          } : null}
          amenities={getAllWorkplaceAmenities(listing)}
        />
      </Marker>
    );
  }

  setNewLocationCoords = (map) => {
    const newCenter = map.getCenter();
    this.props.setCurrentZoom({ zoom: map.getZoom() });

    this.props.listingsGetByCoords({ lat: newCenter.lat(), long: newCenter.lng() })
      .then(() => this.markersVisibilityChanged());
  }

  setNewZoom = (map) => {
    if (map.getZoom() < this.props.currentZoom) {
      const center = map.getCenter();
      this.props.listingsGetByCoords({ lat: center.lat(), long: center.lng() })
        .then(() => this.markersVisibilityChanged());
    } else {
      this.markersVisibilityChanged();
    }
    this.props.setCurrentZoom({ zoom: map.getZoom() });
  }

  handleMapLoaded = ({ map, maps }) => {
    const {
      center, zoom, route, lastRoute, listings
    } = this.props;
    handleApiLoaded(map, maps, this.props.bindMapToState);

    map.addListener('dragend', () => {
      this.setNewLocationCoords(map);
    });
    maps.event.addListenerOnce(map, 'mousemove', () => map.addListener('zoom_changed', () => this.setNewZoom(map))); // do event only if user change zoom, not fitBounds

    const filterProps = this.props.filterQuery.coworking === "san-francisco-bay-area" // Fix for San Francisco bug
      ? {
        ...this.props,
        filterQuery: { ...this.props.filterQuery, coworking: 'san-francisco' }
      }
      : this.props;

    if (route !== lastRoute) {
      getCoordsByLabel(filterProps, (list) => {
        const bounds = new maps.LatLngBounds();

        list.forEach(({ latitude, longitude }) => {
          const latLng = new maps.LatLng(latitude, longitude);
          bounds.extend(latLng);
        });
        map.fitBounds(bounds);
        this.props.setCurrentZoom({ zoom: map.getZoom() });
        this.markersVisibilityChanged();
      });
    } else {
      (() => {
        const bounds = new maps.LatLngBounds();

        listings.forEach(({ latitude, longitude }) => {
          const latLng = new maps.LatLng(latitude, longitude);
          bounds.extend(latLng);
        });
        map.fitBounds(bounds);
        this.props.setCurrentZoom({ zoom: map.getZoom() });
        this.markersVisibilityChanged();
      })()
    }

    if (center && route === lastRoute) {
      map.setCenter(center);
      map.setZoom(zoom);
    }
  }

  markerHovered = (id) => {
    this.setState({ markerHovered: id });
  }

  markersVisibilityChanged = () => {
    const { listings, maps, map } = this.props;

    if (maps) {
      this.createClusters();
      const ids = getVisibleMarkerIds(listings, maps, map);
      this.props.syncVisibleMarkersWithListings(ids);
    }
  }

  clusterClickHandler = (cluster) => {
    const { map, maps } = this.props;
    map.fitBounds(getBoundsOnChange(maps, cluster.points));
    this.markersVisibilityChanged();
  }

  createClusters() {
    const { listings, map } = this.props;

    if (map) {
      const center = map.getCenter();
      const zoom = map.getZoom();
      const bounds = getMapBounds(map);

      const clusterSettings = {
        minZoom: 0,
        maxZoom: 15,
        radius: 30,
      };

      const clusters = supercluster(getPoints(listings), clusterSettings)({
        center,
        zoom,
        bounds,
      });

      this.setState({
        clusters: clusters.map(({
          wx, wy, numPoints, points,
        }) => ({
          lat: wy,
          lng: wx,
          numPoints,
          id: `${points[0].id}`,
          points,
        })),
      });
    }
  }

  render() {
    const { listingsById, highlightMarkerId } = this.props;
    const { clusters, maptype } = this.state;

    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapsKey }}
        defaultCenter={{ lat: 38, lng: -91 }}
        zoom={4}
        options={createMapOptions}
        layerTypes={getLayerType(maptype)}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={this.handleMapLoaded}
        onMapTypeIdChange={this.onMapTypeChangeHandler}
      >
        {clusters.map((cluster, index) =>
          cluster.numPoints === 1 ? (
            this.getMarker(listingsById[cluster.points[0].id], index)
          ) : (
            <Cluster
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...cluster}
              key={cluster.id}
              onClick={() => this.clusterClickHandler(cluster)}
              highlight={some(cluster.points, ['id', highlightMarkerId])}
            />
          ),
        )}
      </GoogleMapReact>
    );
  }
}

const mapStateToProps = state => ({
  listings: selectLatLonByLastRequest(state),
  listingsById: state.listings.byId,
  loading: state.listings.loading,
  filterQuery: state.filterQuery,
  favorites: state.filterQuery.is_workplace ? state.favorites.workplaceIds : state.favorites.ids,
  greviews: state.reviews.greviewsById,
  highlightMarkerId: selectHighlightId(state),
  center: state.listings.center,
  zoom: state.listings.zoom,
  bounds: state.listings.bounds,
  lastRoute: state.listings.lastRoute,
  map: state.reviews.map,
  maps: state.reviews.maps,
  route: state.router.location.pathname,
  currentZoom: state.location.zoom,
  distanceUnits: state.location.distanceUnits,
  market: selectNearestMarket(state),
  userIsAuthenticated: state.auth.userIsAuthenticated,
  isCrawler: state.auth.isCrawler,
  visitorId: state.auth.visitorId,
  markets: state.markets.list,
  isCognitoAuth: state.cognito.token
});

const Map = connect(
  mapStateToProps, {
    bindMapToState,
    syncVisibleMarkersWithListings,
    toggleFavorites,
    push,
    setCurrentZoom,
    setDistanceUnits,
    listingsGetByCoords,
    listingsGetByAddress,
    openRegisterModal,
    setNearestMarket,
    openVerifyPassModal
  },
)(Markers);

const FilterMap = ({ withSearchBar, isNotVisible, seoPage }) => (
  <Wrapper withSearchBar={withSearchBar} isNotVisible={isNotVisible} seoPage={seoPage}>
    <Map />
  </Wrapper>
);

export default FilterMap
