/* global window */
import { checkHttpStatus, parseJSON } from '../utils';
import {
  LOCATION_ACTION_REQUEST,
  LOCATION_ACTION_SET_POSITION,
  LOCATION_ACTION_SET_ERROR,
  LOCATION_SET_NEAREST_MARKET,
  SET_DISTANCE_UNITS,
  SET_CURRENT_ZOOM,
} from '../constants';
import * as api from '../api/location';

export const getNavigatorGeolocation = options => (dispatch, getState) => {
  if (getState().location.position) {
    return null;
  }

  dispatch({ type: LOCATION_ACTION_REQUEST });

  return window.navigator.geolocation.getCurrentPosition(
    (position) => {
      dispatch({
        type: LOCATION_ACTION_SET_POSITION,
        payload: position,
      });
    },
    error => dispatch({
      type: LOCATION_ACTION_SET_ERROR,
      payload: error,
    }),
    options,
  );
};

export const ipLookUp = () => (dispatch, getState) => {
  if (getState().location.position.lat && getState().location.position.lon) {
    return null;
  }

  dispatch({ type: LOCATION_ACTION_REQUEST });

  return api.ipLookUp()
    .then(checkHttpStatus)
    .then(parseJSON)
    .then((response) => {
      dispatch({
        type: LOCATION_ACTION_SET_POSITION,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: LOCATION_ACTION_SET_ERROR,
        payload: error,
      });
    });
};

export const ipLookUpInfo = () => (dispatch, getState) => {
  if (getState().location.position.loc) {
    return null;
  }

  dispatch({ type: LOCATION_ACTION_REQUEST });

  return api.ipInfoGet()
    .then(checkHttpStatus)
    .then(parseJSON)
    .then((response) => {
      dispatch({
        type: LOCATION_ACTION_SET_POSITION,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: LOCATION_ACTION_SET_ERROR,
        payload: error,
      });
    });
};

export const setNearestMarket = payload => dispatch => dispatch({
  type: LOCATION_SET_NEAREST_MARKET,
  payload,
});

export const setDistanceUnits = payload => dispatch => dispatch({
  type: SET_DISTANCE_UNITS,
  payload,
});

export const setCurrentZoom = payload => dispatch => dispatch({
  type: SET_CURRENT_ZOOM,
  payload,
});
