import React from 'react'
import { useSelector } from 'react-redux'
import SpacesList from '../SpacesList'
import ProfileMenu from './menu'
import { Wrap } from './styles'
import { selectSavedSpaceMarkets } from '../../selectors/listings'

const Spaces = () => {
  const { ids } = useSelector(state => state.favorites)
  const lead = useSelector(state => state.lead)
  const markets = useSelector(state => selectSavedSpaceMarkets(state, { ids }))
  const inMarkets = `${markets.length ? ` in ${markets.join(', ')}` : ''}`

  return (
    <Wrap>
      <ProfileMenu />
      <SpacesList
        ids={ids}
        showSaveBtn
        placeholder={`Shortlist of spaces${inMarkets}`}
        title={`Saved Spaces${
          lead.firstName || lead.lastName
            ? ` for ${lead.firstName || ''} ${lead.lastName || ''}`
            : ''
        }${inMarkets}`}
        allowAdding
        isShortlist
      />
    </Wrap>
  )
}

export default Spaces
