import React from 'react';
// import random from 'lodash/random';
// import RegisterMain from './register-main';
import RegisterOption1 from './register-option-1';
// import RegisterOption2 from './register-option-2';

const Register = (props) => {
  // const forms = [RegisterMain, RegisterOption1, RegisterOption2]
  // const RegisterForm = forms[random(0, 2)]

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <RegisterOption1 {...props} />
}

export default Register
