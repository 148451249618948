import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { CompareColumn } from '../../components/compare-page-helpers';
import CompareListingCard from '../../components/compare-listing-card';

import { openRegisterModal, openInteractiveTourModal, openVerifyPassModal } from '../../actions/modal';

import toggleFavorites from '../../actions/favorites';
import { compareMobilePaginate } from '../../actions/ui';

import { trackVirtualTourModal } from '../../utils/analytics';
import { WORKPLACE } from '../../settings/routes';

const ListingCards = (props) => {
  const {
    listings, paginate,
    userIsAuthenticated, isCrawler, visitorId, isCognitoAuth
  } = props;
  const listing = paginate >= 0 && (paginate <= (listings.length - 1)) ? listings[paginate] : {};

  return (
    !isEmpty(listing) && (
      <CompareColumn className="-mobile">
        <CompareListingCard
          removeFavorites={() => props.toggleFavorites(listing.id)}
          key={listing.id}
          mobile
          onVirtualTourClick={url => {
            trackVirtualTourModal(
              `/${WORKPLACE}/${listing.marketName}/${listing.marketplaceSlug}/`,
            );
            props.openInteractiveTourModal({
              tourUrl: url,
              initialValues: {
                locations: [listing.id],
                isWorkspace: [true],
                visitorId,
              },
            });
          }}
          onFirstClick={!(isCognitoAuth || isCrawler) ? () => {
            if (userIsAuthenticated && !isCognitoAuth) {
              props.openVerifyPassModal()
            } else {
              props.openRegisterModal({
                heading:
                  'We have pricing and avialibality for this location!',
                closeOnSubmit: true,
                initialValues: {
                  locations: [listing.id],
                  isWorkspace: [true],
                },
                isWorkspace: true,
                form: 'compare-card-registration',
                visitorId,
              })
            }
          } : null}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...listing}
          isWorkspace
        />
      </CompareColumn>
    )
  );
};

const mapStateToProps = state => ({
  spaces: state.spaces.byLocationId,
  greviews: state.reviews.greviewsById,
  map: state.reviews.map,
  paginate: state.ui.compareMobilePaginate,
  markets: state.markets.byName,
  userIsAuthenticated: state.auth.userIsAuthenticated,
  isCrawler: state.auth.isCrawler,
  visitorId: state.auth.visitorId,
  isCognitoAuth: state.cognito.token
});

export default connect(
  mapStateToProps, {
    toggleFavorites,
    compareMobilePaginate,
    openRegisterModal,
    openInteractiveTourModal,
    openVerifyPassModal
  },
)(ListingCards);
