import { SHOW_MODAL, HIDE_MODAL, SHOW_MODAL_BELOW } from '../constants'
import { bodyClassAdd, bodyClassRemove } from '../utils/ui'
import * as api from '../api/users'
import { leadCreatingFailureReset } from './auth'
import { trackFormsByType } from '../utils/analytics'
import { ROOT, WORKPLACE } from '../settings/routes'

export const showModal = payload => {
  bodyClassAdd('modalEnabled')

  return {
    type: SHOW_MODAL,
    payload
  }
}

export const showModalBelow = payload => {
  bodyClassAdd('modalEnabled')

  return {
    type: SHOW_MODAL_BELOW,
    payload
  }
}

export const hideModal = () => {
  bodyClassRemove('modalEnabled')

  return {
    type: HIDE_MODAL
  }
}

export const openShareModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'SHARE',
      modalProps: {
        canClose: true,
        showClose: true,
        small: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openCompareTourRequestModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'COMPARE_TOUR_REQUEST',
      modalProps: {
        canClose: true,
        medium: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openExportListModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'EXPORT_LIST',
      modalProps: {
        canClose: true,
        medium: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openCtaActionsModal = options => dispatch => {
  const {
    initialValues: { form, visitorId },
    isWorkspace
  } = options
  trackFormsByType(isWorkspace ? WORKPLACE : ROOT, form)

  api.registerModalOpen({ form, visitorId })

  dispatch(
    showModal({
      modalType: 'CTA_ACTIONS',
      modalProps: {
        canClose: true,
        medium: true
      },
      modalOptions: {
        ...options
      }
    })
  )

  dispatch(leadCreatingFailureReset())
}

export const openEmailRequestModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'EMAIL_REQUEST',
      modalProps: {
        canClose: true,
        medium: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openWorkHereModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'WORK_HERE',
      modalProps: {
        canClose: true,
        medium: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openSpacesEditFormModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'SPACES_EDIT_FORM',
      modalProps: {
        canClose: true,
        showClose: true,
        small: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openSpacesDeleteModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'SPACES_DELETE_MODAL',
      modalProps: {
        canClose: true,
        small: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openHelpFindModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'HELP_FIND',
      modalProps: {
        canClose: true,
        medium: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openBookMeetingModal = options => dispatch => {
  const { form, isWorkspace } = options
  trackFormsByType(isWorkspace ? WORKPLACE : ROOT, form)

  dispatch(
    showModal({
      modalType: 'ADVISOR_BOOK_MEETING',
      modalProps: {
        canClose: true,
        showClose: true,
        medium: true
      },
      modalOptions: {
        ...options
      }
    })
  )
}

export const openRegisterModal = options => dispatch => {
  const { form, visitorId } = options
  api.registerModalOpen({ form, visitorId })

  return dispatch(
    showModal({
      modalType: 'REGISTER_MODAL',
      modalProps: {
        canClose: true,
        medium: true
      },
      modalOptions: {
        ...options
      }
    })
  )
}

export const openSaveShortlistModal = options => dispatch => {
  return dispatch(
    showModal({
      modalType: 'SAVE_SHORTLIST',
      modalProps: {
        canClose: true,
        medium: true
      },
      modalOptions: {
        ...options
      }
    })
  )
}

export const openRegisterUserModal = options => dispatch => {
  const { form, visitorId } = options
  api.registerModalOpen({ form, visitorId })

  return dispatch(
    showModal({
      modalType: 'REGISTER_USER',
      modalProps: {
        canClose: true,
        medium: true
      },
      modalOptions: {
        ...options
      }
    })
  )
}

export const openFindSpaceModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'FIND_SPACE',
      modalProps: {
        canClose: true,
        small: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openLeadRegisterModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'LEAD_REGISTER',
      modalProps: {
        canClose: true,
        showClose: true,
        medium: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openInteractiveTourModal = options => dispatch =>
  dispatch(
    showModalBelow({
      modalType: 'INTERACTIVE_TOUR',
      modalProps: {
        canClose: true,
        showClose: true,
        large: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openIframeModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'IFRAME',
      modalProps: {
        canClose: true,
        showClose: true,
        large: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openSliderModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'SLIDER',
      modalProps: {
        canClose: true,
        showClose: true,
        large: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openClosestLocationsModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'CLOSEST_LOCATIONS',
      modalProps: {
        canClose: true,
        showClose: true,
        large: true,
        maxHeightOff: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openVerifyPassModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'VERIFY_PASS',
      modalProps: {
        canClose: true,
        medium: true
      },
      modalOptions: {
        ...options
      }
    })
  )
