import React from 'react'
import { Link } from 'react-router-dom'

import ComparePanel from '../../components/compare-panel'

import { ROOT } from '../../settings/routes'

import image from '../../assets/card-preview.png'

const EmptyPanel = () => (
  <ComparePanel image={image}>
    <span>
      To get the best out of your Upsuite experience, click “Find Space”, select
      the add to shortlist icon, and come back to view locations side-by-side.{' '}
    </span>
    <Link to={`/${ROOT}/`}>Find Space</Link>
  </ComparePanel>
)

export default EmptyPanel
