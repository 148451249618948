import React from 'react';
import classnames from 'classnames';

import './styles.scss';

export const ListingsFeedWrap = ({ seoPage, children, fullWidth }) => (
  <div className={classnames('ListingsFeed-wrap', { '-fullWidth': fullWidth, '-asSection': seoPage })}>
    {children}
  </div>
);

export const ListingsFeedList = (props) => {
  const {
    children,
    withSearchBar,
    className,
    fullWidth,
    seoPage
  } = props;

  const classes = classnames(
    'ListingsFeed-list',
    className,
    { '-withSearchBar': withSearchBar },
    { '-fullWidth': fullWidth },
    { '-asSection': seoPage },
  );

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export const ListingsFeedRow = (props) => {
  const {
    children,
    className,
  } = props;

  const classes = classnames(
    'ListingsFeed-row',
    className,
  );

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export const ListingsFeedBoxed = (props) => {
  const {
    children,
    className,
  } = props;

  const classes = classnames(
    'ListingsFeed-boxed',
    className,
  );

  return (
    <div className={classes}>
      {children}
    </div>
  );
};
